import React, {useContext, useEffect, useState} from "react";
import {
  Container,
  Grid,
  Header,
  Icon,
  Label,
  Menu,
  Table,
} from "semantic-ui-react";
import MonthYearSelector from "../../../components/base/MonthYearSelector";
import { Month } from "../../../base/enums/Month";
import { Formatter } from "../../../base/util/Formatter";
import SimpleLoader from "../../../components/SimpleLoader";
import { FilterContext, FilterValue } from "../../../contexts/FilterContext";
import Translate from "../../../components/translation/Translate";
import {ExpenseTypeUtil} from "../../../base/util/ExpenseTypeUtil";
import {Auth, AuthContext} from "../../../contexts/AuthContext";
import {ExpenseSummaryDto, ExpenseType, useGetExpenseSummary} from '../../../api/generated-sources'
import moment from "moment";

interface SummaryViewState {
  data?: ExpenseSummaryDto[];
  activeUser: string;
}

export default function SummaryView() {

  const auth = useContext<Auth>(AuthContext);
  const { month, year } = useContext<FilterValue>(FilterContext);
  const [state, setState] = useState<SummaryViewState>({
    activeUser: auth.name.split(" ")[1],
  });

  const onDataLoad = (data: ExpenseSummaryDto[]) => {
    setState({
      ...state,
      data,
    });
  };

  const {data, isLoading, error} = useGetExpenseSummary({
    month: month,
    year: year,
  });

  useEffect(() => {
    if (data) {
      onDataLoad(data)
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error]);

  const handleActiveUserClick = (e: any, { name }: any) => {
    setState({ ...state, activeUser: name });
  };

  const displayLoader = isLoading || !state.data;

  if (displayLoader) {
    return (
      <Container>
        <Grid divided="vertically">
          <Grid.Row>
            <Grid.Column width={16}>
              <Header>
                Měsíční shrnutí -{" "}
                <Translate label={Month.ALL[month - 1].translation} /> {year}
              </Header>
            </Grid.Column>
          </Grid.Row>
          <MonthYearSelector />
          {displayLoader && <SimpleLoader text={"Načítám měsíční souhrn"} />}
        </Grid>
      </Container>
    );
  }

  const activeUserData = state.data?.find(
    (summary) => summary.owner.fullname.split(" ")[1] === state.activeUser
  );

  return (
    <Container>
      <Grid divided="vertically">
        <Grid.Row>
          <Grid.Column width={16}>
            <Header>
              Měsíční shrnutí -{" "}
              <Translate label={Month.ALL[month - 1].translation} /> {year}
            </Header>
          </Grid.Column>
        </Grid.Row>
        <MonthYearSelector />
        {state.data && (
          <Grid.Row>
            <Grid.Column width={16}>
              <Header>Shrnutí</Header>
              <Table basic striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      <Label color={"black"} ribbon>
                        <Translate label={Month.ALL[month - 1].translation} />{" "}
                        {year}
                      </Label>
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"}>
                      <Icon name={"shopping cart"} /> Nákupy
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"}>
                      <Icon name={"dollar sign"} /> Výplaty
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"}>
                      <Icon name={"gavel"} /> Náklady
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"}>
                      <Icon name={"money"} /> Půjčky
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"}>
                      Celkem
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {state.data.map((summary) => {
                    return (
                      <Table.Row key={summary.owner.id}>
                        <Table.Cell>{summary.owner.fullname}</Table.Cell>
                        <Table.Cell textAlign={"right"}>
                          {Formatter.money(
                            summary.expenses
                              .filter(
                                (expense) =>
                                  expense.expenseType === ExpenseType.PURCHASE
                              )
                              .flatMap((expense) => expense.amount)
                              .reduce((c, d) => c + d, 0)
                          )}
                        </Table.Cell>
                        <Table.Cell textAlign={"right"}>
                          {Formatter.money(
                            summary.expenses
                              .filter(
                                (expense) =>
                                  expense.expenseType === ExpenseType.PAYMENT
                              )
                              .flatMap((payment) => payment.amount)
                              .reduce((c, d) => c + d, 0)
                          )}
                        </Table.Cell>
                        <Table.Cell textAlign={"right"}>
                          {Formatter.money(
                            summary.expenses
                              .filter(
                                (expense) =>
                                  expense.expenseType ===
                                  ExpenseType.EXPENDITURE
                              )
                              .flatMap((expense) => expense.amount)
                              .reduce((c, d) => c + d, 0)
                          )}
                        </Table.Cell>
                        <Table.Cell textAlign={"right"}>
                          {Formatter.money(
                            summary.expenses
                              .filter(
                                (expense) =>
                                  expense.expenseType === ExpenseType.LOAN
                              )
                              .flatMap((expense) => expense.amount)
                              .reduce((c, d) => c + d, 0)
                          )}
                        </Table.Cell>
                        <Table.Cell textAlign={"right"}>
                          <strong>
                            {Formatter.money(summary.expensesTotal)}
                          </strong>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell />
                    {
                      [ExpenseType.PURCHASE, ExpenseType.PAYMENT, ExpenseType.EXPENDITURE, ExpenseType.LOAN].map(expenseType => {
                      return <Table.HeaderCell textAlign={"right"}>
                        <strong>
                          {Formatter.money(
                            state.data
                              .flatMap((summary) => {
                                return summary.expenses
                                  .filter(
                                    (expense) =>
                                      expense.expenseType === expenseType
                                  )
                                  .flatMap((expense) => expense.amount)
                                  .reduce((c, d) => c + d, 0);
                              })
                              .reduce((a, b) => a + b, 0)
                          )}
                        </strong>
                      </Table.HeaderCell>
                      })
                    }
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Grid.Column>
          </Grid.Row>
        )}
        {state.data && (
          <Grid.Row>
            <Grid className={"summary-detail"}>
              <Grid.Column width={4}>
                <Header>Detailní rozpis</Header>
                <Menu pointing secondary vertical>
                  {state.data &&
                    state.data.map((summary, i) => {
                      return (
                        <Menu.Item
                          key={i}
                          content={
                            summary.owner.fullname.split(" ")[1] +
                            " " +
                            summary.owner.fullname.split(" ")[0]
                          }
                          name={summary.owner.fullname.split(" ")[1]}
                          active={
                            state.activeUser ===
                            summary.owner.fullname.split(" ")[1]
                          }
                          onClick={handleActiveUserClick}
                        />
                      );
                    })}
                </Menu>
              </Grid.Column>
              <Grid.Column stretched width={12}>
                {state.data && activeUserData && (
                  <Grid>
                    {
                      [ExpenseType.PURCHASE, ExpenseType.PAYMENT, ExpenseType.EXPENDITURE, ExpenseType.LOAN].map(expenseType => {
                        const expenseTypeName = ExpenseTypeUtil.getText(expenseType).text;
                        const expenseTypeIcon = ExpenseTypeUtil.getText(expenseType).icon;
                        return <Grid.Row>
                          <Grid.Column width={16}>
                            <Header>
                              <Icon name={expenseTypeIcon} /> {expenseTypeName} - {activeUserData.owner.fullname}
                            </Header>
                            <Table basic striped>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>Datum</Table.HeaderCell>
                                  <Table.HeaderCell>Popis</Table.HeaderCell>
                                  <Table.HeaderCell textAlign={"right"}>Částka</Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {activeUserData.expenses.filter(expense => expense.expenseType === expenseType).length === 0 && (
                                  <Table.Row>
                                    <Table.Cell textAlign={"center"} colSpan={3}>
                                      Žádné {expenseTypeName.toLowerCase()} tento měsíc
                                    </Table.Cell>
                                  </Table.Row>
                                )}
                                {activeUserData.expenses.filter(expense => expense.expenseType === expenseType)
                                  .map(expense => {
                                    return (
                                      <Table.Row key={expense.id}>
                                        <Table.Cell singleLine>{moment(expense.date).format("D. M. YYYY")}</Table.Cell>
                                        <Table.Cell>{expense.description}</Table.Cell>
                                        <Table.Cell
                                          singleLine
                                          textAlign={"right"}
                                        >
                                          {Formatter.money(expense.amount)}
                                        </Table.Cell>
                                      </Table.Row>
                                    );
                                  })}
                              </Table.Body>
                              <Table.Footer>
                                <Table.Row>
                                  <Table.HeaderCell colSpan={"2"} />
                                  <Table.HeaderCell textAlign={"right"}>
                                    <strong>
                                      {Formatter.money(
                                        activeUserData.expenses
                                          .filter(expense => expense.expenseType === expenseType)
                                          .flatMap((expense) => expense.amount)
                                          .reduce((a, b) => a + b, 0)
                                      )}
                                    </strong>
                                  </Table.HeaderCell>
                                </Table.Row>
                              </Table.Footer>
                            </Table>
                          </Grid.Column>
                        </Grid.Row>
                      })
                    }

                  </Grid>
                )}
              </Grid.Column>
            </Grid>
          </Grid.Row>
        )}
      </Grid>
    </Container>
  );
}
