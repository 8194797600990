import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumb } from "semantic-ui-react";
import { View } from "../../base/enums/View";
import {StringUtil} from "../../base/util/StringUtil";
interface BreadcrumbsProps {
  title?: string;
}

function Breadcrumbs(props: BreadcrumbsProps) {
  const [crums, setCrums] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const pathnames = location.pathname
      .split("/")
      .filter((x) => x !== "finance" || "invoicing");
    setCrums(pathnames);
  }, [location.pathname, props]);

  return (
    <Breadcrumb className="breadcrumbs" large="true">
      {crums.map((name, index) => {
        const isLast = index === crums.length - 1;
        const routeTo = crums.slice(0, index + 1).join("/");
        if (name !== "detail-pvv") {
          if (index > 1) {
            return isLast ? (
              <Breadcrumb.Section
                active
                key={index}
                className="breadcrumbs-item"
                large="true"
              >
                <span>
                  {props.title.length > 50
                    ? StringUtil.shortenWithEllipsis(props.title, 40)
                    : props.title}
                </span>
              </Breadcrumb.Section>
            ) : (
              <Breadcrumb.Section
                key={name}
                className="breadcrumbs-itemLink"
                large="true"
              >
                <Link to={routeTo}>
                  {name === View.TRANSACTIONS.name && "Transakce"}
                  {name === View.PLANNED_TRANSACTIONS.name &&
                    "Plánované transakce"}
                  {name === View.INVOICES.name && "Faktury"}
                  {name === View.INVOICES_SUBJECTS.name && "Odběratelé"}
                  {name === View.EMPLOYEES_MANAGEMENT.name && "Zaměstnanci"}
                  {routeTo === View.EMPLOYEES_MANAGEMENT.path + "/0" &&
                    "Detail zaměstnance"}
                  {name === View.DOCUMENT_MANAGEMENT.name && "Správa dokumentů"}
                  {name === View.PROJECTS.name && "Projekty"}
                </Link>
                <Breadcrumb.Divider icon="right chevron" />
              </Breadcrumb.Section>
            );
          } else {
            return null;
          }
        }
        return null;
      })}
    </Breadcrumb>
  );
}

export default Breadcrumbs;
