import React, {useEffect, useState} from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Table,
} from "semantic-ui-react";
import SimpleLoader from "../../../components/SimpleLoader";
import { View } from "../../../base/enums/View";
import { useHistory } from "react-router-dom";
import FullTextFilter from "../../../components/base/FullTextFilter";
import {InvoiceSubjectDto, useGetInvoiceSubjects} from '../../../api/generated-sources'

export default function InvoiceSubjectsViewMobile() {
  const [subjects, setSubjects] = useState<InvoiceSubjectDto[]>([]);
  const [subjectFilter, setSubjectFilter] = useState<InvoiceSubjectDto[]>([]);
  const history = useHistory();

  const {data, error, isLoading} = useGetInvoiceSubjects();

  useEffect(() => {
    console.log('Error:', error)
  }, [error]);

  useEffect(() => {
    setSubjects(data)
  }, [data]);

  return (
    <Container>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header>Adresář odběratelů</Header>
          </Grid.Column>
        </Grid.Row>
        {isLoading ? (
          <SimpleLoader text={"Načítám seznam odběratelů"} />
        ) : (
          <Grid.Row>
            <Grid.Column width={16}>
              <FullTextFilter
                data={subjects}
                returnFilteredData={setSubjectFilter}
                formatData={{
                  formatDateType: "DD. MM. yyyy",
                  activeItem: [
                    "name",
                    "street",
                    "zipCode",
                    "city",
                    "country",
                    "id",
                    "phone",
                    "email",
                    "ic",
                  ],
                }}
              />
              <Table selectable className={"invoices"} basic striped>
                <Table.Body>
                  {subjects.filter((subject) => subject.archived === false)
                    .length === 0 && (
                    <Table.Row disabled>
                      <Table.Cell textAlign={"center"} colSpan={6}>
                        Žádní aktivní odběratelé
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
              {subjectFilter.length
                ? subjectFilter
                    .filter((subject) => subject.archived === false)
                    .map((item) => {
                      return (
                        <Table basic striped key={item.id}>
                          <Table.Body
                            onClick={() =>
                              history.push(
                                `${View.INVOICES_SUBJECTS.path + "/" + item.id}`
                              )
                            }
                            key={item.id}
                          >
                            <Table.Row className="flex">
                              <Table.Cell>Odběratel</Table.Cell>
                              <Table.Cell singleLine>{item.name}</Table.Cell>
                            </Table.Row>
                            <Table.Row className="flex">
                              <Table.Cell>IČ</Table.Cell>
                              <Table.Cell singleLine>{item.ic}</Table.Cell>
                            </Table.Row>
                            <Table.Row className="flex">
                              <Table.Cell>Adresa</Table.Cell>
                              <Table.Cell singleLine>
                                {[
                                  item.street,
                                  item.zipCode,
                                  item.city,
                                  item.country,
                                ]
                                  .filter((field) => !!field)
                                  .join(", ")}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row className="flex">
                              <Table.Cell>Telefon</Table.Cell>
                              <Table.Cell singleLine>{item.phone}</Table.Cell>
                            </Table.Row>
                            <Table.Row className="flex">
                              <Table.Cell>Email</Table.Cell>
                              <Table.Cell singleLine>{item.email}</Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      );
                    })
                : subjects
                    .filter((subject) => subject.archived === false)
                    .map((item) => {
                      return (
                        <Table basic striped key={item.id}>
                          <Table.Body
                            onClick={() =>
                              history.push(
                                `${View.INVOICES_SUBJECTS.path + "/" + item.id}`
                              )
                            }
                            key={item.id}
                          >
                            <Table.Row className="flex">
                              <Table.Cell>Odběratel</Table.Cell>
                              <Table.Cell singleLine>{item.name}</Table.Cell>
                            </Table.Row>
                            <Table.Row className="flex">
                              <Table.Cell>IČ</Table.Cell>
                              <Table.Cell singleLine>{item.ic}</Table.Cell>
                            </Table.Row>
                            <Table.Row className="flex">
                              <Table.Cell>Adresa</Table.Cell>
                              <Table.Cell singleLine>
                                {[
                                  item.street,
                                  item.zipCode,
                                  item.city,
                                  item.country,
                                ]
                                  .filter((field) => !!field)
                                  .join(", ")}
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row className="flex">
                              <Table.Cell>Telefon</Table.Cell>
                              <Table.Cell singleLine>{item.phone}</Table.Cell>
                            </Table.Row>
                            <Table.Row className="flex">
                              <Table.Cell>Email</Table.Cell>
                              <Table.Cell singleLine>{item.email}</Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      );
                    })}
              <Table.Footer>
                <Table.Row>
                  <Table.Cell colSpan={16} textAlign={"right"}>
                    <Button
                      color={"green"}
                      onClick={() =>
                        history.push(`${View.INVOICES_SUBJECTS.path + "/new"}`)
                      }
                    >
                      <Icon name={"plus"} />
                      Přidat odběratele
                    </Button>
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
              <br />
              <br />
              {subjects.filter((subject) => subject.archived === true)
                .length !== 0 && (
                <div>
                  <h4>Archivovaní odběratelé</h4>
                  <p>
                    <Icon name={"info circle"} /> Tito odběratelé nebudou
                    dostupné při tvorbě nových faktur.
                  </p>
                  <Table basic striped selectable className={"invoices"}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Odběratel</Table.HeaderCell>
                        <Table.HeaderCell>IČ</Table.HeaderCell>
                        <Table.HeaderCell>Adresa</Table.HeaderCell>
                        <Table.HeaderCell>Telefon</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {subjects
                        .filter((subject) => subject.archived === true)
                        .map((item) => {
                          return (
                            <Table.Row
                              onClick={() =>
                                history.push(
                                  `${View.INVOICES.path + "/" + item.id}`
                                )
                              }
                              key={item.id}
                            >
                              <Table.Cell singleLine>{item.name}</Table.Cell>
                              <Table.Cell singleLine>{item.ic}</Table.Cell>
                              <Table.Cell singleLine>
                                {[
                                  item.street,
                                  item.zipCode,
                                  item.city,
                                  item.country,
                                ]
                                  .filter((field) => !!field)
                                  .join(", ")}
                              </Table.Cell>
                              <Table.Cell singleLine>{item.phone}</Table.Cell>
                              <Table.Cell singleLine>{item.email}</Table.Cell>
                            </Table.Row>
                          );
                        })}
                    </Table.Body>
                  </Table>
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Container>
  );
}
