import { Translation } from "../Localization.model";

class Navigation {
  public static DASHBOARD: Translation = { en: "Dashboard", cz: "Nástěnka" };
  public static PERSON: Translation = { en: "Person", cz: "Osoba" };
  public static INVOICES: Translation = { en: "Invoices", cz: "Faktury" };
  public static PROJECTS: Translation = {
    en: "Projects",
    cz: "Projekty",
  };
  public static INVOICES_SUBJECTS: Translation = {
    en: "Invoice subject",
    cz: "Odběratelé",
  };
  public static USER_MANAGEMENT: Translation = {
    en: "User management",
    cz: "Správa uživatelů",
  };
  public static ROLE_MANAGEMENT: Translation = {
    en: "Role management",
    cz: "Správa rolí",
  };

  public static HR_MANAGER: Translation = {
    en: "Personal agenda",
    cz: "Personální agenda",
  };
  public static MY_OVERVIEW: Translation = {
    en: "My overview",
    cz: "Můj přehled",
  };
  public static EMPLOYEE_MANAGEMENT: Translation = {
    en: "Employee management",
    cz: "Správa zaměstnanců",
  };
  public static DOCUMENT_MANAGEMENT: Translation = {
    en: "Document Management",
    cz: "Správa dokumentů",
  };

  public static TRANSACTIONS: Translation = {
    en: "List of transactions",
    cz: "Přehled transakcí",
  };
  public static PLANNED_TRANSACTIONS: Translation = {
    en: "Planned transaction",
    cz: "Plánované transakce",
  };
  public static SUMMARY: Translation = {
    en: "Summary of expenses",
    cz: "Přehled nákladů",
  };
  public static WORK: Translation = {
    en: "Work hours",
    cz: "Odpracované hodiny",
  };
  public static LOANS: Translation = {
    en: "Loans overview",
    cz: "Přehled půjček",
  };

  public static INVOICING: Translation = { en: "Invoicing", cz: "Fakturace" };
  public static FINANCE: Translation = { en: "Finances", cz: "Finance" };
  public static DETAIL_PAGE: Translation = {
    en: "Detail page",
    cz: "Detail page",
  };
}

export default Navigation;
