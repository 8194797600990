import React from "react";
import { Button, Grid, Icon, Segment, Table } from "semantic-ui-react";
import { Formatter } from "../../../base/util/Formatter";
import Translate from "../../../components/translation/Translate";
import { DashboardWidgetName } from "../../../base/locale/translations/DashboardWidget";
import { DashboardWidgetIcon } from "../../../base/enums/DashboardWidgetIcon";
import {DashboardWidgetUserDto, PersonalBalanceDto} from '../../../api/generated-sources'

interface ComponentDebtWidgetProps {
  widget: DashboardWidgetUserDto;
  data: string;
  onRemove: (widget: DashboardWidgetUserDto) => void;
}

export default function CompanyDebtWidget(props: ComponentDebtWidgetProps) {
  const debtList: PersonalBalanceDto[] = JSON.parse(props.data);

  return (
    <Grid.Column className={"dashboard-widget"} width={8}>
      <Segment className={"header"} inverted color={"red"} attached={"top"}>
        <strong>
          <Icon name={DashboardWidgetIcon.COMPANY_DEBT} />{" "}
          <Translate
            label={DashboardWidgetName.get(props.widget.dashboardWidget)}
          />
        </strong>
        <Button
          circular
          icon="trash"
          color={"black"}
          inverted
          size={"mini"}
          onClick={() => props.onRemove(props.widget)}
        />
      </Segment>
      <Segment attached={"bottom"}>
        <Table basic={"very"}>
          <Table.Body>
            {debtList.map((entry) => {
              return (
                <Table.Row key={entry.user.id}>
                  <Table.Cell singleLine>
                    <strong>{entry.user.fullname}</strong>
                  </Table.Cell>
                  <Table.Cell textAlign={"right"}>
                    {Formatter.money(entry.owedCurrently)}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell />
              <Table.Cell textAlign={"right"}>
                <strong>
                  ={" "}
                  {Formatter.money(
                    debtList
                      .flatMap((summary) => {
                        return summary.owedCurrently;
                      })
                      .reduce((a, b) => a + b, 0)
                  )}
                </strong>
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Segment>
    </Grid.Column>
  );
}
