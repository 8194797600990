import Axios, { AxiosError, AxiosRequestConfig } from "axios";
import Cookies from "universal-cookie";

// eslint-disable-next-line
export const AXIOS_INSTANCE = Axios.create({
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  baseURL: "/api/v1",
}); // use your own URL here or environment variable

// add a second `options` argument here if you want to pass extra options to each generated query
export const IntranetClientFetchInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig
): Promise<T> => {
  const source = Axios.CancelToken.source();
  const TOKEN_COOKIE_NAME = "_auth";
  const cookie = new Cookies();
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    headers: {authorization: `Bearer ${cookie.get(TOKEN_COOKIE_NAME)}`},
    cancelToken: source.token,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  }).then(({ data }) => data);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  promise.cancel = () => {
    source.cancel("Query was cancelled");
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return promise;
};

// In some case with react-query and swr you want to be able to override the return error type, so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>;
