import React, { useContext, useEffect } from "react";
import "../../../src/App.css";
import {
  getRedirectRequest,
  getSilentRequest,
} from "../../base/auth/AuthProvider";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
  PublicClientApplication,
} from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Auth, AuthContext } from "../../contexts/AuthContext";
import { JwtUtil } from "../../base/util/JwtUtil";
import { Button, Grid, Header, Icon, Image, Segment } from "semantic-ui-react";
import { config } from "../../base/config/DynamicConfig";
import { Redirect, Route, useHistory } from "react-router-dom";
import { View } from "../../base/enums/View";
import AppRoot from '../base/AppRoot'

function LoginAADInternal() {
  const auth = useContext<Auth>(AuthContext);
  const history = useHistory();
  const url = history.location.pathname;

  useEffect(() => {
    if (url !== "/login") {
      localStorage.setItem("backUrlLogin", url);
    }
  }, [url]);

  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {
    if (url !== "/login") {
      localStorage.setItem("backUrlLogin", url);
    }
  }, [url]);

  useEffect(() => {
    if (
      !auth.token &&
      accounts &&
      accounts.length > 0 &&
      inProgress === InteractionStatus.None
    ) {
      instance
        .acquireTokenSilent(getSilentRequest(accounts[0]))
        .then((accessTokenResponse) => {
          const jwt = JwtUtil.parse(accessTokenResponse.accessToken);
          auth.setToken(
            accessTokenResponse.accessToken,
            new Date(jwt.exp * 1000)
          );
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(getRedirectRequest());
          }
        });
      instance
        .acquireTokenSilent(getSilentRequest(accounts[0]))
        .then((accessTokenResponse) => {
          const jwt = JwtUtil.parse(accessTokenResponse.accessToken);
          auth.setToken(
            accessTokenResponse.accessToken,
            new Date(jwt.exp * 1000)
          );
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(getRedirectRequest());
          }
        });
      instance
        .acquireTokenSilent(getSilentRequest(accounts[0]))
        .then((accessTokenResponse) => {
          const jwt = JwtUtil.parse(accessTokenResponse.accessToken);
          auth.setToken(
            accessTokenResponse.accessToken,
            new Date(jwt.exp * 1000)
          );
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(getRedirectRequest());
          }
        });
    }
  }, [auth.token, instance, accounts, inProgress]);

  return (
    <>
      <div>
        <UnauthenticatedTemplate>
          <Route
            exact
            path={url}
            render={() => <Redirect to={{ pathname: View.LOGIN.path }} />}
          />
          <div className={"login-container"}>
            <Grid verticalAlign="middle" centered className="flex-login">
              <Grid.Column width={8}>
                <Image src={"/redrobot.svg"} />
              </Grid.Column>
              <Grid.Column width={8} className="login-template">
                <Segment
                  textAlign={"center"}
                  padded={"very"}
                  inverted
                  color={"red"}
                >
                  <Header>Přihlásit se přes účet Microsoft Teams</Header>
                  <Button
                    color={"black"}
                    size={"large"}
                    onClick={() => instance.loginRedirect(getRedirectRequest())}
                  >
                    <Icon name="microsoft" />
                    Přihlášení
                  </Button>
                </Segment>
              </Grid.Column>
            </Grid>
          </div>
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <AppRoot />
        </AuthenticatedTemplate>
      </div>
    </>
  );
}

function LoginAAD() {
  const msalInstance = new PublicClientApplication({
    auth: {
      clientId: config.env.AZURE_AD_CLIENT_ID,
      authority:
        "https://login.microsoftonline.com/" + config.env.AZURE_AD_TENANT_ID,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
  });

  return (
    <MsalProvider instance={msalInstance}>
      <LoginAADInternal />
    </MsalProvider>
  );
}

export default LoginAAD;
