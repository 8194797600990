import { Translation } from "../Localization.model";

class Months {
  public static JANUARY: Translation = { en: "January", cz: "Leden" };
  public static FEBRUARY: Translation = { en: "February", cz: "Únor" };
  public static MARCH: Translation = { en: "March", cz: "Březen" };
  public static APRIL: Translation = { en: "April", cz: "Duben" };
  public static MAY: Translation = { en: "May", cz: "Květen" };
  public static JUNE: Translation = { en: "June", cz: "Červen" };
  public static JULY: Translation = { en: "July", cz: "Červenec" };
  public static AUGUST: Translation = { en: "August", cz: "Srpen" };
  public static SEPTEMBER: Translation = { en: "September", cz: "Září" };
  public static OCTOBER: Translation = { en: "October", cz: "Říjen" };
  public static NOVEMBER: Translation = { en: "November", cz: "Listopad" };
  public static DECEMBER: Translation = { en: "December", cz: "Prosinec" };
}

export default Months;
