import React, {useEffect, useState} from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Popup,
  Table,
} from "semantic-ui-react";
import SimpleLoader from "../../../components/SimpleLoader";
import UserManagementModal from "../component/UserManagementModal";
import {getUsers, UserDto} from '../../../api/generated-sources'

export default function UserManagementView() {
  const [activeUser, setActiveUser] = useState<UserDto>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [users, setUsers] = useState<UserDto[]>(undefined);
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    getUsers()
      .then(data => {
        setUsers(data)
        setLoading(false)
      })
      .catch(console.error)
  }, []);

  // const getUsers = useGetUsers(
  //   {
  //     data: UserMapper.ALL,
  //   },
  //   {
  //     fetchPolicy: "network-only",
  //     variables: {},
  //     onCompleted: (data: UseGetUsersData) => {
  //       setData(data.users.data);
  //     },
  //     onError: (data: ApolloError) => {
  //       console.error(data.message);
  //     },
  //   }
  // );

  const openDetailModal = (user?: UserDto) => {
    setActiveUser(user);
    setOpenModal(true);
  };

  const closeDetailModal = () => {
    setOpenModal(false);
    setActiveUser(undefined);
  };

  const onUserUpdate = (user: UserDto) => {
    const indexToUpdate = users.findIndex((i) => {
      return user.id === i.id;
    });
    const usersCopy: UserDto[] = Object.assign([], users);
    if (indexToUpdate === -1) {
      usersCopy.push(user);
    } else {
      usersCopy[indexToUpdate] = user;
    }
    setUsers(usersCopy);
    setActiveUser(user);
    setOpenModal(false);
  };

  const onUserDelete = (id: number) => {
      const indexToDelete = users.findIndex((i) => {
        return id === i.id;
      });
      const usersCopy: UserDto[] = Object.assign([], users);
      usersCopy.splice(indexToDelete, 1);
      setActiveUser(undefined);
      setOpenModal(false);
      setUsers(usersCopy);
  };

  const displayLoader = loading || !users;

  return (
    <Container>
      {openModal && (
        <UserManagementModal
          user={activeUser}
          open={openModal}
          onClose={closeDetailModal}
          onUserDelete={onUserDelete}
          onUserUpdate={onUserUpdate}
        />
      )}
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header>Správa uživatelů</Header>
          </Grid.Column>
        </Grid.Row>
        {displayLoader && <SimpleLoader text={"Načítám uživatele"} />}
        {!displayLoader && users && (
          <Grid.Row>
            <Grid.Column width={16}>
              <Table
                basic
                striped
                selectable={!!users.length}
                className={"users"}
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>Jméno</Table.HeaderCell>
                    <Table.HeaderCell>Uživatelské jméno</Table.HeaderCell>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>Role</Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"}>
                      Spec. symbol
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {users.length === 0 && (
                    <Table.Row>
                      <Table.Cell textAlign={"center"} colSpan={7}>
                        Žádní uživatelé
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {users.map((item) => {
                    return (
                      <Table.Row
                        onClick={() => openDetailModal(item)}
                        key={item.id}
                      >
                        <Table.Cell textAlign={"center"} singleLine>
                          {!item.enabled && <Icon name={"lock"} />}
                        </Table.Cell>
                        <Table.Cell singleLine>
                          {item.fullname ?? item.fullname}
                        </Table.Cell>
                        <Table.Cell singleLine>
                          {item.username ?? item.username}
                        </Table.Cell>
                        <Table.Cell singleLine>
                          {item.contactEmail ?? item.contactEmail}
                        </Table.Cell>
                        {item.roles.length > 0 ? (
                          <Popup
                            trigger={
                              <Table.Cell singleLine>
                                {item.roles.length} Role
                              </Table.Cell>
                            }
                          >
                            <Popup.Content>
                              {item.roles.map((role) => {
                                return (
                                  <span key={role.id}>
                                    {role.name} <br />
                                  </span>
                                );
                              })}
                            </Popup.Content>
                          </Popup>
                        ) : (
                          <Popup
                            trigger={
                              <Table.Cell singleLine>Bez role</Table.Cell>
                            }
                          >
                            <Popup.Content>
                              <Icon name={"warning sign"} /> Tento uživatel se
                              bez role 'user' nemůže přihlásit do aplikace!
                            </Popup.Content>
                          </Popup>
                        )}
                        <Table.Cell textAlign={"right"} singleLine>
                          {item.ss ?? item.ss}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.Cell colSpan={8} textAlign={"right"}>
                      <Button color={"green"} onClick={() => openDetailModal()}>
                        <Icon name={"plus"} />
                        Přidat uživatele
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Container>
  );
}
