export class QueryUtil {
  public static getParam(query: string, param: string) {
    const params = query.substring(1).split("&");
    for (let i = 0; i < params.length; i++) {
      if (params[i].startsWith(param)) {
        return params[i].split("=")[1];
      }
    }
    return undefined;
  }
}
