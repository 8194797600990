import {Button, DropdownProps, Form, Header, Icon, List, Modal} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import {SyncOperationState} from "../../../base/state/SyncOperationState";
import toast from "react-hot-toast";
import {DropdownItemProps} from "semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem";
import moment from "moment/moment";
import {useParams} from "react-router-dom";
import {FileUploadUtil} from "../../../base/util/FileUploadUtil";
import {StringUtil} from "../../../base/util/StringUtil";
import {
  createDocument, deleteDocument, deleteDocumentAttachment,
  DocumentInputDto,
  DocumentTypeDto,
  FileAttachmentDto, getDocumentTypes, updateDocument
} from '../../../api/generated-sources'
import {LocalDate} from '../../../build/core/dto/LocalDate'

type DocumentEditModalProps = {
  selectedRow: DocumentInputDto;
  close?: () => void;
  isOpen?: boolean;
  attachmentName?: string;
  handleUpdate: () => void
  handleDelete: (id: number) => void;
};

export function DocumentTableModal(props: DocumentEditModalProps) {
  const {
    selectedRow,
    isOpen,
    close,
    handleUpdate,
    handleDelete,
  } = props;

  const { id } = useParams<{ id: string }>();
  const [doc, setDoc] = useState<DocumentInputDto>({documentDto: {
    ...selectedRow.documentDto,
    validFrom: selectedRow.documentDto?.validFrom ?? moment().format('YYYY-MM-DD')
  }});
  const [documentTypes, setDocumentTypes] = useState<DocumentTypeDto[]>();
  const [documentTypesOptions, setDocumentTypesOptions] = useState<DropdownItemProps[]>();
  const [syncState, setSyncState] = useState<SyncOperationState>({
    inProgress: false,
  });
  const [deleteState, setDeleteState] = useState<SyncOperationState>({
    inProgress: false,
  });

  const deleteAttachment = (id: number) => {
    setDeleteState({inProgress: true, type: "delete"});
    const promise = deleteDocumentAttachment(id);
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Dokument byl úspěšně smazán.",
        error: "Něco se pokazilo!",
      }
    );
    promise.then(data => {
      let d = Object.assign({}, doc);
      d.documentDto.attachmentUrl = undefined;
      d.attachment = undefined;
      setDoc(d);
      setDeleteState({inProgress: false, type: undefined});
      handleUpdate();
    }).catch(console.error)
  }

  const handleChangeAttachment = (attachment: FileAttachmentDto) => {
    setDoc({
      ...doc,
      attachment: attachment,
    });
  }

  const handleChange = (value: any, field: string) => {
    setDoc({
      ...doc,
      documentDto: {
        ...doc.documentDto,
        [field]: value,
      },
    });
  };

  useEffect(() => {
    setSyncState({inProgress: false});
  }, [isOpen]);

  useEffect(() => {
    getDocumentTypes()
      .then(data => {
        let types = [{key: 0, value: 0, text: 'Jiný typ'}];
        types = types.concat(data.documentTypes.map(type => {
          return {key: type.id, value: type.id, text: type.title}
        }));
        setDocumentTypes(data.documentTypes);
        setDocumentTypesOptions(types);
      }).catch(console.error);
  }, [selectedRow]);

  const create = () => {
    setSyncState({inProgress: true});
    const promise = createDocument({
          ...doc,
          documentDto: {
            ...doc.documentDto,
            userId: +id,
          },
          attachment: doc.attachment
    });
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Dokument byl úspěšně nahrán.",
        error: "Něco se pokazilo!",
      }
    );
    promise.then(data => {
      setSyncState({inProgress: false, type: undefined});
      handleUpdate();
      close();
    }).catch(console.error)
  }

  const update = () => {
    setSyncState({inProgress: true});
    let dto : DocumentInputDto = {
      documentDto: {
        id: doc.documentDto.id,
        validFrom: doc.documentDto.validFrom,
        validTo: doc.documentDto.validTo,
        note: doc.documentDto.note,
        userId: +id,
      },
      attachment: doc.attachment
    }
    if (!!doc.documentDto.documentType) {
      dto = {
        ...dto,
        documentDto: {
          ...dto.documentDto,
          documentType: {
            id: doc.documentDto.documentType.id,
            required: doc.documentDto.documentType.required
          }
        }
      }
    }
    const promise = updateDocument(dto.documentDto.id, dto);
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Zaměstnanec byl úspěšně upraven.",
        error: (err) => err + "Něco se pokazilo!",
      }
    );
    promise.then(data => {
      setSyncState({inProgress: false, type: undefined});
      handleUpdate();
      close();
    }).catch(console.error)
  }

  const handleDeleteDocument = () => {
    setDeleteState({inProgress: true, type: "document"});
    const promise = deleteDocument(doc.documentDto.id);
    toast
      .promise(promise,
        {
          loading: "Zpracovávám...",
          success: "Záznam byl úspěšně smazán.",
          error: "Něco se pokazilo!",
        }
      )
      .then(() => close());
    promise.then(data => {
      setSyncState({inProgress: false, type: undefined});
      handleDelete(doc.documentDto.id);
      close();
    }).catch(console.error)
  };

  const handleValidFromChange = (e: any) => {
    let validTo = doc.documentDto.validTo;
    let validFromMoment = moment(e.target.value, moment.ISO_8601)
    if (doc?.documentDto.documentType) {
      let selectedType = documentTypes.find(type => doc.documentDto.documentType?.id === type.id);
      if (selectedType?.expiresAfterMonths > 0) {
        let a = moment(validFromMoment).add(selectedType.expiresAfterMonths, "months")
        validTo = new LocalDate(a.year(), a.month(), a.date()).toString();
      }
    }
    setDoc({
      ...doc,
      documentDto: {
        ...doc.documentDto,
        validFrom: validFromMoment.format("YYYY-MM-DD"),
        validTo: validTo
      }
    });
  }

  const handleTypeChange = (e: any, data: DropdownProps) => {
    let id = data.value;
    let selectedType = documentTypes.find(t => t.id === data.value);
    let validTo = doc.documentDto.validTo;
    if (selectedType && !selectedType.expiresAfterMonths) {
      validTo = undefined
    } else if (doc?.documentDto.validFrom && selectedType?.expiresAfterMonths > 0) {
      let validToMoment = moment(doc?.documentDto.validFrom, moment.ISO_8601).add(selectedType.expiresAfterMonths, "months")
      validTo = new LocalDate(validToMoment.year(), validToMoment.month(), validToMoment.date()).toString();
    }
    setDoc({
      ...doc,
      documentDto: {
        ...doc.documentDto,
        validTo: validTo,
        documentType: {
          id: (id as number),
          required: true
        }
      }
    });
  }

  const requiredFieldsPresent = () => {
    return !!doc.documentDto.validFrom;
  };

  return (
    <Modal
      className={"invoice-detail"}
      onClose={close}
      open={isOpen}
      size={"small"}
    >
      <Header icon="edit" content={doc?.documentDto?.id ? "Úprava dokumentu" : "Nahrát nový dokument"} />
      <Modal.Content>
        <Form>
          <Form.Group widths={2}>
            <Form.Input
              label="Poznámka"
              value={doc?.documentDto.note}
              onChange={(e) => handleChange(e.target.value, "note")}
            />
            <Form.Select
              width={8}
              search
              options={documentTypesOptions ? documentTypesOptions : []}
              loading={!documentTypesOptions}
              label={"Typ dokumentu"}
              value={doc?.documentDto.documentType?.id ? doc.documentDto.documentType.id : 0}
              onChange={(e, data: DropdownProps) => handleTypeChange(e, data)}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Input
              label="Platnost od"
              type="date"
              value={doc?.documentDto.validFrom ? doc.documentDto.validFrom : ""}
              onChange={(e) => handleValidFromChange(e)}
            />
            <Form.Input
              label="Platnost do"
              type="date"
              value={doc?.documentDto.validTo ? doc.documentDto.validTo : ""}
              onChange={(e) => handleChange(e.target.value, "validTo")}
            />
          </Form.Group>
          <List relaxed>
            {!doc?.documentDto.attachmentUrl && (
              <List.Item>
                <Button disabled={syncState.inProgress} icon labelPosition="right"
                  onClick={() =>
                    window.document.getElementById("uploadAttachmentInput")?.click()
                  }
                >
                  {doc.documentDto.attachmentUrl ? "Zobrazit dokument" : doc.attachment ? StringUtil.shortenWithEllipsis(doc.attachment.filename, 27) : "Nahrát dokument"} <Icon name="upload" />
                </Button>
              </List.Item>
            )}
            {
              !doc?.documentDto.attachmentUrl && syncState.error && <List.Item className={"highlight-warning"}>
                Limit 100MB (formáty .png, .jpg, .pdf) <Icon name={"warning"} />
              </List.Item>
            }
            {
              !doc?.documentDto.attachmentUrl && !syncState.error && <List.Item>
                Limit 100MB (formáty .png, .jpg, .pdf)
              </List.Item>
            }
            {!!doc?.documentDto.attachmentUrl && (
              <List.Item>
                  <Button color={"black"} icon labelPosition="left" onClick={() => window.open(doc.documentDto.attachmentUrl)}>
                    <Icon name="download" /> Zobrazit dokument
                  </Button>
                <Button basic icon={"sync alternate"} color={"red"} content={"Nahradit"} disabled={deleteState.inProgress} loading={deleteState.inProgress && deleteState.type === 'attachment'} onClick={() => deleteAttachment(doc.documentDto.id)} />
              </List.Item>
            )}
            <List.Item>
                <input
                  hidden
                  id={"uploadAttachmentInput"}
                  type={"file"}
                  accept="image/png, image/jpeg, application/pdf"
                  onChange={(event) => FileUploadUtil.setAttachment(event, handleChangeAttachment, setSyncState)}
                />
            </List.Item>
          </List>
        </Form>
      </Modal.Content>
      <Modal.Actions className="button-ppv">
        {
          doc.documentDto.id && (!!doc.documentDto.documentType?.deletable) && <Button color={"red"} floated="left" type="submit" icon={"trash alternate"}
            loading={syncState.inProgress && syncState.type === "delete"}
            disabled={syncState.inProgress}
            onClick={handleDeleteDocument} />
        }
        {
          doc.documentDto.id && <Button color={"black"} floated="right" type="submit"
                loading={syncState.inProgress && syncState.type !== "delete"}
                disabled={syncState.inProgress || !requiredFieldsPresent()}
                onClick={update}>
            <Icon name={"save"} /> Uložit změny
          </Button>
        }
        {
          !doc.documentDto.id && <Button color={"black"} floated="right" type="submit"
                        onClick={create}
                        loading={syncState.inProgress && syncState.type !== "delete"}
                        disabled={syncState.inProgress || !requiredFieldsPresent()}>
            <Icon name={"save"} /> Vytvořit
          </Button>
        }
      </Modal.Actions>
    </Modal>
  );
}
