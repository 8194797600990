import {ETaxExemptType} from '../../api/generated-sources'

export class TaxExemptUtil {
  public static getTaxExemptTypeOptions = () => {
    return [
      { key: ETaxExemptType.BASIC, value: ETaxExemptType.BASIC, text: "Základní sleva na poplatníka" },
      { key: ETaxExemptType.CHILD_1, value: ETaxExemptType.CHILD_1, text: "Sleva na první dítě" },
      { key: ETaxExemptType.CHILD_2, value: ETaxExemptType.CHILD_2, text: "Sleva na druhé dítě" },
      { key: ETaxExemptType.CHILD_3, value: ETaxExemptType.CHILD_3, text: "Sleva na třetí a každé další dítě" },
      { key: ETaxExemptType.CHILD_1_ZTP, value: ETaxExemptType.CHILD_1_ZTP, text: "Sleva na první dítě (ZTP/P)" },
      { key: ETaxExemptType.CHILD_2_ZTP, value: ETaxExemptType.CHILD_2_ZTP, text: "Sleva na druhé dítě (ZTP/P)" },
      { key: ETaxExemptType.CHILD_3_ZTP, value: ETaxExemptType.CHILD_3_ZTP, text: "Sleva na třetí a každé další dítě (ZTP/P)" },
      { key: ETaxExemptType.DAYCARE, value: ETaxExemptType.DAYCARE, text: "Sleva na manžela/manželku" },
      { key: ETaxExemptType.DISABILITY_III, value: ETaxExemptType.DISABILITY_III, text: "Sleva na invaliditu I. a II. stupně" },
      { key: ETaxExemptType.DISABILITY_I_II, value: ETaxExemptType.DISABILITY_I_II, text: "Sleva na invaliditu III. stupně" },
      { key: ETaxExemptType.PARTNER, value: ETaxExemptType.PARTNER, text: "Sleva na poplatníka ZTP/P" },
      { key: ETaxExemptType.STUDENT, value: ETaxExemptType.STUDENT, text: "Sleva na studenta" },
      { key: ETaxExemptType.ZTP, value: ETaxExemptType.ZTP, text: "Sleva na umístění dítěte (školkovné)" },
    ];
  };
}
