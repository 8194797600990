/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
import useSwr from "swr";
import type { Arguments, Key, SWRConfiguration } from "swr";
import useSWRMutation from "swr/mutation";
import type { SWRMutationConfiguration } from "swr/mutation";
import type {
  AssignExpensesToTransactionBody,
  CashTransactionDto,
  CreateTransactionAttachmentBody,
  ErrorResponseResponse,
  GetTransactionsParams,
  TransactionDto,
} from "../intranetApi.schemas";
import { IntranetClientFetchInstance } from "../../intranetClientFetchInstance";
import type { ErrorType } from "../../intranetClientFetchInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get transactions
 */
export const getTransactions = (
  params: GetTransactionsParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<TransactionDto[]>(
    { url: `/transaction`, method: "GET", params },
    options
  );
};

export const getGetTransactionsKey = (params: GetTransactionsParams) =>
  [`/transaction`, ...(params ? [params] : [])] as const;

export type GetTransactionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTransactions>>
>;
export type GetTransactionsQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get transactions
 */
export const useGetTransactions = <TError = ErrorType<ErrorResponseResponse>>(
  params: GetTransactionsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getTransactions>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetTransactionsKey(params) : null));
  const swrFn = () => getTransactions(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary create transaction
 */
export const createTransaction = (
  cashTransactionDto: CashTransactionDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<TransactionDto>(
    {
      url: `/transaction`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: cashTransactionDto,
    },
    options
  );
};

export const getCreateTransactionMutationFetcher = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: CashTransactionDto }
  ): Promise<TransactionDto> => {
    return createTransaction(arg, options);
  };
};
export const getCreateTransactionMutationKey = () => `/transaction` as const;

export type CreateTransactionMutationResult = NonNullable<
  Awaited<ReturnType<typeof createTransaction>>
>;
export type CreateTransactionMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary create transaction
 */
export const useCreateTransaction = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createTransaction>>,
    TError,
    string,
    CashTransactionDto,
    Awaited<ReturnType<typeof createTransaction>>
  > & { swrKey?: string };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCreateTransactionMutationKey();
  const swrFn = getCreateTransactionMutationFetcher(requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary get transaction
 */
export const getTransaction = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<TransactionDto>(
    { url: `/transaction/${id}`, method: "GET" },
    options
  );
};

export const getGetTransactionKey = (id: number) =>
  [`/transaction/${id}`] as const;

export type GetTransactionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTransaction>>
>;
export type GetTransactionQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get transaction
 */
export const useGetTransaction = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getTransaction>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetTransactionKey(id) : null));
  const swrFn = () => getTransaction(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary delete transaction
 */
export const deleteTransaction = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<string>(
    { url: `/transaction/${id}`, method: "DELETE" },
    options
  );
};

export const getDeleteTransactionMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, __: { arg: Arguments }): Promise<string> => {
    return deleteTransaction(id, options);
  };
};
export const getDeleteTransactionMutationKey = (id: number) =>
  `/transaction/${id}` as const;

export type DeleteTransactionMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTransaction>>
>;
export type DeleteTransactionMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary delete transaction
 */
export const useDeleteTransaction = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteTransaction>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof deleteTransaction>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getDeleteTransactionMutationKey(id);
  const swrFn = getDeleteTransactionMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary update Transaction
 */
export const updateTransaction = (
  id: number,
  transactionDto: TransactionDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<TransactionDto>(
    {
      url: `/transaction/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: transactionDto,
    },
    options
  );
};

export const getUpdateTransactionMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: TransactionDto }
  ): Promise<TransactionDto> => {
    return updateTransaction(id, arg, options);
  };
};
export const getUpdateTransactionMutationKey = (id: number) =>
  `/transaction/${id}` as const;

export type UpdateTransactionMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTransaction>>
>;
export type UpdateTransactionMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary update Transaction
 */
export const useUpdateTransaction = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateTransaction>>,
      TError,
      string,
      TransactionDto,
      Awaited<ReturnType<typeof updateTransaction>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getUpdateTransactionMutationKey(id);
  const swrFn = getUpdateTransactionMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary create transaction attachment
 */
export const createTransactionAttachment = (
  id: number,
  createTransactionAttachmentBody: CreateTransactionAttachmentBody,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<TransactionDto>(
    {
      url: `/transaction/${id}/attachment`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createTransactionAttachmentBody,
    },
    options
  );
};

export const getCreateTransactionAttachmentMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: CreateTransactionAttachmentBody }
  ): Promise<TransactionDto> => {
    return createTransactionAttachment(id, arg, options);
  };
};
export const getCreateTransactionAttachmentMutationKey = (id: number) =>
  `/transaction/${id}/attachment` as const;

export type CreateTransactionAttachmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof createTransactionAttachment>>
>;
export type CreateTransactionAttachmentMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary create transaction attachment
 */
export const useCreateTransactionAttachment = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof createTransactionAttachment>>,
      TError,
      string,
      CreateTransactionAttachmentBody,
      Awaited<ReturnType<typeof createTransactionAttachment>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getCreateTransactionAttachmentMutationKey(id);
  const swrFn = getCreateTransactionAttachmentMutationFetcher(
    id,
    requestOptions
  );

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary delete transaction attachment
 */
export const deleteTransactionAttachment = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<string>(
    { url: `/transaction/${id}/attachment`, method: "DELETE" },
    options
  );
};

export const getDeleteTransactionAttachmentMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, __: { arg: Arguments }): Promise<string> => {
    return deleteTransactionAttachment(id, options);
  };
};
export const getDeleteTransactionAttachmentMutationKey = (id: number) =>
  `/transaction/${id}/attachment` as const;

export type DeleteTransactionAttachmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTransactionAttachment>>
>;
export type DeleteTransactionAttachmentMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary delete transaction attachment
 */
export const useDeleteTransactionAttachment = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteTransactionAttachment>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof deleteTransactionAttachment>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getDeleteTransactionAttachmentMutationKey(id);
  const swrFn = getDeleteTransactionAttachmentMutationFetcher(
    id,
    requestOptions
  );

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary reassign expenses for transaction
 */
export const assignExpensesToTransaction = (
  id: number,
  assignExpensesToTransactionBody: AssignExpensesToTransactionBody,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<TransactionDto>(
    {
      url: `/transaction/${id}/expense`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: assignExpensesToTransactionBody,
    },
    options
  );
};

export const getAssignExpensesToTransactionMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: AssignExpensesToTransactionBody }
  ): Promise<TransactionDto> => {
    return assignExpensesToTransaction(id, arg, options);
  };
};
export const getAssignExpensesToTransactionMutationKey = (id: number) =>
  `/transaction/${id}/expense` as const;

export type AssignExpensesToTransactionMutationResult = NonNullable<
  Awaited<ReturnType<typeof assignExpensesToTransaction>>
>;
export type AssignExpensesToTransactionMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary reassign expenses for transaction
 */
export const useAssignExpensesToTransaction = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof assignExpensesToTransaction>>,
      TError,
      string,
      AssignExpensesToTransactionBody,
      Awaited<ReturnType<typeof assignExpensesToTransaction>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getAssignExpensesToTransactionMutationKey(id);
  const swrFn = getAssignExpensesToTransactionMutationFetcher(
    id,
    requestOptions
  );

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
