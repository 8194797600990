import { Button, Icon, Message, Modal, Placeholder } from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import Translate from "../../../components/translation/Translate";
import {
  DashboardWidgetDescription,
  DashboardWidgetName,
} from "../../../base/locale/translations/DashboardWidget";
import { DashboardWidgetIcon } from "../../../base/enums/DashboardWidgetIcon";
import {DashboardWidgetDto, useGetAvailableDashboardWidgets} from '../../../api/generated-sources'

interface DashboardComponentSelectionModalProps {
  open: boolean;
  addDashboardWidget: (widget: DashboardWidgetDto) => void;
  onClose: () => void;
}

export default function DashboardWidgetSelectionModal(
  props: DashboardComponentSelectionModalProps
) {
  const [dashboardComponents, setDashboardComponents] = useState<
    DashboardWidgetDto[]
  >([]);

  const {data, isLoading, error} = useGetAvailableDashboardWidgets();

  useEffect(() => {
    if (data) {
      setDashboardComponents(data);
    }
  }, [data]);

  useEffect(() => {
    error && console.error(error)
  }, [error]);

  return (
    <Modal
      className={"dashboard-widget-modal"}
      onClose={props.onClose}
      open={props.open}
      size={"small"}
    >
      <Modal.Header>
        Přidat Widget na nástěnku{" "}
        {isLoading && <Icon loading color={"grey"} name="circle notch" />}
      </Modal.Header>
      <Modal.Content>
        {dashboardComponents.length === 0 && isLoading && (
          <div className={"center"}>
            <Placeholder>
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
            <Placeholder>
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
            <Placeholder>
              <Placeholder.Header image>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </div>
        )}
        {dashboardComponents.length === 0 && !isLoading && (
          <div className={"center"}>
            Nejsou dostupné žádné widgety na nástěnku
          </div>
        )}
        {dashboardComponents.map((component) => {
          return (
            <Message icon key={component.id}>
              <Icon
                className={"component-icon"}
                color={"red"}
                name={DashboardWidgetIcon.get(component)}
              />
              <Message.Content>
                <Button
                  floated={"right"}
                  icon={"plus"}
                  circular
                  onClick={() => props.addDashboardWidget(component)}
                />
                <Message.Header>
                  <Translate label={DashboardWidgetName.get(component)} />
                </Message.Header>
                <Translate label={DashboardWidgetDescription.get(component)} />
              </Message.Content>
            </Message>
          );
        })}
      </Modal.Content>
    </Modal>
  );
}
