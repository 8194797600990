import { SemanticICONS } from "semantic-ui-react";
import {DashboardWidgetDto} from '../../api/generated-sources'

export class DashboardWidgetIcon {
  public static VM_STATUS: SemanticICONS = "server";
  public static INVOICE_SUBJECT_BREAKDOWN: SemanticICONS = "pie chart";
  public static FINANCE_GRAPH: SemanticICONS = "chart bar";
  public static FINANCE_TABLE: SemanticICONS = "euro sign";
  public static COMPANY_DEBT: SemanticICONS = "money bill alternate";

  public static get(widget: DashboardWidgetDto) {
    switch (widget.name) {
      case "vm_status":
        return DashboardWidgetIcon.VM_STATUS;
      case "invoice_subject_breakdown":
        return DashboardWidgetIcon.INVOICE_SUBJECT_BREAKDOWN;
      case "finance_graph":
        return DashboardWidgetIcon.FINANCE_GRAPH;
      case "finance_table":
        return DashboardWidgetIcon.FINANCE_TABLE;
      case "company_debt":
        return DashboardWidgetIcon.COMPANY_DEBT;
    }
  }
}
