import React, {useContext, useEffect, useState} from "react";
import {
  Button,
  Dropdown,
  Grid,
  Header,
  Icon,
  Label, Message,
  Table,
} from "semantic-ui-react";
import MonthYearSelector from "../../../components/base/MonthYearSelector";
import { Month } from "../../../base/enums/Month";
import { Formatter } from "../../../base/util/Formatter";
import SimpleLoader from "../../../components/SimpleLoader";
import { DateUtil } from "../../../base/util/DateUtil";
import { FilterValue, FilterContext } from "../../../contexts/FilterContext";
import PurchaseConfigurationModal from "../component/PurchaseConfigurationModal";
import TransactionCashPaymentModal from "../component/TransactionCashPaymentModal";
import Translate from "../../../components/translation/Translate";
import { SyncOperationState } from "../../../base/state/SyncOperationState";
import { useHistory } from "react-router-dom";
import { View } from "../../../base/enums/View";
import { config } from "../../../base/config/DynamicConfig";
import FullTextFilter from "../../../components/base/FullTextFilter";
import moment from "moment";
import {StringUtil} from "../../../base/util/StringUtil";
import {
  AccountStatementDto, createTransactionAttachment, deleteTransaction,
  FileAttachmentDto, getAccountingFilesForMonth, getUsers,
  TransactionDto, useGetAccountStatementForMonth,
  UserDto
} from '../../../api/generated-sources'
import toast from 'react-hot-toast'

interface DocumentState {
  loading: boolean;
  code?: string;
}

export default function TransactionMobile() {
  const { month, year } = useContext<FilterValue>(FilterContext);
  const [documentState, setDocumentState] = useState<DocumentState>({
    loading: false,
  });

  const [users, setUsers] = useState<UserDto[]>([]);
  const [statement, setStatement] = useState<AccountStatementDto>();
  const [activeTransaction, setActiveTransaction] = useState<TransactionDto>();
  const [filterData, setFilterData] = useState<TransactionDto[]>();
  const [modifiedPurchase, setModifiedPurchase] = useState<TransactionDto>();

  const [cashPaymentOpened, setCashPaymentOpened] = useState(false);

  const [uploadState, setUploadState] = useState<SyncOperationState>({
    inProgress: false,
    error: false,
  });

  const history = useHistory();


  useEffect(() => {
    getUsers()
      .then(value => setUsers(value))
      .catch(reason => {
        toast.error("Něco se pokazilo1!");
        console.error(reason)
      });
  }, []);

  const {data, error, isLoading} = useGetAccountStatementForMonth({
    month: month,
    year: year,
  });

  useEffect(() => {
    if (data) {
      setDocumentState({ loading: false, code: undefined });
      setStatement(data);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error("Něco se pokazilo2!");
      console.error(error)
    }
  }, [error]);

  const assignPurchase = (
    purchase: TransactionDto,
    owner: UserDto | null,
    amount?: number
  ) => {
    if (owner) {
      setExpense(purchase.id, {
        id: purchase.id,
        expenses: [
          {
            owner: {
              id: owner.id,
              fullname: owner.fullname,
              contactEmail: owner.contactEmail,
              username: owner.username,
              ss: owner.ss,
              enabled: owner.enabled,
            },
            amount: amount!,
          },
        ],
      }).then(value => updateTransaction(value))
        .catch(reason => {
          toast.error("Něco se pokazilo3!");
          console.error(reason);
        });
    } else {
      setExpense(purchase.id, {
        id: purchase.id,
        expenses: [],
      }).then(value => updateTransaction(value))
        .catch(reason => {
          toast.error("Něco se pokazilo4!");
          console.error(reason);
        });
    }
  };

  const onTransactionUpdate = (transaction: TransactionDto) => {
    if (activeTransaction) {
      setActiveTransaction(transaction);
    }
    updateTransaction(transaction);
    setStatement((prevState) => {
      return {
        ...prevState,
        activeTransaction: transaction,
      };
    });
  };

  const updateTransaction = (transaction: TransactionDto) => {
    const indexToUpdate = statement!.transactions.findIndex((t) => {
      return transaction.id === t.id;
    });

    const transactions: TransactionDto[] = Object.assign(
      [],
      statement!.transactions
    );
    if (indexToUpdate === -1) {
      transactions.push(transaction);
    } else {
      transactions[indexToUpdate] = {
        ...transactions[indexToUpdate],
        attachmentUrl:
          transaction.attachmentUrl === null
            ? transactions[indexToUpdate].attachmentUrl
            : transaction.attachmentUrl,
        taxDeductible: transaction.taxDeductible,
        vat: transaction.vat,
        requiresAttachment: transaction.requiresAttachment,
        expenses: transaction.expenses,
      };
    }
    if (statement) {
      setStatement({
        ...statement,
        transactions: transactions,
      });
    }
  };

  const deleteCashTransaction = (transaction: TransactionDto) => {
    deleteTransaction(transaction.id)
      .then(value => {
        alert("TODO remove deleted item from table");
      })
      .catch(reason => {
        toast.error("Něco se pokazilo5!");
        console.error(reason);
      });
  };

  const onAttachmentUploadSuccess = (
    response: TransactionDto
  ) => {
    setUploadState({ inProgress: false, id: undefined, error: false });
    onTransactionUpdate(response);
  };

  const onAttachmentUploadError = (data: any) => {
    console.error(data);
    setUploadState({
      inProgress: false,
      error: true,
    });
  };

  const uploadAttachment = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const filetype = event.target.files[0].type;
      if (
        file.size > 100000000 ||
        !["image/png", "image/jpeg", "application/pdf"].includes(filetype)
      ) {
        setUploadState({
          error: true,
        });
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      setUploadState({
        id: id,
        inProgress: true,
        error: false,
      });
      reader.onloadend = () => {
        const fileAttachment: FileAttachmentDto = {
          filename: file.name,
          type: filetype,
          content: reader.result,
        };
        createTransactionAttachment(id, {
          id: id,
          attachment: fileAttachment,
        })
          .then(onAttachmentUploadSuccess)
          .catch(onAttachmentUploadError);
      };
    }
  };

  const downloadDocuments = () => {
    setDocumentState({
      ...documentState,
      loading: true,
    });
    getAccountingFilesForMonth({
      year: year,
      month: month,
    })
      .then(value => {
        setDocumentState({
          ...documentState,
          loading: false,
          code: value,
        });
        window.location.replace(
          config.env.BASE_URL +
          "/api/documents/archive?code=" +
          value
        );
      })
      .catch((reason: any) => {
        console.error(reason);
        setDocumentState({
          ...documentState,
          loading: false,
        });
      });
  };

  const getTransactionIcon = (transaction: TransactionDto) => {
    if (transaction.amount > 0) {
      if (transaction.transactionId != null) {
        return <Label color={"green"}>+</Label>;
      } else {
        return (
          <Label color={"green"}>
            <Icon name={"money bill alternate outline"} />
          </Label>
        );
      }
    } else {
      if (
        [
          "Bezhotovostní platba",
          "Platba převodem uvnitř banky",
          "Okamžitá odchozí platba",
        ].includes(transaction.type)
      ) {
        return <Icon color={"red"} name={"exchange"} />;
      } else if (transaction.type === "Platba kartou") {
        return <Icon color={"red"} name={"credit card"} />;
      } else if (transaction.transactionId === null) {
        return <Icon color={"red"} name={"money bill alternate outline"} />;
      } else {
        return <Icon color={"red"} name={"minus"} />;
      }
    }
  };

  const displayLoader = isLoading || !statement;

  return (
    <div>
      {cashPaymentOpened && (
        <TransactionCashPaymentModal
          open={cashPaymentOpened}
          onClose={() => {
            setCashPaymentOpened(false);
          }}
          onCashTransactionSave={(transaction) => {
            onTransactionUpdate(transaction);
            setCashPaymentOpened(false);
          }}
        />
      )}
      {modifiedPurchase && (
        <PurchaseConfigurationModal
          owners={users}
          transaction={modifiedPurchase}
          onClose={() => setModifiedPurchase(undefined)}
          onTransactionUpdate={onTransactionUpdate}
        />
      )}
      <Header>
        Výpis účtu - <Translate label={Month.ALL[month - 1].translation} />
        {year}
      </Header>
      <MonthYearSelector />
      {displayLoader && <SimpleLoader text={"Načítám výpisy"} />}
      {!displayLoader && statement && <>
          <Grid.Column width={8} className={"table-top"}>
            <FullTextFilter
              data={statement.transactions}
              formatData={{
                dateType: ["date"],
                formatDateType: "DD. MM. yyyy",
                activeItem: [
                  "amount",
                  "date",
                  "id",
                  "counterAccountName",
                  "messageForRecipient",
                  "comment",
                  "vat",
                ],
              }}
              returnFilteredData={setFilterData}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            {
               DateUtil.isCurrentMonth(year, month) && (new Date(statement.lastSync * 1000)) <= DateUtil.getHoursAgo(2) && <Message warning size={'tiny'} attached={'top'}
                icon={<Icon.Group><Icon name={'clock outline'}/><Icon corner name={'warning sign'}/></Icon.Group>}
                header='Seznam transakcí z banky je zastaralý'
                content={'Poslední aktualizace proběhla ' + moment(new Date(statement.lastSync * 1000)).format("DD. MM. yyyy HH:mm") + '.'}
              />
            }
            <br/>
          </Grid.Column>
          <Grid.Column width={16}>
            <Table celled striped>
              <Table.Header>
                <Table.Row className={"topTable-flex flex"}>
                  <Table.HeaderCell>
                    <strong>
                      <Translate label={Month.ALL[month - 1].translation} />
                      {year}
                    </strong>
                  </Table.HeaderCell>
                  <Table.HeaderCell className="popup-download">
                    {!documentState.code ? (
                      <Button
                        color={"grey"}
                        disabled={!DateUtil.isPastMonth(year, month)}
                        loading={documentState.loading}
                        onClick={() => downloadDocuments()}
                      >
                        <Icon name={"download"} /> Stáhnout doklady
                      </Button>
                    ) : (
                      <a
                        href={
                          "/api/documents/archive?code=" + documentState.code
                        }
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <Button
                          color={"black"}
                          disabled={!DateUtil.isPastMonth(year, month)}
                          loading={documentState.loading}
                        >
                          <Icon name={"download"} /> Stáhnout doklady
                        </Button>
                      </a>
                    )}
                    {!DateUtil.isPastMonth(year, month) && (
                      <Label basic color="grey" className="label-download">
                        Dostupné další měsíc
                      </Label>
                    )}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row className={"flex"}>
                  <Table.Cell>Počáteční stav</Table.Cell>
                  <Table.Cell>
                    {Formatter.money(statement.openingBalance)}
                  </Table.Cell>
                </Table.Row>

                <Table.Row className={"flex"}>
                  <Table.Cell>Konečný stav</Table.Cell>
                  <Table.Cell>
                    {Formatter.money(statement.closingBalance)}
                  </Table.Cell>
                </Table.Row>

                <Table.Row className={"flex"}>
                  <Table.Cell>Rozdíl</Table.Cell>
                  <Table.Cell>
                    {Formatter.money(
                      statement.closingBalance - statement.openingBalance
                    )}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <br/>
          </Grid.Column>
          <Grid.Column>
            {statement?.transactions?.length === 0 && (
              <Table.Row>
                <Table.Cell textAlign={"center"} colSpan={9}>
                  Žádné transakce v tomto měsíci zatím neproběhly
                </Table.Cell>
              </Table.Row>
            )}
            {filterData?.length
              ? filterData.map((item) => {
                  return (
                    <Table
                      key={item.id}
                      celled
                      striped
                      compact="very"
                      onClick={() =>
                        history.push(
                          `${View.TRANSACTIONS.path + "/" + item.id}`
                        )
                      }
                    >
                      <Table.Body
                        compact="very"
                        color={item.amount > 0 ? "green" : "red"}
                      >
                        <Table.Row className="flex">
                          <Table.Cell>Datum</Table.Cell>
                          <Table.Cell singleLine>
                            {moment(item.date, moment.ISO_8601).format(
                              "DD. MM. yyyy"
                            )}
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row className="transaction">
                          <Table.Cell>Transakce</Table.Cell>
                          <Table.Cell>{StringUtil.getTransactionLabel(item)}</Table.Cell>
                        </Table.Row>

                        <Table.Row className="flex">
                          <Table.Cell>Částka</Table.Cell>
                          <Table.Cell singleLine>
                            {Formatter.money(item.amount)}
                          </Table.Cell>
                          <Table.HeaderCell>
                            {getTransactionIcon(item)}
                          </Table.HeaderCell>
                        </Table.Row>

                        <Table.Row className="flex">
                          <Table.Cell>DPH</Table.Cell>
                          <Table.Cell singleLine>{item.vat} %</Table.Cell>
                        </Table.Row>

                        <Table.Row className="flex">
                          <Table.Cell>Majitel</Table.Cell>
                          <Table.Cell>
                            <div className={"purchase-owner"}>
                              {item.expenses.map((expense) => {
                                return (
                                  <span key={expense.owner.id}>
                                    {expense.owner.fullname.split(" ")[1] +
                                      " " +
                                      Formatter.money(expense.amount)}
                                  </span>
                                );
                              })}
                            </div>
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row className="flex">
                          <Table.Cell>Náklad</Table.Cell>
                          <Table.Cell>
                            {item.taxDeductible && (
                              <Icon color="black" name="check" />
                            )}
                            {!item.taxDeductible && (
                              <Icon color="black" name="close" />
                            )}
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row className="flex icons-flex">
                          <Table.Cell
                            onClick={(e: Event) => {
                              e.stopPropagation();
                            }}
                          >
                            {uploadState.id === item.id && (
                              <Icon loading name={"circle notch"} />
                            )}
                            {uploadState.id !== item.id &&
                              item.requiresAttachment &&
                              !!item.attachmentUrl && (
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={item.attachmentUrl}
                                >
                                  <Icon color="black" name="download" />
                                </a>
                              )}
                            {uploadState.id !== item.id &&
                              item.requiresAttachment &&
                              !item.attachmentUrl && (
                                <Icon.Group
                                  onClick={() =>
                                    window.document
                                      .getElementById("uploadInput-" + item.id)
                                      ?.click()
                                  }
                                >
                                  <Icon
                                    color="red"
                                    size={"big"}
                                    name="circle outline"
                                  />
                                  <Icon
                                    color="black"
                                    name="file alternate outline"
                                  />
                                  <Icon
                                    color="red"
                                    size={"small"}
                                    corner
                                    name="warning"
                                  />
                                  <input
                                    hidden
                                    id={"uploadInput-" + item.id}
                                    type={"file"}
                                    accept="image/png, image/jpeg, application/pdf"
                                    onChange={(event) =>
                                      uploadAttachment(item.id, event)
                                    }
                                  />
                                </Icon.Group>
                              )}
                          </Table.Cell>

                          <Table.Cell singleLine textAlign={"center"}>
                            <Dropdown item icon="wrench" className="dropdown">
                              <Dropdown.Menu className="dropdown-top">
                                <Dropdown.Item className="dropdown-item">
                                  <Icon name="caret left" />
                                  <Dropdown
                                    icon=""
                                    text="Rychlé přiřazení nákupu"
                                    floating
                                  >
                                    <Dropdown.Menu
                                      className="dropdown-menuMobile"
                                      style={{ top: -297 }}
                                    >
                                      {users.map((owner) => {
                                        return (
                                          <Dropdown.Item
                                            key={owner.id}
                                            onClick={() =>
                                              assignPurchase(
                                                item,
                                                owner,
                                                item.amount
                                              )
                                            }
                                          >
                                            {owner.fullname
                                              ? owner.fullname.split(" ")[1]
                                              : ""}
                                            {owner.fullname
                                              ? owner.fullname.split(" ")[0]
                                              : ""}
                                          </Dropdown.Item>
                                        );
                                      })}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => setModifiedPurchase(item)}
                                >
                                  Rozdělit nákup
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => assignPurchase(item, null)}
                                >
                                  Platí firma
                                </Dropdown.Item>
                                {item.transactionId === null && (
                                  <Dropdown.Item
                                    onClick={() => deleteCashTransaction(item)}
                                  >
                                    Smazat
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  );
                })
              : statement.transactions.map((item) => {
                  return (
                    <Table
                      key={item.id}
                      celled
                      striped
                      compact="very"
                      onClick={() =>
                        history.push(
                          `${View.TRANSACTIONS.path + "/" + item.id}`
                        )
                      }
                    >
                      <Table.Body
                        compact="very"
                        color={item.amount > 0 ? "green" : "red"}
                      >
                        <Table.Row className="flex">
                          <Table.Cell>Datum</Table.Cell>
                          <Table.Cell singleLine>
                            {moment(item.date, moment.ISO_8601).format(
                              "DD. MM. yyyy"
                            )}
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row className="transaction">
                          <Table.Cell>Transakce</Table.Cell>
                          <Table.Cell>{StringUtil.getTransactionLabel(item)}</Table.Cell>
                        </Table.Row>

                        <Table.Row className="flex">
                          <Table.Cell>Částka</Table.Cell>
                          <Table.Cell singleLine>
                            {Formatter.money(item.amount)}
                          </Table.Cell>
                          <Table.HeaderCell>
                            {getTransactionIcon(item)}
                          </Table.HeaderCell>
                        </Table.Row>

                        <Table.Row className="flex">
                          <Table.Cell>DPH</Table.Cell>
                          <Table.Cell singleLine>{item.vat} %</Table.Cell>
                        </Table.Row>

                        <Table.Row className="flex">
                          <Table.Cell>Majitel</Table.Cell>
                          <Table.Cell>
                            <div className={"purchase-owner"}>
                              {item.expenses.map((expense) => {
                                return (
                                  <span key={expense.owner.id}>
                                    {expense.owner.fullname.split(" ")[1] +
                                      " " +
                                      Formatter.money(expense.amount)}
                                  </span>
                                );
                              })}
                            </div>
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row className="flex">
                          <Table.Cell>Náklad</Table.Cell>
                          <Table.Cell>
                            {item.taxDeductible && (
                              <Icon color="black" name="check" />
                            )}
                            {!item.taxDeductible && (
                              <Icon color="black" name="close" />
                            )}
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row className="flex icons-flex">
                          <Table.Cell
                            onClick={(e: Event) => {
                              e.stopPropagation();
                            }}
                          >
                            {uploadState.id === item.id && (
                              <Icon loading name={"circle notch"} />
                            )}
                            {uploadState.id !== item.id &&
                              item.requiresAttachment &&
                              !!item.attachmentUrl && (
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={item.attachmentUrl}
                                >
                                  <Icon color="black" name="download" />
                                </a>
                              )}
                            {uploadState.id !== item.id &&
                              item.requiresAttachment &&
                              !item.attachmentUrl && (
                                <Icon.Group
                                  onClick={() =>
                                    window.document
                                      .getElementById("uploadInput-" + item.id)
                                      ?.click()
                                  }
                                >
                                  <Icon
                                    color="red"
                                    size={"big"}
                                    name="circle outline"
                                  />
                                  <Icon
                                    color="black"
                                    name="file alternate outline"
                                  />
                                  <Icon
                                    color="red"
                                    size={"small"}
                                    corner
                                    name="warning"
                                  />
                                  <input
                                    hidden
                                    id={"uploadInput-" + item.id}
                                    type={"file"}
                                    accept="image/png, image/jpeg, application/pdf"
                                    onChange={(event) =>
                                      uploadAttachment(item.id, event)
                                    }
                                  />
                                </Icon.Group>
                              )}
                          </Table.Cell>

                          <Table.Cell singleLine textAlign={"center"}>
                            <Dropdown item icon="wrench" className="dropdown">
                              <Dropdown.Menu className="dropdown-top">
                                <Dropdown.Item className="dropdown-item">
                                  <Icon name="caret left" />
                                  <Dropdown
                                    icon=""
                                    text="Rychlé přiřazení nákupu"
                                    floating
                                  >
                                    <Dropdown.Menu
                                      className="dropdown-menuMobile"
                                      style={{ top: -297 }}
                                    >
                                      {users.map((owner) => {
                                        return (
                                          <Dropdown.Item
                                            key={owner.id}
                                            onClick={() =>
                                              assignPurchase(
                                                item,
                                                owner,
                                                item.amount
                                              )
                                            }
                                          >
                                            {owner.fullname
                                              ? owner.fullname.split(" ")[1]
                                              : ""}
                                            {owner.fullname
                                              ? owner.fullname.split(" ")[0]
                                              : ""}
                                          </Dropdown.Item>
                                        );
                                      })}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => setModifiedPurchase(item)}
                                >
                                  Rozdělit nákup
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => assignPurchase(item, null)}
                                >
                                  Platí firma
                                </Dropdown.Item>
                                {item.transactionId === null && (
                                  <Dropdown.Item
                                    onClick={() => deleteCashTransaction(item)}
                                  >
                                    Smazat
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  );
                })}

            <Button
              color={"green"}
              onClick={() => {
                setCashPaymentOpened(true);
              }}
            >
              <Icon name={"money bill alternate outline"} /> Přidat transakci v
              hotovosti
            </Button>
          </Grid.Column>
      </>
      }
    </div>
  );
}
