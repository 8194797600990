import MyOverviewView from "../MyOverview/MyOverviewView";
import { useParams, useHistory } from "react-router-dom";
import { PPVTable } from "../../component/PPVTable";
import {Button, Grid} from "semantic-ui-react";
import { SalaryTable } from "../../component/SalaryTable";
import { DocumentTable } from "../../component/DocumentTable";
import { View } from "../../../../base/enums/View";
import {PayrollDeductionTable} from "../../component/PayrollDeductionTable";
import {TaxExemptTable} from "../../component/TaxExemptTable";
import {VacationTable} from "../../component/VacationTable";

export const EmployeeDetailPage = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  return (
    <>
      <MyOverviewView />
        <br/>
        <br/>
      <Grid>
        {id !== "new" && (
          <>
            <Grid.Column width={16} >
              <DocumentTable />
            </Grid.Column>
            <Grid.Column width={8} className="table-fullSize">
              <PPVTable />
            </Grid.Column>
            <Grid.Column width={8} className="table-fullSize">
              <SalaryTable />
            </Grid.Column>
            <Grid.Column width={8} className="table-fullSize">
              <TaxExemptTable />
            </Grid.Column>
            <Grid.Column width={8} className="table-fullSize">
              <PayrollDeductionTable />
            </Grid.Column>
            <Grid.Column width={16} className="table-fullSize">
              <VacationTable />
            </Grid.Column>
            <Grid.Column width={16}>
              <Button
                labelPosition="left"
                icon="left chevron"
                content="Zpět"
                onClick={() => history.push(View.EMPLOYEES_MANAGEMENT.path)}
              />
            </Grid.Column>
          </>
        )}
      </Grid>
    </>
  );
};
