import React, { useEffect, useState } from "react";
import { Button, Grid, Icon, Segment } from "semantic-ui-react";
import Translate from "../../../components/translation/Translate";
import { DashboardWidgetName } from "../../../base/locale/translations/DashboardWidget";
import { DashboardWidgetIcon } from "../../../base/enums/DashboardWidgetIcon";
import { Formatter } from "../../../base/util/Formatter";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Month } from "../../../base/enums/Month";
import "../../../index.css";
import {DashboardWidgetUserDto} from '../../../api/generated-sources'

interface FinanceGraphWidgetProps {
  widget: DashboardWidgetUserDto;
  data: string;
  configuration: string;
  onReload: (widget: DashboardWidgetUserDto, configuration: string) => void;
  onRemove: (widget: DashboardWidgetUserDto) => void;
}

interface FinanceGraphConfiguration {
  period: string;
  length: number;
}

interface FinanceGraphDataRaw {
  additionalExpectedRevenueWithVat: number;
  additionalExpectedRevenue: number;
  revenueWithoutVat: number;
  revenueWithVat: number;
  expensesWithoutVat: number;
  expensesWithVat: number;
  year?: number;
  month?: number;
}

interface FinanceGraphData extends FinanceGraphDataRaw {
  expensesWithVatAbs: number;
  monthLabel: string;
}

export default function FinanceGraphWidget(props: FinanceGraphWidgetProps) {
  const transformData = (data: string) => {
    const dataFromJson = JSON.parse(data);
    const newData: FinanceGraphData[] = dataFromJson.map(
      (item: FinanceGraphDataRaw) => {
        return {
          ...item,
          monthLabel: item.month
            ? Month.ALL[item.month - 1].translation.cz.substring(0, 3) +
              " " +
              item.year
            : null,
        };
      }
    );
    return newData;
  };

  const [data, setData] = useState<FinanceGraphData[]>(
    transformData(props.data)
  );
  const [configuration, setConfiguration] = useState<FinanceGraphConfiguration>(
    JSON.parse(props.configuration)
  );
  const [vat, setVat] = useState(true);

  useEffect(() => {
    setData(transformData(props.data));
  }, [props.data]);

  useEffect(() => {
    setConfiguration(JSON.parse(props.configuration));
  }, [props.configuration]);

  const setPeriod = (period: string, length: number) => {
    const configuration = {
      period: period,
      length: length,
    };
    setConfiguration(configuration);
    props.onReload(props.widget, JSON.stringify(configuration));
  };

  return (
    <Grid.Column
      className={"dashboard-widget"}
      width={16}
      key={props.widget.id}
    >
      <Segment className={"header"} inverted color={"red"} attached={"top"}>
        <strong>
          <Icon name={DashboardWidgetIcon.FINANCE_GRAPH} />{" "}
          <Translate
            label={DashboardWidgetName.get(props.widget.dashboardWidget)}
          />
        </strong>
        <Button
          circular
          icon="trash"
          color={"black"}
          inverted
          size={"mini"}
          onClick={() => props.onRemove(props.widget)}
        />
      </Segment>

      <Segment attached={"bottom"}>
        <Button.Group color={"red"} width={3}>
          <Button
            color={"red"}
            width={3}
            active={configuration.period === "years"}
            onClick={() => setPeriod("years", 10)}
          >
            Roky
          </Button>
          <Button
            color={"red"}
            width={3}
            active={configuration.period === "months"}
            onClick={() => setPeriod("months", 3)}
          >
            Měsíce
          </Button>
        </Button.Group>
        {configuration.period === "months" && (
          <Button.Group
            color={"red"}
            width={3}
            className={"buttongroup--months"}
          >
            <Button
              onClick={() => setPeriod("months", 3)}
              active={configuration.length === 3}
            >
              3 měsíce
            </Button>
            <Button
              onClick={() => setPeriod("months", 6)}
              active={configuration.length === 6}
            >
              6 měsíců
            </Button>
            <Button
              onClick={() => setPeriod("months", 12)}
              active={configuration.length === 12}
            >
              12 měsíců
            </Button>
          </Button.Group>
        )}
        <br />
        <ResponsiveContainer width="100%" aspect={2.5}>
          <BarChart
            data={data}
            margin={{ top: 30, right: 20, left: 50, bottom: 30 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey={configuration.period === "years" ? "year" : "monthLabel"}
            />
            <YAxis tickFormatter={(value: number) => Formatter.money(value)} />
            <Tooltip formatter={(value: number) => Formatter.money(value)} />
            <Legend wrapperStyle={{ bottom: 0 }} />
            <Bar
              name={vat ? "Příjmy s DPH" : "Příjmy bez DPH"}
              dataKey={vat ? "revenueWithVat" : "revenueWithoutVat"}
              fill="#828282"
            />
            <Bar
              name={vat ? "Výdaje s DPH" : "Výdaje bez DPH"}
              dataKey={vat ? "expensesWithVat" : "expensesWithoutVat"}
              fill="#DB2828"
            />
            {data.filter((item) => item.additionalExpectedRevenue !== 0.0)
              .length > 0 && (
              <Bar
                name={
                  vat
                    ? "Nerealizované příjmy s DPH"
                    : "Nerealizované příjmy bez DPH"
                }
                dataKey={
                  vat
                    ? "additionalExpectedRevenueWithVat"
                    : "additionalExpectedRevenue"
                }
                fill="#A26262"
              />
            )}
          </BarChart>
        </ResponsiveContainer>
        <Button.Group color={"red"} width={3}>
          <Button active={vat === true} onClick={() => setVat(!vat)}>
            S DPH
          </Button>
          <Button active={vat === false} onClick={() => setVat(!vat)}>
            Bez DPH
          </Button>
        </Button.Group>
      </Segment>
    </Grid.Column>
  );
}
