/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
import useSwr from "swr";
import type { Key, SWRConfiguration } from "swr";
import type {
  ErrorResponseResponse,
  GetAccountingFilesForMonthParams,
} from "../intranetApi.schemas";
import { IntranetClientFetchInstance } from "../../intranetClientFetchInstance";
import type { ErrorType } from "../../intranetClientFetchInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get accounting files for month
 */
export const getAccountingFilesForMonth = (
  params: GetAccountingFilesForMonthParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<string>(
    { url: `/file`, method: "GET", params },
    options
  );
};

export const getGetAccountingFilesForMonthKey = (
  params: GetAccountingFilesForMonthParams
) => [`/file`, ...(params ? [params] : [])] as const;

export type GetAccountingFilesForMonthQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAccountingFilesForMonth>>
>;
export type GetAccountingFilesForMonthQueryError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary get accounting files for month
 */
export const useGetAccountingFilesForMonth = <
  TError = ErrorType<ErrorResponseResponse>
>(
  params: GetAccountingFilesForMonthParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getAccountingFilesForMonth>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetAccountingFilesForMonthKey(params) : null));
  const swrFn = () => getAccountingFilesForMonth(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
