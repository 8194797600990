import { useContext, useState } from "react";
import "../../../src/App.css";
import { Form, Grid, Header, Image, Message, Segment } from "semantic-ui-react";
import { Auth, AuthContext } from "../../contexts/AuthContext";
import { SyncOperationState } from "../../base/state/SyncOperationState";
import { JwtUtil } from "../../base/util/JwtUtil";
import AppRoot from '../base/AppRoot'

function LoginLocal() {
  const auth = useContext<Auth>(AuthContext);

  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [syncState, setSyncState] = useState<SyncOperationState>({
    inProgress: false,
    error: false,
    done: false,
  });

  const handleChangeUsername = (e: any) => {
    setUsername(e.target.value);
  };

  const handleChangePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const login = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username: username, password: password }),
    };
    setSyncState({
      inProgress: true,
      error: false,
      done: false,
    });
    fetch("/api/auth/login", requestOptions).then((response) => {
      setPassword("");
      if (response.status >= 400) {
        setSyncState({
          error: true,
          done: true,
          id: response.status,
        });
      } else {
        response.text().then((data) => {
          setSyncState({
            id: response.status,
            done: true,
          });
          const jwt = JwtUtil.parse(data);
          auth.setToken(data, new Date(jwt.exp * 1000));
        });
      }
    });
  };

  return (
    <div>
      {auth.token ? (
        <AppRoot />
      ) : (
        <div className={"login-container"}>
          <Grid verticalAlign="middle" centered className="flex-login">
            <Grid.Column width={8}>
              <Image src={"/redrobot.svg"} />
            </Grid.Column>
            <Grid.Column width={8} className="login-template">
              <Segment
                style={{ color: "white!important" }}
                textAlign={"center"}
                padded={"very"}
                inverted
                color={"red"}
              >
                {syncState.error && (
                  <Message
                    error
                    content="Nesprávné přihlašovací údaje. Zkuste to, prosím, znovu."
                  />
                )}
                <Header>Přihlásit se do Intranetu</Header>
                <Form>
                  <Form.Input
                    placeholder={"Username"}
                    error={syncState.error}
                    type={"text"}
                    onChange={(event) => handleChangeUsername(event)}
                    value={username}
                  />
                  <Form.Input
                    placeholder={"Password"}
                    error={syncState.error}
                    type={"password"}
                    onChange={(event) => handleChangePassword(event)}
                    value={password}
                  />
                  <Form.Button
                    disabled={syncState.inProgress}
                    loading={syncState.inProgress}
                    onClick={() => login()}
                    color={"black"}
                  >
                    Přihlásit
                  </Form.Button>
                </Form>
              </Segment>
            </Grid.Column>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default LoginLocal;
