import { createContext, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { JwtUtil } from "../base/util/JwtUtil";

export type Auth = {
  token?: string;
  username?: string;
  name?: string;
  email?: string;
  ss?: string;
  roles?: string[];
  setToken: (token: string, expiration?: Date) => void;
  setUsername: (name: string) => void;
  setName: (name: string) => void;
  setEmail: (email: string) => void;
  setSs: (ss: string) => void;
  setRoles: (roles: string[]) => void;
  hasRole: (role: string) => boolean;
  isAdmin: () => boolean;
};
export const initialValue: Auth = {
  setToken: () => {},
  setName: () => {},
  setUsername: () => {},
  setEmail: () => {},
  setSs: () => {},
  setRoles: () => {},
  hasRole: () => {
    return false;
  },
  isAdmin: () => {
    return false;
  },
};

export const AuthContext = createContext<Auth>(initialValue);

export default function AuthProvider(props: any) {
  const TOKEN_COOKIE_NAME = "_auth";

  const cookies = new Cookies();

  const [token, setTokenInternal] = useState<string>(
    cookies.get(TOKEN_COOKIE_NAME)
  );
  const [name, setName] = useState<string>();
  const [username, setUsername] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [ss, setSs] = useState<string>();
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    let jwt = undefined;
    let expiration = undefined;
    if (token) {
      jwt = JwtUtil.parse(token);
      expiration = new Date(jwt.exp * 1000);
      setTimeout(() => {
        setToken(undefined);
      }, expiration.getTime() - new Date().getTime() - 60000);
    }
    if (expiration && expiration > new Date()) {
      setName(jwt.name);
      setUsername(jwt.username);
      setEmail(jwt.email);
      setSs(jwt.ss);
      setRoles(jwt.roles);
    } else {
      setName(undefined);
      setUsername(undefined);
      setEmail(undefined);
      setSs(undefined);
      setRoles([]);
    }
  }, [token]);

  const hasRole = (role: string): boolean => {
    return roles.includes(role);
  };

  const isAdmin = (): boolean => {
    return roles.includes("admin");
  };

  const setToken = (token: string, expiration?: Date) => {
    if (expiration) {
      cookies.set(TOKEN_COOKIE_NAME, token, { path: "/", expires: expiration });
    }
    if (!token) {
      cookies.remove(TOKEN_COOKIE_NAME);
    }
    setTokenInternal(token);
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        name,
        username,
        email,
        ss,
        roles,
        setToken,
        setName,
        setUsername,
        setEmail,
        setSs,
        setRoles,
        hasRole,
        isAdmin,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
