/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
import useSwr from "swr";
import type { Arguments, Key, SWRConfiguration } from "swr";
import useSWRMutation from "swr/mutation";
import type { SWRMutationConfiguration } from "swr/mutation";
import type {
  ErrorResponseResponse,
  GetTaxExemptBaseValueParams,
  GetTaxExemptsFoCurrentrUserParams,
  GetTaxExemptsForUserParams,
  PageableTaxExemptResponse,
  TaxExemptDto,
  TaxExemptInputDto,
} from "../intranetApi.schemas";
import { IntranetClientFetchInstance } from "../../intranetClientFetchInstance";
import type { ErrorType } from "../../intranetClientFetchInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get tax exempts dor current user
 */
export const getTaxExemptsFoCurrentrUser = (
  params?: GetTaxExemptsFoCurrentrUserParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<PageableTaxExemptResponse>(
    { url: `/taxExempt/currentUser`, method: "GET", params },
    options
  );
};

export const getGetTaxExemptsFoCurrentrUserKey = (
  params?: GetTaxExemptsFoCurrentrUserParams
) => [`/taxExempt/currentUser`, ...(params ? [params] : [])] as const;

export type GetTaxExemptsFoCurrentrUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTaxExemptsFoCurrentrUser>>
>;
export type GetTaxExemptsFoCurrentrUserQueryError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary get tax exempts dor current user
 */
export const useGetTaxExemptsFoCurrentrUser = <
  TError = ErrorType<ErrorResponseResponse>
>(
  params?: GetTaxExemptsFoCurrentrUserParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getTaxExemptsFoCurrentrUser>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetTaxExemptsFoCurrentrUserKey(params) : null));
  const swrFn = () => getTaxExemptsFoCurrentrUser(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary get tax exempts dor user
 */
export const getTaxExemptsForUser = (
  id: number,
  params?: GetTaxExemptsForUserParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<PageableTaxExemptResponse>(
    { url: `/taxExempt/${id}`, method: "GET", params },
    options
  );
};

export const getGetTaxExemptsForUserKey = (
  id: number,
  params?: GetTaxExemptsForUserParams
) => [`/taxExempt/${id}`, ...(params ? [params] : [])] as const;

export type GetTaxExemptsForUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTaxExemptsForUser>>
>;
export type GetTaxExemptsForUserQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get tax exempts dor user
 */
export const useGetTaxExemptsForUser = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  params?: GetTaxExemptsForUserParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getTaxExemptsForUser>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetTaxExemptsForUserKey(id, params) : null));
  const swrFn = () => getTaxExemptsForUser(id, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary delete tax exempt
 */
export const deleteTaxExempt = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<string>(
    { url: `/taxExempt/${id}`, method: "DELETE" },
    options
  );
};

export const getDeleteTaxExemptMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, __: { arg: Arguments }): Promise<string> => {
    return deleteTaxExempt(id, options);
  };
};
export const getDeleteTaxExemptMutationKey = (id: number) =>
  `/taxExempt/${id}` as const;

export type DeleteTaxExemptMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTaxExempt>>
>;
export type DeleteTaxExemptMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary delete tax exempt
 */
export const useDeleteTaxExempt = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteTaxExempt>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof deleteTaxExempt>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getDeleteTaxExemptMutationKey(id);
  const swrFn = getDeleteTaxExemptMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary update tax exempt
 */
export const updateTaxExempt = (
  id: number,
  taxExemptInputDto: TaxExemptInputDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<TaxExemptDto>(
    {
      url: `/taxExempt/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: taxExemptInputDto,
    },
    options
  );
};

export const getUpdateTaxExemptMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: TaxExemptInputDto }
  ): Promise<TaxExemptDto> => {
    return updateTaxExempt(id, arg, options);
  };
};
export const getUpdateTaxExemptMutationKey = (id: number) =>
  `/taxExempt/${id}` as const;

export type UpdateTaxExemptMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTaxExempt>>
>;
export type UpdateTaxExemptMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary update tax exempt
 */
export const useUpdateTaxExempt = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateTaxExempt>>,
      TError,
      string,
      TaxExemptInputDto,
      Awaited<ReturnType<typeof updateTaxExempt>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getUpdateTaxExemptMutationKey(id);
  const swrFn = getUpdateTaxExemptMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary get tax exempts base  value
 */
export const getTaxExemptBaseValue = (
  params: GetTaxExemptBaseValueParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<number>(
    { url: `/taxExempt/baseValue`, method: "GET", params },
    options
  );
};

export const getGetTaxExemptBaseValueKey = (
  params: GetTaxExemptBaseValueParams
) => [`/taxExempt/baseValue`, ...(params ? [params] : [])] as const;

export type GetTaxExemptBaseValueQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTaxExemptBaseValue>>
>;
export type GetTaxExemptBaseValueQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get tax exempts base  value
 */
export const useGetTaxExemptBaseValue = <
  TError = ErrorType<ErrorResponseResponse>
>(
  params: GetTaxExemptBaseValueParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getTaxExemptBaseValue>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetTaxExemptBaseValueKey(params) : null));
  const swrFn = () => getTaxExemptBaseValue(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary create tax exempt
 */
export const createTaxExempt = (
  taxExemptInputDto: TaxExemptInputDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<TaxExemptDto>(
    {
      url: `/taxExempt`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: taxExemptInputDto,
    },
    options
  );
};

export const getCreateTaxExemptMutationFetcher = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: TaxExemptInputDto }
  ): Promise<TaxExemptDto> => {
    return createTaxExempt(arg, options);
  };
};
export const getCreateTaxExemptMutationKey = () => `/taxExempt` as const;

export type CreateTaxExemptMutationResult = NonNullable<
  Awaited<ReturnType<typeof createTaxExempt>>
>;
export type CreateTaxExemptMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary create tax exempt
 */
export const useCreateTaxExempt = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createTaxExempt>>,
    TError,
    string,
    TaxExemptInputDto,
    Awaited<ReturnType<typeof createTaxExempt>>
  > & { swrKey?: string };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCreateTaxExemptMutationKey();
  const swrFn = getCreateTaxExemptMutationFetcher(requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary delete tax exempt attachment
 */
export const deleteTaxExemptAttachment = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<string>(
    { url: `/taxExempt/${id}/attachment`, method: "DELETE" },
    options
  );
};

export const getDeleteTaxExemptAttachmentMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, __: { arg: Arguments }): Promise<string> => {
    return deleteTaxExemptAttachment(id, options);
  };
};
export const getDeleteTaxExemptAttachmentMutationKey = (id: number) =>
  `/taxExempt/${id}/attachment` as const;

export type DeleteTaxExemptAttachmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTaxExemptAttachment>>
>;
export type DeleteTaxExemptAttachmentMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary delete tax exempt attachment
 */
export const useDeleteTaxExemptAttachment = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteTaxExemptAttachment>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof deleteTaxExemptAttachment>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getDeleteTaxExemptAttachmentMutationKey(id);
  const swrFn = getDeleteTaxExemptAttachmentMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
