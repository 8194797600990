import { Button, Form, Icon } from "semantic-ui-react";
import { ChangeEvent, useEffect, useState } from "react";
import { SyncOperationState } from "../../../base/state/SyncOperationState";
import { useHistory } from "react-router-dom";
import { View } from "../../../base/enums/View";
import Breadcrumbs from "../../../components/base/Breadcrumbs";
import toast from "react-hot-toast";
import {
  archiveProject,
  createProject,
  deleteProject,
  ProjectDto,
  updateProject
} from '../../../api/generated-sources'

interface ProjectDetailProps {
  project?: ProjectDto;
}

export default function ProjectDetail(props: ProjectDetailProps) {
  const [saveState, setSaveState] = useState<SyncOperationState>({
    inProgress: false,
    error: false,
  });

  const history = useHistory();

  const initialState = {
  };

  const [project, setProject] = useState<ProjectDto>(initialState);

  useEffect(() => {
    setProject(props.project ? props.project : initialState);
  }, [props.project]);

  const save = () => {
    setSaveState({
      ...saveState,
      inProgress: true,
    });

    const id = props.project ? props.project.id : null
    const promise = createProject({
          id: id,
          archived: project.archived === undefined ? false : project.archived,
          contactEmail: project.contactEmail,
          contactPerson: project.contactPerson,
          contactPhone: project.contactPhone,
          description: project.description,
          name: project.name,
          validFrom: project.validFrom,
          validTo: project.validTo,
    });
    toast.promise(promise, {
        loading: "Zpracovávám...",
        success: "Projekt byla úspěšně vytvořen.",
        error: "Něco se pokazilo!",
      }
    )
    promise.then(value => {
      setProject(value)
      setSaveState({
        inProgress: false,
        error: false,
      });
      history.push(View.PROJECTS.path)
    }).catch(reason => {
      setSaveState({ inProgress: false, error: true });
    });
  };



  const update = () => {
    setSaveState({
      ...saveState,
      inProgress: true,
    });
    const id = props.project ? props.project.id : null
    const promise = updateProject(id, {
        id: id,
        name: project.name,
        description: project.description,
        contactEmail: project.contactEmail,
        contactPerson: project.contactPerson,
        contactPhone: project.contactPhone,
        archived: project.archived,
        validFrom: project.validFrom,
        validTo: project.validTo,
      });
      toast
  .promise(
      promise, {
        loading: "Zpracovávám...",
        success: "Projekt byl úspěšně upraven.",
        error: "Něco se pokazilo!",
      }
    )
    promise.then(value => {
      setProject(value);
      setSaveState({
        inProgress: true,
        error: false,
      });
      history.push(View.PROJECTS.path);
    }).catch(reason => {
      setSaveState({ inProgress: false, error: true });
    })
  };

  const handleChange = (e: any, field: string) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setProject({
      ...project,
      [field]: value,
    });
  };

  const deleteInvoice = () => {
    const promise = deleteProject(props.project.id)
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Projekt byl úspěšně smazán.",
        error: "Něco se pokazilo!",
      },
      {
        success: {
          icon: <Icon name="remove circle" disabled color="red" />,
        },
      }
    );
    promise.then(() => history.push(View.PROJECTS.path))
      .catch(reason => {
        // TODO: napojeni projektu na faktury
        if (reason.response?.data?.code === 400) {
          toast.error("Projekte nelze smazat. Existují pro něho faktury. Pro skrytí projektu použijte funkci archivace.")
        }
        console.error(reason)
      });
  };

  const switchArchivedFlag = (archive: boolean) => {
    const id = props.project.id
    const promise = archiveProject(id, {id, archive})
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Projekt byl úspěšně upraven.",
        error: "Něco se pokazilo!",
      }
    );
    promise
      .then(value => setProject(value))
      .catch(reason => {
        console.error(reason)
        toast.error("Něco se pokazilo!");
      })
  };

  return (
    <>
      <Breadcrumbs
        title={
          props.project
            ? `Detail projektu ${props.project.name}`
            : "Nový projekt"
        }
      />
      <div className={"project-detail"}>
        <div>
          <Form>
            <Form.Group>
              <Form.Input
                width={16}
                required
                label={"Název projektu"}
                onChange={(event) => handleChange(event, "name")}
                value={project?.name}
                type={"text"}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                width={16}
                label={"Popis projektu"}
                onChange={(event: ChangeEvent<any>) =>
                  handleChange(event, "description")
                }
                value={project.description}
                control={"textarea"}
                rows="3"
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                width={6}
                required
                label={"Kontaktní osoba"}
                onChange={(event) => handleChange(event, "contactPerson")}
                value={project.contactPerson}
                type={"text"}
              />
              <Form.Input
                width={6}
                required
                label={"Kontaktní telefon"}
                onChange={(event) => handleChange(event, "contactPhone")}
                value={project.contactPhone}
                type={"text"}
              />
              <Form.Input
                width={5}
                required
                label={"Kontaktní email"}
                onChange={(event) => handleChange(event, "contactEmail")}
                value={project.contactEmail}
                type={"text"}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                width={4}
                label={"Platnost od"}
                onChange={(event) => handleChange(event, "email")}
                value={project.validFrom}
                type={"date"}
              />
              <Form.Input
                width={4}
                label={"Platnost do"}
                onChange={(event) => handleChange(event, "phone")}
                value={project.validTo}
                type={"date"}
              />
            </Form.Group>
            <Form.Group>
              <Form.Checkbox
                width={4}
                label={"Archivováno"}
                onChange={(event: ChangeEvent<any>) =>
                  handleChange(event, "archived")
                }
                checked={project.archived}
              />
            </Form.Group>
          </Form>
        </div>
        <div className="action-btn" style={{ marginTop: "1rem" }}>
          <Button
            icon="left chevron"
            onClick={() => history.push(View.PROJECTS.path)}
            className="mobile-btn"
          />
          <Button
            labelPosition="left"
            icon="left chevron"
            content="Zpět"
            onClick={() => history.push(View.PROJECTS.path)}
            className="mobile-btnHidden"
          />
          <div>
            {props.project && (
              <Button
                floated={"left"}
                color={"red"}
                loading={saveState.inProgress}
                disabled={saveState.inProgress}
                onClick={deleteInvoice}
              >
                <Icon name={"trash"} />{" "}
                <span className="action-btnSpan">Smazat projekt</span>
              </Button>
            )}

            {project && project.archived && (
              <Button
                floated={"left"}
                color={"grey"}
                loading={saveState.inProgress}
                disabled={saveState.inProgress}
                onClick={() => switchArchivedFlag(false)}
              >
                <Icon name="arrow alternate circle up outline" />{" "}
                <span className="action-btnSpan">Obnovit z archivu</span>
              </Button>
            )}
            {props.project && !project.archived && (
              <Button
                floated={"left"}
                color={"grey"}
                loading={saveState.inProgress}
                disabled={saveState.inProgress}
                onClick={() => {
                  switchArchivedFlag(true);
                }}
              >
                <Icon name="arrow alternate circle down" />{" "}
                <span className="action-btnSpan">Archivovat projekt</span>
              </Button>
            )}
            {props.project && (
              <Button
                color={"black"}
                loading={saveState.inProgress}
                disabled={saveState.inProgress}
                onClick={update}
              >
                <Icon name={"save"} />
                <span className="action-btnSpan"> Uložit změny</span>
              </Button>
            )}
            {!props.project && (
              <Button
                color={"black"}
                loading={saveState.inProgress}
                disabled={saveState.inProgress}
                onClick={save}
              >
                <Icon name={"save"} />{" "}
                <span className="action-btnSpan">
                  {" "}
                  Uložit nový projekt
                </span>
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
