import { useState, useEffect } from "react";
import { SyncOperationState } from "../../../base/state/SyncOperationState";
import { useParams, useHistory } from "react-router-dom";
import SimpleLoader from "../../../components/SimpleLoader";
import { Button } from "semantic-ui-react";
import TransactionDetail from "../component/TransactionDetail";
import { View } from "../../../base/enums/View";
import toast from "react-hot-toast";
import {
  createTransactionAttachment,
  FileAttachmentDto,
  getTransaction,
  TransactionDto
} from '../../../api/generated-sources'

const DetailTransactionPage = (props: any) => {
  const [detailTransaction, setDetailTransaction] = useState<TransactionDto>();
  const [uploadState, setUploadState] = useState<SyncOperationState>({
    inProgress: false,
    error: false,
  });
  const [loading, setLoading] = useState<boolean>(true)

  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const onTransactionUpdate = (transaction: TransactionDto) => {
    if (detailTransaction) {
      setDetailTransaction(transaction);
    }
  };

  const uploadAttachment = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const filetype = event.target.files[0].type;
      if (
        file.size > 100000000 ||
        !["image/png", "image/jpeg", "application/pdf"].includes(filetype)
      ) {
        setUploadState({
          error: true,
        });
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      setUploadState({
        id: id,
        inProgress: true,
        error: false,
      });
      reader.onloadend = () => {
        const fileAttachment: FileAttachmentDto = {
          filename: file.name,
          type: filetype,
          content: reader.result,
        };
        const promise = createTransactionAttachment(id, {id, attachment: fileAttachment})
        toast.promise(promise,
          {
            loading: "Zpracovávám...",
            success: "Doklad byl úspěšně nahraný.",
            error: "Něco se pokazilo!",
          }
        );
        promise
          .then(data => {
            setUploadState({ inProgress: false, id: undefined, error: false });
            onTransactionUpdate(data);
          })
          .catch(reason => {
            console.error(reason);
            setUploadState({
              inProgress: false,
              error: true,
            });
          });
      };
    }
  };

  useEffect(() => {
    getTransaction(+id)
      .then(data => {
        setDetailTransaction(data);
        setLoading(false)
      })
      .catch(reason => {
        toast.error("Něco se pokazilo!");
        console.error(reason)
      });
  }, [id, props]);

  const displayLoader = loading || !detailTransaction;

  return (
    <>
      {displayLoader ? (
        <SimpleLoader text={"Načítám data"} />
      ) : (
        <>
          <TransactionDetail
            transaction={detailTransaction}
            uploadStatus={uploadState}
            onUploadAttachment={uploadAttachment}
            onTransactionUpdate={onTransactionUpdate}
          />
          <Button
            labelPosition="left"
            icon="left chevron"
            content="Zpět"
            onClick={() => history.push(View.TRANSACTIONS.path)}
          />
        </>
      )}
    </>
  );
};

export default DetailTransactionPage;
