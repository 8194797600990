import { Button, Grid } from "semantic-ui-react";
import React, { useContext, useEffect } from "react";
import { Month } from "../../base/enums/Month";
import { FilterContext, FilterValue } from "../../contexts/FilterContext";
import Translate from "../translation/Translate";
import { useLocation, useHistory } from "react-router-dom";
import { QueryUtil } from "../../base/util/QueryUtil";
import { FilterUtils } from "../../base/util/FilterUtils";

interface MonthYearProps {
  hideMonth?: boolean;
}

export default function MonthYearSelector(props: MonthYearProps) {
  const location = useLocation();
  const history = useHistory();
  const queryYear = QueryUtil.getParam(location.search, "y");
  const queryMonth = QueryUtil.getParam(location.search, "m");

  const NOW = new Date();
  const MAX_YEAR = NOW.getFullYear();
  const YEARS = [...Array(1 + MAX_YEAR - FilterUtils.MIN_YEAR)].map(
    (v, k) => k + FilterUtils.MIN_YEAR
  );

  const { month, year, setMonth, setYear } =
    useContext<FilterValue>(FilterContext);

  const setMonthProxy = (selectedMonth: number) => {
    const adjustedMonth = FilterUtils.getMonthWithinBounds(selectedMonth, year);
    setMonth(adjustedMonth);
    saveMonthYear({ month: adjustedMonth });
  };

  const setYearProxy = (selectedYear: number) => {
    const adjustedYear = FilterUtils.getYearWithinBounds(selectedYear);
    setYear(adjustedYear);
    const adjustedMonth = FilterUtils.getMonthWithinBounds(month, adjustedYear);
    setMonth(adjustedMonth);
    saveMonthYear({ year: adjustedYear, month: adjustedMonth });
  };

  const saveMonthYear = (monthYear: { year?: number; month?: number }) => {
    const data = {
      month: month,
      year: year,
      saveTime: new Date().getTime(),
      ...monthYear,
    };
    history.push({
      search: FilterUtils.getQueryParams(data.month, data.year),
    });
  };

  const loadMonthYear = () => {
    if (queryYear) {
      setYear(FilterUtils.getYearWithinBounds(Number.parseInt(queryYear)));
    }
    if (queryMonth && queryYear) {
      const y = FilterUtils.getYearWithinBounds(Number.parseInt(queryYear));
      const m = FilterUtils.getMonthWithinBounds(
        Number.parseInt(queryMonth),
        y
      );
      setMonth(m);
      history.push({
        search: FilterUtils.getQueryParams(m, y),
      });
    } else if (queryMonth) {
      setMonth(
        FilterUtils.getMonthWithinBounds(Number.parseInt(queryMonth), year)
      );
    }
  };

  const isButtonDisabled = (month: number): boolean => {
    if (year === FilterUtils.MIN_YEAR && month < FilterUtils.MIN_MONTH) {
      return true;
    }
    if (
      new Date().getFullYear() === year &&
      new Date().getMonth() + 1 < month
    ) {
      return true;
    }
    return false;
  };

  useEffect(loadMonthYear, [
    history,
    year,
    setMonth,
    setYear,
    queryMonth,
    queryYear,
  ]);

  return (
    <Grid.Row>
      <Grid.Column width={16}>
        <Button.Group color="red" widths={"12"}>
          {YEARS.map((yearItem) => {
            return (
              <Button
                key={yearItem}
                onClick={() => setYearProxy(yearItem)}
                active={year === yearItem}
              >
                {yearItem}
              </Button>
            );
          })}
        </Button.Group>
      </Grid.Column>
      {!props.hideMonth && (
        <Grid.Column width={16}>
          <Button.Group
            compact
            color="red"
            widths={"12"}
            className="month-wrap"
          >
            {Month.ALL.map((m) => {
              return (
                <Button
                  key={m.id}
                  onClick={() => setMonthProxy(m.id)}
                  disabled={isButtonDisabled(m.id)}
                  active={month === m.id}
                  className="month-btn"
                >
                  <Translate label={m.translation} />
                </Button>
              );
            })}
          </Button.Group>
        </Grid.Column>
      )}
    </Grid.Row>
  );
}
