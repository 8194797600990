/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
import useSwr from "swr";
import type { Key, SWRConfiguration } from "swr";
import type {
  ErrorResponseResponse,
  GetPersonalBalanceParams,
  PersonalBalanceDto,
} from "../intranetApi.schemas";
import { IntranetClientFetchInstance } from "../../intranetClientFetchInstance";
import type { ErrorType } from "../../intranetClientFetchInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get personal balance
 */
export const getPersonalBalance = (
  params: GetPersonalBalanceParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<PersonalBalanceDto[]>(
    { url: `/personalBalance`, method: "GET", params },
    options
  );
};

export const getGetPersonalBalanceKey = (params: GetPersonalBalanceParams) =>
  [`/personalBalance`, ...(params ? [params] : [])] as const;

export type GetPersonalBalanceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPersonalBalance>>
>;
export type GetPersonalBalanceQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get personal balance
 */
export const useGetPersonalBalance = <
  TError = ErrorType<ErrorResponseResponse>
>(
  params: GetPersonalBalanceParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getPersonalBalance>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetPersonalBalanceKey(params) : null));
  const swrFn = () => getPersonalBalance(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
