/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
import useSwr from "swr";
import type { Arguments, Key, SWRConfiguration } from "swr";
import useSWRMutation from "swr/mutation";
import type { SWRMutationConfiguration } from "swr/mutation";
import type {
  ErrorResponseResponse,
  GetPayrollDeductionsForCurrentUserParams,
  GetPayrollDeductionsForUserParams,
  PageablePayrollDeductionResponse,
  PayrollDeductionDto,
  PayrollDeductionInputDto,
} from "../intranetApi.schemas";
import { IntranetClientFetchInstance } from "../../intranetClientFetchInstance";
import type { ErrorType } from "../../intranetClientFetchInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get payroll deductions for current user
 */
export const getPayrollDeductionsForCurrentUser = (
  params?: GetPayrollDeductionsForCurrentUserParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<PageablePayrollDeductionResponse>(
    { url: `/payrollDeduction/currentUser`, method: "GET", params },
    options
  );
};

export const getGetPayrollDeductionsForCurrentUserKey = (
  params?: GetPayrollDeductionsForCurrentUserParams
) => [`/payrollDeduction/currentUser`, ...(params ? [params] : [])] as const;

export type GetPayrollDeductionsForCurrentUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPayrollDeductionsForCurrentUser>>
>;
export type GetPayrollDeductionsForCurrentUserQueryError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary get payroll deductions for current user
 */
export const useGetPayrollDeductionsForCurrentUser = <
  TError = ErrorType<ErrorResponseResponse>
>(
  params?: GetPayrollDeductionsForCurrentUserParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getPayrollDeductionsForCurrentUser>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getGetPayrollDeductionsForCurrentUserKey(params) : null);
  const swrFn = () =>
    getPayrollDeductionsForCurrentUser(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary get payroll deductions for user
 */
export const getPayrollDeductionsForUser = (
  id: number,
  params?: GetPayrollDeductionsForUserParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<PageablePayrollDeductionResponse>(
    { url: `/payrollDeduction/${id}`, method: "GET", params },
    options
  );
};

export const getGetPayrollDeductionsForUserKey = (
  id: number,
  params?: GetPayrollDeductionsForUserParams
) => [`/payrollDeduction/${id}`, ...(params ? [params] : [])] as const;

export type GetPayrollDeductionsForUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPayrollDeductionsForUser>>
>;
export type GetPayrollDeductionsForUserQueryError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary get payroll deductions for user
 */
export const useGetPayrollDeductionsForUser = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  params?: GetPayrollDeductionsForUserParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getPayrollDeductionsForUser>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetPayrollDeductionsForUserKey(id, params) : null));
  const swrFn = () => getPayrollDeductionsForUser(id, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary delete payroll deduction
 */
export const deletePayrollDeduction = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<string>(
    { url: `/payrollDeduction/${id}`, method: "DELETE" },
    options
  );
};

export const getDeletePayrollDeductionMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, __: { arg: Arguments }): Promise<string> => {
    return deletePayrollDeduction(id, options);
  };
};
export const getDeletePayrollDeductionMutationKey = (id: number) =>
  `/payrollDeduction/${id}` as const;

export type DeletePayrollDeductionMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePayrollDeduction>>
>;
export type DeletePayrollDeductionMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary delete payroll deduction
 */
export const useDeletePayrollDeduction = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deletePayrollDeduction>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof deletePayrollDeduction>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getDeletePayrollDeductionMutationKey(id);
  const swrFn = getDeletePayrollDeductionMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary update payroll deduction
 */
export const updatePayrollDeduction = (
  id: number,
  payrollDeductionInputDto: PayrollDeductionInputDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<PayrollDeductionDto>(
    {
      url: `/payrollDeduction/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: payrollDeductionInputDto,
    },
    options
  );
};

export const getUpdatePayrollDeductionMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: PayrollDeductionInputDto }
  ): Promise<PayrollDeductionDto> => {
    return updatePayrollDeduction(id, arg, options);
  };
};
export const getUpdatePayrollDeductionMutationKey = (id: number) =>
  `/payrollDeduction/${id}` as const;

export type UpdatePayrollDeductionMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePayrollDeduction>>
>;
export type UpdatePayrollDeductionMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary update payroll deduction
 */
export const useUpdatePayrollDeduction = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updatePayrollDeduction>>,
      TError,
      string,
      PayrollDeductionInputDto,
      Awaited<ReturnType<typeof updatePayrollDeduction>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getUpdatePayrollDeductionMutationKey(id);
  const swrFn = getUpdatePayrollDeductionMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary create payroll deduction
 */
export const createPayrollDeduction = (
  payrollDeductionInputDto: PayrollDeductionInputDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<PayrollDeductionDto>(
    {
      url: `/payrollDeduction`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: payrollDeductionInputDto,
    },
    options
  );
};

export const getCreatePayrollDeductionMutationFetcher = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: PayrollDeductionInputDto }
  ): Promise<PayrollDeductionDto> => {
    return createPayrollDeduction(arg, options);
  };
};
export const getCreatePayrollDeductionMutationKey = () =>
  `/payrollDeduction` as const;

export type CreatePayrollDeductionMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPayrollDeduction>>
>;
export type CreatePayrollDeductionMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary create payroll deduction
 */
export const useCreatePayrollDeduction = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createPayrollDeduction>>,
    TError,
    string,
    PayrollDeductionInputDto,
    Awaited<ReturnType<typeof createPayrollDeduction>>
  > & { swrKey?: string };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCreatePayrollDeductionMutationKey();
  const swrFn = getCreatePayrollDeductionMutationFetcher(requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary delete payroll deduction attachment
 */
export const deletePayrollDeductionAttachment = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<string>(
    { url: `/payrollDeduction/attachment/${id}`, method: "DELETE" },
    options
  );
};

export const getDeletePayrollDeductionAttachmentMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, __: { arg: Arguments }): Promise<string> => {
    return deletePayrollDeductionAttachment(id, options);
  };
};
export const getDeletePayrollDeductionAttachmentMutationKey = (id: number) =>
  `/payrollDeduction/attachment/${id}` as const;

export type DeletePayrollDeductionAttachmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePayrollDeductionAttachment>>
>;
export type DeletePayrollDeductionAttachmentMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary delete payroll deduction attachment
 */
export const useDeletePayrollDeductionAttachment = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deletePayrollDeductionAttachment>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof deletePayrollDeductionAttachment>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getDeletePayrollDeductionAttachmentMutationKey(id);
  const swrFn = getDeletePayrollDeductionAttachmentMutationFetcher(
    id,
    requestOptions
  );

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
