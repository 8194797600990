/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
import useSwr from "swr";
import type { Key, SWRConfiguration } from "swr";
import useSWRMutation from "swr/mutation";
import type { SWRMutationConfiguration } from "swr/mutation";
import type { ErrorResponseResponse, UserDto } from "../intranetApi.schemas";
import { IntranetClientFetchInstance } from "../../intranetClientFetchInstance";
import type { ErrorType } from "../../intranetClientFetchInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get current user
 */
export const getCurrentUser = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<UserDto>(
    { url: `/user/currentUser`, method: "GET" },
    options
  );
};

export const getGetCurrentUserKey = () => [`/user/currentUser`] as const;

export type GetCurrentUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCurrentUser>>
>;
export type GetCurrentUserQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get current user
 */
export const useGetCurrentUser = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof getCurrentUser>>, TError> & {
    swrKey?: Key;
    enabled?: boolean;
  };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetCurrentUserKey() : null));
  const swrFn = () => getCurrentUser(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary get users
 */
export const getUsers = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<UserDto[]>(
    { url: `/user`, method: "GET" },
    options
  );
};

export const getGetUsersKey = () => [`/user`] as const;

export type GetUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUsers>>
>;
export type GetUsersQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get users
 */
export const useGetUsers = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof getUsers>>, TError> & {
    swrKey?: Key;
    enabled?: boolean;
  };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetUsersKey() : null));
  const swrFn = () => getUsers(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary create user
 */
export const createUser = (
  userDto: UserDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<UserDto>(
    {
      url: `/user`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: userDto,
    },
    options
  );
};

export const getCreateUserMutationFetcher = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, { arg }: { arg: UserDto }): Promise<UserDto> => {
    return createUser(arg, options);
  };
};
export const getCreateUserMutationKey = () => `/user` as const;

export type CreateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof createUser>>
>;
export type CreateUserMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary create user
 */
export const useCreateUser = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createUser>>,
    TError,
    string,
    UserDto,
    Awaited<ReturnType<typeof createUser>>
  > & { swrKey?: string };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCreateUserMutationKey();
  const swrFn = getCreateUserMutationFetcher(requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary get user
 */
export const getUser = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<UserDto>(
    { url: `/user/${id}`, method: "GET" },
    options
  );
};

export const getGetUserKey = (id: number) => [`/user/${id}`] as const;

export type GetUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUser>>
>;
export type GetUserQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get user
 */
export const useGetUser = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getUser>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetUserKey(id) : null));
  const swrFn = () => getUser(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary update user
 */
export const updateUser = (
  id: number,
  userDto: UserDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<UserDto>(
    {
      url: `/user/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: userDto,
    },
    options
  );
};

export const getUpdateUserMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, { arg }: { arg: UserDto }): Promise<UserDto> => {
    return updateUser(id, arg, options);
  };
};
export const getUpdateUserMutationKey = (id: number) => `/user/${id}` as const;

export type UpdateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUser>>
>;
export type UpdateUserMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary update user
 */
export const useUpdateUser = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateUser>>,
      TError,
      string,
      UserDto,
      Awaited<ReturnType<typeof updateUser>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getUpdateUserMutationKey(id);
  const swrFn = getUpdateUserMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
