/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
import useSwr from "swr";
import type { Arguments, Key, SWRConfiguration } from "swr";
import useSWRMutation from "swr/mutation";
import type { SWRMutationConfiguration } from "swr/mutation";
import type {
  ErrorResponseResponse,
  GetVacationsByYearForCurrentUserParams,
  GetVacationsForUserParams,
  PageableVacationResponse,
  SynchronizeVacationsBody,
  VacationDto,
} from "../intranetApi.schemas";
import { IntranetClientFetchInstance } from "../../intranetClientFetchInstance";
import type { ErrorType } from "../../intranetClientFetchInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get vacation for current user by year
 */
export const getVacationsByYearForCurrentUser = (
  params?: GetVacationsByYearForCurrentUserParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<PageableVacationResponse>(
    { url: `/vacation/currentUser`, method: "GET", params },
    options
  );
};

export const getGetVacationsByYearForCurrentUserKey = (
  params?: GetVacationsByYearForCurrentUserParams
) => [`/vacation/currentUser`, ...(params ? [params] : [])] as const;

export type GetVacationsByYearForCurrentUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getVacationsByYearForCurrentUser>>
>;
export type GetVacationsByYearForCurrentUserQueryError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary get vacation for current user by year
 */
export const useGetVacationsByYearForCurrentUser = <
  TError = ErrorType<ErrorResponseResponse>
>(
  params?: GetVacationsByYearForCurrentUserParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getVacationsByYearForCurrentUser>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetVacationsByYearForCurrentUserKey(params) : null));
  const swrFn = () => getVacationsByYearForCurrentUser(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary get vacation for user
 */
export const getVacationsForUser = (
  id: number,
  params?: GetVacationsForUserParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<PageableVacationResponse>(
    { url: `/vacation/${id}`, method: "GET", params },
    options
  );
};

export const getGetVacationsForUserKey = (
  id: number,
  params?: GetVacationsForUserParams
) => [`/vacation/${id}`, ...(params ? [params] : [])] as const;

export type GetVacationsForUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getVacationsForUser>>
>;
export type GetVacationsForUserQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get vacation for user
 */
export const useGetVacationsForUser = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  params?: GetVacationsForUserParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getVacationsForUser>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetVacationsForUserKey(id, params) : null));
  const swrFn = () => getVacationsForUser(id, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary delete vacation
 */
export const deleteVacation = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<string>(
    { url: `/vacation/${id}`, method: "DELETE" },
    options
  );
};

export const getDeleteVacationMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, __: { arg: Arguments }): Promise<string> => {
    return deleteVacation(id, options);
  };
};
export const getDeleteVacationMutationKey = (id: number) =>
  `/vacation/${id}` as const;

export type DeleteVacationMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteVacation>>
>;
export type DeleteVacationMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary delete vacation
 */
export const useDeleteVacation = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteVacation>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof deleteVacation>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getDeleteVacationMutationKey(id);
  const swrFn = getDeleteVacationMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary update vacation
 */
export const updateVacation = (
  id: number,
  vacationDto: VacationDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<VacationDto>(
    {
      url: `/vacation/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: vacationDto,
    },
    options
  );
};

export const getUpdateVacationMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, { arg }: { arg: VacationDto }): Promise<VacationDto> => {
    return updateVacation(id, arg, options);
  };
};
export const getUpdateVacationMutationKey = (id: number) =>
  `/vacation/${id}` as const;

export type UpdateVacationMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateVacation>>
>;
export type UpdateVacationMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary update vacation
 */
export const useUpdateVacation = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateVacation>>,
      TError,
      string,
      VacationDto,
      Awaited<ReturnType<typeof updateVacation>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getUpdateVacationMutationKey(id);
  const swrFn = getUpdateVacationMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary create vacation
 */
export const createVacation = (
  vacationDto: VacationDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<VacationDto>(
    {
      url: `/vacation`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: vacationDto,
    },
    options
  );
};

export const getCreateVacationMutationFetcher = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, { arg }: { arg: VacationDto }): Promise<VacationDto> => {
    return createVacation(arg, options);
  };
};
export const getCreateVacationMutationKey = () => `/vacation` as const;

export type CreateVacationMutationResult = NonNullable<
  Awaited<ReturnType<typeof createVacation>>
>;
export type CreateVacationMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary create vacation
 */
export const useCreateVacation = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createVacation>>,
    TError,
    string,
    VacationDto,
    Awaited<ReturnType<typeof createVacation>>
  > & { swrKey?: string };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCreateVacationMutationKey();
  const swrFn = getCreateVacationMutationFetcher(requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary synchronize vacations
 */
export const synchronizeVacations = (
  synchronizeVacationsBody: SynchronizeVacationsBody,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<VacationDto[]>(
    {
      url: `/vacation/synchronize`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: synchronizeVacationsBody,
    },
    options
  );
};

export const getSynchronizeVacationsMutationFetcher = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: SynchronizeVacationsBody }
  ): Promise<VacationDto[]> => {
    return synchronizeVacations(arg, options);
  };
};
export const getSynchronizeVacationsMutationKey = () =>
  `/vacation/synchronize` as const;

export type SynchronizeVacationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof synchronizeVacations>>
>;
export type SynchronizeVacationsMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary synchronize vacations
 */
export const useSynchronizeVacations = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof synchronizeVacations>>,
    TError,
    string,
    SynchronizeVacationsBody,
    Awaited<ReturnType<typeof synchronizeVacations>>
  > & { swrKey?: string };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getSynchronizeVacationsMutationKey();
  const swrFn = getSynchronizeVacationsMutationFetcher(requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
