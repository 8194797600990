import { Button, Form, Icon } from "semantic-ui-react";
import { ChangeEvent, useEffect, useState } from "react";
import { SyncOperationState } from "../../../base/state/SyncOperationState";
import { CountryUtil } from "../../../base/util/CountryUtil";
import { useHistory } from "react-router-dom";
import { View } from "../../../base/enums/View";
import Breadcrumbs from "../../../components/base/Breadcrumbs";
import toast from "react-hot-toast";
import {
  createInvoiceSubject, deleteInvoiceSubject,
  InvoiceSubjectDto, updateArchived, updateInvoiceSubject,
} from '../../../api/generated-sources'

interface InvoiceSubjectDetailProps {
  subject?: InvoiceSubjectDto;
}

export default function InvoiceSubjectDetail(props: InvoiceSubjectDetailProps) {
  const [saveState, setSaveState] = useState<SyncOperationState>({
    inProgress: false,
    error: false,
  });

  const history = useHistory();

  const initialState = {
    country: CountryUtil.DEFAULT_COUNTRY.value,
    isVatRegistered: true
  };

  const [subject, setSubject] = useState<InvoiceSubjectDto>(initialState);

  useEffect(() => {
    setSubject(props.subject ? props.subject : initialState);
  }, [props.subject]);

  const save = () => {
    setSaveState({
      ...saveState,
      inProgress: true,
    });
    const promise =
        createInvoiceSubject({
          id: props.subject ? props.subject.id : null,
          city: subject.city,
          dic: subject.dic,
          email: subject.email,
          name: subject.name,
          isVatRegistered:
            subject.isVatRegistered === undefined
              ? false
              : subject.isVatRegistered,
          ic: subject.ic,
          country: subject.country,
          archived: subject.archived === undefined ? false : subject.archived,
          street: subject.street,
          zipCode: subject.zipCode,
          phone: subject.phone,
          note: subject.note,
          website: subject.website,
          account: subject.account,
          iban: subject.iban,
          swift: subject.swift,
        });
    toast.promise(
      promise,
      {
        loading: "Zpracovávám...",
        success: (data) =>
          !(data.id)
            ? "Něco se pokazilo!"
            : "Odběratel byla úspěšně vytvořen.",
        error: "Něco se pokazilo!",
      }
    );
    promise.then(value => {
      setSubject(value);
      setSaveState({
        inProgress: false,
        error: false,
      });
      history.push(View.INVOICES_SUBJECTS.path);
    })
      .catch(reason => {
        setSaveState({ inProgress: false, error: true });
      })
  };

  const update = () => {
    setSaveState({
      ...saveState,
      inProgress: true,
    });
    const promise = updateInvoiceSubject(props.subject ? props.subject.id : null, {
              id: props.subject ? props.subject.id : null,
              city: subject.city,
              dic: subject.dic,
              email: subject.email,
              name: subject.name,
              isVatRegistered: subject.isVatRegistered,
              ic: subject.ic,
              country: subject.country,
              archived: subject.archived,
              street: subject.street,
              zipCode: subject.zipCode,
              phone: subject.phone,
              note: subject.note,
              website: subject.website,
              account: subject.account,
              iban: subject.iban,
              swift: subject.swift,
        })
    toast.promise(promise , {
          loading: "Zpracovávám...",
          success: "Odběratel byl úspěšně upraven.",
          error: "Něco se pokazilo!",
        }
      );
    promise.then(value => {
      setSubject(value);
      setSaveState({
        inProgress: true,
        error: false,
      });
      history.push(View.INVOICES_SUBJECTS.path);
    })
      .catch(reason => {
        setSaveState({ inProgress: false, error: true });
      });
  };

  const handleChange = (e: any, field: string) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setSubject({
      ...subject,
      [field]: value,
    });
  };

  const deleteSubject = () => {
    const promise = deleteInvoiceSubject(props.subject.id)
    toast.promise(
      promise,
      {
        loading: "Zpracovávám...",
        success: "Odběratel byl úspěšně smazán.",
        error: "Něco se pokazilo!",
      },
      {
        success: {
          icon: <Icon name="remove circle" disabled color="red" />,
        },
      }
    );
    promise.then(value => {
      history.push(View.INVOICES_SUBJECTS.path);
    }).catch(reason => {
        if (reason.response?.data?.code === 400) {
          toast.error("Odběratele nelze smazat. Existují pro něho faktury. Pro skrytí odběratele použijte funkci archivace.")
        }
        console.error(reason)
      });
  };

  const switchArchivedFlag = (archive: boolean) => {
    const promise = updateArchived(props.subject!.id, {
          id: props.subject!.id,
          archived: archive,
      })
    toast.promise(
      promise,
      {
        loading: "Zpracovávám...",
        success: "Odběratel byl úspěšně upraven.",
        error: "Něco se pokazilo!",
      }
    );
    promise.then(value => {
      setSubject(value);
    })
      .catch(reason => {
        toast.error("Něco se pokazilo!");
      })
  };

  return (
    <>
      <Breadcrumbs
        title={
          props.subject
            ? `Detail odběratele ${props.subject.name}`
            : "Nový odběratel"
        }
      />
      <div className={"invoice-subject-detail"}>
        <div>
          <Form>
            <Form.Group>
              <Form.Input
                width={6}
                required
                label={"Název odběratele"}
                onChange={(event) => handleChange(event, "name")}
                value={subject?.name}
                type={"text"}
              />
              <Form.Input
                width={6}
                required
                label={"IČ"}
                onChange={(event) => handleChange(event, "ic")}
                value={subject.ic}
                type={"text"}
              />
              <Form.Input
                width={6}
                required={subject.isVatRegistered}
                label={"DIČ"}
                onChange={(event) => handleChange(event, "dic")}
                value={subject.dic}
                type={"text"}
              />
            </Form.Group>
            <Form.Group></Form.Group>
            <Form.Group>
              <Form.Input
                width={4}
                required
                label={"Ulice"}
                onChange={(event) => handleChange(event, "street")}
                value={subject.street}
                type={"text"}
              />
              <Form.Input
                width={4}
                required
                label={"Město"}
                onChange={(event) => handleChange(event, "city")}
                value={subject.city}
                type={"text"}
              />
              <Form.Input
                width={3}
                required
                label={"PSČ"}
                onChange={(event) => handleChange(event, "zipCode")}
                value={subject.zipCode}
                type={"text"}
              />
              <Form.Select
                options={CountryUtil.getBasicCountryOptions()}
                width={5}
                label={"Země"}
                onChange={(event) => handleChange(event, "country")}
                value={subject.country}
                type={"text"}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                width={6}
                label={"E-mail"}
                onChange={(event) => handleChange(event, "email")}
                value={subject.email}
                type={"text"}
              />
              <Form.Input
                width={6}
                label={"Telefon"}
                onChange={(event) => handleChange(event, "phone")}
                value={subject.phone}
                type={"text"}
              />
              <Form.Input
                width={6}
                label={"Webové stránky"}
                onChange={(event) => handleChange(event, "website")}
                value={subject.website}
                type={"text"}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                width={6}
                label={"Číslo účtu"}
                onChange={(event) => handleChange(event, "account")}
                value={subject.account}
                type={"text"}
              />
              <Form.Input
                width={6}
                label={"IBAN"}
                onChange={(event) => handleChange(event, "iban")}
                value={subject.iban}
                type={"text"}
              />
              <Form.Input
                width={6}
                label={"SWIFT"}
                onChange={(event) => handleChange(event, "swift")}
                value={subject.swift}
                type={"text"}
              />
            </Form.Group>
            <Form.Field
              width={16}
              label={"Poznámka"}
              onChange={(event: ChangeEvent<any>) =>
                handleChange(event, "note")
              }
              value={subject.note}
              control={"textarea"}
              rows="3"
            />
            <Form.Checkbox
              width={4}
              label={"Plátce DPH"}
              onChange={(event: ChangeEvent<any>) =>
                handleChange(event, "isVatRegistered")
              }
              checked={subject.isVatRegistered}
            />
            <Form.Checkbox
              width={4}
              label={"Archivováno"}
              onChange={(event: ChangeEvent<any>) =>
                handleChange(event, "archived")
              }
              checked={subject.archived}
            />
          </Form>
        </div>
        <div className="action-btn" style={{ marginTop: "1rem" }}>
          <Button
            icon="left chevron"
            onClick={() => history.push(View.INVOICES_SUBJECTS.path)}
            className="mobile-btn"
          />
          <Button
            labelPosition="left"
            icon="left chevron"
            content="Zpět"
            onClick={() => history.push(View.INVOICES_SUBJECTS.path)}
            className="mobile-btnHidden"
          />
          <div>
            {props.subject && (
              <Button
                floated={"left"}
                color={"red"}
                loading={saveState.inProgress}
                disabled={saveState.inProgress}
                onClick={deleteSubject}
              >
                <Icon name={"trash"} />{" "}
                <span className="action-btnSpan">Smazat odběratele</span>
              </Button>
            )}

            {subject && subject.archived && (
              <Button
                floated={"left"}
                color={"grey"}
                loading={saveState.inProgress}
                disabled={saveState.inProgress}
                onClick={() => switchArchivedFlag(false)}
              >
                <Icon name="arrow alternate circle up outline" />{" "}
                <span className="action-btnSpan">Obnovit z archivu</span>
              </Button>
            )}
            {props.subject && !subject.archived && (
              <Button
                floated={"left"}
                color={"grey"}
                loading={saveState.inProgress}
                disabled={saveState.inProgress}
                onClick={() => {
                  switchArchivedFlag(true);
                }}
              >
                <Icon name="arrow alternate circle down" />{" "}
                <span className="action-btnSpan">Archivovat odběratele</span>
              </Button>
            )}
            {props.subject && (
              <Button
                color={"black"}
                loading={saveState.inProgress}
                disabled={saveState.inProgress}
                onClick={update}
              >
                <Icon name={"save"} />
                <span className="action-btnSpan"> Uložit změny</span>
              </Button>
            )}
            {!props.subject && (
              <Button
                color={"black"}
                loading={saveState.inProgress}
                disabled={saveState.inProgress}
                onClick={save}
              >
                <Icon name={"save"} />{" "}
                <span className="action-btnSpan">
                  {" "}
                  Uložit nového odběratele
                </span>
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
