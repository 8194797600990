import { Translation } from "../locale/Localization.model";
import Navigation from "../locale/translations/Navigation";
import { Module } from "./Module";
import { Role } from "./Role";

export class View {
  public static DASHBOARD: View = new View(
    "dashboard",
    Role.USER,
    Navigation.DASHBOARD,
    "/" + Module.DASHBOARD
  );
  public static PERSON: View = new View(
    "person",
    Role.USER,
    Navigation.PERSON,
    "/person"
  );

  public static INVOICES: View = new View(
    "invoices",
    Role.INVOICE,
    Navigation.INVOICES,
    "/" + Module.INVOICING + "/invoices"
  );
  public static INVOICES_SUBJECTS: View = new View(
    "subjects",
    Role.INVOICE,
    Navigation.INVOICES_SUBJECTS,
    "/" + Module.INVOICING + "/subjects"
  );
  public static PROJECTS: View = new View(
    "projects",
    Role.INVOICE,
    Navigation.PROJECTS,
    "/" + Module.INVOICING + "/projects"
  );

  public static USER_MANAGEMENT: View = new View(
    "users",
    Role.HR_MANAGER,
    Navigation.USER_MANAGEMENT,
    "/" + Module.USER_MANAGEMENT + "/users"
  );
  public static ROLE_MANAGEMENT: View = new View(
    "roles",
    Role.HR_MANAGER,
    Navigation.ROLE_MANAGEMENT,
    "/" + Module.USER_MANAGEMENT + "/roles"
  );

  public static MY_OVERVIEW: View = new View(
    "overview",
    Role.USER,
    Navigation.MY_OVERVIEW,
    "/" + Module.HR_MANAGER + "/overview"
  );
  public static EMPLOYEES_MANAGEMENT: View = new View(
    "employees",
    Role.HR_MANAGER,
    Navigation.EMPLOYEE_MANAGEMENT,
    "/" + Module.HR_MANAGER + "/employees"
  );
  public static DOCUMENT_MANAGEMENT: View = new View(
    "documents",
    Role.HR_MANAGER,
    Navigation.DOCUMENT_MANAGEMENT,
    "/" + Module.HR_MANAGER + "/documents"
  );

  public static TRANSACTIONS: View = new View(
    "transactions",
    Role.TRANSACTION,
    Navigation.TRANSACTIONS,
    "/" + Module.FINANCE + "/transactions"
  );
  public static PLANNED_TRANSACTIONS: View = new View(
    "plans",
    Role.TRANSACTION,
    Navigation.PLANNED_TRANSACTIONS,
    "/" + Module.FINANCE + "/plans"
  );

  public static SUMMARY: View = new View(
    "summary",
    Role.ADMIN,
    Navigation.SUMMARY,
    "/" + Module.FINANCE + "/summary"
  );
  public static WORK: View = new View(
    "work",
    Role.ADMIN,
    Navigation.WORK,
    "/" + Module.FINANCE + "/work"
  );
  public static LOANS: View = new View(
    "loans",
    Role.ADMIN,
    Navigation.LOANS,
    "/" + Module.FINANCE + "/loans"
  );
  public static LOGIN: View = new View(
    "login",
    Role.USER,
    Navigation.PERSON,
    "/login"
  );

  private readonly _name: string;
  private readonly _requiredRole: string;
  private readonly _translation: Translation;
  private readonly _path: string;

  constructor(
    name: string,
    requiredRole: string,
    translation: Translation,
    path: string
  ) {
    this._name = name;
    this._requiredRole = requiredRole;
    this._translation = translation;
    this._path = path;
  }

  get name(): string {
    return this._name;
  }

  get requiredRole(): string {
    return this._requiredRole;
  }

  get translation(): Translation {
    return this._translation;
  }

  get path(): string {
    return this._path;
  }
}
