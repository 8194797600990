import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Table,
} from "semantic-ui-react";
import SimpleLoader from "../../../components/SimpleLoader";
import { View } from "../../../base/enums/View";
import { useHistory } from "react-router-dom";
import InvoiceSubjectsViewMobile from "./InvoiceSubjectsViewMobile";
import FullTextFilter from "../../../components/base/FullTextFilter";
import {InvoiceSubjectDto, useGetInvoiceSubjects} from '../../../api/generated-sources'

export default function InvoiceSubjectsView() {
  const [subjects, setSubjects] = useState<InvoiceSubjectDto[]>([]);
  const [column, setColumn] = useState<string>();
  const [direction, setDirection] = useState<"ascending" | "descending">(
    "ascending"
  );
  const [subjectFilter, setSubjectFilter] = useState<InvoiceSubjectDto[]>([]);

  const history = useHistory();

  const {data, isLoading, error} = useGetInvoiceSubjects();

  useEffect(() => {
    if (data)
    {
      setSubjects(data)
    }
  }, [data]);

  useEffect(() => {
    console.log("Error",  error)
  }, [error]);

  const [isMobile, setIsMobile] = useState(false);

  const getResize = () => {
    if (window.innerWidth <= 770) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    getResize();

    window.addEventListener("resize", getResize);

    return () => {
      window.removeEventListener("resize", getResize);
    };
  }, []);

  const handleSort = (clickedColumn: string) => {
    setSubjectFilter([])
    if (column === clickedColumn) {
      setDirection(direction === "ascending" ? "descending" : "ascending");
    } else {
      setColumn(clickedColumn);
      setDirection("ascending");
    }
  };

  const sortedData = useMemo(() => {
    const sorted = [...subjects].sort((a, b) => {
      const columnType = typeof a[column as keyof InvoiceSubjectDto];
      if (columnType === "string") {
        return direction === "ascending"
          ? a[column as keyof InvoiceSubjectDto]
              .toString()
              .localeCompare(b[column as keyof InvoiceSubjectDto].toString())
          : b[column as keyof InvoiceSubjectDto]
              .toString()
              .localeCompare(a[column as keyof InvoiceSubjectDto].toString());
      } else if (columnType === "number") {
        return direction === "ascending"
          ? Number(a[column as keyof InvoiceSubjectDto]) -
              Number(b[column as keyof InvoiceSubjectDto])
          : Number(b[column as keyof InvoiceSubjectDto]) -
              Number(a[column as keyof InvoiceSubjectDto]);
      } else {
        return 0;
      }
    });
    return sorted;
  }, [column, direction]);

  return (
    <>
      {!isMobile ? (
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header>Adresář odběratelů</Header>
              </Grid.Column>
            </Grid.Row>
            {isLoading ? (
              <SimpleLoader text={"Načítám seznam odběratelů"} />
            ) : (
              <Grid.Row>
                <Grid.Column width={16}>
                  <FullTextFilter
                    data={subjects}
                    returnFilteredData={setSubjectFilter}
                    formatData={{
                      formatDateType: "DD. MM. yyyy",
                      activeItem: [
                        "name",
                        "street",
                        "zipCode",
                        "city",
                        "country",
                        "id",
                        "phone",
                        "email",
                        "ic",
                      ],
                    }}
                  />
                  <Table
                    basic
                    sortable={true}
                    striped
                    selectable
                    className={"invoices"}
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell
                          sorted={column === "name" ? direction : null}
                          onClick={() => {
                            setColumn("name");
                            handleSort("name");
                          }}
                        >
                          Odběratel
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={column === "ic" ? direction : null}
                          onClick={() => {
                            setColumn("ic");
                            handleSort("ic");
                          }}
                        >
                          IČ
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={column === "city" ? direction : null}
                          onClick={() => {
                            setColumn("city");
                            handleSort("city");
                          }}
                        >
                          Adresa
                        </Table.HeaderCell>
                        <Table.HeaderCell>Telefon</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {subjects.filter((subject) => subject.archived === false)
                        .length === 0 && (
                        <Table.Row disabled>
                          <Table.Cell textAlign={"center"} colSpan={6}>
                            Žádní aktivní odběratelé
                          </Table.Cell>
                        </Table.Row>
                      )}
                      {subjectFilter.length
                        ? subjectFilter
                            .filter((subject) => subject.archived === false)
                            .map((item) => {
                              return (
                                <Table.Row
                                  onClick={() =>
                                    history.push(
                                      `${
                                        View.INVOICES_SUBJECTS.path +
                                        "/" +
                                        item.id
                                      }`
                                    )
                                  }
                                  key={item.id}
                                >
                                  <Table.Cell singleLine>
                                    {item.name}
                                  </Table.Cell>
                                  <Table.Cell singleLine>{item.ic}</Table.Cell>
                                  <Table.Cell singleLine>
                                    {[
                                      item.street,
                                      item.zipCode,
                                      item.city,
                                      item.country,
                                    ]
                                      .filter((field) => !!field)
                                      .join(", ")}
                                  </Table.Cell>
                                  <Table.Cell singleLine>
                                    {item.phone}
                                  </Table.Cell>
                                  <Table.Cell singleLine>
                                    {item.email}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            })
                        : sortedData.length
                        ? sortedData
                            .filter((subject) => subject.archived === false)
                            .map((item) => {
                              return (
                                <Table.Row
                                  onClick={() =>
                                    history.push(
                                      `${
                                        View.INVOICES_SUBJECTS.path +
                                        "/" +
                                        item.id
                                      }`
                                    )
                                  }
                                  key={item.id}
                                >
                                  <Table.Cell singleLine>
                                    {item.name}
                                  </Table.Cell>
                                  <Table.Cell singleLine>{item.ic}</Table.Cell>
                                  <Table.Cell singleLine>
                                    {[
                                      item.street,
                                      item.zipCode,
                                      item.city,
                                      item.country,
                                    ]
                                      .filter((field) => !!field)
                                      .join(", ")}
                                  </Table.Cell>
                                  <Table.Cell singleLine>
                                    {item.phone}
                                  </Table.Cell>
                                  <Table.Cell singleLine>
                                    {item.email}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            })
                        : subjects
                            .filter((subject) => subject.archived === false)
                            .map((item) => {
                              return (
                                <Table.Row
                                  onClick={() =>
                                    history.push(
                                      `${
                                        View.INVOICES_SUBJECTS.path +
                                        "/" +
                                        item.id
                                      }`
                                    )
                                  }
                                  key={item.id}
                                >
                                  <Table.Cell singleLine>
                                    {item.name}
                                  </Table.Cell>
                                  <Table.Cell singleLine>{item.ic}</Table.Cell>
                                  <Table.Cell singleLine>
                                    {[
                                      item.street,
                                      item.zipCode,
                                      item.city,
                                      item.country,
                                    ]
                                      .filter((field) => !!field)
                                      .join(", ")}
                                  </Table.Cell>
                                  <Table.Cell singleLine>
                                    {item.phone}
                                  </Table.Cell>
                                  <Table.Cell singleLine>
                                    {item.email}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            })}
                    </Table.Body>
                    <Table.Footer>
                      <Table.Row>
                        <Table.Cell colSpan={16} textAlign={"right"}>
                          <Button
                            color={"green"}
                            onClick={() =>
                              history.push(View.INVOICES_SUBJECTS.path + "/new")
                            }
                          >
                            <Icon name={"plus"} />
                            Přidat odběratele
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                  <br />
                  <br />
                  {subjects.filter((subject) => subject.archived === true)
                    .length !== 0 && (
                    <div>
                      <h4>Archivovaní odběratelé</h4>
                      <p>
                        <Icon name={"info circle"} /> Tito odběratelé nebudou
                        dostupné při tvorbě nových faktur.
                      </p>
                      <Table basic striped selectable className={"invoices"}>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Odběratel</Table.HeaderCell>
                            <Table.HeaderCell>IČ</Table.HeaderCell>
                            <Table.HeaderCell>Adresa</Table.HeaderCell>
                            <Table.HeaderCell>Telefon</Table.HeaderCell>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {subjects
                            .filter((subject) => subject.archived === true)
                            .map((item) => {
                              return (
                                <Table.Row
                                  onClick={() =>
                                    history.push(
                                      `${
                                        View.INVOICES_SUBJECTS.path +
                                        "/" +
                                        item.id
                                      }`
                                    )
                                  }
                                  key={item.id}
                                >
                                  <Table.Cell singleLine>
                                    {item.name}
                                  </Table.Cell>
                                  <Table.Cell singleLine>{item.ic}</Table.Cell>
                                  <Table.Cell singleLine>
                                    {[
                                      item.street,
                                      item.zipCode,
                                      item.city,
                                      item.country,
                                    ]
                                      .filter((field) => !!field)
                                      .join(", ")}
                                  </Table.Cell>
                                  <Table.Cell singleLine>
                                    {item.phone}
                                  </Table.Cell>
                                  <Table.Cell singleLine>
                                    {item.email}
                                  </Table.Cell>
                                </Table.Row>
                              );
                            })}
                        </Table.Body>
                      </Table>
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Container>
      ) : (
        <InvoiceSubjectsViewMobile />
      )}
    </>
  );
}
