import React, { createContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { QueryUtil } from "../base/util/QueryUtil";
import { FilterUtils } from "../base/util/FilterUtils";

export type FilterValue = {
  month: number;
  year?: number;
  setMonth: (month: number) => void;
  setYear?: (year: number) => void;
  invoiceFilter?: string;
  invoicePurchaser?: number;
  invoiceTimeframe?: string;
  searchField?: string;
  setInvoiceFilter?: (filter: string) => void;
  setInvoicePurchaser?: (purchaser: number) => void;
  setInvoiceTimeframe?: (invoiceTimeframe: string) => void;
  setSearchField?: (searchField: string) => void;
};
const now = new Date();

export const initialValue: FilterValue = {
  month: now.getMonth() + 1,
  year: now.getFullYear(),
  setMonth: () => {},
  setYear: () => {},
  invoiceFilter: "all",
  invoicePurchaser: null,
  invoiceTimeframe: "",
  searchField: "",
  setInvoiceFilter: () => {},
  setInvoicePurchaser: () => {},
  setInvoiceTimeframe: () => {},
  setSearchField: () => {},
};
export const FilterContext = createContext<FilterValue>(initialValue);

export default function FilterContextProvider(props: any) {
  const location = useLocation();
  const queryYear = QueryUtil.getParam(location.search, "y");
  const queryMonth = QueryUtil.getParam(location.search, "m");
  const queryInvoicePurchaser = QueryUtil.getParam(location.search, "o");
  const queryInvoiceFilter = QueryUtil.getParam(location.search, "f");
  const queryInvoiceTimeframe = QueryUtil.getParam(location.search, "zp");
  const querySearchField = QueryUtil.getParam(location.search, "s");

  let initYear = queryYear ? Number.parseInt(queryYear) : initialValue.year;
  let initMonth = queryMonth ? Number.parseInt(queryMonth) : initialValue.month;

  initYear = FilterUtils.getYearWithinBounds(initYear);
  initMonth = FilterUtils.getMonthWithinBounds(initMonth, initYear);

  const [year, setYear] = useState<number>(initYear);
  const [month, setMonth] = useState<number>(initMonth);
  const [invoiceFilter, setInvoiceFilter] = useState<string>(queryInvoiceFilter);
  const [invoicePurchaser, setInvoicePurchaser] = useState<number | null>(
    queryInvoicePurchaser ? +queryInvoicePurchaser : null
  );
  const [invoiceTimeframe, setInvoiceTimeframe] = useState<string>(queryInvoiceTimeframe);
  const [searchField, setSearchField] = useState<string>(querySearchField);

  return (
    <FilterContext.Provider
      value={{
        month,
        year,
        invoiceFilter: invoiceFilter,
        invoicePurchaser,
        invoiceTimeframe,
        searchField,
        setMonth,
        setYear,
        setInvoiceFilter: setInvoiceFilter,
        setInvoiceTimeframe,
        setInvoicePurchaser,
        setSearchField,
      }}
    >
      {props.children}
    </FilterContext.Provider>
  );
}
