import React, {useEffect, useState} from "react";
import { Container, Grid, Header, Table } from "semantic-ui-react";
import SimpleLoader from "../../../components/SimpleLoader";
import {getRoles, RoleDto, UserDto} from '../../../api/generated-sources'

export default function RoleManagementView() {
  const [roles, setRoles] = useState<RoleDto[]>(undefined);
  const [loading, setLoading] = useState<boolean>(true)


  useEffect(() => {
    getRoles()
      .then((data: UserDto[]) => {
        setRoles(data);
        setLoading(false)
      })
      .catch(console.error);
  }, []);

  const displayLoader = loading || !roles;

  return (
    <Container>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header>Správa uživatelských rolí</Header>
          </Grid.Column>
        </Grid.Row>
        {displayLoader && <SimpleLoader text={"Načítám uživatelské role"} />}
        {!displayLoader && roles && (
          <Grid.Row>
            <Grid.Column width={16}>
              <Table basic striped className={"roles"}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Role</Table.HeaderCell>
                    <Table.HeaderCell>Popis</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {roles.length === 0 && (
                    <Table.Row>
                      <Table.Cell textAlign={"center"} colSpan={7}>
                        Neevidujeme žádné uživatelské role
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {roles.map((item) => {
                    return (
                      <Table.Row key={item.id}>
                        <Table.Cell singleLine>
                          {item.name ?? item.name}
                        </Table.Cell>
                        <Table.Cell singleLine>
                          {item.description ?? item.description}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Container>
  );
}
