export class DateUtil {
  static REGEX_VALID_DATE = "202[0-9]-[0-1][0-9]-[0-3][0-9]";

  public static isCurrentMonth(year: number, month: number) {
    const today = new Date();
    return today.getFullYear() === year && today.getMonth() === month - 1;
  }

  public static isFutureMonth(year: number, month: number) {
    const today = new Date();
    if (today.getFullYear() < year) {
      return true;
    }
    if (today.getFullYear() > year) {
      return false;
    }
    return today.getMonth() + 1 < month;
  }

  public static isPastMonth(year: number, month: number) {
    const today = new Date();
    if (today.getFullYear() < year) {
      return false;
    }
    if (today.getFullYear() > year) {
      return true;
    }
    return today.getMonth() + 1 > month;
  }

  public static getMonthLength(year: number, month: number): number {
    if ([0, 2, 4, 6, 7, 9, 11].includes(month)) {
      return 31;
    } else if (month === 1 && year % 4 === 0) {
      return 29;
    } else if (month === 1 && year % 4 !== 0) {
      return 28;
    }
    return 30;
  }

  public static isAfterToday(date: Date) {
    const today = new Date();
    if (today.getFullYear() < date.getFullYear()) {
      return true;
    }
    if (today.getFullYear() === date.getFullYear() && today.getMonth() < date.getMonth()) {
      return true;
    }
    if (today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() < date.getDate()) {
      return true;
    }
    return false;
  }

  public static isAfterTodayInclusive(date: Date) {
    const today = new Date();
    if (today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === date.getDate()) {
      return true;
    }
    return DateUtil.isAfterToday(date);
  }

  public static isBeforeToday(date: Date) {
    return !DateUtil.isAfterTodayInclusive(date);
  }

  public static isBeforeTodayInclusive(date: Date) {
    const today = new Date();
    if (today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === date.getDate()) {
      return true;
    }
    return DateUtil.isBeforeToday(date);
  }

  public static getHoursAgo(hoursAgo: number) {
    let now = new Date();
    now.setHours(now.getHours() - hoursAgo);
    return now;
  }

  public static isCurrentlyValid(from: Date, to: Date) {
    const today = new Date();
    return (!from || today >= (new Date(from))) && (!to || today <= (new Date(to)))
  }
}
