import {Button, DropdownProps, Form, Icon, List, Modal} from "semantic-ui-react";
import {useParams} from "react-router-dom";
import toast from "react-hot-toast";
import React, {useEffect, useState} from "react";
import {StringUtil} from "../../../base/util/StringUtil";
import {SyncOperationState} from "../../../base/state/SyncOperationState";
import {DocumentTypeUtil} from "../../../base/util/DocumentTypeUtil";
import {
  createSalary, deleteSalary, deleteSalaryAttachment, EDocumentType,
  FileAttachmentDto,
  SalaryInputDto,
  updateSalary
} from '../../../api/generated-sources'
import {FileUploadUtil} from '../../../base/util/FileUploadUtil'
import {LocalDate} from '../../../build/core/dto/LocalDate'

type SalaryTableModalProps = {
  selectedRow: SalaryInputDto;
  close?: () => void;
  isOpen?: boolean;
  handleUpdate: () => void;
  handleDelete: (id: number) => void;
};

export function SalaryTableModal(props: SalaryTableModalProps) {
  const {
    selectedRow,
    isOpen,
    close,
    handleUpdate,
    handleDelete,
  } = props;

  const { id } = useParams<{ id: string }>();
  const [salaryType, setSalaryType] = useState<string>("monthly");
  const [salary, setSalary] = useState<SalaryInputDto>(selectedRow);
  const [syncState, setSyncState] = useState<SyncOperationState>({
    inProgress: false,
  });
  const [deleteState, setDeleteState] = useState<SyncOperationState>({
    inProgress: false,
  });

  const handleDeleteAttachment = (id: number) => {
    setDeleteState({inProgress: true, type: "delete"});
    const promise = deleteSalaryAttachment(id);
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Dokument byl úspěšně smazán.",
        error: "Něco se pokazilo!",
      }
    );
    promise
      .then(data => {
        setDeleteState({inProgress: false, type: undefined});
        setSalary({
          ...salary,
          attachment: undefined,
          filename: undefined,
          documentUrl: undefined,
          documentType: undefined,
        })
        handleUpdate();
      })
      .catch(console.error);
  }

  const handleChangeAttachment = (attachment: FileAttachmentDto) => {
    setSalary({
      ...salary,
      attachment: attachment,
    });
  };

  const handleChange = (value: any, field: string) => {
    if (field === "hourly") {
      setSalary({
        ...salary,
        hourly: value,
        monthly: null,
      });
    } else if (field === "monthly") {
      setSalary({
        ...salary,
        hourly: null,
        monthly: value,
      });
    } else {
      setSalary({
        ...salary,
        [field]: value,
      });
    }
  };

  useEffect(() => {
    setSyncState({inProgress: false});
  }, [isOpen]);

  useEffect(() => {
    setSalary({
      ...selectedRow,
      monthly: selectedRow.monthly ?? 0,
      hourly: selectedRow.hourly ?? 0,
      validFrom: selectedRow.validFrom ?? new LocalDate((new Date()).getFullYear(), (new Date()).getMonth(), 1).toString()
    });
  }, [selectedRow]);

  const create = () => {
    setSyncState({inProgress: true});
    const newItem: SalaryInputDto= {
      validFrom: salary.validFrom ?? new Date().toString(),
      validTo: salary.validTo,
      monthly: salary.monthly,
      hourly: salary.hourly,
      attachment: salary.attachment,
      documentType: salary.documentType ?? EDocumentType.MZDOVY_VYMER,
      userId: +id,
    };

    const promise = createSalary(newItem)
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Záznam byl úspěšně vytvořen.",
        error: "Něco se pokazilo!",
      }
    ).then(() => close());
    promise
      .then(data => {
        setSyncState({inProgress: false});
        handleUpdate();
        close();
      })
      .catch(console.error)
  };

  const update = () => {
    setSyncState({inProgress: true});
    const updateItem = {
      hourly: salary.hourly,
      id: salary.id,
      monthly: salary.monthly,
      documentType: salary.documentType ?? EDocumentType.MZDOVY_VYMER,
      attachment: salary.attachment,
      userId: +id,
      validFrom: salary.validFrom,
      validTo: salary.validTo ? salary.validTo : null,
    };

    const promise = updateSalary(updateItem.id, updateItem);
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Záznam byl úspěšně upraven.",
        error: "Něco se pokazilo!",
      }
    ).then(() => close());
    promise
      .then(data => {
        setSyncState({inProgress: false, type: undefined});
        handleUpdate();
        close();
      })
      .catch(console.error);
  };

  const handleDeleteSalary = () => {
    setSyncState({inProgress: true, type: "delete"});
    const promise = deleteSalary(salary.id);
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Záznam byl úspěšně smazán.",
        error: "Něco se pokazilo!",
      }
    ).then(() => close());
    promise
      .then(data => {
        setSyncState({inProgress: false, type: undefined});
        handleDelete(salary.id);
        close();
      })
      .catch(console.error);
  };

  const requiredFieldsPresent = () => {
    let definiteSalaryPresent = salaryType === "monthly" ? !!salary.monthly : !!salary.hourly;
    return definiteSalaryPresent && salary.validFrom;
  };

  return (
    <Modal
      className={"invoice-detail"}
      onClose={close}
      open={isOpen}
      size={"small"}
      closeIcon
    >
      <Modal.Header>
        <Icon name={'edit'} /> {salary.id ? "Úprava mzdové historie" : "Nová změna mzdy"}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths={2}>
            <Form.Select
              required
              width={8}
              options={[
                { text: "měsíční sazba", value: "monthly" },
                { text: "za hodinu", value: "hourly" },
              ]}
              label={"Částka"}
              onChange={(e, data) => setSalaryType(data.value as string)}
              defaultValue={salary.hourly ? "hourly" : "monthly"}
            />
            {salaryType === "monthly" && (
              <Form.Input
                required
                width={8}
                label={"Měsíční částka"}
                onChange={(e) => handleChange(e?.target.value, "monthly")}
                defaultValue={salary.monthly && salary.monthly}
              />
            )}
            {salaryType === "hourly" && (
              <Form.Input
                required
                width={8}
                label={"Částka za MH"}
                onChange={(e) => handleChange(e?.target.value, "hourly")}
                defaultValue={salary.hourly && salary.hourly}
              />
            )}
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Input
              label="Platnost od"
              required
              type="date"
              value={salary.validFrom}
              onChange={(e) => handleChange(e?.target.value, "validFrom")}
            />
            <Form.Field>
              <label>Platnost do</label>
              <Form.Input
                type="date"
                value={salary.validTo}
                onChange={(e) => handleChange(e?.target.value, "validTo")}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Select
              value={salary.documentType ?? EDocumentType.MZDOVY_VYMER}
              required
              width={8}
              label={"Typ dokumentu"}
              options={DocumentTypeUtil.getSalaryTypeOptions()}
              onChange={(e, data: DropdownProps) => handleChange(data.value, "documentType")}
            />
            <List relaxed className={"formUpload"}>
              {!salary.documentUrl && (
                <List.Item>
                  <Button disabled={syncState.inProgress} icon labelPosition="right"
                    onClick={() =>
                      window.document.getElementById("uploadAttachmentInput")?.click()
                    }
                  >
                  {salary.documentUrl ? "Zobrazit dokument" : salary.attachment ? StringUtil.shortenWithEllipsis(salary.attachment.filename, 27) : "Nahrát dokument"} <Icon name="upload" />
                  </Button>
                </List.Item>
              )}
              {
                !salary.documentUrl && <List.Item className={syncState.error ? "highlight-warning" : null}>
                  Limit 100MB (formáty .png, .jpg, .pdf) {syncState.error && <Icon name={"warning"} />}
                </List.Item>
              }
              {!!salary.documentUrl && (
                <List.Item>
                  <Button color={"black"} icon labelPosition="left" onClick={() => window.open(salary.documentUrl)}>
                    <Icon name="download" /> Zobrazit dokument
                  </Button>
                  <Button basic icon={"trash"} color={"red"} loading={deleteState.inProgress} onClick={(e) => handleDeleteAttachment(salary.id)} />
                </List.Item>
              )}
              <List.Item>
                  <input
                    hidden
                    id={"uploadAttachmentInput"}
                    type={"file"}
                    accept="image/png, image/jpeg, application/pdf"
                    onChange={(event) => FileUploadUtil.setAttachment(event, handleChangeAttachment, setSyncState)}
                  />
              </List.Item>
            </List>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions className="button-ppv">
        {salary.id ? <>
          <Button color={"red"} floated="left" type="submit" icon={"trash alternate"}
                  loading={syncState.inProgress && syncState.type === "delete"}
                  disabled={syncState.inProgress}
                  onClick={handleDeleteSalary} />
          <Button color={"black"} floated="right" type="submit"
                  loading={syncState.inProgress && syncState.type !== "delete"}
                  disabled={syncState.inProgress || !requiredFieldsPresent()}
                  onClick={update}>
            <Icon name={"save"} /> Uložit změny
          </Button>
        </> : <Button color={"black"} floated="right" type="submit"
                      onClick={create}
                      loading={syncState.inProgress && syncState.type !== "delete"}
                      disabled={syncState.inProgress || !requiredFieldsPresent()}
        >
          <Icon name={"save"} /> Vytvořit
        </Button>
        }
      </Modal.Actions>
    </Modal>
  );
}
