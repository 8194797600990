/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
import useSwr from "swr";
import type { Key, SWRConfiguration } from "swr";
import useSWRMutation from "swr/mutation";
import type { SWRMutationConfiguration } from "swr/mutation";
import type { ErrorResponseResponse, RoleDto } from "../intranetApi.schemas";
import { IntranetClientFetchInstance } from "../../intranetClientFetchInstance";
import type { ErrorType } from "../../intranetClientFetchInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get roles
 */
export const getRoles = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<RoleDto[]>(
    { url: `/role`, method: "GET" },
    options
  );
};

export const getGetRolesKey = () => [`/role`] as const;

export type GetRolesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRoles>>
>;
export type GetRolesQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get roles
 */
export const useGetRoles = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof getRoles>>, TError> & {
    swrKey?: Key;
    enabled?: boolean;
  };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetRolesKey() : null));
  const swrFn = () => getRoles(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary update role
 */
export const updateRole = (
  id: number,
  roleDto: RoleDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<RoleDto>(
    {
      url: `/role/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: roleDto,
    },
    options
  );
};

export const getUpdateRoleMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, { arg }: { arg: RoleDto }): Promise<RoleDto> => {
    return updateRole(id, arg, options);
  };
};
export const getUpdateRoleMutationKey = (id: number) => `/role/${id}` as const;

export type UpdateRoleMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateRole>>
>;
export type UpdateRoleMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary update role
 */
export const useUpdateRole = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateRole>>,
      TError,
      string,
      RoleDto,
      Awaited<ReturnType<typeof updateRole>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getUpdateRoleMutationKey(id);
  const swrFn = getUpdateRoleMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
