import { Icon, Popup, Table } from "semantic-ui-react";
import { Formatter } from "../../../base/util/Formatter";
import { InvoiceUtil } from "../../../base/util/InvoiceUtil";
import { useHistory } from "react-router-dom";
import { View } from "../../../base/enums/View";
import {MathUtil} from "../../../base/util/MathUtil";
import {InvoiceDto} from '../../../api/generated-sources'
import moment from "moment";

interface InvoiceTableRowProps {
  invoice: InvoiceDto;
}

export default function InvoiceTableRow(props: InvoiceTableRowProps) {
  const itemsWithoutRounding = props.invoice.invoiceItems.filter(
    (i) => i.name !== InvoiceUtil.ROUNDING_ITEM_NAME
  );
  const history = useHistory();
  return (
    <Table.Row
      onClick={() =>
        history.push(`${View.INVOICES.path + "/" + props.invoice.id}`)
      }
      key={props.invoice.id}
    >
      {InvoiceUtil.getIsPaidPopup(props.invoice, MathUtil.sum(props.invoice.transactions.map(item => item.amount)))}
      <Table.Cell singleLine>
        {props.invoice.invoiceNumber ?? props.invoice.vs}
      </Table.Cell>
      <Table.Cell singleLine>
        {props.invoice.invoiceSubject ? props.invoice.invoiceSubject.name : ""}
      </Table.Cell>
      {!!itemsWithoutRounding && itemsWithoutRounding.length > 1 && (
        <Popup
          trigger={
            <Table.Cell>
              Více položek ({itemsWithoutRounding.length})
            </Table.Cell>
          }
        >
          {itemsWithoutRounding.map((i) => {
            return (
              <span key={i.id}>
                {i.name}
                <br />
              </span>
            );
          })}
        </Popup>
      )}
      {!!itemsWithoutRounding && itemsWithoutRounding.length === 1 && (
        <Table.Cell>{itemsWithoutRounding[0].name}</Table.Cell>
      )}
      {(!itemsWithoutRounding || itemsWithoutRounding.length === 0) && (
        <Table.Cell>Bez položek</Table.Cell>
      )}
      <Table.Cell singleLine>{moment(props.invoice.dateCreated).format("D. M. YYYY")}</Table.Cell>
      <Table.Cell singleLine>{moment(props.invoice.dateTax).format("D. M. YYYY")}</Table.Cell>
      <Table.Cell singleLine>{moment(props.invoice.dateDue).format("D. M. YYYY")}</Table.Cell>
      <Table.Cell singleLine textAlign={"right"}>
        {Formatter.money(props.invoice.totalPrice)}
      </Table.Cell>
      <Table.Cell singleLine textAlign={"right"}>
        {Formatter.money(props.invoice.totalPriceWithVat)}
      </Table.Cell>
      <Table.Cell
        textAlign={"center"}
        onClick={(e: Event) => {
          e.stopPropagation();
        }}
      >
        {!!props.invoice.attachmentUrl && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={props.invoice.attachmentUrl}
          >
            <Icon color="black" name="download" />
          </a>
        )}
        {!props.invoice.attachmentUrl && (
          <Icon.Group>
            <Icon color="red" size={"big"} name="circle outline" />
            <Icon color="black" name="file alternate outline" />
            <Icon color="red" size={"small"} corner name="warning" />
          </Icon.Group>
        )}
      </Table.Cell>
    </Table.Row>
  );
}
