import {
  Button, Divider,
  DropdownProps,
  Form,
  Grid,
  Header,
  Icon,
  Table,
} from "semantic-ui-react";
import { Formatter } from "../../../base/util/Formatter";
import React, { useEffect, useState } from "react";
import { SyncOperationState } from "../../../base/state/SyncOperationState";
import InvoiceSubjectSelector from "./InvoiceSubjectSelector";
import { DateUtil } from "../../../base/util/DateUtil";
import { VatUtil } from "../../../base/util/VatUtil";
import { StringUtil } from "../../../base/util/StringUtil";
import SimpleLoader from "../../../components/SimpleLoader";
import { InvoiceUtil } from "../../../base/util/InvoiceUtil";
import { View } from "../../../base/enums/View";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/base/Breadcrumbs";
import toast from "react-hot-toast";
import {MathUtil} from "../../../base/util/MathUtil";
import InvoicePaymentGraph from "./InvoicePaymentGraph";
import {
  createInvoice, deleteInvoice,
  getInvoice, getNextInvoiceNumber,
  getTransactions,
  InvoiceDto,
  InvoiceItemDto,
  pairInvoice,
  TransactionDto,
  unpairInvoice,
  updateInvoice,
} from '../../../api/generated-sources'
import {LocalDate} from '../../../build/core/dto/LocalDate'
import moment from "moment/moment";

export default function InvoiceDetail() {

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [invoice, setInvoice] = useState<InvoiceDto>();

  const getBlankInvoiceItem = (
    name?: string,
    amount?: number,
    vat?: number,
    price?: number
  ) => {
    return {
      name: name ?? "",
      amount: amount ?? 1,
      price: price ?? 0,
      vat: vat ?? 21,
      paid: false,
    };
  };

  const isRoundingItem = (item: InvoiceItemDto) => {
    return item.vat === 0 && item.price < 1 && item.price > -1;
  };

  const [saveState, setSaveState] = useState<SyncOperationState>({
    inProgress: false,
    error: false,
  });

  const [deleteState, setDeleteState] = useState<SyncOperationState>({
    inProgress: false,
  });


  const today = new Date();
  const [transactions, setTransactions] = useState<TransactionDto[]>();
  const [useRoundingItem, setUseRoundingItem] = useState<boolean>(true);
  const [invoiceItems, setInvoiceItems] = useState<InvoiceItemDto[]>([getBlankInvoiceItem()]);
  const [roundingItem, setRoundingItem] = useState<InvoiceItemDto>(getBlankInvoiceItem(InvoiceUtil.ROUNDING_ITEM_NAME, 1, 0, 0));
  const [subjectFilter, setSubjectFilter] = useState<number>();

  const onSaveInvoiceSuccess = (invoice: InvoiceDto) => {
    setInvoice(invoice);
    setSaveState({
      inProgress: false,
      error: false,
    });
  };

  const onInvoiceLoad = (invoice : InvoiceDto) => {
    const roundingItems = invoice ? invoice.invoiceItems.filter((item) => isRoundingItem(item)) : [];
    const nonRoundingItems = invoice ? invoice.invoiceItems.filter((item) => !isRoundingItem(item)) : [];
    setInvoiceItems(
      nonRoundingItems?.length > 0 ? nonRoundingItems : [getBlankInvoiceItem()]
    );
    setRoundingItem(roundingItems.length > 0 ? roundingItems[0] : getBlankInvoiceItem(InvoiceUtil.ROUNDING_ITEM_NAME, 1, 0, 0))
    getTransactions({
      from: invoice.dateCreated,
      to: null,
      minAmount: 0,
      maxAmount: null,
      assignedToInvoice: false,
    })
      .then(transactions => {
        setTransactions(transactions)
      })
      .catch(console.error);
    setSubjectFilter(invoice.invoiceSubject.id);
  }

  const pairTransaction = (transactionId : number) => {
    setSaveState({
      ...saveState,
      inProgress: true,
    });

    const promise: Promise<InvoiceDto> = pairInvoice(invoice.id, {invoiceId: invoice.id, transactionId: transactionId})
    toast.promise(promise, {
      loading: "Přiřazuji platbu k faktuře...",
      success: "Platba byla úspěšně přiřazená",
      error: "Něco se pokazilo!",
    });
    promise.then((i: InvoiceDto) => {
      setInvoice(i);
      onInvoiceLoad(invoice);
      setSaveState({inProgress: false});
    }).catch(console.error);
  }

  const unpairTransaction = (transactionId : number) => {
    setSaveState({
      ...saveState,
      inProgress: true,
    });
    const promise = unpairInvoice(invoice.id, {invoiceId: invoice.id, transactionId: transactionId})
    toast.promise(promise, {
      loading: "Odstraňuji párování platby z faktury...",
      success: "Platba byla úspěšně odpárovaná",
      error: "Něco se pokazilo!",
    });
    promise.then((i) => {
      setInvoice(i);
      onInvoiceLoad(invoice);
      setSaveState({inProgress: false});
    }).catch(console.error)
  }

  const save = () => {
    setSaveState({
      ...saveState,
      inProgress: true,
    });
    const ii = Object.assign([], invoiceItems);
    if (useRoundingItem && roundingItem.price !== 0) {
      const ri = Object.assign({}, roundingItem);
      ii.push(ri);
    }

    const requestInvoice: InvoiceDto = {
      id: invoice.id,
      invoiceNumber: StringUtil.isEmpty(invoice.invoiceNumber) ? null : invoice.invoiceNumber,
      orderNumber: StringUtil.isEmpty(invoice.orderNumber) ? null : invoice.orderNumber,
      dateCreated: invoice.dateCreated,
      dateDue: invoice.dateDue,
      dateTax: invoice.dateTax,
      vs: StringUtil.isEmpty(invoice.vs) ? null : invoice.vs,
      ks: StringUtil.isEmpty(invoice.ks) ? null : invoice.ks,
      ss: StringUtil.isEmpty(invoice.ss) ? null : invoice.ss,
      comment: StringUtil.isEmpty(invoice.comment) ? null : invoice.comment,
      invoiceSubject: {
        id: subjectFilter,
      },
      invoiceItems: ii.map((i) => {
        return {
          id: i.id,
          amount: i.amount,
          name: i.name,
          price: i.price,
          vat: i.vat,
        };
      }),
    };
    if (+id) {
      const promise = updateInvoice(requestInvoice.id, requestInvoice);
      toast.promise(promise, {
          loading: "Upravuji fakturu a generuji nové PDF...",
          success: "Faktura byla úspěšně upravená.",
          error: "Něco se pokazilo!",
        })
        .then(() => history.push(View.INVOICES.path));
      promise.then(i => {
        onSaveInvoiceSuccess(i);
      }).catch(reason => {
        setSaveState({ inProgress: false, error: true });
      })
    } else {
      const promise = createInvoice(requestInvoice);
      toast.promise(promise, {
          loading: "Generuji novou fakturu...",
          success: "Faktura byla úspěšně vytvořená.",
          error: "Něco se pokazilo!",
        })
        .then(() => history.push(View.INVOICES.path));
      promise.then(i => {
        onSaveInvoiceSuccess(i);
      }).catch(reason => {
        setSaveState({ inProgress: false, error: true });
      })
    }
  };

  const handleChange = (e: any, field: string) => {
    let newValue = e.target.value;
    setInvoice({
      ...invoice,
      [field]: newValue,
    });
  };

  const handleChangeInvoiceItem = (e: any, id: number, field: string) => {
    const updateItems: InvoiceItemDto[] = Object.assign([], invoiceItems);
    updateItems[id] = {
      ...updateItems[id],
      [field]: typeof e === "number" ? e : e.target.value,
    };
    if (["amount", "price", "vat"].includes(field)) {
      recalculateRoundingItem(updateItems);
    }
    setInvoiceItems(updateItems);
  };

  const addInvoiceItem = () => {
    const updateItems: InvoiceItemDto[] = Object.assign([], invoiceItems);
    updateItems.push(getBlankInvoiceItem());
    setInvoiceItems(updateItems);
  };

  const removeInvoiceItem = (id: number) => {
    const updatedInvoiceItems = invoiceItems.filter((item, key) => {
      return key !== id;
    });
    setInvoiceItems(updatedInvoiceItems);
  };

  const getTotalInvoicePrice = (includeVat: boolean, includeRounding: boolean, input?: InvoiceItemDto[]) => {
    const items = Object.assign([], input ?? invoiceItems);
    if (includeRounding && useRoundingItem) {
      items.push(Object.assign({}, roundingItem));
    }
    return items
      .flatMap((i) => {
        if (includeVat) {
          return ((i.amount * i.price) / 100) * (100 + i.vat);
        } else {
          return i.amount * i.price;
        }
      })
      .reduce((a, b) => a + b, 0);
  };

  const recalculateRoundingItem = (items: InvoiceItemDto[]) => {
    const totalInvoicePrice = getTotalInvoicePrice(true, false, items);
    const priceAfterDecimal = (totalInvoicePrice * 100) % 100;
    let roundingPrice = 0;
    if (priceAfterDecimal !== 0) {
      if (priceAfterDecimal >= 50) {
        roundingPrice = (100 - priceAfterDecimal) / 100;
      } else {
        roundingPrice = (-1 * priceAfterDecimal) / 100;
      }
    }
    setRoundingItem({
      ...roundingItem,
      price: roundingPrice,
    });
  };

  const deleteInv = () => {
    setDeleteState({
      inProgress: true,
    });

    toast.promise(
      deleteInvoice(+id),
      {
        loading: "Zpracovávám...",
        success: "Faktura byla úspěšně smazaná.",
        error: "Něco se pokazilo!",
      }
    )
    .then(() => {
      setDeleteState({inProgress: false});
      history.push(View.INVOICES.path);
    });
  };

  useEffect(() => {
    if (+id) {
      getInvoice(+id)
        .then(invoice => {
          console.log(invoice);
          setInvoice({
            ...invoice,
            dateCreated: invoice.dateCreated,
            dateDue: invoice.dateDue,
            dateTax: invoice.dateTax,
          });
          onInvoiceLoad(invoice);
        })
        .catch(reason => {
          toast.error("Něco se pokazilo!");
          console.error(reason)
        });
    } else {
      getNextInvoiceNumber({
        dateTax: new LocalDate().toISOString(),
      })
        .then((value: string) => {
          setInvoice({
            invoiceNumber: value,
            vs: value,
            orderNumber: "",
            ks: "",
            ss: "",
            comment: "",
            paid: false,
            dateCreated: new LocalDate().toISOString(),
            dateDue:
              new Date().getMonth() >= 11
                ? new LocalDate(today.getFullYear() + 1, 1, today.getDate()).toISOString()
                : new LocalDate(today.getFullYear(), today.getMonth() + 1, today.getDate()).toISOString(),
            dateTax: new LocalDate().toISOString(),
            transactions: []
          });
        })
        .catch(console.error);
    }
  }, [id]);

  const requiredFieldsPresent = () => {
    return (
      !(subjectFilter > 0) ||
      StringUtil.isEmpty(invoice.invoiceNumber) ||
      StringUtil.isEmpty(invoice.vs)
    );
  };

  const amountPaid = (invoice && invoice.transactions) ? MathUtil.sum(invoice.transactions.map(item => item.amount)) : 0;

  if (!invoice) {
    return <SimpleLoader text={"Načítám fakturu"} />
  }

  return (
    <>
      <div className={"invoice-detail"}>
        <Grid>
          <Grid.Column width={12}>
            <Breadcrumbs
              title={invoice.id ? `Detail faktury č.${invoice && invoice.invoiceNumber}` : "Nová faktura"}
            />
          </Grid.Column>
          {
            invoice.id && <Grid.Column width={4} textAlign={'right'}>
              {InvoiceUtil.getIsPaidLabel(invoice, amountPaid)}
            </Grid.Column>
          }
          <Grid.Row>
            <Grid.Column width={16}>
              <Form>
                <Form.Group>
                  <InvoiceSubjectSelector
                    required
                    width={6}
                    onSubjectChange={(id: number) => setSubjectFilter(id)}
                    nothingSelectedText="Vyberte odběratele"
                    value={invoice.invoiceSubject?.id}
                    label={"Odběratel"}
                  />
                  <Form.Input
                    width={2}
                    readOnly={!!invoice.id}
                    required
                    label={"Číslo dokladu"}
                    error={StringUtil.isEmpty(invoice.invoiceNumber)}
                    onChange={(event) => handleChange(event, "invoiceNumber")}
                    value={invoice.invoiceNumber}
                  />
                  <Form.Input
                    width={2}
                    label={"Číslo objednávky"}
                    onChange={(event) => handleChange(event, "orderNumber")}
                    value={invoice.orderNumber}
                  />
                  <Form.Input
                    width={2}
                    required
                    label={"VS"}
                    readOnly={!!invoice.id}
                    type={"text"}
                    error={StringUtil.isEmpty(invoice.vs)}
                    onChange={(event) => handleChange(event, "vs")}
                    value={invoice.vs}
                  />
                  <Form.Input
                    width={2}
                    label={"KS"}
                    type={"text"}
                    onChange={(event) => handleChange(event, "ks")}
                    value={invoice.ks}
                  />
                  <Form.Input
                    width={2}
                    label={"SS"}
                    type={"text"}
                    onChange={(event) => handleChange(event, "ss")}
                    value={invoice.ss}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Input
                    width={6}
                    required
                    label={"Datum vydání"}
                    error={
                      invoice.dateCreated &&
                      !new LocalDate(invoice.dateCreated)
                        .toString()
                        .match(DateUtil.REGEX_VALID_DATE)
                    }
                    onChange={(event) => handleChange(event, "dateCreated")}
                    value={invoice.dateCreated}
                    type={"date"}
                  />
                  <Form.Input
                    width={6}
                    required
                    label={"Datum zdanitelného plnění"}
                    error={
                      invoice.dateTax &&
                      !new LocalDate(invoice.dateTax)
                        .toString()
                        .match(DateUtil.REGEX_VALID_DATE)
                    }
                    onChange={(event) => handleChange(event, "dateTax")}
                    value={invoice.dateTax}
                    type={"date"}
                  />
                  <Form.Input
                    width={6}
                    required
                    label={"Datum splatnosti"}
                    error={
                      invoice.dateDue &&
                      !new LocalDate(invoice.dateDue)
                        .toString()
                        .match(DateUtil.REGEX_VALID_DATE)
                    }
                    onChange={(event) => handleChange(event, "dateDue")}
                    value={invoice.dateDue}
                    type={"date"}
                  />
                </Form.Group>
                <Form.Input
                  label={"Poznámka"}
                  onChange={(event) => handleChange(event, "comment")}
                  value={invoice.comment}
                  type={"text"}
                />

                <Header>Seznam fakturovaných položek</Header>
                {invoiceItems.map((item, key) => {
                  return (
                    <Form.Group key={key}>
                      <Form.Input
                        width={8}
                        label={key === 0 ? "Název položky" : ""}
                        placeholder={"Položka"}
                        onChange={(event) =>
                          handleChangeInvoiceItem(event, key, "name")
                        }
                        value={item.name}
                      />
                      <Form.Input
                        width={3}
                        label={key === 0 ? "Kusů" : ""}
                        onChange={(event) =>
                          handleChangeInvoiceItem(event, key, "amount")
                        }
                        value={item.amount}
                        type={"number"}
                      />
                      <Form.Input
                        width={5}
                        label={key === 0 ? "Cena za kus bez DPH" : ""}
                        onChange={(event) =>
                          handleChangeInvoiceItem(event, key, "price")
                        }
                        value={item.price}
                        type={"number"}
                      />
                      <Form.Select
                        label={key === 0 ? "DPH" : ""}
                        options={VatUtil.getVatOptions()}
                        onChange={(e, data: DropdownProps) =>
                          handleChangeInvoiceItem(data.value, key, "vat")
                        }
                        value={item.vat}
                      />
                      <Form.Input
                        width={5}
                        readOnly
                        label={key === 0 ? "Celkem bez DPH" : ""}
                        value={Formatter.money(item.amount * item.price)}
                      />
                      <Form.Input
                        width={5}
                        readOnly
                        label={key === 0 ? "Celkem s DPH" : ""}
                        value={Formatter.money(
                          ((item.amount * item.price) / 100) * (100 + item.vat)
                        )}
                      />
                      {key === invoiceItems.length - 1 ? (
                        <Form.Button
                          className={'add-row'}
                          label={key === 0 ? "." : ""}
                          onClick={() => addInvoiceItem()}
                          color={"black"}
                          content={"+"}
                        />
                      ) : (
                        <Form.Button
                          label={key === 0 ? "." : ""}
                          onClick={() => removeInvoiceItem(key)}
                          color={"black"}
                          content={"-"}
                        />
                      )}
                    </Form.Group>
                  );
                })}
                <Form.Checkbox
                  label={"Přidat zaokrouhlouvací položku na celé jednotky"}
                  onChange={() => setUseRoundingItem(!useRoundingItem)}
                  checked={useRoundingItem}
                />
                <Header>Shrnutí</Header>
                <Form.Group>
                  <Form.Input
                    width={3}
                    readOnly
                    label={"Celkem bez DPH"}
                    value={Formatter.money(getTotalInvoicePrice(false, true))}
                  />
                  <Form.Input
                    width={3}
                    readOnly
                    label={"Celkem s DPH"}
                    value={Formatter.money(getTotalInvoicePrice(true, true))}
                  />
                  {useRoundingItem && (
                    <Form.Input
                      width={3}
                      readOnly
                      label={"Zaokrouhlení"}
                      value={Formatter.money(roundingItem.price)}
                    />
                  )}
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className="action-btn">
                <Button
                  icon="left chevron"
                  onClick={() => history.push(View.INVOICES.path)}
                  className="mobile-btn"
                />
                <Button
                  labelPosition="left"
                  icon="left chevron"
                  content="Zpět"
                  onClick={() => history.push(View.INVOICES.path)}
                  className="mobile-btnHidden"
                />
                <div className="flex">
                  {invoice.id && (
                    <Button
                      floated={"left"}
                      color={"red"}
                      loading={deleteState.inProgress}
                      disabled={deleteState.inProgress || saveState.inProgress}
                      onClick={deleteInv}
                    >
                      <Icon name={"trash"}/>
                      <span className="action-btnSpan">Smazat fakturu</span>
                    </Button>
                  )}
                  <Button
                    color={"black"}
                    loading={saveState.inProgress && !saveState.id}
                    disabled={
                      saveState.inProgress ||
                      deleteState.inProgress ||
                      requiredFieldsPresent()
                    }
                    onClick={() => save()}
                  >
                    <Icon name={"save"}/>
                    <span className="action-btnSpan">{invoice.id ? "Uložit změny" : "Vytvořit fakturu"}</span>
                  </Button>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          {invoice.id && invoice.transactions.length > 0 && <>
            <Grid.Row>
              <Grid.Column width={16}>
                <Divider/>
                <Header>Spárované platby</Header>

                <Table basic striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Datum</Table.HeaderCell>
                      <Table.HeaderCell>Poznámka</Table.HeaderCell>
                      <Table.HeaderCell>Variabilní symbol</Table.HeaderCell>
                      <Table.HeaderCell textAlign={"right"}>Částka</Table.HeaderCell>
                      <Table.HeaderCell/>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {
                      invoice.transactions.map(incomingTransaction => {
                        return <Table.Row key={incomingTransaction.id}>
                          <Table.Cell>{moment(incomingTransaction.date).format("D. M. YYYY")}</Table.Cell>
                          <Table.Cell>{StringUtil.getTransactionLabel(incomingTransaction)}</Table.Cell>
                          <Table.Cell>{incomingTransaction.vs}</Table.Cell>
                          <Table.Cell textAlign={"right"}>{Formatter.money(incomingTransaction.amount)}</Table.Cell>
                          <Table.Cell collapsing>
                            <Button size={"mini"} loading={saveState.inProgress && saveState.id === incomingTransaction.id}
                              disabled={
                                saveState.inProgress ||
                                deleteState.inProgress
                              }
                              onClick={() => unpairTransaction(incomingTransaction.id)}
                            >
                              Zrušit párování
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      })
                    }
                  </Table.Body>
                  <Table.Footer>
                    <Table.Row>
                      <Table.Cell>{InvoiceUtil.getIsPaidLabel(invoice, amountPaid)}</Table.Cell>
                      <Table.Cell colSpan={3} textAlign={"right"}><strong>
                        Celkem: {
                          Formatter.money(amountPaid)
                        }
                        &nbsp;(Zbývá {Formatter.money(invoice.totalPriceWithVat - amountPaid)})
                      </strong>
                      </Table.Cell>
                        <Table.Cell />
                    </Table.Row>
                  </Table.Footer>
                </Table>
              </Grid.Column>
            </Grid.Row>

          {InvoiceUtil.getIsPaidMetadata(invoice, amountPaid).color === 'yellow' && <>
            <Grid.Row style={{height: '300px'}}>
              <InvoicePaymentGraph invoice={invoice} />
            </Grid.Row>
            </>}
          </>}
          {invoice.id && !invoice.paid && (
            <Grid.Row>
              <Grid.Column width={16}>
                <Header>Přiřadit transakci k faktuře</Header>
                {!transactions && (
                  <SimpleLoader text={"Načítám nedávné příchozí platby"} />
                )}
                {transactions && (
                  <Table basic striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Datum</Table.HeaderCell>
                        <Table.HeaderCell>Poznámka</Table.HeaderCell>
                        <Table.HeaderCell>Variabilní symbol</Table.HeaderCell>
                        <Table.HeaderCell textAlign={"right"}>Částka</Table.HeaderCell>
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {transactions.length === 0 ? (
                        <Table.Row>
                          <Table.Cell textAlign={"center"} colSpan={5}>
                            Nenalezeny žádné příchozí transakce, které by bylo možné přiřadit k faktuře.
                          </Table.Cell>
                        </Table.Row>
                      ) : (
                        transactions.map((transaction) => {
                          return (
                            <Table.Row key={transaction.transactionId}>
                              <Table.Cell>{moment(transaction.date).format("D. M. YYYY")}</Table.Cell>
                              <Table.Cell>{StringUtil.getTransactionLabel(transaction)}</Table.Cell>
                              <Table.Cell>{transaction.vs}</Table.Cell>
                              <Table.Cell textAlign={"right"}>
                                {Formatter.money(transaction.amount)}
                              </Table.Cell>
                              <Table.Cell collapsing>
                                <Button size={"mini"} loading={saveState.inProgress && saveState.id === transaction.id}
                                  disabled={saveState.inProgress || deleteState.inProgress}
                                  onClick={() => pairTransaction(transaction.id)}
                                >
                                  Spárovat
                                </Button>
                              </Table.Cell>
                            </Table.Row>
                          );
                        })
                      )}
                    </Table.Body>
                  </Table>
                )}
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </div>
    </>
  );
}
