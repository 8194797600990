import { useContext, useEffect, useState } from "react";
import { Container } from "semantic-ui-react";
import MonthYearProvider from "../../contexts/FilterContext";
import TransactionsView from "../../views/finance/view/TransactionsView";
import InvoicesView from "../../views/invoice/view/InvoicesView";
import InvoiceSubjectsView from "../../views/invoice/view/InvoiceSubjectsView";
import TransactionPlansView from "../../views/finance/view/TransactionPlansView";
import SummaryView from "../../views/finance/view/SummaryView";
import WorkView from "../../views/finance/view/WorkView";
import SideNavigation from "../menu/SideNavigation";
import { View } from "../../base/enums/View";
import TopNavigation from "../menu/TopNavigation";
import { Module } from "../../base/enums/Module";
import { ModuleViews } from "../../base/enums/ModuleViews";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import DashboardView from "../../views/dashboard/view/DashboardView";
import { Auth, AuthContext } from "../../contexts/AuthContext";
import UserManagementView from "../../views/usermgmt/view/UserManagementView";
import RoleManagementView from "../../views/usermgmt/view/RoleManagementView";
import { LoginType } from "../../base/enums/LoginType";
import DetailTransactionPage from "../../views/finance/view/DetailTransactionPage";
import DetailTransactionPlansPage from "../../views/finance/view/DetailTransactionPlansPage";
import DetailInvoiceSubjectsViewPage from "../../views/invoice/view/DetailInvoiceSubjectsViewPage";
import { config } from "../../base/config/DynamicConfig";
import { Toaster } from "react-hot-toast";
import EmployeesManagementView from "../../views/agenda/view/EmployeesManagement/EmployeesManagementView";
import { EmployeeDetailPage } from "../../views/agenda/view/EmployeesManagement/EmployeeDetailPage";
import { DocumentManagementView } from "../../views/agenda/view/DocumentTypeManagement/DocumentTypesView";
import { DocumentTypeDetailPage } from "../../views/agenda/view/DocumentTypeManagement/DocumentTypeDetailPage";
import ProjectsView from "../../views/project/view/ProjectsView";
import DetailProjectsViewPage from "../../views/project/view/DetailProjectsViewPage";
import LoansView from "../../views/finance/view/LoansView";
import InvoiceDetail from "../../views/invoice/component/InvoiceDetail";
interface ActivePageState {
  activeModule: string;
  activeView: string;
}

export default function AppRoot() {
  const paths = useLocation().pathname.trim().split("/");
  const module = paths.length > 1 && paths[1].length > 0 ? paths[1] : undefined;
  const view = paths.length > 2 && paths[2].length > 0 ? paths[2] : undefined;
  const history = useHistory();
  const auth = useContext<Auth>(AuthContext);
  const [isToggleExpanded, setIsToggleExpanded] = useState(false);
  const [indentation, setIndentation] = useState({});
  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    if (isToggleExpanded) {
      setIndentation({
        marginLeft: "15em",
        width: "calc(100vw - 15rem)",
      });
    } else {
      setIndentation({
        marginLeft: "1.5em",
        width: "calc(100vw - 1.5rem)",
      });
    }
  }, [setIndentation, isToggleExpanded]);

  if (!module && !view) {
    history.push(View.DASHBOARD.path);
  }

  const [state, setState] = useState<ActivePageState>({
    activeModule: module ? module : Module.DASHBOARD,
    activeView: view ? view : View.DASHBOARD.name,
  });

  const setModule = (module: string) => {
    setState({
      ...state,
      activeModule: module,
      activeView: ModuleViews.getViews(module)[0].name,
    });
  };

  const setView = (view: string) => {
    setState({
      ...state,
      activeView: view,
    });
  };

  return (
    <MonthYearProvider>
      <div className={isScroll ? "page scroll" : "page"}>
        <SideNavigation
          module={state.activeModule}
          onModuleChange={setModule}
          isToggleExpanded={setIsToggleExpanded}
          isScroll={setIsScroll}
        />
        <div className={"content"} style={indentation}>
          <Container>
            <Route
              exact
              path={View.LOGIN.path}
              render={() => (
                <Redirect
                  to={{
                    pathname: localStorage.getItem("backUrlLogin")
                      ? localStorage.getItem("backUrlLogin")
                      : "/dashboard",
                  }}
                />
              )}
            />
            <Toaster position="bottom-right" reverseOrder={false} />
            <TopNavigation
              module={state.activeModule}
              view={state.activeView}
              onViewChange={setView}
            />
            {(auth.hasRole(View.DASHBOARD.requiredRole) || auth.isAdmin()) && (
              <Route
                path={View.DASHBOARD.path}
                render={() => <DashboardView />}
              />
            )}
            {(auth.hasRole(View.TRANSACTIONS.requiredRole) ||
              auth.isAdmin()) && (
              <Route
                path={View.TRANSACTIONS.path}
                render={() => <TransactionsView />}
                exact
              />
            )}
            {(auth.hasRole(View.PLANNED_TRANSACTIONS.requiredRole) ||
              auth.isAdmin()) && (
              <Route
                path={View.TRANSACTIONS.path + "/:id"}
                render={(props) => <DetailTransactionPage {...props} />}
              />
            )}
            {(auth.hasRole(View.PLANNED_TRANSACTIONS.requiredRole) ||
              auth.isAdmin()) && (
              <Route
                path={View.PLANNED_TRANSACTIONS.path}
                render={() => <TransactionPlansView />}
                exact
              />
            )}
            {(auth.hasRole(View.PLANNED_TRANSACTIONS.requiredRole) ||
              auth.isAdmin()) && (
              <Route
                path={View.PLANNED_TRANSACTIONS.path + "/:id"}
                render={() => <DetailTransactionPlansPage />}
              />
            )}
            {(auth.hasRole(View.SUMMARY.requiredRole) || auth.isAdmin()) && (
              <Route path={View.SUMMARY.path} render={() => <SummaryView />} />
            )}
            {(auth.hasRole(View.WORK.requiredRole) || auth.isAdmin()) && (
              <Route path={View.WORK.path} render={() => <WorkView />} />
            )}
            {(auth.hasRole(View.LOANS.requiredRole) || auth.isAdmin()) && (
              <Route path={View.LOANS.path} render={() => <LoansView />} />
            )}
            {(auth.hasRole(View.PROJECTS.requiredRole) || auth.isAdmin()) && (
              <Route
                path={View.PROJECTS.path}
                render={() => <ProjectsView />}
                exact
              />
            )}
            {(auth.hasRole(View.PROJECTS.requiredRole) || auth.isAdmin()) && (
              <Route
                path={View.PROJECTS.path + "/:id"}
                render={() => <DetailProjectsViewPage />}
                exact
              />
            )}
            {(auth.hasRole(View.INVOICES.requiredRole) || auth.isAdmin()) && (
              <Route
                path={View.INVOICES.path}
                render={() => <InvoicesView />}
                exact
              />
            )}
            {(auth.hasRole(View.INVOICES.requiredRole) || auth.isAdmin()) && (
              <Route
                path={View.INVOICES.path + "/:id"}
                render={() => <InvoiceDetail />}
                exact
              />
            )}
            {(auth.hasRole(View.INVOICES_SUBJECTS.requiredRole) ||
              auth.isAdmin()) && (
              <Route
                path={View.INVOICES_SUBJECTS.path}
                render={() => <InvoiceSubjectsView />}
                exact
              />
            )}
            {(auth.hasRole(View.INVOICES_SUBJECTS.requiredRole) ||
              auth.isAdmin()) && (
              <Route
                path={View.INVOICES_SUBJECTS.path + "/:id"}
                render={() => <DetailInvoiceSubjectsViewPage />}
              />
            )}
            {config.env.LOGIN_TYPE === LoginType.LOCAL &&
              (auth.hasRole(View.USER_MANAGEMENT.requiredRole) ||
                auth.isAdmin()) && (
                <Route
                  path={View.USER_MANAGEMENT.path}
                  render={() => <UserManagementView />}
                />
              )}
            {config.env.LOGIN_TYPE === LoginType.LOCAL &&
              (auth.hasRole(View.ROLE_MANAGEMENT.requiredRole) ||
                auth.isAdmin()) && (
                <Route
                  path={View.ROLE_MANAGEMENT.path}
                  render={() => <RoleManagementView />}
                />
              )}
            {(auth.hasRole(View.MY_OVERVIEW.requiredRole) ||
              auth.isAdmin()) && (
              <Route
                path={View.MY_OVERVIEW.path}
                render={() => <EmployeeDetailPage />}
                exact
              />
            )}
            {(auth.hasRole(View.MY_OVERVIEW.requiredRole) ||
              auth.isAdmin()) && (
              <Route
                path={View.EMPLOYEES_MANAGEMENT.path}
                render={() => <EmployeesManagementView />}
                exact
              />
            )}
            {(auth.hasRole(View.MY_OVERVIEW.requiredRole) ||
              auth.isAdmin()) && (
              <Route
                path={View.EMPLOYEES_MANAGEMENT.path + "/:id"}
                render={() => <EmployeeDetailPage />}
                exact
              />
            )}
            {(auth.hasRole(View.MY_OVERVIEW.requiredRole) ||
              auth.isAdmin()) && (
              <Route
                path={View.DOCUMENT_MANAGEMENT.path}
                render={() => <DocumentManagementView />}
                exact
              />
            )}
            {(auth.hasRole(View.MY_OVERVIEW.requiredRole) ||
              auth.isAdmin()) && (
              <Route
                path={View.DOCUMENT_MANAGEMENT.path + "/:id"}
                render={() => <DocumentTypeDetailPage />}
                exact
              />
            )}
          </Container>
        </div>
      </div>
    </MonthYearProvider>
  );
}
