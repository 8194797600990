import { Form } from "semantic-ui-react";
import InvoiceSubjectSelector from "./InvoiceSubjectSelector";
import { InvoiceOption, InvoiceUtil } from "../../../base/util/InvoiceUtil";
import { useContext, useEffect } from "react";
import { FilterContext, FilterValue } from "../../../contexts/FilterContext";
import { useHistory } from "react-router-dom";
import { View } from "../../../base/enums/View";
import { FilterUtils } from "../../../base/util/FilterUtils";
import {InvoiceStatus} from '../../../api/generated-sources'

interface InvoiceFilterProps {
  subjectId: number;
  status: InvoiceStatus;
  timeframe: InvoiceOption;
  handleStatusChange: (data: any) => void;
  handlePeriodChange: (data: any) => void;
  onSubjectChange: (id: number) => void;
}
export const InvoiceFilter = (props: InvoiceFilterProps) => {
  const {
    subjectId,
    status,
    timeframe,
    handleStatusChange,
    handlePeriodChange,
    onSubjectChange,
  } = props;
  const {
    invoiceFilter,
    invoicePurchaser,
    invoiceTimeframe,
    setInvoiceTimeframe,
    setInvoiceFilter,
  } = useContext<FilterValue>(FilterContext);
  const history = useHistory();

  useEffect(() => {
    setInvoiceTimeframe(timeframe.key);
    setInvoiceFilter(status?.toLowerCase());
    history.push({
      pathname: View.INVOICES.path,
      search: FilterUtils.getQueryParamsInvoice(invoiceFilter, invoiceTimeframe),
    });

    if (invoicePurchaser && status) {
      history.push({
        pathname: View.INVOICES.path,
        search: FilterUtils.getQueryParamsInvoice(
          invoiceFilter,
          invoiceTimeframe,
          invoicePurchaser
        ),
      });
    }
  }, [
    history,
    invoiceFilter,
    invoicePurchaser,
    invoiceTimeframe,
    setInvoiceTimeframe,
    timeframe?.key,
  ]);

  return (
    <Form>
      <Form.Group>
        <InvoiceSubjectSelector
          width={4}
          onSubjectChange={onSubjectChange}
          value={invoicePurchaser ? invoicePurchaser : subjectId}
          label={"Odběratel"}
          nothingSelectedText="Všichni odběratelé"
          emptyItem={true}
        />
        <Form.Select
          options={InvoiceUtil.getInvoiceStatusOptions()}
          width={4}
          label={"Filtr"}
          onChange={(e, data) => handleStatusChange(data.value)}
          value={invoiceFilter?.toUpperCase()}
          type={"text"}
        />
        <Form.Select
          options={InvoiceUtil.getInvoicePeriodOptions()}
          width={4}
          label={"Zdanitelné plnění"}
          onChange={(e, data) => {
            handlePeriodChange(data.value);
          }}
          value={timeframe?.key}
          type={"text"}
        />
      </Form.Group>
    </Form>
  );
};
