import React from "react";
import {SyncOperationState} from "../state/SyncOperationState";
import {FileAttachmentDto} from '../../api/generated-sources'

export class FileUploadUtil {
  public static setAttachment = (event: React.ChangeEvent<HTMLInputElement>, handleChangeAttachment: (value: FileAttachmentDto) => void, setSyncState :  React.Dispatch<React.SetStateAction<SyncOperationState>>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const filetype = event.target.files[0].type;
      if (file.size > 100000000 || !["image/png", "image/jpeg", "application/pdf"].includes(filetype)) {
        setSyncState({
          error: true,
        });
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const fileAttachment: FileAttachmentDto = {
          filename: file.name,
          type: filetype,
          content: reader.result,
        };
        handleChangeAttachment(fileAttachment);
      };
    }
  };
}
