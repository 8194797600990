import {
  Button,
  DropdownProps,
  Form,
  Grid,
  Icon,
  Modal,
} from "semantic-ui-react";
import React, { ChangeEvent, useState } from "react";
import { SyncOperationState } from "../../../base/state/SyncOperationState";
import { VatUtil } from "../../../base/util/VatUtil";
import {createTransaction, TransactionDto} from '../../../api/generated-sources'
import {LocalDate} from '../../../build/core/dto/LocalDate'

interface NewCashPaymentState {
  date: LocalDate;
  amount: number;
  comment: string;
  taxDeductible: boolean;
  vat: number;
}

interface NewCashPaymentProps {
  onCashTransactionSave?: (data: TransactionDto) => void;
  onClose?: () => void;
  open?: boolean;
}

export default function TransactionCashPaymentModal(
  props: NewCashPaymentProps
) {
  const [state, setState] = useState<NewCashPaymentState>({
    date: new LocalDate(),
    amount: -1000,
    comment: "",
    taxDeductible: false,
    vat: 21,
  });

  const handleChange = (e: any, field: string) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setState({
      ...state,
      [field]: value,
    });
  };

  const handleSelectChange = (obj: DropdownProps, field: string) => {
    setState({
      ...state,
      [field]: obj.value,
    });
  };

  const [saveState, setSaveState] = useState<SyncOperationState>({
    inProgress: false,
    error: false,
  });

  const onSaveSuccess = (data: TransactionDto) => {
    setSaveState({
      inProgress: false,
      error: false,
    });
    props.onCashTransactionSave(data);
  };

  const onSaveError = (data: any) => {
    console.error(data);
    setSaveState({
      inProgress: false,
      error: true,
    });
  };

  const save = () => {
    setSaveState({
      ...saveState,
      inProgress: true,
    });
    createTransaction({
      amount: state.amount,
      comment: state.comment,
      taxDeductible: state.taxDeductible,
      vat: state.vat,
      date: state.date,
    })
        .then(onSaveSuccess)
        .catch(onSaveError);
  };

  return (
    <Modal
      className={"transaction-cash-payment-detail"}
      size={"small"}
      open={props.open}
      onClose={props.onClose}
    >
      <Modal.Header>Nová transakce v hotovosti</Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Column width={16}>
            <Form>
              <Grid divided="vertically">
                <Grid.Column width={16}>
                  <Form.Group>
                    <Form.Input
                      width={6}
                      label={"Částka"}
                      value={state.amount}
                      type={"number"}
                      onChange={(event) => handleChange(event, "amount")}
                    />
                    <Form.Select
                      label={"DPH"}
                      width={4}
                      options={VatUtil.getVatOptions()}
                      value={state.vat}
                      onChange={(e, data: DropdownProps) =>
                        handleSelectChange(data, "vat")
                      }
                    />
                    <Form.Input
                      width={6}
                      label={"Datum"}
                      value={state.date}
                      type={"date"}
                      onChange={(event: ChangeEvent) =>
                        handleChange(event, "date")
                      }
                    />
                  </Form.Group>
                  <Form.Field
                    label={"Poznámka"}
                    onChange={(event: ChangeEvent<any>) =>
                      handleChange(event, "comment")
                    }
                    value={state.comment}
                    control={"textarea"}
                    rows="3"
                  />
                </Grid.Column>
                {state.amount < 0 && (
                  <Grid.Column width={8}>
                    <Form.Field
                      label={"Daňově uznatelný náklad"}
                      control="input"
                      onChange={(event: ChangeEvent<any>) =>
                        handleChange(event, "taxDeductible")
                      }
                      checked={state.taxDeductible}
                      vlaue=""
                      type="checkbox"
                    />
                  </Grid.Column>
                )}
              </Grid>
            </Form>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color={"black"}
          loading={saveState.inProgress}
          disabled={saveState.inProgress}
          onClick={save}
        >
          <Icon name={"save"} /> Uložit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
