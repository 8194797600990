import React from "react";
import { useTranslation } from "../../base/locale/LocaleUtils";
import { Translation } from "../../base/locale/Localization.model";

type TranslateProps = {
  label: Translation;
};

export const Translate: React.FunctionComponent<TranslateProps> = (props) => {
  const { label } = props;
  const translation = useTranslation(label);
  return <>{translation}</>;
};

export default Translate;
