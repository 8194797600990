import { useEffect, useState } from "react";
import TransactionPlanDetail from "../component/TransactionPlanDetail";
import { useParams } from "react-router-dom";
import SimpleLoader from "../../../components/SimpleLoader";
import {
  getTransactionPlan,
  TransactionPlanDto,
} from '../../../api/generated-sources'
import toast from 'react-hot-toast'

export default function DetailTransactionPlansPage() {
  const [activePlan, setActivePlan] = useState<TransactionPlanDto>();
  const [loading, setLoading] = useState<boolean>(true)

  const { id } = useParams<{ id: string }>();


  useEffect(() => {
    if (id === "new") {
      setLoading(false);
      return;
    }
    getTransactionPlan(+id)
      .then(data => {
        setActivePlan(data);
        setLoading(false);
      })
      .catch(reason => {
        toast.error("Něco se pokazilo!");
        console.error(reason)
      });
  }, [id]);

  return (
    <div>
      {loading ? (
        <SimpleLoader text={"Načítám data"} />
      ) : (
        <TransactionPlanDetail plan={activePlan ? activePlan : undefined} />
      )}
    </div>
  );
}
