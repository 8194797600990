import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { ModuleViews } from "../../base/enums/ModuleViews";
import { Translate } from "../translation/Translate";
import { FilterValue, FilterContext } from "../../contexts/FilterContext";
import { Auth, AuthContext } from "../../contexts/AuthContext";

interface TopNavigationProps {
  module: string;
  view: string;
  onViewChange: (item: string) => void;
}

export default function TopNavigation(props: TopNavigationProps) {
  const { month, year } = useContext<FilterValue>(FilterContext);
  const auth = useContext<Auth>(AuthContext);

  const setView = (view: string) => {
    props.onViewChange(view);
  };

  if (ModuleViews.getViews(props.module).length < 2) {
    return null;
  }

  return (
    <Menu pointing secondary color={"red"} className={"top-navigation"}>
      {ModuleViews.getViews(props.module).map((item) => {
        if (auth.isAdmin() || auth.hasRole(item.requiredRole)) {
          return (
            <Link to={item.path + "?y=" + year + "&m=" + month} key={item.name}>
              <div
                className={props.view === item.name ? "item active" : "item"}
                onClick={() => setView(item.name)}
              >
                <Translate label={item.translation} />
              </div>
            </Link>
          );
        }
        return null;
      })}
    </Menu>
  );
}
