import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Label,
  Modal,
  Popup,
  Segment,
} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import { SyncOperationState } from "../../../base/state/SyncOperationState";
import { StringUtil } from "../../../base/util/StringUtil";
import {
  createUser,
  getRoles,
  RoleDto,
  updateUser,
  UserDto
} from '../../../api/generated-sources'

interface UserModalProps {
  user?: UserDto;
  onUserUpdate: (user: UserDto) => void;
  onUserDelete: (id: number) => void;
  onClose: () => void;
  open: boolean;
}

export default function UserManagementModal(props: UserModalProps) {
  const [activeRole, setActiveRole] = useState<RoleDto>();
  const [roles, setRoles] = useState<RoleDto[]>();
  const [saveState, setSaveState] = useState<SyncOperationState>({
    inProgress: false,
    error: false,
  });

  const initialState: UserDto = props.user
    ? {
        id: props.user.id,
        username: props.user.username,
        fullname: props.user.fullname,
        contactEmail: props.user.contactEmail,
        ss: props.user.ss,
        enabled: props.user.enabled,
        roles: props.user.roles,
      }
    : {
        username: "",
        fullname: "",
        contactEmail: "",
        ss: null,
        enabled: true,
        roles: roles
          ? roles.filter((r) => {
              return r.name === "user";
            })
          : [],
      };

  const [user, setUser] = useState<UserDto>(initialState);

  useEffect(() => {
    getRoles()
      .then(data => {
        if (!user.id) {
          setUser({
            ...user,
            roles: data.filter((r) => {
              return r.name === "user";
            }),
          });
        }
        setRoles(data);
      })
      .catch(console.error);
  }, []);

  const onSaveUserSuccess = (user: UserDto) => {
    props.onUserUpdate(user);
    setSaveState({
      inProgress: false,
      error: false,
    });
    props.onUserUpdate(user);
  };

  const save = (enabled?: boolean) => {
    setSaveState({
      ...saveState,
      id: user.id,
      inProgress: true,
    });

    const userRoles: RoleDto[] = user.roles.map((role) => {
      return {
        id: role.id,
        name: role.name,
        description: role.description,
      };
    });

    const requestUser: UserDto = {
      id: user.id,
      username: StringUtil.isEmpty(user.username) ? null : user.username,
      fullname: StringUtil.isEmpty(user.fullname) ? null : user.fullname,
      contactEmail: StringUtil.isEmpty(user.contactEmail)
        ? null
        : user.contactEmail,
      ss: user.ss,
      enabled: enabled ?? user.enabled,
      roles: userRoles,
    };

    if (props.user) {
      updateUser(requestUser.id, requestUser)
        .then(data => {
          onSaveUserSuccess(data);
        })
        .catch(reason => {
          setSaveState({ inProgress: false, error: true });
          console.error(reason.message)
        });
    } else {
      createUser(requestUser)
        .then(data => {
          onSaveUserSuccess(data);
        })
        .catch(reason => {
          console.log(reason.message)
          setSaveState({ inProgress: false, error: true });
        });
    }
  };

  const handleChange = (e: any, field: string) => {
    setUser({
      ...user,
      [field]: e.target.value,
    });
  };

  const addActiveRole = () => {
    const rls: RoleDto[] = Object.assign([], user.roles);
    if (rls.includes(activeRole)) {
      return;
    }
    rls.push(activeRole);
    setUser({
      ...user,
      roles: rls,
    });
  };

  const removeActiveRole = () => {
    setUser({
      ...user,
      roles: user.roles.filter((role) => {
        return role.id !== activeRole.id;
      }),
    });
  };

  const requiredFieldsPresent = () => {
    return !(
      StringUtil.isEmpty(user.fullname) ||
      StringUtil.isEmpty(user.contactEmail) ||
      StringUtil.isEmpty(user.username)
    );
  };

  return (
    <Modal
      className={"user-detail"}
      onClose={props.onClose}
      open={props.open}
      size={"tiny"}
    >
      {props.user ? (
        <Modal.Header>
          {props.user.fullname} - {props.user.username}{" "}
          {!props.user.enabled && (
            <Popup
              content={
                "Tento uživatel se nemůže přihlásit do aplikace a nebude se v aplikaci zobrazovat."
              }
              trigger={<Label color="red">Zablokovaný</Label>}
            />
          )}
        </Modal.Header>
      ) : (
        <Modal.Header>Nový uživatel</Modal.Header>
      )}
      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Form>
                <Form.Input
                  required
                  label={"Uživatelské jméno"}
                  error={StringUtil.isEmpty(user.username)}
                  onChange={(event) => handleChange(event, "username")}
                  value={user.username}
                />
                <Form.Input
                  required
                  label={"Email"}
                  error={StringUtil.isEmpty(user.contactEmail)}
                  onChange={(event) => handleChange(event, "email")}
                  value={user.contactEmail}
                />
                <Form.Input
                  required
                  label={"Jméno"}
                  error={StringUtil.isEmpty(user.contactEmail)}
                  onChange={(event) => handleChange(event, "name")}
                  value={user.contactEmail}
                />
                {props.user && (
                  <Form.Input
                    type={"number"}
                    required
                    label={"Specifický symbol pro identifikaci plateb"}
                    error={+user.ss <= 0}
                    onChange={(event) => handleChange(event, "ss")}
                    value={user.ss}
                  />
                )}
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={"role-assignments"}>
            <Grid.Column width={7}>
              <Header>Dostupné role</Header>
              {roles && (
                <Segment.Group>
                  {" "}
                  {roles
                    .filter((role) => {
                      return (
                        user.roles.filter((r) => r.id === role.id).length === 0
                      );
                    })
                    .map((role) => {
                      return (
                        <Popup
                          key={role.id}
                          content={role.description}
                          trigger={
                            <Segment
                              inverted={activeRole && activeRole.id === role.id}
                              onClick={() => {
                                setActiveRole(role);
                              }}
                            >
                              {role.name}
                            </Segment>
                          }
                        />
                      );
                    })}
                </Segment.Group>
              )}
            </Grid.Column>
            <Grid.Column className={"controls"} width={2}>
              <Button icon={"arrow right"} onClick={() => addActiveRole()} />
              <Button icon={"arrow left"} onClick={() => removeActiveRole()} />
            </Grid.Column>
            <Grid.Column width={7}>
              <Header>Aktivní role</Header>
              {user.roles.length > 0 && (
                <Segment.Group>
                  {user.roles.map((role) => {
                    return (
                      <Popup
                        key={role.id}
                        content={role.description}
                        trigger={
                          <Segment
                            inverted={activeRole && activeRole.id === role.id}
                            onClick={() => {
                              setActiveRole(role);
                            }}
                          >
                            {role.name}
                          </Segment>
                        }
                      />
                    );
                  })}
                </Segment.Group>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        {props.user && props.user.enabled && (
          <Button
            floated={"left"}
            color={"red"}
            disabled={saveState.inProgress}
            onClick={() => save(false)}
          >
            <Icon name={"lock"} />
            Zablokovat uživatele
          </Button>
        )}
        {props.user && !props.user.enabled && (
          <Button
            floated={"left"}
            color={"green"}
            disabled={saveState.inProgress}
            onClick={() => save(true)}
          >
            <Icon name={"unlock"} />
            Odblokovat uživatele
          </Button>
        )}
        <Button
          color={"black"}
          loading={saveState.inProgress && !saveState.id}
          disabled={saveState.inProgress || !requiredFieldsPresent()}
          onClick={() => save()}
        >
          <Icon name={"save"} />
          {props.user ? "Uložit změny" : "Založit uživatele"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
