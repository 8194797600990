import {useContext, useEffect, useMemo, useState} from "react";
import {
  Button,
  Grid,
  Header,
  Icon,
  Label,
  Popup,
  Table,
} from "semantic-ui-react";
import MonthYearSelector from "../../../components/base/MonthYearSelector";
import { Formatter } from "../../../base/util/Formatter";
import SimpleLoader from "../../../components/SimpleLoader";
import { Month } from "../../../base/enums/Month";
import { FilterContext, FilterValue } from "../../../contexts/FilterContext";
import Translate from "../../../components/translation/Translate";
import { SyncOperationState } from "../../../base/state/SyncOperationState";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { View } from "../../../base/enums/View";
import FullTextFilter from "../../../components/base/FullTextFilter";
import {
  TransactionPlanDto,
  TransactionPlanStatus,
  updateTransactionPlan,
  useGetTransactionPlansForMonth
} from '../../../api/generated-sources'
import toast from 'react-hot-toast'

interface TransactionPlansViewState {
  activePlan?: TransactionPlanDto;
  openDetailModal: boolean;
  data?: TransactionPlanDto[];
}

export default function TransactionPlansView() {
  const { month, year } = useContext<FilterValue>(FilterContext);
  const [state, setState] = useState<TransactionPlansViewState>({
    openDetailModal: false,
  });
  const [syncState, setSyncState] = useState<SyncOperationState>({
    inProgress: false,
  });
  const [filterData, setFilterData] = useState<TransactionPlanDto[]>();
  const history = useHistory();
  const [column, setColumn] = useState<string>();
  const [direction, setDirection] = useState<"ascending" | "descending">(
    "ascending"
  );

  const onDataLoad = (data: TransactionPlanDto[]) => {
    setState({
      ...state,
      data: data,
    });
  };

  const { data, isLoading, error } = useGetTransactionPlansForMonth({
        month: month,
        year: year,
      });

  useEffect(() => {
    if (data) {
      onDataLoad(data)
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error("Něco se pokazilo!");
      console.error(error);
    }
  }, [error]);

  const getSumOfTransaction = (plan: TransactionPlanDto) => {
    return plan.transactions
      .flatMap((i) => {
        return i.amount;
      })
      .reduce((a, b) => a + b, 0);
  };

  const onPlanUpdate = (plan: TransactionPlanDto, oldId?: number) => {
    const indexToUpdate = state.data!.findIndex((i) => {
      return plan.id === i.id;
    });
    const plans: TransactionPlanDto[] = Object.assign([], state.data);
    if (indexToUpdate === -1) {
      plans.push(plan);
    } else {
      plans[indexToUpdate] = plan;
    }
    if (oldId && oldId !== plan.id) {
      const indexToDelete = state.data!.findIndex((i) => {
        return oldId === i.id;
      });
      if (indexToDelete !== -1) {
        plans.splice(indexToDelete, 1);
      }
    }
    setState({
      ...state,
      data: plans,
      activePlan: undefined,
      openDetailModal: false,
    });
  };

  const isEditable = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    if (year < currentYear) {
      return false;
    }
    return !(year === currentYear && month < currentMonth);
  };

  const disableNotify = (item: TransactionPlanDto) => {
    setSyncState({ inProgress: true, error: false, done: false, id: item.id });
    updateTransactionPlan(item.id, {
      id: item.id,
      account: item.account,
      dueOnDayOfMonth: item.dueOnDayOfMonth,
      notify: !item.notify,
      vat: item.vat,
      vs: item.vs,
      ss: item.ss,
      requiresAttachment: item.requiresAttachment,
      description: item.description,
      repeatsEveryMonths: item.repeatsEveryMonths,
      expectedCount: item.expectedCount,
    })
      .then(value => {
        setSyncState({ inProgress: false, done: true });
        onPlanUpdate(value);
      })
      .catch(reason => {
        setSyncState({ inProgress: false, error: true, done: true });
      });
  };

  const handleSort = (clickedColumn: string) => {
    setFilterData([])
    if (column === clickedColumn) {
      setDirection(direction === "ascending" ? "descending" : "ascending");
    } else {
      setColumn(clickedColumn);
      setDirection("ascending");
    }
  };

  const sortedData = useMemo(() => {
    if (state?.data) {
      const sorted = [...state?.data]?.sort((a, b) => {
        const columnType = typeof a[column as keyof TransactionPlanDto];
        if (columnType === "string" || column === "dueDate") {
          if ("invoiceNumber" in a) {
            return direction === "ascending"
              ? Number(a[column as keyof TransactionPlanDto]) -
                  Number(b[column as keyof TransactionPlanDto])
              : Number(b[column as keyof TransactionPlanDto]) -
                  Number(a[column as keyof TransactionPlanDto]);
          }
          return direction === "ascending"
            ? a[column as keyof TransactionPlanDto]
                .toString()
                .localeCompare(b[column as keyof TransactionPlanDto].toString())
            : b[column as keyof TransactionPlanDto]
                .toString()
                .localeCompare(
                  a[column as keyof TransactionPlanDto].toString()
                );
        } else if (columnType === "number") {
          return direction === "ascending"
            ? Number(a[column as keyof TransactionPlanDto]) -
                Number(b[column as keyof TransactionPlanDto])
            : Number(b[column as keyof TransactionPlanDto]) -
                Number(a[column as keyof TransactionPlanDto]);
        } else {
          return 0;
        }
      });
      return sorted;
    }
  }, [column, direction]);

  const displayLoader = isLoading || !state.data;

  return (
    <>
      {/* <TransactionPlanDetail year={year} month={month} plan={state.activePlan} onPlanDelete={onPlanDelete} onPlanUpdate={onPlanUpdate} /> */}

      <Grid divided="vertically">
        <Grid.Row>
          <Grid.Column width={16}>
            <Header>
              Plánované platby -{" "}
              <Translate label={Month.ALL[month - 1].translation} /> {year}
            </Header>
          </Grid.Column>
        </Grid.Row>
        <MonthYearSelector />
        {displayLoader && <SimpleLoader text={"Načítám plánované platby"} />}
        {!displayLoader && state.data && (
          <Grid.Row>
            <Grid.Column width={16}>
              <FullTextFilter
                data={state.data}
                returnFilteredData={setFilterData}
                formatData={{
                  dateType: ["dueDate"],
                  formatDateType: "DD. MM. yyyy",
                  activeItem: [
                    "description",
                    "dueDate",
                    "id",
                    "expectedCount",
                    "vat",
                  ],
                }}
              />
              <Table basic striped sortable={true} selectable={isEditable()}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Stav</Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "description" ? direction : null}
                      onClick={() => {
                        setColumn("description");
                        handleSort("description");
                      }}
                    >
                      Popis
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "dueDate" ? direction : null}
                      onClick={() => {
                        setColumn("dueDate");
                        handleSort("dueDate");
                      }}
                    >
                      Splatné ke dni
                    </Table.HeaderCell>
                    <Table.HeaderCell>Odeslané transakce</Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={column === "vat" ? direction : null}
                      onClick={() => {
                        setColumn("vat");
                        handleSort("vat");
                      }}
                    >
                      DPH
                    </Table.HeaderCell>
                    <Table.HeaderCell>Celkem</Table.HeaderCell>
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {state.data.length === 0 && (
                    <Table.Row>
                      <Table.Cell textAlign={"center"} colSpan={5}>
                        Na tento měsíc nejsou plánované žádné transakce
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {filterData?.length
                    ? filterData.map((item) => {
                        return (
                          <Table.Row
                            onClick={() =>
                              history.push(
                                `${
                                  View.PLANNED_TRANSACTIONS.path + "/" + item.id
                                }`
                              )
                            }
                            key={item.id}
                          >
                            <Table.Cell>
                              {item.status ===
                                TransactionPlanStatus.FULFILLED && (
                                <Label color={"green"} ribbon>
                                  <Icon name={"checkmark"} />
                                  Odesláno
                                </Label>
                              )}
                              {item.status ===
                                TransactionPlanStatus.DUE_DATE_SOON && (
                                <Label color={"yellow"} ribbon>
                                  <Icon name={"time"} />
                                  Datum splatnosti se blíží
                                </Label>
                              )}
                              {item.status ===
                                TransactionPlanStatus.PAST_DUE_DATE && (
                                <Label color={"red"} ribbon>
                                  <Icon name={"times circle"} />
                                  Po datu splatnosti
                                </Label>
                              )}
                              {item.status === TransactionPlanStatus.ERROR && (
                                <Label color={"red"} ribbon>
                                  <Icon name={"warning sign"} />
                                  Chyba
                                </Label>
                              )}
                              {item.status ===
                                TransactionPlanStatus.UPCOMING && (
                                <Label color={"grey"} ribbon>
                                  Ještě je čas
                                </Label>
                              )}
                              {item.status ===
                                TransactionPlanStatus.PARTIALLY_MISSING && (
                                <Label color={"orange"} ribbon>
                                  <Icon name={"warning"} />
                                  Pouze část odeslaná
                                </Label>
                              )}
                            </Table.Cell>
                            <Table.Cell>{item.description}</Table.Cell>
                            <Table.Cell singleLine>
                              {moment(item.dueDate, moment.ISO_8601).format(
                                "DD. MM. yyyy"
                              )}
                            </Table.Cell>
                            <Table.Cell singleLine>
                              {item.transactions.length} / {item.expectedCount}
                            </Table.Cell>
                            <Table.Cell singleLine>{item.vat}%</Table.Cell>
                            <Table.Cell singleLine textAlign={"right"}>
                              {Formatter.money(getSumOfTransaction(item))}
                            </Table.Cell>
                            <Table.Cell
                              singleLine
                              onClick={(e: any) => {
                                e.stopPropagation();
                                disableNotify(item);
                              }}
                              textAlign={"center"}
                            >
                              {item.notify ? (
                                <Popup
                                  content={
                                    "Vypnout notifikace o blížící se platbě (nebude chodit upozornění emailem)"
                                  }
                                  trigger={
                                    <Icon
                                      disabled={
                                        syncState.inProgress &&
                                        syncState.id === item.id
                                      }
                                      loading={syncState.id === item.id}
                                      name={
                                        syncState.inProgress &&
                                        syncState.id === item.id
                                          ? "circle notched"
                                          : "bell"
                                      }
                                    />
                                  }
                                />
                              ) : (
                                <Popup
                                  content={
                                    "Zapnout notifikace o blížící se platbě (bude chodit upozornění emailem)"
                                  }
                                  trigger={
                                    <Icon
                                      disabled={
                                        syncState.inProgress &&
                                        syncState.id === item.id
                                      }
                                      loading={syncState.id === item.id}
                                      name={
                                        syncState.inProgress &&
                                        syncState.id === item.id
                                          ? "circle notched"
                                          : "bell slash"
                                      }
                                    />
                                  }
                                />
                              )}
                            </Table.Cell>
                          </Table.Row>
                        );
                      })
                    : sortedData
                    ? sortedData.map((item) => {
                        return (
                          <Table.Row
                            onClick={() =>
                              history.push(
                                `${
                                  View.PLANNED_TRANSACTIONS.path + "/" + item.id
                                }`
                              )
                            }
                            key={item.id}
                          >
                            <Table.Cell>
                              {item.status ===
                                TransactionPlanStatus.FULFILLED && (
                                <Label color={"green"} ribbon>
                                  <Icon name={"checkmark"} />
                                  Odesláno
                                </Label>
                              )}
                              {item.status ===
                                TransactionPlanStatus.DUE_DATE_SOON && (
                                <Label color={"yellow"} ribbon>
                                  <Icon name={"time"} />
                                  Datum splatnosti se blíží
                                </Label>
                              )}
                              {item.status ===
                                TransactionPlanStatus.PAST_DUE_DATE && (
                                <Label color={"red"} ribbon>
                                  <Icon name={"times circle"} />
                                  Po datu splatnosti
                                </Label>
                              )}
                              {item.status === TransactionPlanStatus.ERROR && (
                                <Label color={"red"} ribbon>
                                  <Icon name={"warning sign"} />
                                  Chyba
                                </Label>
                              )}
                              {item.status ===
                                TransactionPlanStatus.UPCOMING && (
                                <Label color={"grey"} ribbon>
                                  Ještě je čas
                                </Label>
                              )}
                              {item.status ===
                                TransactionPlanStatus.PARTIALLY_MISSING && (
                                <Label color={"orange"} ribbon>
                                  <Icon name={"warning"} />
                                  Pouze část odeslaná
                                </Label>
                              )}
                            </Table.Cell>
                            <Table.Cell>{item.description}</Table.Cell>
                            <Table.Cell singleLine>
                              {moment(item.dueDate, moment.ISO_8601).format(
                                "DD. MM. yyyy"
                              )}
                            </Table.Cell>
                            <Table.Cell singleLine>
                              {item.transactions.length} / {item.expectedCount}
                            </Table.Cell>
                            <Table.Cell singleLine>{item.vat}%</Table.Cell>
                            <Table.Cell singleLine textAlign={"right"}>
                              {Formatter.money(getSumOfTransaction(item))}
                            </Table.Cell>
                            <Table.Cell
                              singleLine
                              onClick={(e: any) => {
                                e.stopPropagation();
                                disableNotify(item);
                              }}
                              textAlign={"center"}
                            >
                              {item.notify ? (
                                <Popup
                                  content={
                                    "Vypnout notifikace o blížící se platbě (nebude chodit upozornění emailem)"
                                  }
                                  trigger={
                                    <Icon
                                      disabled={
                                        syncState.inProgress &&
                                        syncState.id === item.id
                                      }
                                      loading={syncState.id === item.id}
                                      name={
                                        syncState.inProgress &&
                                        syncState.id === item.id
                                          ? "circle notched"
                                          : "bell"
                                      }
                                    />
                                  }
                                />
                              ) : (
                                <Popup
                                  content={
                                    "Zapnout notifikace o blížící se platbě (bude chodit upozornění emailem)"
                                  }
                                  trigger={
                                    <Icon
                                      disabled={
                                        syncState.inProgress &&
                                        syncState.id === item.id
                                      }
                                      loading={syncState.id === item.id}
                                      name={
                                        syncState.inProgress &&
                                        syncState.id === item.id
                                          ? "circle notched"
                                          : "bell slash"
                                      }
                                    />
                                  }
                                />
                              )}
                            </Table.Cell>
                          </Table.Row>
                        );
                      })
                    : state.data.map((item) => {
                        return (
                          <Table.Row
                            onClick={() =>
                              history.push(
                                `${
                                  View.PLANNED_TRANSACTIONS.path + "/" + item.id
                                }`
                              )
                            }
                            key={item.id}
                          >
                            <Table.Cell>
                              {item.status ===
                                TransactionPlanStatus.FULFILLED && (
                                <Label color={"green"} ribbon>
                                  <Icon name={"checkmark"} />
                                  Odesláno
                                </Label>
                              )}
                              {item.status ===
                                TransactionPlanStatus.DUE_DATE_SOON && (
                                <Label color={"yellow"} ribbon>
                                  <Icon name={"time"} />
                                  Datum splatnosti se blíží
                                </Label>
                              )}
                              {item.status ===
                                TransactionPlanStatus.PAST_DUE_DATE && (
                                <Label color={"red"} ribbon>
                                  <Icon name={"times circle"} />
                                  Po datu splatnosti
                                </Label>
                              )}
                              {item.status === TransactionPlanStatus.ERROR && (
                                <Label color={"red"} ribbon>
                                  <Icon name={"warning sign"} />
                                  Chyba
                                </Label>
                              )}
                              {item.status ===
                                TransactionPlanStatus.UPCOMING && (
                                <Label color={"grey"} ribbon>
                                  Ještě je čas
                                </Label>
                              )}
                              {item.status ===
                                TransactionPlanStatus.PARTIALLY_MISSING && (
                                <Label color={"orange"} ribbon>
                                  <Icon name={"warning"} />
                                  Pouze část odeslaná
                                </Label>
                              )}
                            </Table.Cell>
                            <Table.Cell>{item.description}</Table.Cell>
                            <Table.Cell singleLine>
                              {moment(item.dueDate, moment.ISO_8601).format(
                                "DD. MM. yyyy"
                              )}
                            </Table.Cell>
                            <Table.Cell singleLine>
                              {item.transactions.length} / {item.expectedCount}
                            </Table.Cell>
                            <Table.Cell singleLine>{item.vat}%</Table.Cell>
                            <Table.Cell singleLine textAlign={"right"}>
                              {Formatter.money(getSumOfTransaction(item))}
                            </Table.Cell>
                            <Table.Cell
                              singleLine
                              onClick={(e: any) => {
                                e.stopPropagation();
                                disableNotify(item);
                              }}
                              textAlign={"center"}
                            >
                              {item.notify ? (
                                <Popup
                                  content={
                                    "Vypnout notifikace o blížící se platbě (nebude chodit upozornění emailem)"
                                  }
                                  trigger={
                                    <Icon
                                      disabled={
                                        syncState.inProgress &&
                                        syncState.id === item.id
                                      }
                                      loading={syncState.id === item.id}
                                      name={
                                        syncState.inProgress &&
                                        syncState.id === item.id
                                          ? "circle notched"
                                          : "bell"
                                      }
                                    />
                                  }
                                />
                              ) : (
                                <Popup
                                  content={
                                    "Zapnout notifikace o blížící se platbě (bude chodit upozornění emailem)"
                                  }
                                  trigger={
                                    <Icon
                                      disabled={
                                        syncState.inProgress &&
                                        syncState.id === item.id
                                      }
                                      loading={syncState.id === item.id}
                                      name={
                                        syncState.inProgress &&
                                        syncState.id === item.id
                                          ? "circle notched"
                                          : "bell slash"
                                      }
                                    />
                                  }
                                />
                              )}
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.Cell colSpan={7} textAlign={"right"}>
                      <Button
                        color={"green"}
                        onClick={() =>
                          history.push(
                            `${View.PLANNED_TRANSACTIONS.path + "/new"}`
                          )
                        }
                      >
                        <Icon name={"plus"} />
                        Přidat plánovanou transakci
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </>
  );
}
