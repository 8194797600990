/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
import useSwr from "swr";
import type { Arguments, Key, SWRConfiguration } from "swr";
import useSWRMutation from "swr/mutation";
import type { SWRMutationConfiguration } from "swr/mutation";
import type {
  ErrorResponseResponse,
  InvoiceSubjectDto,
  UpdateArchivedBody,
} from "../intranetApi.schemas";
import { IntranetClientFetchInstance } from "../../intranetClientFetchInstance";
import type { ErrorType } from "../../intranetClientFetchInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get invoice subjects
 */
export const getInvoiceSubjects = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<InvoiceSubjectDto[]>(
    { url: `/invoiceSubject`, method: "GET" },
    options
  );
};

export const getGetInvoiceSubjectsKey = () => [`/invoiceSubject`] as const;

export type GetInvoiceSubjectsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInvoiceSubjects>>
>;
export type GetInvoiceSubjectsQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get invoice subjects
 */
export const useGetInvoiceSubjects = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof getInvoiceSubjects>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetInvoiceSubjectsKey() : null));
  const swrFn = () => getInvoiceSubjects(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary create invoice subject
 */
export const createInvoiceSubject = (
  invoiceSubjectDto: InvoiceSubjectDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<InvoiceSubjectDto>(
    {
      url: `/invoiceSubject`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: invoiceSubjectDto,
    },
    options
  );
};

export const getCreateInvoiceSubjectMutationFetcher = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: InvoiceSubjectDto }
  ): Promise<InvoiceSubjectDto> => {
    return createInvoiceSubject(arg, options);
  };
};
export const getCreateInvoiceSubjectMutationKey = () =>
  `/invoiceSubject` as const;

export type CreateInvoiceSubjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof createInvoiceSubject>>
>;
export type CreateInvoiceSubjectMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary create invoice subject
 */
export const useCreateInvoiceSubject = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createInvoiceSubject>>,
    TError,
    string,
    InvoiceSubjectDto,
    Awaited<ReturnType<typeof createInvoiceSubject>>
  > & { swrKey?: string };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCreateInvoiceSubjectMutationKey();
  const swrFn = getCreateInvoiceSubjectMutationFetcher(requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary get invoice subject by id
 */
export const getInvoiceSubject = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<InvoiceSubjectDto>(
    { url: `/invoiceSubject/${id}`, method: "GET" },
    options
  );
};

export const getGetInvoiceSubjectKey = (id: number) =>
  [`/invoiceSubject/${id}`] as const;

export type GetInvoiceSubjectQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInvoiceSubject>>
>;
export type GetInvoiceSubjectQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get invoice subject by id
 */
export const useGetInvoiceSubject = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getInvoiceSubject>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetInvoiceSubjectKey(id) : null));
  const swrFn = () => getInvoiceSubject(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary delete invoice subject
 */
export const deleteInvoiceSubject = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<string>(
    { url: `/invoiceSubject/${id}`, method: "DELETE" },
    options
  );
};

export const getDeleteInvoiceSubjectMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, __: { arg: Arguments }): Promise<string> => {
    return deleteInvoiceSubject(id, options);
  };
};
export const getDeleteInvoiceSubjectMutationKey = (id: number) =>
  `/invoiceSubject/${id}` as const;

export type DeleteInvoiceSubjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteInvoiceSubject>>
>;
export type DeleteInvoiceSubjectMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary delete invoice subject
 */
export const useDeleteInvoiceSubject = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteInvoiceSubject>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof deleteInvoiceSubject>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getDeleteInvoiceSubjectMutationKey(id);
  const swrFn = getDeleteInvoiceSubjectMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary update invoice subject
 */
export const updateInvoiceSubject = (
  id: number,
  invoiceSubjectDto: InvoiceSubjectDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<InvoiceSubjectDto>(
    {
      url: `/invoiceSubject/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: invoiceSubjectDto,
    },
    options
  );
};

export const getUpdateInvoiceSubjectMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: InvoiceSubjectDto }
  ): Promise<InvoiceSubjectDto> => {
    return updateInvoiceSubject(id, arg, options);
  };
};
export const getUpdateInvoiceSubjectMutationKey = (id: number) =>
  `/invoiceSubject/${id}` as const;

export type UpdateInvoiceSubjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateInvoiceSubject>>
>;
export type UpdateInvoiceSubjectMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary update invoice subject
 */
export const useUpdateInvoiceSubject = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateInvoiceSubject>>,
      TError,
      string,
      InvoiceSubjectDto,
      Awaited<ReturnType<typeof updateInvoiceSubject>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getUpdateInvoiceSubjectMutationKey(id);
  const swrFn = getUpdateInvoiceSubjectMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary update archived
 */
export const updateArchived = (
  id: number,
  updateArchivedBody: UpdateArchivedBody,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<InvoiceSubjectDto>(
    {
      url: `/invoiceSubject/${id}/archive`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateArchivedBody,
    },
    options
  );
};

export const getUpdateArchivedMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: UpdateArchivedBody }
  ): Promise<InvoiceSubjectDto> => {
    return updateArchived(id, arg, options);
  };
};
export const getUpdateArchivedMutationKey = (id: number) =>
  `/invoiceSubject/${id}/archive` as const;

export type UpdateArchivedMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateArchived>>
>;
export type UpdateArchivedMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary update archived
 */
export const useUpdateArchived = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateArchived>>,
      TError,
      string,
      UpdateArchivedBody,
      Awaited<ReturnType<typeof updateArchived>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getUpdateArchivedMutationKey(id);
  const swrFn = getUpdateArchivedMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
