import { Translation } from "../locale/Localization.model";
import Months from "../locale/translations/Months";

export class Month {
  public static JANUARY: Month = new Month(1, "january", Months.JANUARY);
  public static FEBRUARY: Month = new Month(2, "february", Months.FEBRUARY);
  public static MARCH: Month = new Month(3, "march", Months.MARCH);
  public static APRIL: Month = new Month(4, "april", Months.APRIL);
  public static MAY: Month = new Month(5, "may", Months.MAY);
  public static JUNE: Month = new Month(6, "june", Months.JUNE);
  public static JULY: Month = new Month(7, "july", Months.JULY);
  public static AUGUST: Month = new Month(8, "august", Months.AUGUST);
  public static SEPTEMBER: Month = new Month(9, "september", Months.SEPTEMBER);
  public static OCTOBER: Month = new Month(10, "october", Months.OCTOBER);
  public static NOVEMBER: Month = new Month(11, "november", Months.NOVEMBER);
  public static DECEMBER: Month = new Month(12, "december", Months.DECEMBER);

  public static ALL: Month[] = [
    Month.JANUARY,
    Month.FEBRUARY,
    Month.MARCH,
    Month.APRIL,
    Month.MAY,
    Month.JUNE,
    Month.JULY,
    Month.AUGUST,
    Month.SEPTEMBER,
    Month.OCTOBER,
    Month.NOVEMBER,
    Month.DECEMBER,
  ];

  private readonly _id: number;
  private readonly _name: string;
  private readonly _translation: Translation;

  constructor(id: number, name: string, translation: Translation) {
    this._id = id;
    this._name = name;
    this._translation = translation;
  }

  get id(): number {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get translation(): Translation {
    return this._translation;
  }
}
