import React, {useEffect, useState} from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Segment,
} from "semantic-ui-react";
import DashboardWidgetSelectionModal from "../component/DashboardWidgetSelectionModal";
import CompanyDebtWidget from "../component/CompanyDebtWidget";
import InvoiceSubjectBreakdownWidget from "../component/InvoiceSubjectBreakdownWidget";
import FinanceGraphWidget from "../component/FinanceGraphWidget";
import { SyncOperationState } from "../../../base/state/SyncOperationState";
import SimpleLoader from "../../../components/SimpleLoader";
import FinanceTableWidget from "../component/FinanceTableWidget";
import {
  addDashboardWidget,
  DashboardWidgetDto,
  DashboardWidgetUserDto, removeDashboardWidget, updateDashboardWidget,
  useGetOwnDashboardWidgets
} from '../../../api/generated-sources'

export default function DashboardView() {
  const [widgetsLoading, setWidgetsLoading] = useState<SyncOperationState>({
    inProgress: true,
  });
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [widgets, setWidgets] = useState<DashboardWidgetUserDto[]>([]);

  const {data, error, isLoading} = useGetOwnDashboardWidgets()

  useEffect(() => {
    if (data) {
      setWidgets(data)
    }
  }, [data]);

  useEffect(() => {
    if(!isLoading) {
      if (error) {
        setWidgetsLoading({
          inProgress: false,
          error: true,
        })
      } else {
        setWidgetsLoading({
          inProgress: false,
          success: true,
        })
      }
    }
  }, [isLoading, error]);


  const onAddDashboardWidget = (w: DashboardWidgetDto) => {
    const widgetsCopy: DashboardWidgetUserDto[] = Object.assign([], widgets);
    widgetsCopy.push(w);
    setWidgets(widgetsCopy);
  };

  const addWidget = async (widget: DashboardWidgetDto) => {
    const w = await addDashboardWidget({
      id: widget.id,
    });
    onAddDashboardWidget(w)

    setModalVisible(false);
  };

  const onUpdateDashboardWidget = (w: DashboardWidgetDto) => {
    const oldWidgetData = widgets.filter(
      (widget) => widget.id === w.id
    );
    if (
      oldWidgetData.length === 1 &&
      oldWidgetData[0].data !== w
    ) {
      const updatedWidgets = widgets.map((widget) =>
        widget.id !== w.id
          ? widget
          : w
      );
      setWidgets(updatedWidgets);
    }
  };

  const updateWidget = async (
    widget: DashboardWidgetUserDto,
    configuration?: string
  ) => {
    const w = await updateDashboardWidget(widget.id, {
      id: widget.id,
      position: widget.position,
      data: widget.data,
      configuration: configuration ? configuration : widget.configuration,
      dashboardWidget: {
        id: widget.dashboardWidget.id,
        name: widget.dashboardWidget.name,
      },
    });
    onUpdateDashboardWidget(w);
  };

  const removeWidget = async (widget: DashboardWidgetUserDto) => {
    await removeDashboardWidget(widget.id);
    const widgetsCopy: DashboardWidgetUserDto[] = Object.assign([], widgets);
    setWidgets(
      widgetsCopy.filter((w) => {
        return w.id !== widget.id;
      })
    );
  };

  const getWidgetComponent = (widget: DashboardWidgetUserDto) => {
    switch (widget.dashboardWidget.name) {
      case "company_debt":
        return (
          <CompanyDebtWidget
            key={widget.id}
            onRemove={removeWidget}
            widget={widget}
            data={widget.data}
          />
        );
      case "invoice_subject_breakdown":
        return (
          <InvoiceSubjectBreakdownWidget
            key={widget.id}
            onRemove={removeWidget}
            widget={widget}
            data={widget.data}
          />
        );
      case "finance_graph":
        return (
          <FinanceGraphWidget
            key={widget.id}
            onRemove={removeWidget}
            onReload={updateWidget}
            widget={widget}
            data={widget.data}
            configuration={widget.configuration}
          />
        );
      case "finance_table":
        return (
          <FinanceTableWidget
            key={widget.id}
            onRemove={removeWidget}
            onReload={updateWidget}
            widget={widget}
            data={widget.data}
            configuration={widget.configuration}
          />
        );
      default:
        return (
          <Segment key={widget.id} attached={"bottom"}>
            Unknown Widget <Icon name={"exclamation triangle"} color={"red"} />
          </Segment>
        );
    }
  };

  return (
    <Container>
      {modalVisible && (
        <DashboardWidgetSelectionModal
          addDashboardWidget={addWidget}
          open={modalVisible}
          onClose={() => setModalVisible(false)}
        />
      )}
      <Grid centered>
        <Grid.Column width={8}>
          <Header>
            <h1>Nástěnka</h1>
          </Header>
        </Grid.Column>
        <Grid.Column width={8}>
          <Button icon floated={"right"} onClick={() => setModalVisible(true)}>
            <Icon name="plus" /> Přidat Widget
          </Button>
        </Grid.Column>
        {widgetsLoading.inProgress && (
          <SimpleLoader text={"Načítám Nástěnku"} />
        )}
        {widgetsLoading.error && (
          <Segment>
            <Icon color={"red"} name={"exclamation triangle"} /> Chyba při
            načítání Nástěnky.
          </Segment>
        )}
        {widgetsLoading.success && widgets.length === 0 && (
          <Segment>
            <Icon name={"info circle"} /> Nemáte žádné Widgety na nástěnce.
            Přidejte pomocí tlačítka <Icon name={"plus"} bordered /> v horní
            části obrazovky.
          </Segment>
        )}
        {widgetsLoading.success &&
          widgets.map((widget) => getWidgetComponent(widget))}
      </Grid>
    </Container>
  );
}
