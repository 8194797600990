import {Button, Header, Table, Icon, Pagination} from "semantic-ui-react";
import SimpleLoader from "../../../components/SimpleLoader";
import { TaxExemptTableModal } from "./TaxExemptTableModal";
import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import {Auth, AuthContext} from "../../../contexts/AuthContext";
import {Role} from "../../../base/enums/Role";
import {DateUtil} from "../../../base/util/DateUtil";
import {TaxExemptUtil} from "../../../base/util/TaxExemptUtil";
import {StringUtil} from "../../../base/util/StringUtil";
import {
  getTaxExemptsFoCurrentrUser,
  getTaxExemptsForUser,
  PageInfo, SortDirection, TaxExemptDto,
} from '../../../api/generated-sources'

export function TaxExemptTable() {
  const [list, setList] = useState<TaxExemptDto[]>();
  const [active, setActive] = useState<TaxExemptDto>();
  const { id } = useParams<{ id: string }>();
  const auth = useContext<Auth>(AuthContext);
  const canEdit = id && (auth.hasRole(Role.HR_MANAGER) || auth.isAdmin());
  const [pageInfo, setPageInfo] = useState<PageInfo>({
      numberOfElements: 0,
      pageNumber: 0,
      pageSize: 5,
      totalElements: 0,
      totalPages: 0,
  });

  const activeRow = (id: number) => {
    const activeItem = list.filter((item) => item.id === id);
    setActive(activeItem[0]);
  };

  const handleDelete = (id: number) => {
    const updateArr = list.filter((item) => item.id !== id);
    setList(updateArr);
  };

  const reloadTable = () => {
    if (id) {
      getTaxExemptsForUser(+id, {
        page: pageInfo.pageNumber,
        size: pageInfo.pageSize,
        sortBy: "validTo",
        sortDirection: SortDirection.DESC
      })
        .then(data => {
          setPageInfo(data.pageInfo);
          setList(data.taxExempt);
        }).catch(console.error)
    } else {
      getTaxExemptsFoCurrentrUser({
        page: pageInfo.pageNumber,
        size: pageInfo.pageSize,
        sortBy: "validTo",
        sortDirection: SortDirection.DESC
      })
        .then(data => {
          setPageInfo(data.pageInfo);
          setList(data.taxExempt);
        })
        .catch(console.error);
    }
  }

  useEffect(() => {
    reloadTable();
  }, [id, pageInfo.pageNumber]);

  const displayLoader = !list;

  return (
    <>
      {displayLoader ? <SimpleLoader text={"Načítám list"} />
      : <>
          {
            canEdit && !!active && <TaxExemptTableModal
              selectedRow={active}
              close={() => setActive(undefined)}
              isOpen={!!active}
              handleUpdate={reloadTable}
              handleDelete={handleDelete}
            />
          }
          <Header as="h3" textAlign="center">
            SLEVY NA DANI
          </Header>
          <Table basic striped selectable={canEdit}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Platné od</Table.HeaderCell>
                <Table.HeaderCell>Platné do</Table.HeaderCell>
                <Table.HeaderCell>Typ slevy</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Dokument</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {list?.length ? (
                list?.map((item, index) => {
                  return (
                    <Table.Row
                      key={item.id}
                      onClick={() => {
                        if (canEdit) {
                          activeRow(item.id);
                        }
                      }}
                    >
                      <Table.Cell singleLine style={{borderLeft: DateUtil.isCurrentlyValid(new Date (item.validFrom), new Date(item.validTo)) ? "4px solid #21BA45" : ""}}>
                        {item.validFrom &&
                          moment(item.validFrom, moment.ISO_8601).format(
                            "DD. MM. yyyy"
                          )}
                      </Table.Cell>
                      <Table.Cell singleLine>
                        {!item.validTo
                          ? "-"
                          : moment(item.validTo, moment.ISO_8601).format(
                              "DD. MM. yyyy"
                            )}{" "}
                      </Table.Cell>
                      <Table.Cell singleLine>
                        {
                          StringUtil.shortenWithEllipsis(
                            TaxExemptUtil.getTaxExemptTypeOptions().filter(
                              (option) => option.value === item?.type
                            )[0].text
                          , 26)
                        }
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {
                          item.documentUrl ? <a target="_blank" rel="noopener noreferrer" href={item.documentUrl} onClick={(e) => e.stopPropagation()}>
                            <Icon color="black" name="download" />
                          </a>
                          :
                          <Icon.Group>
                            <>
                              <Icon color="red" size={"big"} name="circle outline" />
                              <Icon color="black" name="file alternate outline" />
                              <Icon color="red" size={"small"} corner name="warning"/>
                            </>
                          </Icon.Group>
                        }
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell textAlign={"center"} colSpan={7}>
                    Seznam slev na dani poplatníka je prázdný
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
            {
              canEdit && <Table.Footer>
                <Table.Row className="new-btn">
                  <Table.HeaderCell colSpan="4">
                    {
                      pageInfo.totalPages > 1 && <Pagination size={'tiny'} activePage={pageInfo.pageNumber + 1} totalPages={pageInfo.totalPages} siblingRange={2} onPageChange={(e, page) => setPageInfo({...pageInfo, pageNumber: (page.activePage as number) - 1})} />
                    }
                    <Button
                      floated="right"
                      icon
                      labelPosition="left"
                      size="small"
                      positive
                      onClick={() => {
                        setActive({userId: +id});
                      }}
                    >
                      <Icon name="add" /> Nová sleva
                    </Button>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            }
          </Table>
        </>
      }
    </>
  );
}
