import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Grid,
  Icon,
  Segment,
  Table,
} from "semantic-ui-react";
import Translate from "../../../components/translation/Translate";
import { DashboardWidgetName } from "../../../base/locale/translations/DashboardWidget";
import { DashboardWidgetIcon } from "../../../base/enums/DashboardWidgetIcon";
import { Formatter } from "../../../base/util/Formatter";
import {DashboardWidgetUserDto} from '../../../api/generated-sources'

interface FinanceTableWidgetProps {
  widget: DashboardWidgetUserDto;
  data: string;
  configuration: string;
  onReload: (widget: DashboardWidgetUserDto, configuration: string) => void;
  onRemove: (widget: DashboardWidgetUserDto) => void;
}

interface FinanceTableConfiguration {
  period: string;
  length: number;
}

interface FinanceTableBalanceData {
  expensesWithoutVat: number;
  revenueWithoutVat: number;
  revenueWithoutVatUnpaid: number;
  period: string;
  periodId: number;
  revenueWithVat?: number;
  expensesWithVat?: number;
  vatEstimate?: number;
  label?: string;
}

export default function FinanceTableWidget(props: FinanceTableWidgetProps) {
  const [data, setData] = useState<FinanceTableBalanceData[]>(
    JSON.parse(props.data)
  );
  const [configuration, setConfiguration] = useState<FinanceTableConfiguration>(
    JSON.parse(props.configuration)
  );

  useEffect(() => {
    setData(JSON.parse(props.data));
  }, [props.data]);

  useEffect(() => {
    setConfiguration(JSON.parse(props.configuration));
  }, [props.configuration]);

  const setPeriod = (period: string) => {
    const configuration = {
      period: period,
      length: 10,
    };
    setConfiguration(configuration);
    props.onReload(props.widget, JSON.stringify(configuration));
  };

  const filterItems = (data: FinanceTableBalanceData[]) => {
    const filteredItems: FinanceTableBalanceData[] = [];
    let maxMonth: number = Math.max.apply(
      Math,
      data
        .filter((item) => item.period === "month")
        .map(function (o) {
          return o.periodId;
        })
    );
    maxMonth = maxMonth === 12 ? 1 : maxMonth;

    data.forEach((item) => {
      let selectedData: FinanceTableBalanceData;
      if (item.period === "quarter") {
        selectedData = {
          label: "Tento kvartál (Q" + item.periodId + ")",
          ...item,
        };
        filteredItems.push(selectedData);
      } else if (item.period === "year") {
        selectedData = { label: "Tento rok", ...item };
        filteredItems.push(selectedData);
      } else if (item.period === "month" && item.periodId === maxMonth) {
        selectedData = { label: "Tento měsíc", ...item };
        filteredItems.push(selectedData);
      } else {
        selectedData = { label: "Předchozí měsíc", ...item };
        filteredItems.push(selectedData);
      }
    });
    return filteredItems;
  };
  filterItems(data);

  return (
    <Grid.Column
      className={"dashboard-widget"}
      width={16}
      key={props.widget.id}
    >
      <Segment className={"header"} inverted color={"red"} attached={"top"}>
        <strong>
          <Icon name={DashboardWidgetIcon.INVOICE_SUBJECT_BREAKDOWN} />{" "}
          <Translate
            label={DashboardWidgetName.get(props.widget.dashboardWidget)}
          />
        </strong>
        <Button
          circular
          icon="trash"
          color={"black"}
          inverted
          size={"mini"}
          onClick={() => props.onRemove(props.widget)}
        />
      </Segment>
      <Segment attached={"bottom"}>
        <ButtonGroup color="red" widths={"1"}>
          <Button
            color={"red"}
            width={3}
            active={configuration.period === "currentYear"}
            onClick={() => setPeriod("currentYear")}
          >
            Aktuální přehled
          </Button>
          <Button
            color={"red"}
            width={3}
            active={configuration.period === "allYears"}
            onClick={() => setPeriod("allYears")}
          >
            Předchozí roky
          </Button>
        </ButtonGroup>
        {configuration.period === "currentYear" ? (
          <Table size="small" striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell rowSpan="2" textAlign="left">
                  Období
                </Table.HeaderCell>
                <Table.HeaderCell rowSpan="2" textAlign="center">
                  Výdaje
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="2" textAlign="center">
                  Příjmy
                </Table.HeaderCell>
                <Table.HeaderCell rowSpan="2" textAlign="center">
                  Rozdíl <br /> (Příjmy - Výdaje)
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="3" textAlign="center">
                  Odhad DPH
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell textAlign="center">Celkem</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Nezaplacené
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Přijato DPH
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Zaplaceno DPH
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Doplatek DPH
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {filterItems(data).map((item) => {
                return (
                  <Table.Row key={item.periodId} textAlign="center">
                    <Table.Cell textAlign="left">
                      <strong>{item.label}</strong>
                    </Table.Cell>
                    <Table.Cell>
                      {Formatter.money(item.expensesWithoutVat)}
                    </Table.Cell>
                    <Table.Cell>
                      {Formatter.money(item.revenueWithoutVat)}
                    </Table.Cell>
                    <Table.Cell negative>
                      {Formatter.money(item.revenueWithoutVatUnpaid)}
                    </Table.Cell>
                    <Table.Cell>
                      <strong>
                        {Formatter.money(
                          item.revenueWithoutVat - item.expensesWithoutVat
                        )}
                      </strong>
                    </Table.Cell>
                    <Table.Cell>
                      {Formatter.money(
                        item.revenueWithVat - item.revenueWithoutVat
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {Formatter.money(
                        item.expensesWithVat - item.expensesWithoutVat
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <strong>
                        {Formatter.money(
                          item.revenueWithVat -
                            item.revenueWithoutVat -
                            (item.expensesWithVat - item.expensesWithoutVat)
                        )}
                      </strong>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        ) : (
          <Table size="small" striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell rowSpan="2" textAlign="left">
                  Období
                </Table.HeaderCell>
                <Table.HeaderCell rowSpan="2" textAlign="center">
                  Výdaje
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="2" textAlign="center">
                  Příjmy
                </Table.HeaderCell>
                <Table.HeaderCell rowSpan="2" textAlign="center">
                  Rozdíl
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell textAlign="center">Celkem</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  Nezaplacené
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data
                .sort((a, b) => Number(b.periodId) - Number(a.periodId))
                .map((item) => {
                  return (
                    <Table.Row key={item.periodId} textAlign="center">
                      <Table.Cell textAlign="left">
                        <strong>{item.periodId}</strong>
                      </Table.Cell>
                      <Table.Cell>
                        {Formatter.money(item.expensesWithoutVat)}
                      </Table.Cell>
                      <Table.Cell>
                        {Formatter.money(item.revenueWithoutVat)}
                      </Table.Cell>
                      <Table.Cell negative>
                        {Formatter.money(item.revenueWithoutVatUnpaid)}
                      </Table.Cell>
                      <Table.Cell>
                        <strong>
                          {Formatter.money(
                            item.revenueWithoutVat - item.expensesWithoutVat
                          )}
                        </strong>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        )}
      </Segment>
    </Grid.Column>
  );
}
