import React, { useEffect, useState } from "react";
import InvoiceSubjectDetail from "../component/InvoiceSubjectDetail";
import { useParams } from "react-router-dom";
import SimpleLoader from "../../../components/SimpleLoader";
import {
  getInvoiceSubject,
  InvoiceSubjectDto
} from '../../../api/generated-sources'
import toast from 'react-hot-toast'

export default function DetailInvoiceSubjectsViewPage() {
  const [activeSubject, setActiveSubject] = useState<InvoiceSubjectDto>();
  const [loading, setLoading] = useState(false);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (parseInt(id, 10)) {
      setLoading(true)
      getInvoiceSubject(+id)
        .then(subject => setActiveSubject(subject))
        .catch(reason => {
          console.log("Error", reason)
          toast.error("Něco se pokazilo!");
        })
        .finally(() => setLoading(false));
    }
  }, [id]);

  return (
    <>
      {loading ? (
        <SimpleLoader text={"Načítám data"} />
      ) : (
        <InvoiceSubjectDetail subject={activeSubject} />
      )}
    </>
  );
}
