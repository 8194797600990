import {Button, DropdownProps, Form, Icon, List, Modal} from "semantic-ui-react";
import {useParams} from "react-router-dom";
import toast from "react-hot-toast";
import {StringUtil} from "../../../base/util/StringUtil";
import React, {useEffect, useState} from "react";
import {SyncOperationState} from "../../../base/state/SyncOperationState";
import {TaxExemptUtil} from "../../../base/util/TaxExemptUtil";
import {FileUploadUtil} from "../../../base/util/FileUploadUtil";
import {
  createTaxExempt, deleteTaxExempt, deleteTaxExemptAttachment, EDocumentType,
  ETaxExemptType, FileAttachmentDto, getTaxExemptBaseValue, TaxExemptDto,
  TaxExemptInputDto,
  updateTaxExempt
} from '../../../api/generated-sources'
import {LocalDate} from '../../../build/core/dto/LocalDate'

type TaxExemptTableModalProps = {
  selectedRow: TaxExemptDto;
  close?: () => void;
  isOpen?: boolean;
  handleUpdate: () => void;
  handleDelete: (id: number) => void;
};

export function TaxExemptTableModal(props: TaxExemptTableModalProps) {
  const {
    selectedRow,
    isOpen,
    close,
    handleUpdate,
    handleDelete,
  } = props;

  const { id } = useParams<{ id: string }>();

  const [taxExempt, setTaxExempt] = useState<TaxExemptInputDto>({ taxExempt: selectedRow, attachment: undefined});
  const [syncState, setSyncState] = useState<SyncOperationState>({
    inProgress: false,
  });
  const [deleteState, setDeleteState] = useState<SyncOperationState>({
    inProgress: false,
  });

  const handleDeleteAttachment = (id: number) => {
    setDeleteState({inProgress: true, type: "delete"});
    const promise = deleteTaxExemptAttachment(id);
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Dokument byl úspěšně smazán.",
        error: "Něco se pokazilo!",
      });
    promise
      .then(data => {
        setDeleteState({inProgress: false, type: undefined});
        setTaxExempt({
          ...taxExempt,
          attachment: undefined,
          taxExempt: {
            filename: undefined,
            documentUrl: undefined,
            documentType: undefined,
          }
        })
        handleUpdate();
      })
      .catch(console.error)
  }

  const handleChange = (value: any, field: string) => {
    setTaxExempt({
      taxExempt: {
        ...taxExempt.taxExempt,
        [field]: value,
      },
      attachment: taxExempt.attachment,
    });
  };

  const handleChangeAttachment = (attachment: FileAttachmentDto) => {
    setTaxExempt({
      ...taxExempt,
      attachment: attachment,
    });
  }

  useEffect(() => {
    setSyncState({inProgress: false});
  }, [isOpen]);

  useEffect(() => {
    setTaxExempt({
      ...selectedRow,
      taxExempt: {
        type: selectedRow.type ?? ETaxExemptType.BASIC,
        amount: selectedRow.amount ?? 0,
        note: selectedRow.note ?? "",
        validFrom: selectedRow.validFrom ?? new LocalDate((new Date()).getFullYear(), (new Date()).getMonth(), 1).toString()
      }
    });
  }, [selectedRow]);

  useEffect(() => {
    if (taxExempt) {
      const today = new Date();
      getTaxExemptBaseValue({
          type: taxExempt.taxExempt.type,
          date: new LocalDate(today.getFullYear(), today.getMonth(), today.getDate()).toString()
      })
        .then(data => {
          setTaxExempt({
            ...taxExempt,
            taxExempt: {
              ...taxExempt.taxExempt,
              amount: data,
            }
          })
        })
        .catch(console.error);
    }
  }, [taxExempt.taxExempt.type]);

  const create = () => {
    setSyncState({inProgress: true});

    const promise = createTaxExempt({
      taxExempt: {
        ...taxExempt.taxExempt,
        documentType: taxExempt.taxExempt.type === ETaxExemptType.BASIC ? EDocumentType.PROHLASENI_POPLATNIKA : null,
        userId: +id
      },
      attachment: taxExempt.attachment,
    });
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Záznam byl úspěšně vytvořen.",
        error: "Něco se pokazilo!",
      }
    ).then(() => close());
    promise
      .then(data => {
        setSyncState({inProgress: false, type: undefined});
        handleUpdate();
        close();
      })
      .catch(reason => {
        console.error(reason)
      });
  };

  const update = () => {
    setSyncState({inProgress: true});
    const updateItem = {
      taxExempt: {
        id: taxExempt.taxExempt.id,
        amount: taxExempt.taxExempt.amount,
        documentType: taxExempt.taxExempt.type === ETaxExemptType.BASIC ? EDocumentType.PROHLASENI_POPLATNIKA : null,
        note: taxExempt.taxExempt.note,
        type: taxExempt.taxExempt.type,
        userId: +id,
        validFrom: taxExempt.taxExempt.validFrom,
        validTo: taxExempt.taxExempt.validTo,
      },
      attachment: taxExempt.attachment,
    };

    const promise = updateTaxExempt(updateItem.taxExempt.id, updateItem);
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Záznam byl úspěšně upraven.",
        error: "Něco se pokazilo!",
      }
    ).then(() => handleUpdate());
    promise
      .then(data => {
        setSyncState({inProgress: false, type: undefined});
        handleUpdate();
        close();
      })
      .catch(console.error)
  }

  const handleDeleteTaxExempt = () => {
    setSyncState({inProgress: true, type: "delete"});

    const promise = deleteTaxExempt(taxExempt.taxExempt.id);
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Záznam byl úspěšně smazán.",
        error: "Něco se pokazilo!",
      }
    ).then(() => close());
    promise
      .then(data => {
        setSyncState({inProgress: false, type: undefined});
        handleDelete(taxExempt.taxExempt.id);
        close();
      })
      .catch(console.log);
  };

  const requiredFieldsPresent = () => {
    return !!taxExempt?.taxExempt.validFrom;
  };

  return (
    <Modal
      className={"invoice-detail"}
      onClose={close}
      open={isOpen}
      size={"small"}
      closeIcon
    >
      <Modal.Header>
        <Icon name={'edit'} /> {taxExempt.taxExempt.id ? "Úprava slevy na dani" : "Nová sleva na dani"}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths={2}>
            <Form.Input
              required
              type={"number"}
              width={8}
              label={"Měsíční částka"}
              onChange={(e) => handleChange(e?.target.value, "amount")}
              value={taxExempt.taxExempt.amount}
            />
            <Form.Select
              value={taxExempt.taxExempt.type}
              required
              width={8}
              label={"Druh slevy"}
              options={TaxExemptUtil.getTaxExemptTypeOptions()}
              onChange={(e, data: DropdownProps) => handleChange(data.value, "type")}
            />
          </Form.Group>
          <Form.Input
            width={16}
            label={"Poznámka"}
            onChange={(e) => handleChange(e?.target.value, "note")}
            value={taxExempt.taxExempt.note}
          />
          <Form.Group widths={2}>
            <Form.Input
              label="Platnost od"
              required
              type="date"
              value={taxExempt.taxExempt.validFrom}
              onChange={(e) => handleChange(e?.target.value, "validFrom")}
            />
            <Form.Field>
              <label>Platnost do</label>
              <Form.Input
                type="date"
                value={taxExempt.taxExempt.validTo}
                onChange={(e) => handleChange(e?.target.value, "validTo")}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <List relaxed className={"formUpload"}>
              {!taxExempt.taxExempt.documentUrl && (
                <List.Item>
                  <Button disabled={syncState.inProgress} icon labelPosition="right"
                    onClick={() =>
                      window.document.getElementById("uploadAttachmentInput")?.click()
                    }
                  >
                  {taxExempt.taxExempt.documentUrl ? "Zobrazit dokument" : taxExempt?.attachment?.filename ? StringUtil.shortenWithEllipsis(taxExempt.attachment.filename, 27) : "Nahrát dokument"} <Icon name="upload" />
                  </Button>
                </List.Item>
              )}
              {
                !taxExempt.taxExempt.documentUrl && <List.Item className={syncState.error ? "highlight-warning" : null}>
                  Limit 100MB (formáty .png, .jpg, .pdf) {syncState.error && <Icon name={"warning"} />}
                </List.Item>
              }
              {!!taxExempt.taxExempt.documentUrl && (
                <List.Item>
                  <Button color={"black"} icon labelPosition="left" onClick={() => window.open(taxExempt.taxExempt.documentUrl)}>
                    <Icon name="download" /> Zobrazit dokument
                  </Button>
                  <Button basic icon={"trash"} color={"red"} loading={deleteState.inProgress} onClick={(e) => handleDeleteAttachment(taxExempt.taxExempt.id)} />
                </List.Item>
              )}
              <List.Item>
                  <input
                    hidden
                    id={"uploadAttachmentInput"}
                    type={"file"}
                    accept="image/png, image/jpeg, application/pdf"
                    onChange={(event) => FileUploadUtil.setAttachment(event, handleChangeAttachment, setSyncState)}
                  />
              </List.Item>
            </List>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions className="button-ppv">
        {taxExempt.taxExempt.id ? (
          <>
            <Button color={"red"} floated="left" type="submit" icon={"trash alternate"}
              loading={syncState.inProgress && syncState.type === "delete"}
              disabled={syncState.inProgress}
              onClick={handleDeleteTaxExempt} />
            <Button
              color={"black"}
              floated="right"
              type="submit"
              loading={syncState.inProgress && syncState.type !== "delete"}
              disabled={syncState.inProgress || !requiredFieldsPresent()}
              onClick={update}
            >
              <Icon name={"save"} /> Uložit změny
            </Button>
          </>
        ) : (
          <Button
            color={"black"}
            floated="right"
            type="submit"
            onClick={create}
            loading={syncState.inProgress && syncState.type !== "delete"}
            disabled={syncState.inProgress || !requiredFieldsPresent()}
          >
            <Icon name={"save"} /> Vytvořit
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
}
