import { useEffect, useState } from "react";
import { Button, Grid, Header, Icon, Segment } from "semantic-ui-react";
import Translate from "../../../components/translation/Translate";
import { DashboardWidgetName } from "../../../base/locale/translations/DashboardWidget";
import { DashboardWidgetIcon } from "../../../base/enums/DashboardWidgetIcon";
import { Formatter } from "../../../base/util/Formatter";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import {DashboardWidgetUserDto} from '../../../api/generated-sources'

interface InvoiceSubjectBreakdownWidgetProps {
  widget: DashboardWidgetUserDto;
  data: string;
  onRemove: (widget: DashboardWidgetUserDto) => void;
}

interface InvoiceSubjectBreakdownData {
  year: number;
  subjects: InvoiceSubjectBreakdownItemData[];
}

interface InvoiceSubjectBreakdownItemData {
  name: string;
  amount: number;
}

const COLORS = ["#DB2828", "#C83C3C", "#A26262", "#8F7575", "#828282"];

const RADIAN = Math.PI / 180;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={/* x > cx ? 'start' : 'end' */ "middle"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function InvoiceSubjectBreakdownWidget(
  props: InvoiceSubjectBreakdownWidgetProps
) {
  const [data, setData] = useState<InvoiceSubjectBreakdownData>(
    JSON.parse(props.data)
  );

  useEffect(() => {
    setData(getTopCustomers(props.data));
  }, [props.data]);

  const getTopCustomers = (input: string) => {
    const customers: InvoiceSubjectBreakdownData = JSON.parse(input);
    customers.subjects.sort((a, b) => Number(b.amount) - Number(a.amount));
    const topFiveCustomers = customers.subjects.slice(0, 4);
    const otherCustomers = customers.subjects.slice(4);
    const otherCustomersAmount = otherCustomers
      .map((item) => item.amount)
      .reduce((prev, next) => prev + next, 0);

    if (otherCustomersAmount > 0) {
      const otherCustomersSumItem = {
        amount: otherCustomersAmount,
        name: "Ostatní",
      };
      topFiveCustomers.push(otherCustomersSumItem);
    }
    return { year: customers.year, subjects: topFiveCustomers };
  };

  return (
    <Grid.Column className={"dashboard-widget"} width={8} key={props.widget.id}>
      <Segment className={"header"} inverted color={"red"} attached={"top"}>
        <strong>
          <Icon name={DashboardWidgetIcon.INVOICE_SUBJECT_BREAKDOWN} />{" "}
          <Translate
            label={DashboardWidgetName.get(props.widget.dashboardWidget)}
          />
        </strong>
        <Button
          circular
          icon="trash"
          color={"black"}
          inverted
          size={"mini"}
          onClick={() => props.onRemove(props.widget)}
        />
      </Segment>
      <Segment attached={"bottom"}>
        <br />
        {data.subjects.length === 0 ? (
          <Segment placeholder>
            <Header icon>
              <Icon name="file" />
              Nevydali jste klientům zatím žádné faktury.
            </Header>
          </Segment>
        ) : (
          <ResponsiveContainer width="100%" aspect={1}>
            <PieChart>
              <Pie
                cx={"50%"}
                cy={"40%"}
                data={data.subjects}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={100}
                fill="#8884d8"
                dataKey="amount"
              >
                {data.subjects.map((entry, index) => {
                  return (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  );
                })}
              </Pie>
              <Tooltip
                formatter={(value: number) => `${Formatter.money(value)}`}
              />
              <Legend
                verticalAlign="bottom"
                layout="horizontal"
                align="center"
                payload={data.subjects.map((item, index) => ({
                  type: "square",
                  color: COLORS[index % COLORS.length],
                  value: `${item.name} (celkem ${Formatter.money(
                    item.amount
                  )})`,
                }))}
              />
            </PieChart>
          </ResponsiveContainer>
        )}
      </Segment>
    </Grid.Column>
  );
}
