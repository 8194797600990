import {
  Button,
  DropdownProps,
  Form,
  Grid,
  Icon,
  List,
} from "semantic-ui-react";
import { useContext, useEffect, useState } from "react";
import { SyncOperationState } from "../../../base/state/SyncOperationState";
import { VatUtil } from "../../../base/util/VatUtil";
import { FilterContext, FilterValue } from "../../../contexts/FilterContext";
import { useHistory } from "react-router-dom";
import { View } from "../../../base/enums/View";
import Breadcrumbs from "../../../components/base/Breadcrumbs";
import toast from "react-hot-toast";
import {
  createTransactionPlan, deleteTransactionPlan,
  TransactionPlanDto,
  updateTransactionPlan
} from '../../../api/generated-sources'
interface TransactionPlanDetailProps {
  plan?: TransactionPlanDto;
}

export default function TransactionPlanDetail(
  props: TransactionPlanDetailProps
) {
  const { month, year } = useContext<FilterValue>(FilterContext);
  const [saveState, setSaveState] = useState<SyncOperationState>({
    inProgress: false,
  });

  const [deleteState, setDeleteState] = useState<SyncOperationState>({
    inProgress: false,
  });

  const history = useHistory();

  const initialState = props.plan
    ? {
        description: props.plan.description,
        vs: props.plan.vs,
        ss: props.plan.ss,
        account: props.plan.account,
        expectedCount: props.plan.expectedCount,
        repeatsEveryMonths: props.plan.repeatsEveryMonths,
        vat: props.plan.vat,
        requiresAttachment: props.plan.requiresAttachment,
        dueOnDayOfMonth: props.plan.dueOnDayOfMonth,
        notify: props.plan.notify,
      }
    : {
        vs: "",
        ss: "",
        account: "",
        description: "Popis vaší plánované transakce",
        expectedCount: 1,
        repeatsEveryMonths: 1,
        vat: 21,
        requiresAttachment: false,
        dueOnDayOfMonth: 20,
        notify: true,
      };

  const [state, setState] = useState<TransactionPlanDto>(initialState);

  useEffect(() => {
    setState(initialState);
  }, [props]);

  const onSetTransactionPlanError = () => {
    setSaveState({
      inProgress: false,
    });
  };

  const save = () => {
    setSaveState({
      ...saveState,
      inProgress: true,
    });
    if (props.plan?.id) {
      const promise = updateTransactionPlan(props.plan.id, {
        id: props.plan.id,
        description: state.description,
        vs: state.vs,
        ss: state.ss,
        account: state.account,
        dueOnDayOfMonth: state.dueOnDayOfMonth,
        repeatsEveryMonths: state.repeatsEveryMonths,
        vat: state.vat,
        requiresAttachment: state.requiresAttachment,
        expectedCount: state.expectedCount,
        notify: state.notify,
      })
      toast.promise(promise,
        {
          loading: "Zpracovávám...",
          success: "Plánovaná transakce byla úspěšně upravená.",
          error: "Něco se pokazilo!",
        }
      );
      promise
        .then(value => {
          setSaveState({ inProgress: true });
          history.push(View.PLANNED_TRANSACTIONS.path);
        })
        .catch(onSetTransactionPlanError);

    } else {

      const promise = createTransactionPlan({
        transactionPlan: {
          description: state.description,
          vs: state.vs,
          ss: state.ss,
          account: state.account,
          dueOnDayOfMonth: state.dueOnDayOfMonth,
          repeatsEveryMonths: state.repeatsEveryMonths,
          vat: state.vat,
          requiresAttachment: state.requiresAttachment,
          expectedCount: state.expectedCount,
          notify: state.notify,
        },
        year: year,
        month: month,
      });
      toast.promise(promise,
        {
          loading: "Zpracovávám...",
          success: "Plánovaná transakce byla úspěšně vytvořená.",
          error: "Něco se pokazilo!",
        }
      );
      promise
        .then(value => {
          setSaveState({ inProgress: true });
          history.push(View.PLANNED_TRANSACTIONS.path);
        })
        .catch(onSetTransactionPlanError);
    }
  };

  const handleChange = (e: any, field: string) => {
    if (field === "requiresAttachment") {
      setState({
        ...state,
        requiresAttachment: e,
      });
    } else if (e.target.type === "number") {
      const parsedValue = Number.parseInt(e.target.value);
      const isValidInteger = Number.isInteger(parsedValue);
      setState({
        ...state,
        [field]: isValidInteger ? parsedValue : e.target.value,
      });
    } else {
      setState({
        ...state,
        [field]: e.target.value,
      });
    }
  };

  const handleSelectChange = (obj: DropdownProps, field: string) => {
    setState({
      ...state,
      [field]: obj.value,
    });
  };

  const onDeleteSuccess = () => {
    if (props.plan) {
      setDeleteState({
        inProgress: false,
      });
      history.push(View.PLANNED_TRANSACTIONS.path);
    }
  };

  const onDeleteError = (data: any) => {
    console.error(data);
    setDeleteState({
      inProgress: false,
    });
  };

  const deletePlan = () => {
    setDeleteState({
      inProgress: true,
    });
    const promise = deleteTransactionPlan(props?.plan!.id)
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Plánovaná transakce byla úspěšně smazaná.",
        error: "Něco se pokazilo!",
      }
    );
    promise
      .then(onDeleteSuccess)
      .catch(onDeleteError)
  };

  return (
    <>
      <Breadcrumbs
        title={
          props.plan
            ? `Detail "${props.plan.description}"`
            : "Nová plánovaná transakce"
        }
      />
      <div className={"transaction-plan-detail"}>
        <Grid className="plan-detail">
          <Grid.Column width={16}>
            <Form>
              <Form.Group>
                <Form.Input
                  width={8}
                  label={"Číslo účtu"}
                  onChange={(event) => handleChange(event, "account")}
                  type={"text"}
                  value={state.account}
                />
                <Form.Input
                  width={4}
                  label={"Variabilní symbol"}
                  onChange={(event) => handleChange(event, "vs")}
                  value={state.vs}
                  type={"text"}
                />
                <Form.Input
                  width={4}
                  label={"Specifický symbol"}
                  onChange={(event) => handleChange(event, "ss")}
                  value={state.ss}
                  type={"text"}
                />
                <Form.Select
                  label={"DPH"}
                  width={4}
                  options={VatUtil.getVatOptions()}
                  value={state.vat}
                  onChange={(e, data: DropdownProps) =>
                    handleSelectChange(data, "vat")
                  }
                />
              </Form.Group>
              <Form.Input
                width={16}
                label={"Popis"}
                onChange={(event) => handleChange(event, "description")}
                value={state.description}
                type={"text"}
              />
              <Form.Group widths={"equal"}>
                <Form.Input
                  label={
                    "Opakuje se " +
                    (state.repeatsEveryMonths === 1
                      ? "každý měsíc"
                      : "každé " + state.repeatsEveryMonths + " měsíce")
                  }
                  onChange={(event) =>
                    handleChange(event, "repeatsEveryMonths")
                  }
                  value={state.repeatsEveryMonths}
                  type={"number"}
                  error={
                    state.repeatsEveryMonths < 1 ||
                    state.repeatsEveryMonths > 12
                  }
                />
                <Form.Input
                  label={
                    "Splatné k " + state.dueOnDayOfMonth + ". dni v měsíci"
                  }
                  onChange={(event) => handleChange(event, "dueOnDayOfMonth")}
                  value={state.dueOnDayOfMonth}
                  type={"number"}
                  error={
                    state.dueOnDayOfMonth < 1 || state.dueOnDayOfMonth > 30
                  }
                />
                <Form.Input
                  label={"Počet očekávaných plateb měsíčně"}
                  onChange={(event) => handleChange(event, "expectedCount")}
                  value={state.expectedCount}
                  type={"number"}
                  error={state.expectedCount < 1}
                />
              </Form.Group>
              <Form.Checkbox
                label={"Vyžaduje doklad"}
                onChange={(event, data) =>
                  handleChange(data.checked, "requiresAttachment")
                }
                checked={state.requiresAttachment}
              />
            </Form>
            <List>
              <List.Item>
                <Icon name={"info circle"} /> Změna záznamu se propíše pro tento
                a každý následující měsíc.
              </List.Item>
            </List>
          </Grid.Column>
        </Grid>
        <div className="action-btn">
          <Button
            icon="left chevron"
            onClick={() => history.push(View.PLANNED_TRANSACTIONS.path)}
            className="mobile-btn"
          />
          <Button
            labelPosition="left"
            icon="left chevron"
            content="Zpět"
            onClick={() => history.push(View.PLANNED_TRANSACTIONS.path)}
            className="mobile-btnHidden"
          />
          <div>
            {props.plan && (
              <Button
                floated={"left"}
                color={"red"}
                loading={deleteState.inProgress}
                disabled={saveState.inProgress}
                onClick={deletePlan}
              >
                <Icon name={"trash"} />{" "}
                <span className="action-btnSpan">Smazat plán</span>
              </Button>
            )}
            <Button
              color={"black"}
              loading={saveState.inProgress}
              disabled={deleteState.inProgress}
              onClick={save}
            >
              <Icon name={"save"} />{" "}
              <span className="action-btnSpan">
                {props.plan ? "Uložit změny" : "Vytvořit plánovanou tranzakce"}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
