import {useEffect, useMemo, useState} from "react";
import {Button, Container, Grid, Header, Icon, Table,} from "semantic-ui-react";
import SimpleLoader from "../../../../components/SimpleLoader";
import {useHistory} from "react-router-dom";
import {View} from "../../../../base/enums/View";
import moment from "moment";
import {config} from "../../../../base/config/DynamicConfig";
import {LoginType} from "../../../../base/enums/LoginType";
import FullTextFilter from "../../../../components/base/FullTextFilter";
import {getUsers, UserDto} from '../../../../api/generated-sources'

export default function EmployeesManagementView() {
  const [employees, setEmployees] = useState<UserDto[]>();
  const [filter, setFilter] = useState(employees);
  const [column, setColumn] = useState<string>();
  const [direction, setDirection] = useState<"ascending" | "descending">(
    "ascending"
  );
  const history = useHistory();

  useEffect(() => {
    getUsers()
      .then(setEmployees)
      .catch(console.error)
  }, []);

  const handleSort = (clickedColumn: string) => {
    setFilter([])
    if (column === clickedColumn) {
      setDirection(direction === "ascending" ? "descending" : "ascending");
    } else {
      setColumn(clickedColumn);
      setDirection("ascending");
    }
  };

  const sortedData = useMemo(() => {
    if (employees) {
      return [...employees]?.sort((a, b) => {
        const columnType = typeof a[column as keyof UserDto];
        if (columnType === "string" || column === "birthDate") {
          return direction === "ascending"
              ? a[column as keyof UserDto]
                  .toString()
                  .localeCompare(b[column as keyof UserDto].toString())
              : b[column as keyof UserDto]
                  .toString()
                  .localeCompare(a[column as keyof UserDto].toString());
        } else if (columnType === "number") {
          return direction === "ascending"
              ? Number(a[column as keyof UserDto]) -
              Number(b[column as keyof UserDto])
              : Number(b[column as keyof UserDto]) -
              Number(a[column as keyof UserDto]);
        } else {
          return 0;
        }
      });
    }
  }, [column, direction]);

  const getEmployeeRow = (item : UserDto) => {
    return <Table.Row
        onClick={() =>
          history.push(
            `${
              View.EMPLOYEES_MANAGEMENT.path +
              "/" +
              item?.id
            }`
          )
        }
        key={item.id}
      >
        <Table.Cell singleLine>
          {item?.lastname}
        </Table.Cell>
        <Table.Cell singleLine>
          {item?.firstname}
        </Table.Cell>
        <Table.Cell singleLine>
          {item.position}
        </Table.Cell>
        <Table.Cell singleLine>
          {
            item.birthDate && moment(
              item?.birthDate,
              moment.ISO_8601
            ).format("DD. MM. yyyy")
          }
        </Table.Cell>
        <Table.Cell
          singleLine
          className="mobile-align"
          textAlign={"center"}
        >
          {item?.insurance}
        </Table.Cell>
        <Table.Cell
          singleLine
          className="mobile-align"
          textAlign={"right"}
        >
          {item?.phone}
        </Table.Cell>
        <Table.Cell
          singleLine
          className="mobile-align"
          textAlign={"right"}
        >
          {item?.contactEmail}
        </Table.Cell>
      </Table.Row>
  }

  const displayLoader = !employees;

  return (
    <>
      <Container>
        {displayLoader ? (
          <Grid>
            <SimpleLoader text={"Načítám data"} />
          </Grid>
        ) : (
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Header>Seznam zaměstnanců</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <div>
                  <FullTextFilter
                    data={employees}
                    formatData={{
                      dateType: ["birthDate"],
                      formatDateType: "DD. MM. yyyy",
                      activeItem: [
                        "birthDate",
                        "firstname",
                        "id",
                        "fullname",
                        "insurance",
                        "lastname",
                        "maidenName",
                        "phone",
                        "position",
                        "roles",
                      ],
                    }}
                    returnFilteredData={setFilter}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Table basic striped selectable sortable={true}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        className="mobile-align"
                        sorted={column === "lastname" ? direction : null}
                        onClick={() => {
                          setColumn("lastname");
                          handleSort("lastname");
                        }}
                      >
                        Příjmení
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={column === "firstname" ? direction : null}
                        onClick={() => {
                          setColumn("firstname");
                          handleSort("firstname");
                        }}
                      >
                        Jméno
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={column === "position" ? direction : null}
                        onClick={() => {
                          setColumn("position");
                          handleSort("position");
                        }}
                      >
                        Pozice
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={column === "birthDate" ? direction : null}
                        onClick={() => {
                          setColumn("birthDate");
                          handleSort("birthDate");
                        }}
                      >
                        Datum narození
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        sorted={column === "insurance" ? direction : null}
                        onClick={() => {
                          setColumn("insurance");
                          handleSort("insurance");
                        }}
                      >
                        Zdravotní pojišťovna
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        textAlign={"center"}
                        className="mobile-align"
                      >
                        Telefonní číslo
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        className="mobile-align"
                        textAlign={"center"}
                        sorted={column === "contactEmail" ? direction : null}
                        onClick={() => {
                          setColumn("contactEmail");
                          handleSort("contactEmail");
                        }}
                      >
                        Email
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {employees?.length === 0 && (
                      <Table.Row>
                        <Table.Cell
                          textAlign={"center"}
                          className="mobile-align"
                          colSpan={7}
                        >
                          Nebyli nalezeni žádní zaměstnanci
                        </Table.Cell>
                      </Table.Row>
                    )}
                    {filter?.length
                      ? filter?.map(getEmployeeRow)
                      : sortedData
                      ? sortedData
                          .filter((employee) => employee.enabled === true)
                          .map(getEmployeeRow)
                      : employees
                          .filter((employee) => employee.enabled === true)
                          .map(getEmployeeRow)
                    }
                  </Table.Body>
                  {
                    config.env.LOGIN_TYPE === LoginType.LOCAL && <Table.Footer fullWidth>
                      <Table.Row
                        className="new-btn"
                        onClick={() => history.push(`${View.EMPLOYEES_MANAGEMENT.path + "/new"}`)}
                      >
                        <Table.HeaderCell colSpan="7" className="tr-add">
                          <Button className="new-btn" floated="right" icon labelPosition="left" positive size="small">
                            <Icon name="add" /> Nový zaměstnanec
                          </Button>
                        </Table.HeaderCell>
                      </Table.Row>
                  </Table.Footer>
                  }
                </Table>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                {employees.filter((employee) => employee.enabled === false)
                  .length !== 0 && (
                  <>
                    <h4>Archivovaní zaměstnanci</h4>
                    <Table basic striped selectable>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell className="mobile-align" textAlign={"center"}>Příjmení</Table.HeaderCell>
                          <Table.HeaderCell>Jméno</Table.HeaderCell>
                          <Table.HeaderCell>Pozice</Table.HeaderCell>
                          <Table.HeaderCell>Datum narození</Table.HeaderCell>
                          <Table.HeaderCell>Zdravotní pojišťovna</Table.HeaderCell>
                          <Table.HeaderCell className="mobile-align" textAlign={"center"}>Telefonní číslo</Table.HeaderCell>
                          <Table.HeaderCell className="mobile-align" textAlign={"center"}>Email</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {
                          employees
                          .filter((employee) => employee.enabled === false)
                          .map(getEmployeeRow)
                        }
                      </Table.Body>
                    </Table>
                  </>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Container>
    </>
  );
}
