/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
import useSwr from "swr";
import type { Key, SWRConfiguration } from "swr";
import type {
  ErrorResponseResponse,
  ExportInvoicesParams,
  ExportInvoicesProjectsParams,
} from "../intranetApi.schemas";
import { IntranetClientFetchInstance } from "../../intranetClientFetchInstance";
import type { ErrorType } from "../../intranetClientFetchInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary invoices export
 */
export const exportInvoices = (
  params?: ExportInvoicesParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<Blob>(
    { url: `/invoice/export`, method: "GET", params, responseType: "blob" },
    options
  );
};

export const getExportInvoicesKey = (params?: ExportInvoicesParams) =>
  [`/invoice/export`, ...(params ? [params] : [])] as const;

export type ExportInvoicesQueryResult = NonNullable<
  Awaited<ReturnType<typeof exportInvoices>>
>;
export type ExportInvoicesQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary invoices export
 */
export const useExportInvoices = <TError = ErrorType<ErrorResponseResponse>>(
  params?: ExportInvoicesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof exportInvoices>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getExportInvoicesKey(params) : null));
  const swrFn = () => exportInvoices(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary invoices export and its projects
 */
export const exportInvoicesProjects = (
  params?: ExportInvoicesProjectsParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<Blob>(
    {
      url: `/invoice/export/projects`,
      method: "GET",
      params,
      responseType: "blob",
    },
    options
  );
};

export const getExportInvoicesProjectsKey = (
  params?: ExportInvoicesProjectsParams
) => [`/invoice/export/projects`, ...(params ? [params] : [])] as const;

export type ExportInvoicesProjectsQueryResult = NonNullable<
  Awaited<ReturnType<typeof exportInvoicesProjects>>
>;
export type ExportInvoicesProjectsQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary invoices export and its projects
 */
export const useExportInvoicesProjects = <
  TError = ErrorType<ErrorResponseResponse>
>(
  params?: ExportInvoicesProjectsParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof exportInvoicesProjects>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getExportInvoicesProjectsKey(params) : null));
  const swrFn = () => exportInvoicesProjects(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
