/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
import useSwr from "swr";
import type { Arguments, Key, SWRConfiguration } from "swr";
import useSWRMutation from "swr/mutation";
import type { SWRMutationConfiguration } from "swr/mutation";
import type {
  CreateTransactionPlanBody,
  ErrorResponseResponse,
  GetTransactionPlansForMonthParams,
  TransactionPlanDto,
} from "../intranetApi.schemas";
import { IntranetClientFetchInstance } from "../../intranetClientFetchInstance";
import type { ErrorType } from "../../intranetClientFetchInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get transaction plans for month
 */
export const getTransactionPlansForMonth = (
  params: GetTransactionPlansForMonthParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<TransactionPlanDto[]>(
    { url: `/transactionPlan`, method: "GET", params },
    options
  );
};

export const getGetTransactionPlansForMonthKey = (
  params: GetTransactionPlansForMonthParams
) => [`/transactionPlan`, ...(params ? [params] : [])] as const;

export type GetTransactionPlansForMonthQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTransactionPlansForMonth>>
>;
export type GetTransactionPlansForMonthQueryError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary get transaction plans for month
 */
export const useGetTransactionPlansForMonth = <
  TError = ErrorType<ErrorResponseResponse>
>(
  params: GetTransactionPlansForMonthParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getTransactionPlansForMonth>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetTransactionPlansForMonthKey(params) : null));
  const swrFn = () => getTransactionPlansForMonth(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary create transaction plan
 */
export const createTransactionPlan = (
  createTransactionPlanBody: CreateTransactionPlanBody,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<TransactionPlanDto>(
    {
      url: `/transactionPlan`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createTransactionPlanBody,
    },
    options
  );
};

export const getCreateTransactionPlanMutationFetcher = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: CreateTransactionPlanBody }
  ): Promise<TransactionPlanDto> => {
    return createTransactionPlan(arg, options);
  };
};
export const getCreateTransactionPlanMutationKey = () =>
  `/transactionPlan` as const;

export type CreateTransactionPlanMutationResult = NonNullable<
  Awaited<ReturnType<typeof createTransactionPlan>>
>;
export type CreateTransactionPlanMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary create transaction plan
 */
export const useCreateTransactionPlan = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createTransactionPlan>>,
    TError,
    string,
    CreateTransactionPlanBody,
    Awaited<ReturnType<typeof createTransactionPlan>>
  > & { swrKey?: string };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCreateTransactionPlanMutationKey();
  const swrFn = getCreateTransactionPlanMutationFetcher(requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary get transaction plan
 */
export const getTransactionPlan = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<TransactionPlanDto>(
    { url: `/transactionPlan/${id}`, method: "GET" },
    options
  );
};

export const getGetTransactionPlanKey = (id: number) =>
  [`/transactionPlan/${id}`] as const;

export type GetTransactionPlanQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTransactionPlan>>
>;
export type GetTransactionPlanQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get transaction plan
 */
export const useGetTransactionPlan = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getTransactionPlan>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetTransactionPlanKey(id) : null));
  const swrFn = () => getTransactionPlan(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary delete transaction plan
 */
export const deleteTransactionPlan = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<string>(
    { url: `/transactionPlan/${id}`, method: "DELETE" },
    options
  );
};

export const getDeleteTransactionPlanMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, __: { arg: Arguments }): Promise<string> => {
    return deleteTransactionPlan(id, options);
  };
};
export const getDeleteTransactionPlanMutationKey = (id: number) =>
  `/transactionPlan/${id}` as const;

export type DeleteTransactionPlanMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTransactionPlan>>
>;
export type DeleteTransactionPlanMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary delete transaction plan
 */
export const useDeleteTransactionPlan = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteTransactionPlan>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof deleteTransactionPlan>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getDeleteTransactionPlanMutationKey(id);
  const swrFn = getDeleteTransactionPlanMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary update Transaction plan
 */
export const updateTransactionPlan = (
  id: number,
  transactionPlanDto: TransactionPlanDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<TransactionPlanDto>(
    {
      url: `/transactionPlan/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: transactionPlanDto,
    },
    options
  );
};

export const getUpdateTransactionPlanMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: TransactionPlanDto }
  ): Promise<TransactionPlanDto> => {
    return updateTransactionPlan(id, arg, options);
  };
};
export const getUpdateTransactionPlanMutationKey = (id: number) =>
  `/transactionPlan/${id}` as const;

export type UpdateTransactionPlanMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTransactionPlan>>
>;
export type UpdateTransactionPlanMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary update Transaction plan
 */
export const useUpdateTransactionPlan = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateTransactionPlan>>,
      TError,
      string,
      TransactionPlanDto,
      Awaited<ReturnType<typeof updateTransactionPlan>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getUpdateTransactionPlanMutationKey(id);
  const swrFn = getUpdateTransactionPlanMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
