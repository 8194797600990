import React, { ReactElement } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./assets/semantic/dist/semantic.css";
import { config, configUrl } from "./base/config/DynamicConfig";

fetch(configUrl)
  .then((response) => {
    return response.json();
  })
  .then((response) => {
    config.env = response;
    return <App />;
  })
  .catch((e) => {
    console.log(e);
    return (
      <p style={{ color: "red", textAlign: "center" }}>
        Error while fetching global config
      </p>
    );
  })
  .then((reactElement: ReactElement) => {
    ReactDOM.render(reactElement, document.getElementById("root"));
  });

serviceWorkerRegistration.unregister();
