import {SemanticICONS} from "semantic-ui-react";
import {ExpenseType} from '../../api/generated-sources'

export class ExpenseTypeUtil {
  public static getAllOptions = () => {
    return [
      { key: ExpenseType.PURCHASE, value: ExpenseType.PURCHASE, text: "Nákup" },
      { key: ExpenseType.EXPENDITURE, value: ExpenseType.EXPENDITURE, text: "Náklad" },
      { key: ExpenseType.PAYMENT, value: ExpenseType.PAYMENT, text: "Výplata" },
      { key: ExpenseType.LOAN, value: ExpenseType.LOAN, text: "Půjčka" },
    ];
  };
  public static getType = (type: string) => {
    switch (type) {
      case 'PURCHASE': return ExpenseType.PURCHASE;
      case 'EXPENDITURE': return ExpenseType.EXPENDITURE;
      case 'PAYMENT': return ExpenseType.PAYMENT;
      case 'LOAN': return ExpenseType.LOAN;
    }
    return null;
  };
  public static getText = (type: ExpenseType) : {text: string, icon: SemanticICONS} => {
    switch (type) {
      case ExpenseType.PURCHASE: return {text: 'Nákup', icon: 'shopping cart'};
      case ExpenseType.EXPENDITURE: return {text: 'Náklad', icon: 'gavel'};
      case ExpenseType.PAYMENT: return {text: 'Výplata', icon: 'dollar sign'};
      case ExpenseType.LOAN: return {text: 'Půjčka', icon: 'money'};
    }
    return null;
  };
}
