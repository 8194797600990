import React, {useMemo} from "react";
import {Label} from "semantic-ui-react";
import {CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {Formatter} from "../../../base/util/Formatter";
import {InvoiceDto, TransactionDto} from '../../../api/generated-sources'

interface InvoicePaymentGraphProps {
  invoice: InvoiceDto;
}

export default function InvoicePaymentGraph(props: InvoicePaymentGraphProps) {

  let today = new Date();
  today.setHours(2);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  let data : any[] = useMemo(() => {
    if (!props.invoice) {
      return [];
    }
    let chrono = [];
    chrono.push({
      epoch: props.invoice.dateCreated.valueOf(),
      CZK: props.invoice.totalPriceWithVat
    });
    chrono.push({
      epoch: props.invoice.dateDue.valueOf()
    });
    chrono.push({
      epoch: today.valueOf()
    });

    let amountRemaining = props.invoice.totalPriceWithVat;
    let sortedTransactions = Object.assign([], props.invoice.transactions);

    sortedTransactions
      .sort((t1 : TransactionDto, t2 : TransactionDto) => {return t1.date.valueOf() > t2.date.valueOf() ? 1 : -1})
      .forEach(t => {
        amountRemaining -= t.amount;
        chrono.push({
          tooltip: 'Platba - ' + Formatter.money(t.amount) + ' dne ' + t.date,
          epoch: t.date.valueOf(),
          CZK: amountRemaining
        });
      });
    return chrono.sort((a1, a2) => a1.epoch > a2.epoch ? 1 : -1);
  }, [props.invoice]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 20,
          left: 20,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="5 5" />
        <XAxis tickCount={10} allowDataOverflow dataKey="epoch" allowDuplicatedCategory={false} tickFormatter={(v, i) => {
          const d = new Date(v);
          if (i === 0) {
            return 'Vystavení faktury';
          }
          if (v === props.invoice.dateDue.valueOf()) {
            return 'Datum splatnosti';
          }
          const today = new Date();
          if (d.getFullYear() === today.getFullYear() && d.getMonth() === today.getMonth() && d.getDate() === today.getDate()) {
            return 'Dnes'
          }
          return `${d.getDate()}. ${d.getMonth() + 1}.`;
        }} />
        <YAxis width={100} tickFormatter={(v) => Formatter.money(v)}/>
        <Tooltip formatter={(v : any) => Formatter.money(v)} labelFormatter={(label) => {
          if (typeof label === 'string') {
            return label;
          } else if (typeof label === 'number') {
            const d = new Date(label);
            return `Platba dne - ${d.getDate()}. ${d.getMonth() + 1}. ${d.getFullYear()}`;
          }
          return "";
        }}/>
        <ReferenceLine x={props.invoice.dateDue.valueOf()} label={<Label content={'Datum splatnosti'} />} stroke="red" />
        <Line connectNulls type="linear" dataKey="CZK" stroke="#DB2828" strokeWidth={3} activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
}
