/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
import useSwr from "swr";
import type { Arguments, Key, SWRConfiguration } from "swr";
import useSWRMutation from "swr/mutation";
import type { SWRMutationConfiguration } from "swr/mutation";
import type {
  EmploymentDto,
  EmploymentInputDto,
  ErrorResponseResponse,
  GetEmploymentForCurrentUserParams,
  GetEmploymentForUserParams,
  PageableEmploymentResponse,
} from "../intranetApi.schemas";
import { IntranetClientFetchInstance } from "../../intranetClientFetchInstance";
import type { ErrorType } from "../../intranetClientFetchInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get employment for current user
 */
export const getEmploymentForCurrentUser = (
  params?: GetEmploymentForCurrentUserParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<PageableEmploymentResponse>(
    { url: `/employment/currentUser`, method: "GET", params },
    options
  );
};

export const getGetEmploymentForCurrentUserKey = (
  params?: GetEmploymentForCurrentUserParams
) => [`/employment/currentUser`, ...(params ? [params] : [])] as const;

export type GetEmploymentForCurrentUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEmploymentForCurrentUser>>
>;
export type GetEmploymentForCurrentUserQueryError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary get employment for current user
 */
export const useGetEmploymentForCurrentUser = <
  TError = ErrorType<ErrorResponseResponse>
>(
  params?: GetEmploymentForCurrentUserParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getEmploymentForCurrentUser>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetEmploymentForCurrentUserKey(params) : null));
  const swrFn = () => getEmploymentForCurrentUser(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary get employment for user
 */
export const getEmploymentForUser = (
  id: number,
  params?: GetEmploymentForUserParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<PageableEmploymentResponse>(
    { url: `/employment/${id}`, method: "GET", params },
    options
  );
};

export const getGetEmploymentForUserKey = (
  id: number,
  params?: GetEmploymentForUserParams
) => [`/employment/${id}`, ...(params ? [params] : [])] as const;

export type GetEmploymentForUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEmploymentForUser>>
>;
export type GetEmploymentForUserQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get employment for user
 */
export const useGetEmploymentForUser = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  params?: GetEmploymentForUserParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getEmploymentForUser>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetEmploymentForUserKey(id, params) : null));
  const swrFn = () => getEmploymentForUser(id, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary delete employment
 */
export const deleteEmployment = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<string>(
    { url: `/employment/${id}`, method: "DELETE" },
    options
  );
};

export const getDeleteEmploymentMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, __: { arg: Arguments }): Promise<string> => {
    return deleteEmployment(id, options);
  };
};
export const getDeleteEmploymentMutationKey = (id: number) =>
  `/employment/${id}` as const;

export type DeleteEmploymentMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteEmployment>>
>;
export type DeleteEmploymentMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary delete employment
 */
export const useDeleteEmployment = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteEmployment>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof deleteEmployment>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getDeleteEmploymentMutationKey(id);
  const swrFn = getDeleteEmploymentMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary update employment
 */
export const updateEmployment = (
  id: number,
  employmentInputDto: EmploymentInputDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<EmploymentDto>(
    {
      url: `/employment/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: employmentInputDto,
    },
    options
  );
};

export const getUpdateEmploymentMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: EmploymentInputDto }
  ): Promise<EmploymentDto> => {
    return updateEmployment(id, arg, options);
  };
};
export const getUpdateEmploymentMutationKey = (id: number) =>
  `/employment/${id}` as const;

export type UpdateEmploymentMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateEmployment>>
>;
export type UpdateEmploymentMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary update employment
 */
export const useUpdateEmployment = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateEmployment>>,
      TError,
      string,
      EmploymentInputDto,
      Awaited<ReturnType<typeof updateEmployment>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getUpdateEmploymentMutationKey(id);
  const swrFn = getUpdateEmploymentMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary createEmployment
 */
export const createEmployment = (
  employmentInputDto: EmploymentInputDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<EmploymentDto>(
    {
      url: `/employment`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: employmentInputDto,
    },
    options
  );
};

export const getCreateEmploymentMutationFetcher = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: EmploymentInputDto }
  ): Promise<EmploymentDto> => {
    return createEmployment(arg, options);
  };
};
export const getCreateEmploymentMutationKey = () => `/employment` as const;

export type CreateEmploymentMutationResult = NonNullable<
  Awaited<ReturnType<typeof createEmployment>>
>;
export type CreateEmploymentMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary createEmployment
 */
export const useCreateEmployment = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createEmployment>>,
    TError,
    string,
    EmploymentInputDto,
    Awaited<ReturnType<typeof createEmployment>>
  > & { swrKey?: string };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCreateEmploymentMutationKey();
  const swrFn = getCreateEmploymentMutationFetcher(requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary delete employment attachment
 */
export const deleteEmploymentAttachment = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<string>(
    { url: `/employment/${id}/attachment`, method: "DELETE" },
    options
  );
};

export const getDeleteEmploymentAttachmentMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, __: { arg: Arguments }): Promise<string> => {
    return deleteEmploymentAttachment(id, options);
  };
};
export const getDeleteEmploymentAttachmentMutationKey = (id: number) =>
  `/employment/${id}/attachment` as const;

export type DeleteEmploymentAttachmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteEmploymentAttachment>>
>;
export type DeleteEmploymentAttachmentMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary delete employment attachment
 */
export const useDeleteEmploymentAttachment = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteEmploymentAttachment>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof deleteEmploymentAttachment>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getDeleteEmploymentAttachmentMutationKey(id);
  const swrFn = getDeleteEmploymentAttachmentMutationFetcher(
    id,
    requestOptions
  );

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
