import React, {useContext, useEffect, useState} from "react";
import {Container, Form, Grid, GridColumn, GridRow, Header, Icon, IconGroup, List, Menu, Segment, Table} from "semantic-ui-react";
import SimpleLoader from "../../../components/SimpleLoader";
import {Formatter} from "../../../base/util/Formatter";
import {Auth, AuthContext} from "../../../contexts/AuthContext";
import QRCode from "react-qr-code";
import {
  ExpenseExtendedDto,
  ExpenseSummaryDto, ExpenseType,
  useGetExpenseSummary
} from '../../../api/generated-sources'
import moment from "moment";

type ExpenseExtendedDtoWithSubtotal = ExpenseExtendedDto & {
    subtotal: number;
}

type ExpenseSummaryExtended = ExpenseSummaryDto & {
  expensesWithSubtotal: ExpenseExtendedDtoWithSubtotal[];
}

export default function LoansView() {

  const auth = useContext<Auth>(AuthContext);
  const [activeUser, setActiveUser] = useState<string>(auth.name.split(" ")[1]);
  const [activeUserData, setActiveUserData] = useState<ExpenseSummaryExtended>();
  const [expenseSummary, setExpenseSummary] = useState<ExpenseSummaryExtended[]>();
  const [outstandingLoanAmount, setOutstandingLoanAmount] = useState<number>();
  const [vs, setVs] = useState<string>();

  const {data, error, isLoading} = useGetExpenseSummary({
    type: ExpenseType.LOAN,
  });

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      console.log(data);
      let expenseSummary = data.map(summary => {
        let expensesWithSubtotal = summary.expenses.map(expense => {
          return {
            ...expense,
            subtotal: 0
          }
        });

        expensesWithSubtotal.forEach(((expense, key) => {
          if (key > 0) {
            expense.subtotal = expensesWithSubtotal[key - 1].subtotal + expense.amount;
          } else {
            expense.subtotal = expense.amount;
          }
        }));

        return {
          ...summary,
          expensesWithSubtotal: expensesWithSubtotal,
        }
      });
      setExpenseSummary(expenseSummary);


      const currentUserLastName = auth.name.split(" ")[1];
      const report = expenseSummary?.find(summary => summary.owner.fullname.split(" ")[1] === currentUserLastName);
      if (report && report.expensesTotal < 0) {
        setOutstandingLoanAmount(Math.abs(report.expensesTotal));
      }
    }
  }, [data]);

  const handleActiveUserClick = (e: any, { name }: any) => {
    setActiveUser(name);
  };

  const generateQRCode = (vs?: string) : string => {
    const accountNumberIBAN = 'CZ0220100000002601810198'
    const am = (Math.round(outstandingLoanAmount * 100) / 100).toFixed(2);
    const msg = 'Splatka pujcky ' + auth.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    if (vs) {
      return `SPD*1.0*ACC:${accountNumberIBAN}*AM:${am}*CC:CZK*MSG:${msg}*X-VS:${vs}`;
    }
    return `SPD*1.0*ACC:${accountNumberIBAN}*AM:${am}*CC:CZK*MSG:${msg}`;
  }

  useEffect(() => {
      setActiveUserData(expenseSummary?.find(summary => summary.owner.fullname.split(" ")[1] === activeUser));
  }, [expenseSummary, activeUser]);

  if (!expenseSummary || isLoading) {
    return <Grid divided="vertically">
        <Grid.Row>
          <Grid.Column width={16}>
            <Header>Přehled půjček</Header>
          </Grid.Column>
        </Grid.Row>
        <SimpleLoader text={"Načítám přehled půjček"} />
    </Grid>
  }

  return (
    <Container>
      <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header>Přehled půjček</Header>
              <Table basic striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell textAlign={"right"}>
                      <Icon name={"shopping cart"} /> Půjčil si
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"}>
                      <Icon name={"dollar sign"} /> Vrátil
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign={"right"}>
                      Aktuální stav
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {expenseSummary.map((summary) => {
                    return (
                      <Table.Row key={summary.owner.id}>
                        <Table.Cell><strong>{summary.owner.fullname}</strong></Table.Cell>
                        <Table.Cell textAlign={"right"}>
                          {Formatter.money(
                            summary.expenses
                              .filter(expense => expense.amount < 0)
                              .flatMap((expense) => Math.abs(expense.amount))
                              .reduce((c, d) => c + d, 0)
                          )}
                        </Table.Cell>
                        <Table.Cell textAlign={"right"}>
                          {Formatter.money(
                            summary.expenses
                              .filter(expense => expense.amount > 0)
                              .flatMap((expense) => expense.amount)
                              .reduce((c, d) => c + d, 0)
                          )}
                        </Table.Cell>
                        <Table.Cell textAlign={"right"}>
                          {Formatter.money(
                            summary.expenses
                              .flatMap((expense) => expense.amount)
                              .reduce((c, d) => c + d, 0)
                          )}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell textAlign={"right"}>
                        <strong>
                          {Formatter.money(
                            expenseSummary.flatMap((summary) => {
                                return summary.expenses
                                  .filter(expense => expense.amount < 0)
                                  .flatMap((expense) => Math.abs(expense.amount))
                                  .reduce((c, d) => c + d, 0);
                              })
                              .reduce((a, b) => a + b, 0)
                          )}
                        </strong>
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign={"right"}>
                        <strong>
                          {Formatter.money(
                            expenseSummary.flatMap((summary) => {
                                return summary.expenses
                                  .filter(expense => expense.amount > 0)
                                  .flatMap((expense) => expense.amount)
                                  .reduce((c, d) => c + d, 0);
                              })
                              .reduce((a, b) => a + b, 0)
                          )}
                        </strong>
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign={"right"}>
                        <strong>
                          {Formatter.money(
                            expenseSummary.flatMap((summary) => {
                                return summary.expenses
                                  .flatMap((expense) => expense.amount)
                                  .reduce((c, d) => c + d, 0);
                              })
                              .reduce((a, b) => a + b, 0)
                          )}
                        </strong>
                      </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              {
                outstandingLoanAmount && <Segment placeholder>
                  <Grid columns={2} textAlign='center' style={{margin: '1.5em'}}>

                    <GridRow verticalAlign='middle'>
                      <GridColumn>
                        <Header>
                          Splať své dluhy vůči firmě
                        </Header>

                        <List>
                            <List.Item>Číslo účtu: 2601810198 / 2010</List.Item>
                            <List.Item>Variabilní symbol: dle smlouvy o zápůjčce</List.Item>
                            <List.Item>Specifický symbol: {auth.ss}</List.Item>
                            <List.Item>Dlužná částka celkem: {Formatter.money(outstandingLoanAmount)}</List.Item>
                        </List>

                        <Header>Znáš variabilní symbol?</Header>
                        <Form.Input label={'Doplň a přegeneruj QR kód'} placeholder={'2024001001'} value={vs} onChange={(e) => setVs(e.target.value)}/>

                      </GridColumn>
                      <GridColumn>
                        <QRCode value={generateQRCode()} />
                      </GridColumn>
                    </GridRow>
                  </Grid>
                </Segment>
              }
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header>Detailní rozpis půjček a splátek</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
              <Grid.Column width={4}>
                <Menu pointing secondary vertical>
                  {
                    expenseSummary.map((summary, i) => {
                      let name = summary.owner.fullname.split(" ");
                      return (
                        <Menu.Item
                          key={i}
                          content={name[1] + " " + name[0]}
                          name={name[1]}
                          active={activeUser === name[1]}
                          onClick={handleActiveUserClick}
                        />
                      );
                    })}
                </Menu>
              </Grid.Column>
              <Grid.Column stretched width={12}>
                {activeUserData && <Table basic striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell/>
                      <Table.HeaderCell singleLine>Datum</Table.HeaderCell>
                      <Table.HeaderCell singleLine>Popis</Table.HeaderCell>
                      <Table.HeaderCell singleLine textAlign={"right"}>Částka</Table.HeaderCell>
                      <Table.HeaderCell singleLine textAlign={"right"}>Mezisoučet</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {activeUserData.expensesWithSubtotal.length === 0 && (
                      <Table.Row>
                        <Table.Cell textAlign={"center"} colSpan={5}>
                          Nejsou evidovány žádné půjčky
                        </Table.Cell>
                      </Table.Row>
                    )}
                    {activeUserData.expensesWithSubtotal.map(expense => {
                        return (
                          <Table.Row key={expense.id}>
                            <Table.Cell style={{borderLeft: expense.amount > 0 ? "4px solid #21BA45" : "4px solid #DB2828"}}>
                              {
                                  expense.amount > 0 && <IconGroup size={'large'}>
                                    <Icon name='money' color={'green'}/>
                                    <Icon corner name='plus' color={'green'}/>
                                  </IconGroup>
                              }
                              {
                                  expense.amount < 0 && <IconGroup size={'large'}>
                                    <Icon name='money' color={'red'}/>
                                    <Icon corner name='minus' color={'red'}/>
                                  </IconGroup>
                              }
                            </Table.Cell>
                            <Table.Cell singleLine>{moment(expense.date).format("D. M. YYYY")}</Table.Cell>
                            <Table.Cell>
                              {expense.description}
                            </Table.Cell>
                            <Table.Cell singleLine textAlign={"right"}>
                              {Formatter.money(expense.amount)}
                            </Table.Cell>
                            <Table.Cell singleLine textAlign={"right"} style={{color: expense.subtotal < 0 ? '#DB2828' : '#21BA45'}}>
                                <strong>{expense.subtotal > 0 ? '+' : ''}{Formatter.money(expense.subtotal)}</strong>
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                  </Table.Body>
                  <Table.Footer>
                    <Table.Row>
                      <Table.Cell colSpan={'5'} textAlign={'right'}>
                        <strong>
                        {activeUserData.expensesTotal > 0 && <>{activeUserData.owner.fullname} ke dnešnímu dni má přeplatek: {Formatter.money(Math.abs(activeUserData.expensesTotal))}</>}
                        {activeUserData.expensesTotal < 0 && <>{activeUserData.owner.fullname} ke dnešnímu dni dluží: {Formatter.money(Math.abs(activeUserData.expensesTotal))}</>}
                        {activeUserData.expensesTotal === 0 && <>{activeUserData.owner.fullname} ke dnešnímu dni nic nedluží.</>}
                        </strong>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Footer>
                </Table>}
              </Grid.Column>
          </Grid.Row>
      </Grid>
    </Container>
  );
}
