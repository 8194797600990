import React, {useContext, useEffect, useState} from "react";
import {Grid, Header, Icon, Label, Table} from "semantic-ui-react";
import MonthYearSelector from "../../../components/base/MonthYearSelector";
import {Month} from "../../../base/enums/Month";
import {SyncOperationState} from "../../../base/state/SyncOperationState";
import SimpleLoader from "../../../components/SimpleLoader";
import {Formatter} from "../../../base/util/Formatter";
import {FilterContext, FilterValue} from "../../../contexts/FilterContext";
import {FilterUtils} from "../../../base/util/FilterUtils";
import {
  getPersonalBalance,
  PersonalBalanceDto, synchronizeWorkReport,
  useGetWorkReport,
  WorkMonthDto,
  WorkReportDto
} from '../../../api/generated-sources'
import toast from 'react-hot-toast'

interface WorkReportState {
  workReportData?: WorkReportDto;
}

interface PersonalBalanceState {
  personalBalanceData?: PersonalBalanceDto[];
}

export default function WorkView() {
  const { year } = useContext<FilterValue>(FilterContext);
  const [syncState, setSyncState] = useState<SyncOperationState>({
    inProgress: false,
    error: false,
  });

  const [personalBalanceLoading, setPersonalBalanceLoading] = useState<boolean>(false)
  const [workReport, setWorkReport] = useState<WorkReportState>({});
  const [personalBalance, setPersonalBalance] = useState<PersonalBalanceState>({});

  const {data, isLoading, error} = useGetWorkReport({year});

  useEffect(() => {
    if (data) {
      setWorkReport({
        ...workReport,
        workReportData: data,
      });
    }
  }, [data]);

  useEffect(() => {
    if(error) {
      console.error(error)
      toast.error("Něco se pokazilo!");
    }
  }, [error]);

  const loadPersonalBalance = () => {
    getPersonalBalance({year})
      .then(value => {
        setPersonalBalanceLoading(true)
        setPersonalBalance({
          ...personalBalance,
          personalBalanceData: value,
        });
        setSyncState({
          success: true,
          inProgress: false,
          error: false,
        });
      })
      .catch(reason => {
        setSyncState({
          success: false,
          inProgress: false,
          error: true,
        });
        toast.error("Něco se pokazilo!");
        console.error(reason)
      })
      .finally(() => setPersonalBalanceLoading(false));
  }

  /////////// synchronizeWorkReport - waiting for API without id
  const onSynchronizeWorkReportSuccess = (
    response: WorkReportDto
  ) => {
    setWorkReport({
      ...workReport,
      workReportData: response,
    });

    loadPersonalBalance();

    setSyncState({
      success: true,
      inProgress: false,
      error: false,
    });
  };

  /////////// synchronizeWorkReport - waiting for API without id
  const onSynchronizeWorkReportError = (data: any) => {
    console.error(data);
    setSyncState({
      inProgress: false,
      error: true,
    });
  };

  const synchronizeReport = () => {
    if (syncState.inProgress) {
      return;
    }
    setSyncState({
      inProgress: true,
    });
    /////////// synchronizeWorkReport - waiting for API without id
    synchronizeWorkReport({
        year: year,
    })
      .then(value => {
        onSynchronizeWorkReportSuccess(value)
      })
      .catch(reason => {
        onSynchronizeWorkReportError(reason)
      })
    ;
  };

  const isWorkMonthDisabled = (month: WorkMonthDto) => {
    return (
      month.month < FilterUtils.MIN_MONTH && month.year === FilterUtils.MIN_YEAR
    );
  };

  useEffect(() => {
    loadPersonalBalance()
  }, []);

  const displayLoader =
    isLoading ||
    personalBalanceLoading ||
    !workReport.workReportData ||
    !personalBalance.personalBalanceData;

  return (
    <Grid divided="vertically">
      <Grid.Row>
        <Grid.Column width={16}>
          <Header>Přehled odpracovaných hodiny - {year}</Header>
        </Grid.Column>
      </Grid.Row>
      <MonthYearSelector hideMonth />
      {displayLoader && (
        <SimpleLoader text={"Načítám přehled odpracovaných hodin"} />
      )}
      {!displayLoader && (
        <Grid.Row>
          <Grid.Column width={16}>
            <Header>Odpracované hodiny</Header>
            <Table basic striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1} key="0">
                    <Icon
                      className={"clickable"}
                      disabled={syncState.inProgress}
                      name={
                        syncState.error
                          ? "exclamation triangle"
                          : "sync alternate"
                      }
                      loading={syncState.inProgress}
                      color={syncState.error ? "red" : "grey"}
                      onClick={() => synchronizeReport()}
                    />
                  </Table.HeaderCell>
                  {Month.ALL.map((month) => {
                    return (
                      <Table.HeaderCell
                        textAlign={"center"}
                        width={1}
                        key={month.id}
                      >
                        {FilterUtils.isMonthDisabled(month.id, year) ? (
                          <span className={"disabled"}>{month.id}.</span>
                        ) : (
                          <strong>{month.id}.</strong>
                        )}
                      </Table.HeaderCell>
                    );
                  })}
                  <Table.HeaderCell textAlign={"center"}>
                    Celkem
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {workReport.workReportData.entries.length === 0 ? (
                  <Table.Row>
                    <Table.Cell textAlign={"center"} colSpan={14}>
                      Nejsou zde zatím žádné výkazy.
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  <>
                    {workReport.workReportData.entries.map((entry) => {
                      return (
                        <Table.Row key={entry.user.id}>
                          <Table.Cell singleLine>
                            {entry.user.fullname}
                          </Table.Cell>
                          {entry.entry.map((month) => {
                            return (
                              <Table.Cell
                                disabled={isWorkMonthDisabled(month)}
                                textAlign={"center"}
                                key={month.id}
                              >
                                {Math.round(month.hours * 10) / 10}
                              </Table.Cell>
                            );
                          })}
                          <Table.Cell textAlign={"center"}>
                            <strong>
                              {Math.round(entry.totalHours * 10) / 10}
                            </strong>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                    <Table.Row>
                      <Table.Cell>
                        <Label ribbon color={"red"}>
                          Manday Rate
                        </Label>
                      </Table.Cell>
                      {workReport.workReportData.entries[0].entry.map(
                        (month) => {
                          return (
                            <Table.Cell
                              disabled={isWorkMonthDisabled(month)}
                              textAlign={"center"}
                              key={month.id}
                            >
                              <strong>{month.mandayRate}</strong>
                            </Table.Cell>
                          );
                        }
                      )}
                      <Table.Cell />
                    </Table.Row>
                  </>
                )}
              </Table.Body>
            </Table>
          </Grid.Column>

          <Grid.Column width={16}>
            <Header>Stav osobního konta</Header>
            <Table basic striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell textAlign={"right"}>
                    {year === new Date().getFullYear()
                      ? "Výdělek v tomto roce"
                      : "Výdělek v roce " + year}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign={"right"}>
                    {year === new Date().getFullYear()
                      ? "Vyplaceno tento rok"
                      : "Vyplaceno v roce " + year}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign={"right"}>
                    Stav z předchozích let
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign={"right"}>
                    {year === new Date().getFullYear()
                      ? "Zbývá vyplatit"
                      : "Stav na konci roku " + year}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {personalBalance.personalBalanceData.length === 0 ? (
                  <Table.Row>
                    <Table.Cell colSpan={5} textAlign={"center"}>
                      Nejsou zde zatím žádná data.
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  <>
                    {personalBalance.personalBalanceData.map((entry) => {
                      return (
                        <Table.Row key={entry.user.id}>
                          <Table.Cell singleLine>
                            <strong>{entry.user.fullname}</strong>
                          </Table.Cell>
                          <Table.Cell textAlign={"right"}>
                            {Formatter.money(entry.totalEarnedThisYear)}
                          </Table.Cell>
                          <Table.Cell textAlign={"right"}>
                            {Formatter.money(entry.totalPaidOutThisYear)}
                          </Table.Cell>
                          <Table.Cell textAlign={"right"}>
                            {Formatter.money(entry.owedFromPreviousYears)}
                          </Table.Cell>
                          <Table.Cell textAlign={"right"}>
                            <strong>
                              {Formatter.money(entry.owedCurrently)}
                            </strong>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </>
                )}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.Cell />
                  <Table.Cell textAlign={"right"}>
                    <strong>
                      {Formatter.money(
                        personalBalance.personalBalanceData
                          .flatMap((summary) => {
                            return summary.totalEarnedThisYear;
                          })
                          .reduce((a, b) => a + b, 0)
                      )}
                    </strong>
                  </Table.Cell>
                  <Table.Cell textAlign={"right"}>
                    <strong>
                      {Formatter.money(
                        personalBalance.personalBalanceData
                          .flatMap((summary) => {
                            return summary.totalPaidOutThisYear;
                          })
                          .reduce((a, b) => a + b, 0)
                      )}
                    </strong>
                  </Table.Cell>
                  <Table.Cell textAlign={"right"}>
                    <strong>
                      {Formatter.money(
                        personalBalance.personalBalanceData
                          .flatMap((summary) => {
                            return summary.owedFromPreviousYears;
                          })
                          .reduce((a, b) => a + b, 0)
                      )}
                    </strong>
                  </Table.Cell>
                  <Table.Cell textAlign={"right"}>
                    <strong>
                      {Formatter.money(
                        personalBalance.personalBalanceData
                          .flatMap((summary) => {
                            return summary.owedCurrently;
                          })
                          .reduce((a, b) => a + b, 0)
                      )}
                    </strong>
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Grid.Column>
        </Grid.Row>

      )}
    </Grid>
  );
}
