import React, { useEffect, useState } from "react";
import ProjectDetail from "../component/ProjectDetail";
import { useParams } from "react-router-dom";
import SimpleLoader from "../../../components/SimpleLoader";
import {getProject, ProjectDto} from '../../../api/generated-sources'
import toast from 'react-hot-toast'

export default function DetailProjectsViewPage() {
  const [activeProject, setActiveProject] = useState<ProjectDto>();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(id !== 'new');

  useEffect(() => {
    if (parseInt(id, 10)) {
      getProject(+id)
        .then(value => {
          setActiveProject(value)
          setLoading(false)
        })
        .catch(reason => {
          toast.error("Něco se pokazilo!");
          console.error(reason)
        })
    }
  }, [id, getProject]);

  return (
    <>
      {(loading) ? (
        <SimpleLoader text={"Načítám data"} />
      ) : (
        <ProjectDetail project={activeProject} />
      )}
    </>
  );
}
