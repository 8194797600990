export class Formatter {
  private static moneyFormatter = new Intl.NumberFormat("cs-CZ", {
    style: "currency",
    currency: "CZK",
  });

  public static money(amount: number) {
    return this.moneyFormatter.format(amount);
  }

  public static sum(amounts: number[]) {
    return this.moneyFormatter.format(amounts.reduce((a, b) => {
                                                      return a + b;
                                                    }));
  }
}
