/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
export type CreateTransactionPlanBody = {
  month?: number;
  transactionPlan?: TransactionPlanDto;
  year?: number;
};

export type GetTransactionPlansForMonthParams = {
  year: number;
  month: number;
};

export type AssignExpensesToTransactionBody = {
  expenses?: ExpenseDto[];
};

export type CreateTransactionAttachmentBody = {
  attachment?: FileAttachmentDto;
  id?: number;
};

export type GetTransactionsParams = {
  from: string;
  to?: string;
  minAmount: number;
  maxAmount?: number;
  assignedToInvoice?: boolean;
};

export type GetPersonalBalanceParams = {
  year: number;
};

export type GetAccountingFilesForMonthParams = {
  year: number;
  month: number;
};

export type GetExpenseSummaryParams = {
  year?: number;
  month?: number;
  type?: ExpenseType;
};

export type ArchiveProjectBody = {
  archive?: boolean;
  id?: number;
};

export type GetAccountStatementForMonthParams = {
  year: number;
  month: number;
};

export type SynchronizeWorkReportBody = {
  year: number;
};

export type GetWorkReportParams = {
  /**
   * year
   */
  year: number;
};

export type SynchronizeVacationsBody = {
  userId?: number;
  year?: number;
};

export type GetVacationsForUserParams = {
  page?: PageableInputPageParameter;
  size?: PageableInputSizeParameter;
  sortDirection?: PageableInputSortDirectionParameter;
  sortBy?: PageableInputSortByParameter;
  /**
   * year
   */
  year?: number;
};

export type GetTaxExemptBaseValueParams = {
  /**
   * date
   */
  date: string;
  /**
   * date
   */
  type: ETaxExemptType;
};

export type GetTaxExemptsFoCurrentrUserParams = {
  page?: PageableInputPageParameter;
  size?: PageableInputSizeParameter;
  sortDirection?: PageableInputSortDirectionParameter;
  sortBy?: PageableInputSortByParameter;
};

export type GetSalariesForCurrentUserParams = {
  page?: PageableInputPageParameter;
  size?: PageableInputSizeParameter;
  sortDirection?: PageableInputSortDirectionParameter;
  sortBy?: PageableInputSortByParameter;
};

export type GetPayrollDeductionsForCurrentUserParams = {
  page?: PageableInputPageParameter;
  size?: PageableInputSizeParameter;
  sortDirection?: PageableInputSortDirectionParameter;
  sortBy?: PageableInputSortByParameter;
};

export type ExportInvoicesProjectsParams = {
  from?: string;
  to?: string;
  status?: InvoiceStatus;
  subjectId?: number;
};

export type ExportInvoicesParams = {
  from?: string;
  to?: string;
  status?: InvoiceStatus;
  subjectId?: number;
};

export type UpdateArchivedBody = {
  archived: boolean;
  id: number;
};

export type GetNextInvoiceNumberParams = {
  dateTax: string;
};

export type UnpairInvoiceBody = {
  invoiceId?: number;
  transactionId?: number;
};

export type PairInvoiceBody = {
  invoiceId?: number;
  transactionId?: number;
};

export type GetInvoicesParams = {
  from?: string;
  to?: string;
  status?: InvoiceStatus;
  subjectId?: number;
};

export type GetApplicationConfigurationParams = {
  key: string;
};

export type AddDashboardWidgetBody = {
  id: number;
};

export type PageableInputSortByParameter = string;

export type PageableInputSortDirectionParameter =
  (typeof PageableInputSortDirectionParameter)[keyof typeof PageableInputSortDirectionParameter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PageableInputSortDirectionParameter = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export type PageableInputSizeParameter = number;

export type PageableInputPageParameter = number;

export type GetVacationsByYearForCurrentUserParams = {
  page?: PageableInputPageParameter;
  size?: PageableInputSizeParameter;
  sortDirection?: PageableInputSortDirectionParameter;
  sortBy?: PageableInputSortByParameter;
  /**
   * year
   */
  year?: number;
};

export type GetTaxExemptsForUserParams = {
  page?: PageableInputPageParameter;
  size?: PageableInputSizeParameter;
  sortDirection?: PageableInputSortDirectionParameter;
  sortBy?: PageableInputSortByParameter;
};

export type GetSalariesForUserParams = {
  page?: PageableInputPageParameter;
  size?: PageableInputSizeParameter;
  sortDirection?: PageableInputSortDirectionParameter;
  sortBy?: PageableInputSortByParameter;
};

export type GetPayrollDeductionsForUserParams = {
  page?: PageableInputPageParameter;
  size?: PageableInputSizeParameter;
  sortDirection?: PageableInputSortDirectionParameter;
  sortBy?: PageableInputSortByParameter;
};

export type GetEmploymentForUserParams = {
  page?: PageableInputPageParameter;
  size?: PageableInputSizeParameter;
  sortDirection?: PageableInputSortDirectionParameter;
  sortBy?: PageableInputSortByParameter;
};

export type GetEmploymentForCurrentUserParams = {
  page?: PageableInputPageParameter;
  size?: PageableInputSizeParameter;
  sortDirection?: PageableInputSortDirectionParameter;
  sortBy?: PageableInputSortByParameter;
};

export type GetDocumentTypesParams = {
  page?: PageableInputPageParameter;
  size?: PageableInputSizeParameter;
  sortDirection?: PageableInputSortDirectionParameter;
  sortBy?: PageableInputSortByParameter;
};

export type GetDocumentsForUserParams = {
  page?: PageableInputPageParameter;
  size?: PageableInputSizeParameter;
  sortDirection?: PageableInputSortDirectionParameter;
  sortBy?: PageableInputSortByParameter;
  type?: string;
};

export type GetDocumentsForCurrentUserParams = {
  page?: PageableInputPageParameter;
  size?: PageableInputSizeParameter;
  sortDirection?: PageableInputSortDirectionParameter;
  sortBy?: PageableInputSortByParameter;
  type?: string;
};

/**
 * A generic response
 */
export type ErrorResponseResponse = ErrorObjectDto;

export interface ProjectDto {
  archived?: boolean;
  contactEmail?: string;
  contactPerson?: string;
  contactPhone?: string;
  description?: string;
  id?: number;
  name?: string;
  validFrom?: string;
  validTo?: string;
}

export type EVacationType = (typeof EVacationType)[keyof typeof EVacationType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EVacationType = {
  PAID_LEAVE: "PAID_LEAVE",
  NONPAID_LEAVE: "NONPAID_LEAVE",
  ILLNESS: "ILLNESS",
} as const;

export interface VacationDto {
  date?: string;
  hours?: number;
  id?: number;
  note?: string;
  type?: EVacationType;
  userId?: number;
}

export type FileType = (typeof FileType)[keyof typeof FileType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileType = {
  PA_DOCUMENT: "PA_DOCUMENT",
  INVOICE_OUT: "INVOICE_OUT",
  TRANSACTION_IN: "TRANSACTION_IN",
} as const;

export interface AccountingFileMetadataDto {
  filename?: string;
  month?: number;
  type?: FileType;
  year?: number;
}

export interface PAFileMetadataDto {
  documentType?: string;
  employeeName?: string;
  employeeUsername?: string;
  filename?: string;
  type?: FileType;
  validFrom?: string;
  validTo?: string;
}

export interface TaxExemptInputDto {
  attachment?: FileAttachmentDto;
  taxExempt?: TaxExemptDto;
}

export type ETaxExemptType =
  (typeof ETaxExemptType)[keyof typeof ETaxExemptType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ETaxExemptType = {
  BASIC: "BASIC",
  CHILD_1: "CHILD_1",
  CHILD_2: "CHILD_2",
  CHILD_3: "CHILD_3",
  CHILD_1_ZTP: "CHILD_1_ZTP",
  CHILD_2_ZTP: "CHILD_2_ZTP",
  CHILD_3_ZTP: "CHILD_3_ZTP",
  PARTNER: "PARTNER",
  PARTNER_ZTP: "PARTNER_ZTP",
  DISABILITY_I_II: "DISABILITY_I_II",
  DISABILITY_III: "DISABILITY_III",
  ZTP: "ZTP",
  STUDENT: "STUDENT",
  DAYCARE: "DAYCARE",
} as const;

export interface TaxExemptDto {
  amount?: number;
  documentType?: EDocumentType;
  documentUrl?: string;
  filename?: string;
  id?: number;
  note?: string;
  type?: ETaxExemptType;
  userId?: number;
  validFrom?: string;
  validTo?: string;
}

export type TransactionPlanStatus =
  (typeof TransactionPlanStatus)[keyof typeof TransactionPlanStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TransactionPlanStatus = {
  FULFILLED: "FULFILLED",
  PARTIALLY_MISSING: "PARTIALLY_MISSING",
  PAST_DUE_DATE: "PAST_DUE_DATE",
  DUE_DATE_SOON: "DUE_DATE_SOON",
  UPCOMING: "UPCOMING",
  ERROR: "ERROR",
} as const;

export type InvoiceStatus = (typeof InvoiceStatus)[keyof typeof InvoiceStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvoiceStatus = {
  AFTER_DATE_DUE: "AFTER_DATE_DUE",
  ALL: "ALL",
  PAID: "PAID",
  UNPAID: "UNPAID",
} as const;

export interface CashTransactionDto {
  amount?: number;
  comment?: string;
  date?: string;
  taxDeductible?: boolean;
  vat?: number;
}

export interface PersonalBalanceDto {
  amountsThisYear?: number[];
  owedCurrently?: number;
  owedFromPreviousYears?: number;
  totalEarnedThisYear?: number;
  totalPaidOutThisYear?: number;
  user?: UserDto;
}

export interface ExpenseExtendedDto {
  amount?: number;
  date?: string;
  description?: string;
  expenseType?: ExpenseType;
  id?: number;
  owner?: UserDto;
}

export interface ExpenseSummaryDto {
  expenses?: ExpenseExtendedDto[];
  expensesTotal?: number;
  owner?: UserDto;
}

export interface AccountStatementDto {
  closingBalance?: number;
  id?: number;
  lastSync?: number;
  month?: number;
  openingBalance?: number;
  transactions?: TransactionDto[];
  year?: number;
}

export interface WorkMonthDto {
  hours?: number;
  id?: number;
  mandayRate?: number;
  month?: number;
  year?: number;
}

export interface WorkReportEntryDto {
  entry?: WorkMonthDto[];
  totalHours?: number;
  user?: UserDto;
}

export interface WorkReportDto {
  entries?: WorkReportEntryDto[];
  year?: number;
}

export interface SalaryInputDto {
  attachment?: FileAttachmentDto;
  documentType?: EDocumentType;
  documentUrl?: string;
  filename?: string;
  hourly?: number;
  id?: number;
  monthly?: number;
  userId?: number;
  validFrom?: string;
  validTo?: string;
}

export interface PayrollDeductionInputDto {
  amount?: number;
  attachment?: FileAttachmentDto;
  documentType?: EDocumentType;
  documentUrl?: string;
  filename?: string;
  id?: number;
  note?: string;
  userId?: number;
  validFrom?: string;
  validTo?: string;
}

export interface PayrollDeductionDto {
  amount?: number;
  documentType?: EDocumentType;
  documentUrl?: string;
  filename?: string;
  id?: number;
  note?: string;
  userId?: number;
  validFrom?: string;
  validTo?: string;
}

export type EEmploymentType =
  (typeof EEmploymentType)[keyof typeof EEmploymentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EEmploymentType = {
  CONTRACTOR: "CONTRACTOR",
  DPP: "DPP",
  DPC: "DPC",
  HPP: "HPP",
} as const;

export interface EmploymentInputDto {
  attachment?: FileAttachmentDto;
  documentType?: EDocumentType;
  documentUrl?: string;
  filename?: string;
  id?: number;
  note?: string;
  type?: EEmploymentType;
  userId?: number;
  validFrom?: string;
  validTo?: string;
}

export interface EmploymentDto {
  documentType?: EDocumentType;
  documentUrl?: string;
  filename?: string;
  id?: number;
  note?: string;
  type?: EEmploymentType;
  userId?: number;
  validFrom?: string;
  validTo?: string;
}

export interface RoleDto {
  description?: string;
  id?: number;
  name?: string;
}

export interface UserDto {
  addressCity?: string;
  addressCountry?: string;
  addressStreet?: string;
  addressZip?: string;
  bankAccount?: string;
  birthDate?: string;
  contactEmail?: string;
  enabled?: boolean;
  firstname?: string;
  fullname?: string;
  id?: number;
  insurance?: string;
  lastname?: string;
  maidenName?: string;
  nationality?: string;
  phone?: string;
  placeOfBirth?: string;
  position?: string;
  roles?: RoleDto[];
  ss?: string;
  taxReturn?: boolean;
  username?: string;
  vs?: string;
}

export type ExpenseType = (typeof ExpenseType)[keyof typeof ExpenseType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseType = {
  PURCHASE: "PURCHASE",
  PAYMENT: "PAYMENT",
  EXPENDITURE: "EXPENDITURE",
  LOAN: "LOAN",
} as const;

export interface ExpenseDto {
  amount?: number;
  expenseType?: ExpenseType;
  id?: number;
  owner?: UserDto;
}

export interface InvoiceItemDto {
  amount?: number;
  id?: number;
  name?: string;
  price?: number;
  project?: ProjectDto;
  totalPrice?: number;
  totalPriceWithVat?: number;
  vat?: number;
}

export interface InvoiceSubjectDto {
  account?: string;
  archived?: boolean;
  city?: string;
  country?: string;
  dic?: string;
  email?: string;
  iban?: string;
  ic?: string;
  id?: number;
  isVatRegistered?: boolean;
  name?: string;
  note?: string;
  phone?: string;
  street?: string;
  swift?: string;
  website?: string;
  zipCode?: string;
}

export interface InvoiceDto {
  attachmentUrl?: string;
  comment?: string;
  dateCreated?: string;
  dateDue?: string;
  dateTax?: string;
  filename?: string;
  id?: number;
  invoiceItems?: InvoiceItemDto[];
  invoiceNumber?: string;
  invoiceSubject?: InvoiceSubjectDto;
  ks?: string;
  orderNumber?: string;
  paid?: boolean;
  ss?: string;
  totalPrice?: number;
  totalPriceWithVat?: number;
  transactions?: TransactionDto[];
  vs?: string;
}

export interface TransactionDto {
  amount?: number;
  attachmentUrl?: string;
  authorizedBy?: string;
  comment?: string;
  counterAccount?: string;
  counterAccountName?: string;
  currency?: string;
  date?: string;
  expenses?: ExpenseDto[];
  filename?: string;
  id?: number;
  invoice?: InvoiceDto;
  ks?: string;
  messageForRecipient?: string;
  requiresAttachment?: boolean;
  ss?: string;
  taxDeductible?: boolean;
  transactionId?: number;
  type?: string;
  vat?: number;
  vs?: string;
}

export interface TransactionPlanDto {
  account?: string;
  description?: string;
  dueDate?: string;
  dueOnDayOfMonth?: number;
  expectedCount?: number;
  id?: number;
  notify?: boolean;
  repeatsEveryMonths?: number;
  requiresAttachment?: boolean;
  ss?: string;
  status?: TransactionPlanStatus;
  transactions?: TransactionDto[];
  vat?: number;
  vs?: string;
}

export interface ApplicationConfigurationDto {
  description?: string;
  id?: number;
  key?: string;
  validFrom?: string;
  validTo?: string;
  value?: string;
}

export interface FileAttachmentDto {
  content?: string;
  filename?: string;
  type?: string;
}

export interface FileAttachmentInputDto {
  attachment?: FileAttachmentDto;
  id?: number;
}

export type EDocumentType = (typeof EDocumentType)[keyof typeof EDocumentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EDocumentType = {
  SMLOUVA_O_DILO: "SMLOUVA_O_DILO",
  ZAMESTNANECKA_SMLOUVA: "ZAMESTNANECKA_SMLOUVA",
  DPP: "DPP",
  DPC: "DPC",
  DODATEK_K_PRACOVNI_SMLOUVE: "DODATEK_K_PRACOVNI_SMLOUVE",
  DOHODA_O_SRAZKACH_ZE_MZDY: "DOHODA_O_SRAZKACH_ZE_MZDY",
  MZDOVY_VYMER: "MZDOVY_VYMER",
  PROHLASENI_POPLATNIKA: "PROHLASENI_POPLATNIKA",
  GDPR: "GDPR",
  BOZP: "BOZP",
} as const;

export interface SalaryDto {
  documentType?: EDocumentType;
  documentUrl?: string;
  filename?: string;
  hourly?: number;
  id?: number;
  monthly?: number;
  userId?: number;
  validFrom?: string;
  validTo?: string;
}

export interface DocumentTypeDto {
  deletable?: boolean;
  expiresAfterMonths?: number;
  id?: number;
  notifyDaysBeforeExpiration?: number;
  required?: boolean;
  title?: string;
  type?: EDocumentType;
}

export interface DocumentDto {
  attachmentUrl?: string;
  documentType?: DocumentTypeDto;
  filename?: string;
  id?: number;
  note?: string;
  notifyAboutExpirationAt?: string;
  userId?: number;
  validFrom?: string;
  validTo?: string;
}

export interface DocumentInputDto {
  attachment?: FileAttachmentDto;
  documentDto?: DocumentDto;
}

export interface PageableVacationResponse {
  pageInfo?: PageInfo;
  vacation?: VacationDto[];
}

export interface PageableSalaryResponse {
  pageInfo?: PageInfo;
  salary?: SalaryDto[];
}

export interface PageableEmploymentResponse {
  employment?: EmploymentDto[];
  pageInfo?: PageInfo;
}

export interface DashboardWidgetDto {
  id?: number;
  name?: string;
  roleRestriction?: string;
}

export interface DashboardWidgetUserDto {
  configuration?: string;
  dashboardWidget?: DashboardWidgetDto;
  data?: string;
  id?: number;
  position?: number;
}

export interface ErrorObjectDto {
  code?: number;
  message?: string;
}

export interface PageInfo {
  numberOfElements?: number;
  pageNumber?: number;
  pageSize?: number;
  totalElements?: number;
  totalPages?: number;
}

export interface PageableTaxExemptResponse {
  pageInfo?: PageInfo;
  taxExempt?: TaxExemptDto[];
}

export interface PageablePayrollDeductionResponse {
  pageInfo?: PageInfo;
  payrollDeduction?: PayrollDeductionDto[];
}

export interface PageableDocumentTypeResponse {
  documentTypes?: DocumentTypeDto[];
  pageInfo?: PageInfo;
}

export interface PageableDocumentResponse {
  documents?: DocumentDto[];
  pageInfo?: PageInfo;
}

export type SortDirection = (typeof SortDirection)[keyof typeof SortDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortDirection = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export interface PageableInput {
  page?: number;
  size?: number;
  sortBy?: string;
  sortDirection?: SortDirection;
}
