/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
import useSwr from "swr";
import type { Arguments, Key, SWRConfiguration } from "swr";
import useSWRMutation from "swr/mutation";
import type { SWRMutationConfiguration } from "swr/mutation";
import type {
  AddDashboardWidgetBody,
  DashboardWidgetDto,
  DashboardWidgetUserDto,
  ErrorResponseResponse,
} from "../intranetApi.schemas";
import { IntranetClientFetchInstance } from "../../intranetClientFetchInstance";
import type { ErrorType } from "../../intranetClientFetchInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get available dashboard widgets
 */
export const getAvailableDashboardWidgets = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<DashboardWidgetDto[]>(
    { url: `/dashboard/widget`, method: "GET" },
    options
  );
};

export const getGetAvailableDashboardWidgetsKey = () =>
  [`/dashboard/widget`] as const;

export type GetAvailableDashboardWidgetsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAvailableDashboardWidgets>>
>;
export type GetAvailableDashboardWidgetsQueryError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary get available dashboard widgets
 */
export const useGetAvailableDashboardWidgets = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof getAvailableDashboardWidgets>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetAvailableDashboardWidgetsKey() : null));
  const swrFn = () => getAvailableDashboardWidgets(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary add dashboard widget by id to current user
 */
export const addDashboardWidget = (
  addDashboardWidgetBody: AddDashboardWidgetBody,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<DashboardWidgetUserDto>(
    {
      url: `/dashboard/widget`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: addDashboardWidgetBody,
    },
    options
  );
};

export const getAddDashboardWidgetMutationFetcher = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: AddDashboardWidgetBody }
  ): Promise<DashboardWidgetUserDto> => {
    return addDashboardWidget(arg, options);
  };
};
export const getAddDashboardWidgetMutationKey = () =>
  `/dashboard/widget` as const;

export type AddDashboardWidgetMutationResult = NonNullable<
  Awaited<ReturnType<typeof addDashboardWidget>>
>;
export type AddDashboardWidgetMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary add dashboard widget by id to current user
 */
export const useAddDashboardWidget = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof addDashboardWidget>>,
    TError,
    string,
    AddDashboardWidgetBody,
    Awaited<ReturnType<typeof addDashboardWidget>>
  > & { swrKey?: string };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getAddDashboardWidgetMutationKey();
  const swrFn = getAddDashboardWidgetMutationFetcher(requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary get own dashboard widgets
 */
export const getOwnDashboardWidgets = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<DashboardWidgetUserDto[]>(
    { url: `/dashboard/ownWidget`, method: "GET" },
    options
  );
};

export const getGetOwnDashboardWidgetsKey = () =>
  [`/dashboard/ownWidget`] as const;

export type GetOwnDashboardWidgetsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOwnDashboardWidgets>>
>;
export type GetOwnDashboardWidgetsQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get own dashboard widgets
 */
export const useGetOwnDashboardWidgets = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof getOwnDashboardWidgets>>,
    TError
  > & { swrKey?: Key; enabled?: boolean };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetOwnDashboardWidgetsKey() : null));
  const swrFn = () => getOwnDashboardWidgets(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary update dashboard widget
 */
export const updateDashboardWidget = (
  id: number,
  dashboardWidgetUserDto: DashboardWidgetUserDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<DashboardWidgetUserDto>(
    {
      url: `/dashboard/widget/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: dashboardWidgetUserDto,
    },
    options
  );
};

export const getUpdateDashboardWidgetMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: DashboardWidgetUserDto }
  ): Promise<DashboardWidgetUserDto> => {
    return updateDashboardWidget(id, arg, options);
  };
};
export const getUpdateDashboardWidgetMutationKey = (id: number) =>
  `/dashboard/widget/${id}` as const;

export type UpdateDashboardWidgetMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateDashboardWidget>>
>;
export type UpdateDashboardWidgetMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary update dashboard widget
 */
export const useUpdateDashboardWidget = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateDashboardWidget>>,
      TError,
      string,
      DashboardWidgetUserDto,
      Awaited<ReturnType<typeof updateDashboardWidget>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getUpdateDashboardWidgetMutationKey(id);
  const swrFn = getUpdateDashboardWidgetMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary remove user dashboard widget
 */
export const removeDashboardWidget = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<string>(
    { url: `/dashboard/widget/${id}`, method: "DELETE" },
    options
  );
};

export const getRemoveDashboardWidgetMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, __: { arg: Arguments }): Promise<string> => {
    return removeDashboardWidget(id, options);
  };
};
export const getRemoveDashboardWidgetMutationKey = (id: number) =>
  `/dashboard/widget/${id}` as const;

export type RemoveDashboardWidgetMutationResult = NonNullable<
  Awaited<ReturnType<typeof removeDashboardWidget>>
>;
export type RemoveDashboardWidgetMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary remove user dashboard widget
 */
export const useRemoveDashboardWidget = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof removeDashboardWidget>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof removeDashboardWidget>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getRemoveDashboardWidgetMutationKey(id);
  const swrFn = getRemoveDashboardWidgetMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
