import {Input} from "semantic-ui-react";
import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import {FilterContext, FilterValue} from "../../contexts/FilterContext";

interface FullTextFilterProps {
  data?: any;
  returnFilteredData: (data: any) => void;
  formatData: {
    dateType?: string[];
    formatDateType: string;
    activeItem?: string[];
  };
}

export default function FullTextFilter(props: FullTextFilterProps) {
  const { data, returnFilteredData, formatData } = props;
  const [formatedData, setFormatedData] = useState([]);

  const {
    searchField,
    setSearchField
  } = useContext<FilterValue>(FilterContext);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      returnFilteredData([])
    }
    const text = event.target.value;
    setSearchField(text);
  };

  const getFormatDate = (filteredDataItem: any) => {
    const dateTypes = formatData.dateType;
    const formatDateType = formatData.formatDateType;

    return filteredDataItem.map((item: any) => {
      const newItem = { ...item };
      dateTypes?.forEach((dateType: any) => {
        if (newItem[dateType]) {
          newItem[dateType] = moment(newItem[dateType]).format(formatDateType);
        }
      });
      return newItem;
    });
  };

  useEffect(() => {
    const filteredList = formatedData?.filter((item: any) =>
      Object.values(item).some((value) =>
        value
          ?.toString()
          ?.trim()
          ?.toLowerCase()
          ?.normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          ?.includes(
            searchField
              .trim()
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          )
      )
    );

    const arr = data.filter((item: any) => {
      return filteredList.some((list) => {
        return item.id === list.id;
      });
    });
    returnFilteredData(arr);
  }, [searchField]);

  useEffect(() => {
    if (formatData?.activeItem?.length > 0) {
      const filteredDataItem = data.map((item: any) => {
        return Object.keys(item).reduce(
            (acc: any, key: string) => {
              if (formatData.activeItem?.includes(key)) {
                acc[key] = item[key];
              }
              return acc;
            },
            {}
        );
      });
      setFormatedData(getFormatDate(filteredDataItem));
    } else {
      setFormatedData(getFormatDate(data));
    }
  }, [data]);

  return (
    <Input
      icon="search"
      placeholder="Hledat..."
      value={searchField}
      onChange={handleSearchChange}
    />
  );
}
