import {Button, Header, Icon, Pagination, Table} from "semantic-ui-react";
import SimpleLoader from "../../../components/SimpleLoader";
import {PPVTableModal} from "./PPVTableModal";
import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import {useParams} from "react-router-dom";
import {EmploymentUtil} from "../../../base/util/EmploymentUtil";
import {Auth, AuthContext} from "../../../contexts/AuthContext";
import {Role} from "../../../base/enums/Role";
import {DateUtil} from "../../../base/util/DateUtil";
import {
  EmploymentDto,
  EmploymentInputDto, getEmploymentForCurrentUser,
  getEmploymentForUser,
  PageInfo, SortDirection
} from '../../../api/generated-sources'

export function PPVTable() {
  const [list, setList] = useState<EmploymentDto[]>();
  const [active, setActive] = useState<EmploymentInputDto>();
  const { id } = useParams<{ id: string }>();
  const auth = useContext<Auth>(AuthContext);
  const canEdit = id && (auth.hasRole(Role.HR_MANAGER) || auth.isAdmin());
  const [pageInfo, setPageInfo] = useState<PageInfo>({
      numberOfElements: 0,
      pageNumber: 0,
      pageSize: 5,
      totalElements: 0,
      totalPages: 0,
  });

  const activeRow = (id: number) => {
    const activeItem = list.filter((item) => item.id === id);
    setActive(activeItem[0]);
  };

  const handleDelete = (id: number) => {
    const updateArr = list.filter((item) => item.id !== id);
    setList(updateArr);
  };

  const reloadTable = () => {
    if (id) {
      getEmploymentForUser(+id, {
        page: pageInfo.pageNumber,
        size: pageInfo.pageSize,
        sortBy: "validFrom",
        sortDirection: SortDirection.DESC
      }).then(data => {
        setPageInfo(data.pageInfo);
        setList(data.employment);
      })
        .catch(console.log)
    } else {
      getEmploymentForCurrentUser({
        page: pageInfo.pageNumber,
        size: pageInfo.pageSize,
        sortBy: "validFrom",
        sortDirection: SortDirection.DESC
      }).then(data => {
        // setPageInfo(data.pageInfo);
        setList(data.employment);
      });
    }
  }

  useEffect(() => {
    reloadTable();
  }, [id, pageInfo.pageNumber]);

  const displayLoader = !list;

  return (
    <>
      {displayLoader ? <SimpleLoader text={"Načítám data"} />
      : <>
          {
            canEdit && !!active && <PPVTableModal
              selectedRow={active}
              close={() => setActive(undefined)}
              isOpen={!!active}
              handleUpdate={reloadTable}
              handleDelete={handleDelete}
            />
          }
          <Header as="h3" textAlign="center">
            HISTORIE PRACOVNĚPRÁVNÍCH VZTAHŮ
          </Header>
          <Table basic striped selectable={canEdit}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Platné od</Table.HeaderCell>
                <Table.HeaderCell>Platné do</Table.HeaderCell>
                <Table.HeaderCell>Typ vztahu</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Dokument</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {list?.length ? (
                list?.map((item) => {
                  return (
                    <Table.Row
                      key={item.id}
                      onClick={() => {
                        if (canEdit) {
                          activeRow(item.id);
                        }
                      }}
                    >
                      <Table.Cell style={{borderLeft: DateUtil.isCurrentlyValid(new Date(item.validFrom), new Date(item.validTo)) ? "4px solid #21BA45" : ""}}>
                        {moment(item.validFrom, moment.ISO_8601).format(
                          "DD. MM. yyyy"
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {!item?.validTo
                          ? "-"
                          : moment(item.validTo, moment.ISO_8601).format(
                              "DD. MM. yyyy"
                            )}
                      </Table.Cell>
                      <Table.Cell>
                        {
                          EmploymentUtil.getTypePPVOptions().filter(
                            (option) => option.value === item?.type
                          )[0].text
                        }
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {
                          item.documentUrl ? <a target="_blank" rel="noopener noreferrer" href={item.documentUrl} onClick={(e) => e.stopPropagation()}>
                            <Icon color="black" name="download" />
                          </a>
                          :
                          <Icon.Group>
                            <>
                              <Icon color="red" size={"big"} name="circle outline" />
                              <Icon color="black" name="file alternate outline" />
                              <Icon color="red" size={"small"} corner name="warning"/>
                            </>
                          </Icon.Group>
                        }
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell textAlign={"center"} colSpan={7}>
                    Historie PPV je prázdná
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
            {
              canEdit && <Table.Footer>
                <Table.Row className="new-btn">
                  <Table.HeaderCell colSpan="4">
                    {
                      pageInfo.totalPages > 1 && <Pagination size={'tiny'} activePage={pageInfo.pageNumber + 1} totalPages={pageInfo.totalPages} siblingRange={2} onPageChange={(e, page) => setPageInfo({...pageInfo, pageNumber: (page.activePage as number) - 1})} />
                    }
                    <Button
                      floated="right"
                      icon
                      labelPosition="left"
                      size="small"
                      positive
                      onClick={() => {
                        setActive({userId: +id});
                      }}
                    >
                      <Icon name="add" /> Nová změna
                    </Button>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            }
          </Table>
        </>
      }
    </>
  );
}
