interface CountrySelection {
  key: string;
  value: string;
  flag: string;
  text: string;
}

export class CountryUtil {
  static DEFAULT_COUNTRY: CountrySelection = {
    key: "cz",
    value: "Česká republika",
    flag: "cz",
    text: "Česká republika",
  };

  public static getBasicCountryOptions(): CountrySelection[] {
    return [
      {
        key: "cz",
        value: "Česká republika",
        flag: "cz",
        text: "Česká republika",
      },
      { key: "sk", value: "Slovensko", flag: "sk", text: "Slovensko" },
      { key: "de", value: "Německo", flag: "de", text: "Německo" },
      { key: "pl", value: "Polsko", flag: "pl", text: "Polsko" },
      { key: "at", value: "Rakousko", flag: "at", text: "Rakousko" },
      {
        key: "us",
        value: "Spojené státy americké",
        flag: "us",
        text: "Spojené státy americké",
      },
      {
        key: "uk",
        value: "Velká Británie",
        flag: "uk",
        text: "Velká Británie",
      },
    ];
  }
}
