import {Icon, Table} from "semantic-ui-react";
import { Formatter } from "../../../base/util/Formatter";
import {ExpenseTypeUtil} from "../../../base/util/ExpenseTypeUtil";
import {ExpenseDto} from '../../../api/generated-sources'

interface TransactionExpensesTableProps {
  data: ExpenseDto[];
}

export const TransactionExpensesTable = (
  props: TransactionExpensesTableProps
) => {
  const { data } = props;

  return (
    <>
      <Table
        basic
        striped
        compact="very"
        unstackable
        className="table-detailTransaction"
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Jméno</Table.HeaderCell>
            <Table.HeaderCell>Částka</Table.HeaderCell>
            <Table.HeaderCell>Typ</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data.map((item: ExpenseDto) => {
            let text = ExpenseTypeUtil.getText(item.expenseType);
            return (
              <Table.Row key={item?.owner.id}>
                <Table.Cell>{item?.owner.fullname}</Table.Cell>
                <Table.Cell>{Formatter.money(item?.amount)}</Table.Cell>
                <Table.Cell><Icon name={text.icon} /> {text.text}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};
