import {useEffect, useState} from "react";
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Icon,
  Popup,
  Table,
} from "semantic-ui-react";
import { Formatter } from "../../../base/util/Formatter";
import SimpleLoader from "../../../components/SimpleLoader";
import InvoiceSubjectSelector from "../component/InvoiceSubjectSelector";
import { InvoiceOption, InvoiceUtil } from "../../../base/util/InvoiceUtil";
import { DateUtil } from "../../../base/util/DateUtil";
import { View } from "../../../base/enums/View";
import { useHistory } from "react-router-dom";
import FullTextFilter from "../../../components/base/FullTextFilter";
import {InvoiceDto, InvoiceStatus, useGetInvoices} from '../../../api/generated-sources'
import {LocalDate} from '../../../build/core/dto/LocalDate'
import moment from "moment/moment";

export default function InvoicesViewMobile() {
  const [filterData, setFilterData] = useState<InvoiceDto[]>();
  const [subjectId, setSubjectId] = useState<number>(null);
  const [from, setFrom] = useState<LocalDate>(
    InvoiceUtil.getFrom(InvoiceUtil.PERIOD_THIS_MONTH.key)
  );
  const [to, setTo] = useState<LocalDate>(
    InvoiceUtil.getTo(InvoiceUtil.PERIOD_THIS_MONTH.key)
  );
  const [status, setStatus] = useState<InvoiceStatus>(InvoiceStatus.ALL);
  const [timeframe, setTimeframe] = useState<InvoiceOption>(
    InvoiceUtil.PERIOD_THIS_MONTH
  );

  const history = useHistory();

  const {data, isLoading, error} = useGetInvoices({
    from: from,
    to: to,
    status: InvoiceStatus[status],
    subjectId: subjectId,
  });

  useEffect(() => {
    console.log("Error:", error)
  }, [error]);

  const handleStatusChange = (data: any) => {
    setStatus(InvoiceUtil.getInvoiceStatusOption(data));
  };

  const handlePeriodChange = (data: any) => {
    setTimeframe(InvoiceUtil.getInvoicePeriodOption(data));
    const from = InvoiceUtil.getFrom(data);
    const to = InvoiceUtil.getTo(data);
    setFrom(from);
    setTo(to);
  };

  const onSubjectChange = (id: number) => {
    setSubjectId(id);
  };

  const displayLoader = isLoading || !data;

  return (
    <Container>
      {displayLoader && (
        <Grid>
          <SimpleLoader text={"Načítám faktury"} />
        </Grid>
      )}
      {!displayLoader && data && (
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header>Seznam faktur</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Form>
                <Form.Group>
                  <InvoiceSubjectSelector
                    width={4}
                    onSubjectChange={onSubjectChange}
                    value={subjectId}
                    label={"Odběratel"}
                    nothingSelectedText="Všichni odběratelé"
                    emptyItem={true}
                  />
                  <Form.Select
                    options={InvoiceUtil.getInvoiceStatusOptions()}
                    width={4}
                    label={"Filtr"}
                    onChange={(e, data) => handleStatusChange(data.value)}
                    value={status}
                    type={"text"}
                  />
                  <Form.Select
                    options={InvoiceUtil.getInvoicePeriodOptions()}
                    width={4}
                    label={"Zdanitelné plnění"}
                    onChange={(e, data) => handlePeriodChange(data.value)}
                    value={timeframe.key}
                    type={"text"}
                  />
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <FullTextFilter
                data={data}
                returnFilteredData={setFilterData}
                formatData={{
                  dateType: ["dateCreated", "dateDue", "dateTax"],
                  formatDateType: "DD. MM. yyyy",
                  activeItem: [
                    "invoiceNumber",
                    "vs",
                    "dateCreated",
                    "dateTax",
                    "dateDue",
                    "invoiceSubject",
                    "id",
                    "expectedCount",
                    "totalPrice",
                    "totalPriceWithVat",
                    "dateCreated",
                    "comment",
                    "orderNumber",
                  ],
                }}
              />
              <Table basic striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      <strong>
                        {
                          InvoiceUtil.getInvoiceStatusOptions().find(
                            (s) => s.key === status
                          ).text
                        }{" "}
                        - {timeframe.text}
                      </strong>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row className="flex">
                    <Table.Cell>Celková částka</Table.Cell>
                    <Table.Cell>
                      {Formatter.money(
                        data
                          .flatMap((i) => {
                            return i.totalPriceWithVat;
                          })
                          .reduce((a, b) => a + b, 0)
                      )}
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row className="flex">
                    <Table.Cell>Zaplaceno</Table.Cell>
                    <Table.Cell>
                      {Formatter.money(
                        data
                          .filter((i) => {
                            return i.paid;
                          })
                          .flatMap((i) => {
                            return i.totalPriceWithVat;
                          })
                          .reduce((a, b) => a + b, 0)
                      )}
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row className="flex">
                    <Table.Cell>Čeká na zaplacení</Table.Cell>
                    <Table.Cell>
                      {Formatter.money(
                        data.filter((i) => {
                            return !i.paid;
                          }).flatMap((i) => {
                            return i.totalPriceWithVat;
                          }).reduce((a, b) => a + b, 0)
                      )}
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row className="flex">
                    <Table.Cell>Z toho po splatnosti</Table.Cell>
                    <Table.Cell>
                      {Formatter.money(
                        data.filter((i) => {
                            return !i.paid && DateUtil.isBeforeToday(new LocalDate(i.dateDue));
                          }).flatMap((i) => {
                            return i.totalPriceWithVat;
                          }).reduce((a, b) => a + b, 0)
                      )}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Column width={16}>
            <Table basic striped>
              <Table.Body>
                {data.length === 0 && (
                  <Table.Row>
                    <Table.Cell textAlign={"center"} colSpan={7}>
                      Žádné vydané faktury tento měsíc
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Grid.Column>

          <Grid.Column width={16}>
            {filterData?.length
              ? filterData.map((item) => {
                  const itemsWithoutRounding = item.invoiceItems.filter(
                    (i) => i.name !== InvoiceUtil.ROUNDING_ITEM_NAME
                  );
                  return (
                    <Table
                      basic
                      striped
                      compact="very"
                      onClick={() =>
                        history.push(`${View.INVOICES.path + "/" + item.id}`)
                      }
                      key={item.id}
                    >
                      <Table.Body>
                        <Table.Row className="flex space-beetwen">
                          <Table.Cell>Stav</Table.Cell>
                          <Popup
                            position={"left center"}
                            trigger={
                              <Table.Cell textAlign={"center"}>
                                {item.paid && (
                                  <Icon name={"money bill alternate"} />
                                )}
                                {!item.paid &&
                                  DateUtil.isAfterToday(new LocalDate(item.dateDue)) && (
                                    <Icon
                                      name={"money bill alternate"}
                                      disabled
                                    />
                                  )}
                                {!item.paid &&
                                  !DateUtil.isAfterToday(new LocalDate(item.dateDue)) && (
                                    <Icon name={"clock"} color={"red"} />
                                  )}
                              </Table.Cell>
                            }
                          >
                            <Popup.Content>
                              {item.paid && <span>Zaplaceno</span>}
                              {!item.paid &&
                                DateUtil.isAfterToday(new LocalDate(item.dateDue)) && (
                                  <span>Nezaplaceno</span>
                                )}
                              {!item.paid &&
                                !DateUtil.isAfterToday(new LocalDate(item.dateDue)) && (
                                  <span>Po splatnosti</span>
                                )}
                            </Popup.Content>
                          </Popup>
                          <Table.Cell
                            onClick={(e: Event) => {
                              e.stopPropagation();
                            }}
                          >
                            {!!item.attachmentUrl && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={item.attachmentUrl}
                              >
                                <Icon color="black" name="download" />
                              </a>
                            )}
                            {!item.attachmentUrl && (
                              <Icon.Group>
                                <Icon
                                  color="red"
                                  size={"big"}
                                  name="circle outline"
                                />
                                <Icon
                                  color="black"
                                  name="file alternate outline"
                                />
                                <Icon
                                  color="red"
                                  size={"small"}
                                  corner
                                  name="warning"
                                />
                              </Icon.Group>
                            )}
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row className="flex">
                          <Table.Cell>Číslo dokladu</Table.Cell>
                          <Table.Cell singleLine>
                            {item.invoiceNumber ?? item.vs}
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row className="flex">
                          <Table.HeaderCell>Odběratel</Table.HeaderCell>
                          <Table.Cell singleLine>
                            {item.invoiceSubject
                              ? item.invoiceSubject.name
                              : ""}
                          </Table.Cell>
                        </Table.Row>

                        {!!itemsWithoutRounding &&
                          itemsWithoutRounding.length > 1 && (
                            <Popup
                              trigger={
                                <Table.Row className="flex">
                                  <Table.Cell>
                                    Více položek ({itemsWithoutRounding.length})
                                  </Table.Cell>
                                </Table.Row>
                              }
                            >
                              {itemsWithoutRounding.map((i) => {
                                return (
                                  <span key={i.id}>
                                    {i.name}
                                    <br />
                                  </span>
                                );
                              })}
                            </Popup>
                          )}
                        {!!itemsWithoutRounding &&
                          itemsWithoutRounding.length === 1 && (
                            <Table.Row className="flex">
                              <Table.Cell>Položky</Table.Cell>
                              <Table.Cell>
                                {itemsWithoutRounding[0].name}
                              </Table.Cell>
                            </Table.Row>
                          )}
                        {(!itemsWithoutRounding ||
                          itemsWithoutRounding.length === 0) && (
                          <Table.Row className="flex">
                            <Table.Cell>Položky</Table.Cell>
                            <Table.Cell>Bez položek</Table.Cell>
                          </Table.Row>
                        )}

                        <Table.Row className="flex">
                          <Table.Cell>Vydáno</Table.Cell>
                          <Table.Cell singleLine>{moment(item.dateCreated).format("D. M. YYYY")}</Table.Cell>
                        </Table.Row>
                        <Table.Row className="flex">
                          <Table.Cell>Zdaň. plnění</Table.Cell>
                          <Table.Cell singleLine>{moment(item.dateTax).format("D. M. YYYY")}</Table.Cell>
                        </Table.Row>
                        <Table.Row className="flex">
                          <Table.Cell>Splatnost</Table.Cell>
                          <Table.Cell singleLine>{moment(item.dateDue).format("D. M. YYYY")}</Table.Cell>
                        </Table.Row>
                        <Table.Row className="flex">
                          <Table.Cell>Částka bez DPH</Table.Cell>
                          <Table.Cell singleLine>
                            {Formatter.money(item.totalPrice)}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row className="flex">
                          <Table.Cell>Částka</Table.Cell>
                          <Table.Cell singleLine>
                            {Formatter.money(item.totalPriceWithVat)}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  );
                })
              : data.map((item) => {
                  const itemsWithoutRounding = item.invoiceItems.filter(
                    (i) => i.name !== InvoiceUtil.ROUNDING_ITEM_NAME
                  );
                  return (
                    <Table
                      basic
                      striped
                      compact="very"
                      onClick={() =>
                        history.push(`${View.INVOICES.path + "/" + item.id}`)
                      }
                      key={item.id}
                    >
                      <Table.Body>
                        <Table.Row className="flex space-beetwen">
                          <Table.Cell>Stav</Table.Cell>
                          <Popup
                            position={"left center"}
                            trigger={
                              <Table.Cell textAlign={"center"}>
                                {item.paid && (
                                  <Icon name={"money bill alternate"} />
                                )}
                                {!item.paid &&
                                  DateUtil.isAfterToday(new LocalDate(item.dateDue)) && (
                                    <Icon
                                      name={"money bill alternate"}
                                      disabled
                                    />
                                  )}
                                {!item.paid &&
                                  !DateUtil.isAfterToday(new LocalDate(item.dateDue)) && (
                                    <Icon name={"clock"} color={"red"} />
                                  )}
                              </Table.Cell>
                            }
                          >
                            <Popup.Content>
                              {item.paid && <span>Zaplaceno</span>}
                              {!item.paid &&
                                DateUtil.isAfterToday(new LocalDate(item.dateDue)) && (
                                  <span>Nezaplaceno</span>
                                )}
                              {!item.paid &&
                                !DateUtil.isAfterToday(new LocalDate(item.dateDue)) && (
                                  <span>Po splatnosti</span>
                                )}
                            </Popup.Content>
                          </Popup>
                          <Table.Cell
                            onClick={(e: Event) => {
                              e.stopPropagation();
                            }}
                          >
                            {!!item.attachmentUrl && (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={item.attachmentUrl}
                              >
                                <Icon color="black" name="download" />
                              </a>
                            )}
                            {!item.attachmentUrl && (
                              <Icon.Group>
                                <Icon
                                  color="red"
                                  size={"big"}
                                  name="circle outline"
                                />
                                <Icon
                                  color="black"
                                  name="file alternate outline"
                                />
                                <Icon
                                  color="red"
                                  size={"small"}
                                  corner
                                  name="warning"
                                />
                              </Icon.Group>
                            )}
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row className="flex">
                          <Table.Cell>Číslo dokladu</Table.Cell>
                          <Table.Cell singleLine>
                            {item.invoiceNumber ?? item.vs}
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row className="flex">
                          <Table.HeaderCell>Odběratel</Table.HeaderCell>
                          <Table.Cell singleLine>
                            {item.invoiceSubject
                              ? item.invoiceSubject.name
                              : ""}
                          </Table.Cell>
                        </Table.Row>

                        {!!itemsWithoutRounding &&
                          itemsWithoutRounding.length > 1 && (
                            <Popup
                              trigger={
                                <Table.Row className="flex">
                                  <Table.Cell>
                                    Více položek ({itemsWithoutRounding.length})
                                  </Table.Cell>
                                </Table.Row>
                              }
                            >
                              {itemsWithoutRounding.map((i) => {
                                return (
                                  <span key={i.id}>
                                    {i.name}
                                    <br />
                                  </span>
                                );
                              })}
                            </Popup>
                          )}
                        {!!itemsWithoutRounding &&
                          itemsWithoutRounding.length === 1 && (
                            <Table.Row className="flex">
                              <Table.Cell>Položky</Table.Cell>
                              <Table.Cell>
                                {itemsWithoutRounding[0].name}
                              </Table.Cell>
                            </Table.Row>
                          )}
                        {(!itemsWithoutRounding ||
                          itemsWithoutRounding.length === 0) && (
                          <Table.Row className="flex">
                            <Table.Cell>Položky</Table.Cell>
                            <Table.Cell>Bez položek</Table.Cell>
                          </Table.Row>
                        )}

                        <Table.Row className="flex">
                          <Table.Cell>Vydáno</Table.Cell>
                          <Table.Cell singleLine>{moment(item.dateCreated).format("D. M. YYYY")}</Table.Cell>
                        </Table.Row>
                        <Table.Row className="flex">
                          <Table.Cell>Zdaň. plnění</Table.Cell>
                          <Table.Cell singleLine>{moment(item.dateTax).format("D. M. YYYY")}</Table.Cell>
                        </Table.Row>
                        <Table.Row className="flex">
                          <Table.Cell>Splatnost</Table.Cell>
                          <Table.Cell singleLine>{moment(item.dateDue).format("D. M. YYYY")}</Table.Cell>
                        </Table.Row>
                        <Table.Row className="flex">
                          <Table.Cell>Částka bez DPH</Table.Cell>
                          <Table.Cell singleLine>
                            {Formatter.money(item.totalPrice)}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row className="flex">
                          <Table.Cell>Částka</Table.Cell>
                          <Table.Cell singleLine>
                            {Formatter.money(item.totalPriceWithVat)}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  );
                })}
            <Table.Footer>
              <Table.Row>
                <Table.Cell colSpan={10} textAlign={"right"}>
                  <Button
                    color={"green"}
                    onClick={() =>
                      history.push(`${View.INVOICES.path + "/new"}`)
                    }
                  >
                    <Icon name={"plus"} />
                    Přidat fakturu
                  </Button>
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          </Grid.Column>
        </Grid>
      )}
    </Container>
  );
}
