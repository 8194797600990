/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
import useSwr from "swr";
import type { Arguments, Key, SWRConfiguration } from "swr";
import useSWRMutation from "swr/mutation";
import type { SWRMutationConfiguration } from "swr/mutation";
import type {
  DocumentTypeDto,
  ErrorResponseResponse,
  GetDocumentTypesParams,
  PageableDocumentTypeResponse,
} from "../intranetApi.schemas";
import { IntranetClientFetchInstance } from "../../intranetClientFetchInstance";
import type { ErrorType } from "../../intranetClientFetchInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get document types
 */
export const getDocumentTypes = (
  params?: GetDocumentTypesParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<PageableDocumentTypeResponse>(
    { url: `/documentType`, method: "GET", params },
    options
  );
};

export const getGetDocumentTypesKey = (params?: GetDocumentTypesParams) =>
  [`/documentType`, ...(params ? [params] : [])] as const;

export type GetDocumentTypesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDocumentTypes>>
>;
export type GetDocumentTypesQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get document types
 */
export const useGetDocumentTypes = <TError = ErrorType<ErrorResponseResponse>>(
  params?: GetDocumentTypesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getDocumentTypes>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetDocumentTypesKey(params) : null));
  const swrFn = () => getDocumentTypes(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary create document type
 */
export const createDocumentType = (
  documentTypeDto: DocumentTypeDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<DocumentTypeDto>(
    {
      url: `/documentType`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: documentTypeDto,
    },
    options
  );
};

export const getCreateDocumentTypeMutationFetcher = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: DocumentTypeDto }
  ): Promise<DocumentTypeDto> => {
    return createDocumentType(arg, options);
  };
};
export const getCreateDocumentTypeMutationKey = () => `/documentType` as const;

export type CreateDocumentTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof createDocumentType>>
>;
export type CreateDocumentTypeMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary create document type
 */
export const useCreateDocumentType = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createDocumentType>>,
    TError,
    string,
    DocumentTypeDto,
    Awaited<ReturnType<typeof createDocumentType>>
  > & { swrKey?: string };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCreateDocumentTypeMutationKey();
  const swrFn = getCreateDocumentTypeMutationFetcher(requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary get document type
 */
export const getDocumentType = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<DocumentTypeDto>(
    { url: `/documentType/${id}`, method: "GET" },
    options
  );
};

export const getGetDocumentTypeKey = (id: number) =>
  [`/documentType/${id}`] as const;

export type GetDocumentTypeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDocumentType>>
>;
export type GetDocumentTypeQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get document type
 */
export const useGetDocumentType = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getDocumentType>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetDocumentTypeKey(id) : null));
  const swrFn = () => getDocumentType(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary delete document type
 */
export const deleteDocumentType = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<string>(
    { url: `/documentType/${id}`, method: "DELETE" },
    options
  );
};

export const getDeleteDocumentTypeMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, __: { arg: Arguments }): Promise<string> => {
    return deleteDocumentType(id, options);
  };
};
export const getDeleteDocumentTypeMutationKey = (id: number) =>
  `/documentType/${id}` as const;

export type DeleteDocumentTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteDocumentType>>
>;
export type DeleteDocumentTypeMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary delete document type
 */
export const useDeleteDocumentType = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteDocumentType>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof deleteDocumentType>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getDeleteDocumentTypeMutationKey(id);
  const swrFn = getDeleteDocumentTypeMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary update document type
 */
export const updateDocumentType = (
  id: number,
  documentTypeDto: DocumentTypeDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<DocumentTypeDto>(
    {
      url: `/documentType/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: documentTypeDto,
    },
    options
  );
};

export const getUpdateDocumentTypeMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: DocumentTypeDto }
  ): Promise<DocumentTypeDto> => {
    return updateDocumentType(id, arg, options);
  };
};
export const getUpdateDocumentTypeMutationKey = (id: number) =>
  `/documentType/${id}` as const;

export type UpdateDocumentTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateDocumentType>>
>;
export type UpdateDocumentTypeMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary update document type
 */
export const useUpdateDocumentType = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateDocumentType>>,
      TError,
      string,
      DocumentTypeDto,
      Awaited<ReturnType<typeof updateDocumentType>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getUpdateDocumentTypeMutationKey(id);
  const swrFn = getUpdateDocumentTypeMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
