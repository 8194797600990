import {TransactionDto} from '../../api/generated-sources'

export class StringUtil {
  public static isEmpty(text: string) : boolean {
    return !text || text.length === 0;
  }

  public static shortenWithEllipsis(text: string, maxLength: number) : string {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substr(0, maxLength) + "...";
  }

  public static getTransactionLabel(transaction: TransactionDto) {
    let label = [
        transaction.counterAccountName,
        transaction.comment,
        transaction.messageForRecipient,
      ].find((item) => item !== undefined && item !== null && item.length > 0);
    return label ?? "";
  };
}
