import { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Container, Dropdown, DropdownItem, DropdownMenu,
  Grid,
  Header,
  Icon, Menu,
  Table,
} from "semantic-ui-react";
import { Formatter } from "../../../base/util/Formatter";
import SimpleLoader from "../../../components/SimpleLoader";
import { InvoiceOption, InvoiceUtil } from "../../../base/util/InvoiceUtil";
import { DateUtil } from "../../../base/util/DateUtil";
import InvoiceTableRow from "../component/InvoiceTableRow";
import { View } from "../../../base/enums/View";
import { useHistory } from "react-router-dom";
import InvoicesViewMobile from "./InvoicesViewMobile";
import { InvoiceFilter } from "../component/InvoiceFilter";
import { FilterContext, FilterValue } from "../../../contexts/FilterContext";
import FullTextFilter from "../../../components/base/FullTextFilter";
import {
  exportInvoices, exportInvoicesProjects,
  InvoiceDto,
  InvoiceStatus,
  useGetInvoices
} from '../../../api/generated-sources'
import toast from 'react-hot-toast'
import moment from 'moment'
import {LocalDate} from '../../../build/core/dto/LocalDate'

export default function InvoicesView() {
  const {
    invoiceFilter,
    invoiceTimeframe,
    invoicePurchaser,
    setInvoiceFilter,
    setInvoiceTimeframe,
    setInvoicePurchaser,
  } = useContext<FilterValue>(FilterContext);
  const invoiceTimeframeInitial = InvoiceUtil.getInvoicePeriodOptions().filter(
    (item) => item.key === invoiceTimeframe
  );
  const InvoiceStatusInitial = InvoiceUtil.getInvoiceStatusOptions().filter(
    (item) => item.key === invoiceFilter?.toLocaleUpperCase()
  );

  const [data, setData] = useState<InvoiceDto[]>();
  const [column, setColumn] = useState<string>();
  const [direction, setDirection] = useState<"ascending" | "descending">(
    "ascending"
  );
  const [filterData, setFilterData] = useState<InvoiceDto[]>();
  const [subjectId, setSubjectId] = useState<number>(invoicePurchaser);
  const [status, setStatus] = useState<InvoiceStatus>(
    invoiceFilter === undefined
      ? InvoiceStatus.ALL
      : (InvoiceStatusInitial[0]?.key as InvoiceStatus)
  );
  const [timeframe, setTimeframe] = useState<InvoiceOption>(
    invoiceTimeframe
      ? invoiceTimeframeInitial[0]
      : InvoiceUtil.PERIOD_THIS_MONTH
  );
  const [from, setFrom] = useState<LocalDate>(
    InvoiceUtil.getFrom(invoiceTimeframe ?? InvoiceUtil.PERIOD_THIS_MONTH.key)
  );
  const [to, setTo] = useState<LocalDate>(
    InvoiceUtil.getTo(invoiceTimeframe ?? InvoiceUtil.PERIOD_THIS_MONTH.key)
  );

  const history = useHistory();

  const {data: invoices, isLoading, error} = useGetInvoices({
      from: from ? from.toISOString() : null,
      to: to ? to.toISOString() : null,
      status: InvoiceStatus[status],
      subjectId: subjectId,
    });

  useEffect(() => {
    setData(invoices)
  }, [invoices]);
  useEffect(() => {
    console.error("Error:", error)
  }, [error]);

  const handleStatusChange = (data: any) => {
    setStatus(InvoiceUtil.getInvoiceStatusOption(data));
    setInvoiceFilter(data.toLowerCase());
  };

  const handlePeriodChange = (data: any) => {
    setTimeframe(InvoiceUtil.getInvoicePeriodOption(data));
    const from = InvoiceUtil.getFrom(data);
    const to = InvoiceUtil.getTo(data);
    setFrom(from);
    setTo(to);
    setInvoiceTimeframe(timeframe.key);
  };

  const onSubjectChange = (id: number) => {
    setSubjectId(id);
    setInvoicePurchaser(id);
  };

  const displayLoader = isLoading || !data;

  const [isMobile, setIsMobile] = useState(false);

  const getResize = () => {
    if (window.innerWidth <= 770) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    getResize();

    window.addEventListener("resize", getResize);

    return () => {
      window.removeEventListener("resize", getResize);
    };
  }, []);

  const handleSort = (clickedColumn: string) => {
    setFilterData([])
    if (column === clickedColumn) {
      setDirection(direction === "ascending" ? "descending" : "ascending");
    } else {
      setColumn(clickedColumn);
      setDirection("ascending");
    }
  };

  const sortedData = useMemo(() => {
    if (data) {
      const sorted = [...data]?.sort((a, b) => {
        const columnType = typeof a[column as keyof InvoiceDto];
        if (columnType === "string") {
          if ("invoiceNumber" in a) {
            return direction === "ascending"
              ? Number(a[column as keyof InvoiceDto]) -
                  Number(b[column as keyof InvoiceDto])
              : Number(b[column as keyof InvoiceDto]) -
                  Number(a[column as keyof InvoiceDto]);
          }
          return direction === "ascending"
            ? a[column as keyof InvoiceDto]
                .toString()
                .localeCompare(b[column as keyof InvoiceDto].toString())
            : b[column as keyof InvoiceDto]
                .toString()
                .localeCompare(a[column as keyof InvoiceDto].toString());
        } else if (columnType === "number") {
          return direction === "ascending"
            ? Number(a[column as keyof InvoiceDto]) -
                Number(b[column as keyof InvoiceDto])
            : Number(b[column as keyof InvoiceDto]) -
                Number(a[column as keyof InvoiceDto]);
        } else if (column === "invoiceSubject") {
          return direction === "ascending"
            ? a.invoiceSubject.name
                .toString()
                .localeCompare(b.invoiceSubject.name)
            : b.invoiceSubject.name
                .toString()
                .localeCompare(a.invoiceSubject.name);
        } else if (column === "dateCreated" || "dateTax" || "dateDue") {
          return direction === "ascending"
            ? a[column as keyof InvoiceDto]
                .toString()
                .localeCompare(b.invoiceSubject.name)
            : b[column as keyof InvoiceDto]
                .toString()
                .localeCompare(a.invoiceSubject.name);
        } else {
          return 0;
        }
      });
      return sorted;
    }
  }, [column, direction]);

  // const auth = useContext<Auth>(AuthContext);

  const download = async (filename: string, promise: Promise<Blob>) => {
    const loading = toast.loading("Probíhá export ...")
    promise
      .then(data => {
        toast.dismiss(loading)
        toast.success("Export proběhl v pořádku.")
        const href = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = href;
        const urlDateFormat = "YYYYMMDD"
        const f = from ? "_" + moment(from.toString()).format(urlDateFormat) : "";
        const t = to ? "_" + moment(to.toString()).format(urlDateFormat) : "";
        const s = status ? "_" + status : "";
        const i = subjectId ? "_" + subjectId.toString() : "";
        link.setAttribute('download', `${filename}${f}${t}${s}${i}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }).catch(reason => {
      toast.dismiss(loading)
      toast.error("Export se nezdařil.");
    })
  };

  const downloadXlsx = async () => {
    download("invoices", exportInvoices({
        from: from ? from.toISOString() : null,
        to: to ? to.toISOString() : null,
        status: status,
        subjectId: subjectId,
      }));
  }

  const downloadProjectsXlsx = async () => {
   download("invoice_items", exportInvoicesProjects({
        from: from ? from.toISOString() : null,
        to: to ? to.toISOString() : null,
        status: status,
        subjectId: subjectId,
      }))
  };

  return (
    <>
      {!isMobile ? (
        <Container>
          {displayLoader && (
            <Grid>
              <SimpleLoader text={"Načítám faktury"} />
            </Grid>
          )}
          {!displayLoader && data && (
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Header>Seznam faktur</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <InvoiceFilter
                    subjectId={subjectId}
                    status={status}
                    timeframe={timeframe}
                    handleStatusChange={handleStatusChange}
                    handlePeriodChange={handlePeriodChange}
                    onSubjectChange={onSubjectChange}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Table basic striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell>Celková částka</Table.HeaderCell>
                        <Table.HeaderCell>Zaplaceno</Table.HeaderCell>
                        <Table.HeaderCell>Čeká na zaplacení</Table.HeaderCell>
                        <Table.HeaderCell>Z toho po splatnosti</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          <strong>
                            {
                              InvoiceUtil.getInvoiceStatusOptions().find(
                                (s) => s.key === status
                              )?.text
                            }{" "}
                            {timeframe?.text}
                          </strong>
                        </Table.Cell>
                        <Table.Cell>
                          {Formatter.money(
                            data
                              .flatMap((i) => {
                                return i.totalPriceWithVat;
                              })
                              .reduce((a, b) => a + b, 0)
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {Formatter.money(
                            data
                              .filter((i) => {
                                return i.paid;
                              })
                              .flatMap((i) => {
                                return i.totalPriceWithVat;
                              })
                              .reduce((a, b) => a + b, 0)
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {Formatter.money(
                            data
                              .filter((i) => {
                                return !i.paid;
                              })
                              .flatMap((i) => {
                                return i.totalPriceWithVat;
                              })
                              .reduce((a, b) => a + b, 0)
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {Formatter.money(
                            data
                              .filter((i) => {
                                return (
                                  !i.paid && !DateUtil.isAfterToday(new LocalDate(i.dateDue))
                                );
                              })
                              .flatMap((i) => {
                                return i.totalPriceWithVat;
                              })
                              .reduce((a, b) => a + b, 0)
                          )}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column floated={"left"} width={8}>
                  <FullTextFilter
                    data={data}
                    returnFilteredData={setFilterData}
                    formatData={{
                      dateType: ["dateCreated", "dateDue", "dateTax"],
                      formatDateType: "DD. MM. yyyy",
                      activeItem: [
                        "invoiceNumber",
                        "vs",
                        "dateCreated",
                        "dateTax",
                        "dateDue",
                        "invoiceSubject",
                        "id",
                        "expectedCount",
                        "totalPrice",
                        "totalPriceWithVat",
                        "dateCreated",
                        "comment",
                        "orderNumber",
                      ],
                    }}
                  />
                </Grid.Column>
                <Grid.Column floated={"right"} width={8}>
                  <Menu floated={"right"}>
                    <Dropdown item text='Export'>
                      <DropdownMenu>
                        <DropdownItem onClick={downloadXlsx}>
                          Export do XLSX
                        </DropdownItem>
                        <DropdownItem onClick={downloadProjectsXlsx}>
                          Export polžek projektů do XLSX
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </Menu>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Table
                    basic
                    striped
                    sortable={true}
                    selectable={!!data.length}
                    className={"invoices"}
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell textAlign={"center"}>
                          Stav
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={column === "invoiceNumber" ? direction : null}
                          onClick={() => {
                            setColumn("invoiceNumber");
                            handleSort("invoiceNumber");
                          }}
                        >
                          Číslo dokladu
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={
                            column === "invoiceSubject" ? direction : null
                          }
                          onClick={() => {
                            setColumn("invoiceSubject");
                            handleSort("invoiceSubject");
                          }}
                        >
                          Odběratel
                        </Table.HeaderCell>
                        <Table.HeaderCell>Položky</Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={column === "dateCreated" ? direction : null}
                          onClick={() => {
                            setColumn("dateCreated");
                            handleSort("dateCreated");
                          }}
                        >
                          Vydáno
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={column === "dateTax" ? direction : null}
                          onClick={() => {
                            setColumn("dateTax");
                            handleSort("dateTax");
                          }}
                        >
                          Zdaň. plnění
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={column === "dateDue" ? direction : null}
                          onClick={() => {
                            setColumn("dateDue");
                            handleSort("dateDue");
                          }}
                        >
                          Splatnost
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          textAlign={"right"}
                          sorted={
                            column === "totalPrice" ? direction : null
                          }
                          onClick={() => {
                            setColumn("totalPrice");
                            handleSort("totalPrice");
                          }}
                        >
                          Částka bez DPH
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          textAlign={"right"}
                          sorted={
                            column === "totalPriceWithVat" ? direction : null
                          }
                          onClick={() => {
                            setColumn("totalPriceWithVat");
                            handleSort("totalPriceWithVat");
                          }}
                        >
                          Částka
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign={"center"}>
                          Doklad
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.length === 0 && (
                        <Table.Row>
                          <Table.Cell textAlign={"center"} colSpan={10}>
                            Žádné vydané faktury tento měsíc
                          </Table.Cell>
                        </Table.Row>
                      )}
                      {filterData?.length
                        ? filterData.map((item) => {
                            return (
                              <InvoiceTableRow key={item.id} invoice={item} />
                            );
                          })
                        : sortedData?.length
                        ? sortedData.map((item) => {
                            return (
                              <InvoiceTableRow key={item.id} invoice={item} />
                            );
                          })
                        : data.map((item) => {
                            return (
                              <InvoiceTableRow key={item.id} invoice={item} />
                            );
                          })}
                    </Table.Body>
                    <Table.Footer>
                      <Table.Row>
                        <Table.Cell colSpan={10} textAlign={"right"}>
                          <Button
                            color={"green"}
                            onClick={() =>
                              history.push(`${View.INVOICES.path + "/new"}`)
                            }
                          >
                            <Icon name={"plus"} />
                            Přidat fakturu
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </Container>
      ) : (
        <InvoicesViewMobile />
      )}
    </>
  );
}
