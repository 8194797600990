/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
import useSwr from "swr";
import type { Arguments, Key, SWRConfiguration } from "swr";
import useSWRMutation from "swr/mutation";
import type { SWRMutationConfiguration } from "swr/mutation";
import type {
  ErrorResponseResponse,
  GetSalariesForCurrentUserParams,
  GetSalariesForUserParams,
  PageableSalaryResponse,
  SalaryDto,
  SalaryInputDto,
} from "../intranetApi.schemas";
import { IntranetClientFetchInstance } from "../../intranetClientFetchInstance";
import type { ErrorType } from "../../intranetClientFetchInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get salaries for current user
 */
export const getSalariesForCurrentUser = (
  params?: GetSalariesForCurrentUserParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<PageableSalaryResponse>(
    { url: `/salary/currentUser`, method: "GET", params },
    options
  );
};

export const getGetSalariesForCurrentUserKey = (
  params?: GetSalariesForCurrentUserParams
) => [`/salary/currentUser`, ...(params ? [params] : [])] as const;

export type GetSalariesForCurrentUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSalariesForCurrentUser>>
>;
export type GetSalariesForCurrentUserQueryError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary get salaries for current user
 */
export const useGetSalariesForCurrentUser = <
  TError = ErrorType<ErrorResponseResponse>
>(
  params?: GetSalariesForCurrentUserParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getSalariesForCurrentUser>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetSalariesForCurrentUserKey(params) : null));
  const swrFn = () => getSalariesForCurrentUser(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary get salaries for user
 */
export const getSalariesForUser = (
  id: number,
  params?: GetSalariesForUserParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<PageableSalaryResponse>(
    { url: `/salary/${id}`, method: "GET", params },
    options
  );
};

export const getGetSalariesForUserKey = (
  id: number,
  params?: GetSalariesForUserParams
) => [`/salary/${id}`, ...(params ? [params] : [])] as const;

export type GetSalariesForUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSalariesForUser>>
>;
export type GetSalariesForUserQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get salaries for user
 */
export const useGetSalariesForUser = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  params?: GetSalariesForUserParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getSalariesForUser>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetSalariesForUserKey(id, params) : null));
  const swrFn = () => getSalariesForUser(id, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary update salary
 */
export const updateSalary = (
  id: number,
  salaryInputDto: SalaryInputDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<SalaryDto>(
    {
      url: `/salary/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: salaryInputDto,
    },
    options
  );
};

export const getUpdateSalaryMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, { arg }: { arg: SalaryInputDto }): Promise<SalaryDto> => {
    return updateSalary(id, arg, options);
  };
};
export const getUpdateSalaryMutationKey = (id: number) =>
  `/salary/${id}` as const;

export type UpdateSalaryMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateSalary>>
>;
export type UpdateSalaryMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary update salary
 */
export const useUpdateSalary = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateSalary>>,
      TError,
      string,
      SalaryInputDto,
      Awaited<ReturnType<typeof updateSalary>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getUpdateSalaryMutationKey(id);
  const swrFn = getUpdateSalaryMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary delete salary
 */
export const deleteSalary = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<string>(
    { url: `/salary/${id}`, method: "DELETE" },
    options
  );
};

export const getDeleteSalaryMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, __: { arg: Arguments }): Promise<string> => {
    return deleteSalary(id, options);
  };
};
export const getDeleteSalaryMutationKey = (id: number) =>
  `/salary/${id}` as const;

export type DeleteSalaryMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSalary>>
>;
export type DeleteSalaryMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary delete salary
 */
export const useDeleteSalary = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteSalary>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof deleteSalary>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getDeleteSalaryMutationKey(id);
  const swrFn = getDeleteSalaryMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary delete salary attachment
 */
export const deleteSalaryAttachment = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<string>(
    { url: `/salary/${id}/attachment`, method: "DELETE" },
    options
  );
};

export const getDeleteSalaryAttachmentMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, __: { arg: Arguments }): Promise<string> => {
    return deleteSalaryAttachment(id, options);
  };
};
export const getDeleteSalaryAttachmentMutationKey = (id: number) =>
  `/salary/${id}/attachment` as const;

export type DeleteSalaryAttachmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSalaryAttachment>>
>;
export type DeleteSalaryAttachmentMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary delete salary attachment
 */
export const useDeleteSalaryAttachment = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteSalaryAttachment>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof deleteSalaryAttachment>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getDeleteSalaryAttachmentMutationKey(id);
  const swrFn = getDeleteSalaryAttachmentMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary create salary
 */
export const createSalary = (
  salaryInputDto: SalaryInputDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<SalaryDto>(
    {
      url: `/salary`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: salaryInputDto,
    },
    options
  );
};

export const getCreateSalaryMutationFetcher = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, { arg }: { arg: SalaryInputDto }): Promise<SalaryDto> => {
    return createSalary(arg, options);
  };
};
export const getCreateSalaryMutationKey = () => `/salary` as const;

export type CreateSalaryMutationResult = NonNullable<
  Awaited<ReturnType<typeof createSalary>>
>;
export type CreateSalaryMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary create salary
 */
export const useCreateSalary = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createSalary>>,
    TError,
    string,
    SalaryInputDto,
    Awaited<ReturnType<typeof createSalary>>
  > & { swrKey?: string };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCreateSalaryMutationKey();
  const swrFn = getCreateSalaryMutationFetcher(requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
