import { config } from "../config/DynamicConfig";

export class FilterUtils {
  public static MIN_MONTH: number = config.env.START_MONTH;
  public static MIN_YEAR: number = config.env.START_YEAR;

  public static getMonthWithinBounds(month: number, year: number) {
    let adjustedMonth = Math.max(1, Math.min(month, 12));
    adjustedMonth =
      year === FilterUtils.MIN_YEAR
        ? Math.max(FilterUtils.MIN_MONTH, adjustedMonth)
        : adjustedMonth;
    adjustedMonth =
      year === new Date().getFullYear()
        ? Math.min(new Date().getMonth() + 1, adjustedMonth)
        : adjustedMonth;
    return adjustedMonth;
  }

  public static getYearWithinBounds(year: number) {
    return Math.max(
      FilterUtils.MIN_YEAR,
      Math.min(year, new Date().getFullYear())
    );
  }

  public static getQueryParams(month: number, year?: number) {
    const params = new URLSearchParams();
    params.append("y", year.toString());
    params.append("m", month.toString());
    return params.toString();
  }

  public static isMonthDisabled(month: number, year: number) {
    return month < FilterUtils.MIN_MONTH && year === FilterUtils.MIN_YEAR;
  }

  public static getQueryParamsInvoice(
    filtr: string,
    timeframe: string,
    purchaser?: number
  ) {
    const params = new URLSearchParams();
    if (purchaser) {
      params.append("o", purchaser.toString());
    }

    params.append("f", filtr);
    params.append("zp", timeframe);
    return params.toString();
  }
}
