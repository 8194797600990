/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
import useSwr from "swr";
import type { Key, SWRConfiguration } from "swr";
import type {
  ErrorResponseResponse,
  ExpenseSummaryDto,
  GetExpenseSummaryParams,
} from "../intranetApi.schemas";
import { IntranetClientFetchInstance } from "../../intranetClientFetchInstance";
import type { ErrorType } from "../../intranetClientFetchInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get expense summary
 */
export const getExpenseSummary = (
  params?: GetExpenseSummaryParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<ExpenseSummaryDto[]>(
    { url: `/expenseSummary`, method: "GET", params },
    options
  );
};

export const getGetExpenseSummaryKey = (params?: GetExpenseSummaryParams) =>
  [`/expenseSummary`, ...(params ? [params] : [])] as const;

export type GetExpenseSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getExpenseSummary>>
>;
export type GetExpenseSummaryQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get expense summary
 */
export const useGetExpenseSummary = <TError = ErrorType<ErrorResponseResponse>>(
  params?: GetExpenseSummaryParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getExpenseSummary>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetExpenseSummaryKey(params) : null));
  const swrFn = () => getExpenseSummary(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
