import {EEmploymentType} from '../../api/generated-sources'

export interface EmploymentOption {
  key: string;
  value: string;
  text: string;
}

export class EmploymentUtil {
  public static getTypePPV(key: string): EEmploymentType {
    if (key === EEmploymentType.CONTRACTOR) {
      return EEmploymentType.CONTRACTOR;
    }
    if (key === EEmploymentType.DPC) {
      return EEmploymentType.DPC;
    }
    if (key === EEmploymentType.DPP) {
      return EEmploymentType.DPP;
    }
    if (key === EEmploymentType.HPP) {
      return EEmploymentType.HPP;
    }
  }

  public static getTypePPVOptions(): EmploymentOption[] {
    return [
      {
        key: EEmploymentType.HPP,
        value: EEmploymentType.HPP,
        text: "HPP",
      },
      {
        key: EEmploymentType.DPC,
        value: EEmploymentType.DPC,
        text: "DPČ",
      },
      {
        key: EEmploymentType.DPP,
        value: EEmploymentType.DPP,
        text: "DPP",
      },
      {
        key: EEmploymentType.CONTRACTOR,
        value: EEmploymentType.CONTRACTOR,
        text: "Kontraktoři",
      }
    ];
  }
}
