import {Button, DropdownProps, Form, Grid, Icon, InputOnChangeData, Modal,} from "semantic-ui-react";
import React, {SyntheticEvent, useState} from "react";
import {Formatter} from "../../../base/util/Formatter";
import {SyncOperationState} from "../../../base/state/SyncOperationState";
import toast from "react-hot-toast";
import {StringUtil} from "../../../base/util/StringUtil";
import {ExpenseTypeUtil} from "../../../base/util/ExpenseTypeUtil";
import {
  assignExpensesToTransaction,
  ExpenseDto,
  ExpenseType,
  TransactionDto,
  UserDto
} from '../../../api/generated-sources'

interface PurchaseConfigurationInput {
  owner: number;
  amount: number;
  type: ExpenseType;
}

interface PurchaseConfigurationModalProps {
  transaction: TransactionDto;
  onTransactionUpdate: (transaction: TransactionDto) => void;
  owners: UserDto[];
  onClose: () => void;
}

interface PurchaseConfigurationModalState {
  amountRemaining: number;
}

export default function PurchaseConfigurationModal(
  props: PurchaseConfigurationModalProps
) {
  const [saveState, setSaveState] = useState<SyncOperationState>({
    inProgress: false,
    done: false,
  });

  const existingOwners = props.transaction.expenses.map((item) => {
    return { owner: item.owner.id, amount: item.amount, type: item.expenseType };
  });

  existingOwners.push({ owner: 0, amount: 0, type: ExpenseType.PURCHASE });

  const calculateRemainingAmount = (
    existingPurchase: PurchaseConfigurationInput[]
  ): number => {
    const maxAmountToDivide = props.transaction.amount;
    return (
      Math.round(
        (maxAmountToDivide -
          existingPurchase
            .map((item) => item.amount)
            .reduce((a, b) => {
              return a + b;
            })) *
          100
      ) / 100
    );
  };

  const [inputFields, setInputFields] =
    useState<PurchaseConfigurationInput[]>(existingOwners);
  const [state, setState] = useState<PurchaseConfigurationModalState>({
    amountRemaining: calculateRemainingAmount(existingOwners),
  });

  const handleSubmit = () => {
    const expenses: ExpenseDto[] = [];
    inputFields.forEach((field) => {
      const owner = props.owners.find((o) => o.id === field.owner);
      if (owner) {
        expenses.push({
          amount: field.amount,
          expenseType: field.type,
          owner: {
            id: owner.id,
            fullname: owner.fullname,
            contactEmail: owner.contactEmail,
            username: owner.username,
            ss: owner.ss,
            enabled: owner.enabled,
          },
        });
      }
    });
    if (expenses.length > 0) {
      const id = props.transaction.id;
      const promise = assignExpensesToTransaction(id, {
        id,
        expenses,
      })
      toast.promise(promise,
        {
          loading: "Zpracovávám...",
          success: "Transakce úspěšně přerozdělena.",
          error: "Něco se pokazilo!",
        }
      );
      promise
        .then(onSetPurchaseSuccess)
        .catch(reason => {
          console.error(reason);
          setSaveState({
            inProgress: true,
            done: false,
            error: true
          });
        })
    }
  };

  const onSetPurchaseSuccess = (response: TransactionDto) => {
    setSaveState({
      inProgress: false,
      done: true,
    });
    props.onTransactionUpdate(response);
    props.onClose();
  };

  const handleChange = (
    e: SyntheticEvent,
    data: DropdownProps | InputOnChangeData,
    index: number,
    field: string
  ) => {
    const values = [...inputFields];
    if (field === 'amount') {
      const value = Number.parseFloat(data.value ? data.value.toString() : "0");
      values[index].amount =
        props.transaction.amount < 0 && value > 0 ? value * -1 : value;
    } else if (field === 'owner') {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      values[index].owner = Number.parseInt(data.value!.toString());
    } else if (field === 'type') {
      values[index].type = ExpenseTypeUtil.getType(data.value as string)
    }
    setState({
      amountRemaining: calculateRemainingAmount(values),
    });
    setInputFields(values);
  };

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ owner: 0, amount: 0, type:  existingOwners.length > 0 ? existingOwners[0].type : ExpenseType.PURCHASE});
    setInputFields(values);
  };

  const handleRemoveFields = (index: number) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const ownerOptions = props.owners.map((item) => {
    return {
      text: item.fullname.split(" ")[1] + " " + item.fullname.split(" ")[0],
      value: item.id,
    };
  });
  const companyCoversAmount =
    props.transaction.amount -
    inputFields
      .map((item) => item.amount)
      .reduce((a, b) => {
        return a + b;
      });

  return (
    <Modal
      className={"purchase-configuration"}
      onClose={props.onClose}
      open={!!props.transaction}
      size={"small"}
    >
      <Modal.Header>
        Nastavení výdajů pro: {StringUtil.shortenWithEllipsis(StringUtil.getTransactionLabel(props.transaction), 30)}
        za {Formatter.money(props.transaction.amount)}
      </Modal.Header>
      <Modal.Content>
        <Grid>
          {props.transaction.vat > 0 && (
            <Grid.Row>
              <Grid.Column width={16}>
                <Icon
                  bordered
                  inverted
                  color="red"
                  name="percent"
                />{" "}
                <strong>DPH je {props.transaction.vat}%</strong>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column width={16}>
              <Form>
                {inputFields.map((inputField, index) => (
                  <Form.Group key={index} widths={16}>
                    <Form.Select width={6}
                      fluid
                      label="Jméno"
                      onChange={(event, data) =>
                        handleChange(event, data, index, 'owner')
                      }
                      value={inputField.owner}
                      options={ownerOptions}
                    />
                    <Form.Input width={4}
                      label={"Částka"}
                      onChange={(event, data) =>
                        handleChange(event, data, index, 'amount')
                      }
                      value={inputField.amount}
                      type={"number"}
                    />
                    <Form.Select width={4}
                      label={"Typ výdaje"}
                      onChange={(event, data) =>
                        handleChange(event, data, index, 'type')
                      }
                      value={inputField.type}
                      options={ExpenseTypeUtil.getAllOptions()}
                    />
                    {index < inputFields.length - 1 ? (
                      <Form.Button width={2} className={'remove-row'}
                        label={"."}
                        onClick={() => handleRemoveFields(index)}
                        color={"black"}
                        content={"-"}
                      />
                    ) : (
                      <Form.Button width={2} className={'add-row'}
                        label={"."}
                        onClick={() => handleAddFields()}
                        color={"black"}
                        content={"+"}
                      />
                    )}
                  </Form.Group>
                ))}
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {state.amountRemaining < 0 && (
              <Grid.Column width={16} className={"emphasis-strong"}>
                <strong>
                  Zbývá rozdělit:{" "}
                  {Formatter.money(Math.abs(state.amountRemaining))}
                </strong>
              </Grid.Column>
            )}
            {state.amountRemaining === 0 && (
              <Grid.Column width={16} className={"highlight-positive"}>
                <strong>
                  Zbývá rozdělit:{" "}
                  {Formatter.money(Math.abs(state.amountRemaining))}
                  <Icon name={"check"} />
                </strong>
              </Grid.Column>
            )}
            {state.amountRemaining > 0 && (
              <Grid.Column width={16} className={"highlight-warning"}>
                <strong>
                  Nutno ubrat:{" "}
                  {Formatter.money(Math.abs(state.amountRemaining))}
                  <Icon name={"warning"} />
                </strong>
              </Grid.Column>
            )}
            <Grid.Column width={16}>
              Firma kryje: {Formatter.money(Math.abs(companyCoversAmount))}{" "}
              {props.transaction.vat > 0 && (
                <span>
                  (z toho{" "}
                  {Formatter.money(
                    (Math.abs(props.transaction.amount) /
                      (100 + props.transaction.vat)) *
                      props.transaction.vat
                  )}{" "}
                  je DPH)
                </span>
              )}
            </Grid.Column>
            <Grid.Column width={16}>
              <strong>
                Celkem: {Formatter.money(Math.abs(props.transaction.amount))}
              </strong>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          disabled={saveState.inProgress}
          onClick={props.onClose}
        >
          Zavřít
        </Button>
        <Button
          color="red"
          loading={saveState.inProgress}
          onClick={handleSubmit}
        >
          Uložit
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
