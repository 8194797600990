import React, {useEffect, useState} from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Table,
} from "semantic-ui-react";
import SimpleLoader from "../../../components/SimpleLoader";
import { View } from "../../../base/enums/View";
import { useHistory } from "react-router-dom";
import FullTextFilter from "../../../components/base/FullTextFilter";
import {ProjectDto, useGetProjects} from '../../../api/generated-sources'
import toast from 'react-hot-toast'

export default function ProjectsViewMobile() {
  const [projects, setProjects] = useState<ProjectDto[]>([]);
  const [projectFilter, setProjectFilter] = useState<ProjectDto[]>([]);
  const history = useHistory();

  const {data, isLoading, error} = useGetProjects();

  useEffect(() => {
    if (data) {
      setProjects(data)
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error("Něco se pokazilo!");
      console.error(error)
    }
  }, [error]);

  return (
    <Container>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header>Seznam projektů</Header>
          </Grid.Column>
        </Grid.Row>
        {isLoading ? (
          <SimpleLoader text={"Načítám seznam projektů"} />
        ) : (
          <Grid.Row>
            <Grid.Column width={16}>
              <FullTextFilter
                data={projects}
                returnFilteredData={setProjectFilter}
                formatData={{
                  formatDateType: "DD. MM. yyyy",
                  activeItem: [
                    "name",
                    "contactPerson",
                    "contactPhone",
                    "contactEmail",
                    "id",
                  ],
                }}
              />
              <Table selectable className={"projects"} basic striped>
                <Table.Body>
                  {projects.filter((project) => project.archived === false)
                    .length === 0 && (
                    <Table.Row disabled>
                      <Table.Cell textAlign={"center"} colSpan={6}>
                        Žádné aktivní projekty
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
              {projectFilter.length
                ? projectFilter
                    .filter((project) => project.archived === false)
                    .map((item) => {
                      return (
                        <Table basic striped key={item.id}>
                          <Table.Body
                            onClick={() =>
                              history.push(
                                `${View.PROJECTS.path + "/" + item.id}`
                              )
                            }
                            key={item.id}
                          >
                            <Table.Row className="flex">
                              <Table.Cell>Projekt</Table.Cell>
                              <Table.Cell singleLine>{item.name}</Table.Cell>
                            </Table.Row>
                            <Table.Row className="flex">
                              <Table.Cell>Kontaktní osoba</Table.Cell>
                              <Table.Cell singleLine>{item.contactPerson}</Table.Cell>
                            </Table.Row>
                            <Table.Row className="flex">
                              <Table.Cell>Telefon</Table.Cell>
                              <Table.Cell singleLine>{item.contactPhone}</Table.Cell>
                            </Table.Row>
                            <Table.Row className="flex">
                              <Table.Cell>Email</Table.Cell>
                              <Table.Cell singleLine>{item.contactEmail}</Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      );
                    })
                : projects
                    .filter((project) => project.archived === false)
                    .map((item) => {
                      return (
                        <Table basic striped key={item.id}>
                          <Table.Body
                            onClick={() =>
                              history.push(
                                `${View.PROJECTS.path + "/" + item.id}`
                              )
                            }
                            key={item.id}
                          >
                            <Table.Row className="flex">
                              <Table.Cell>Projekt</Table.Cell>
                              <Table.Cell singleLine>{item.name}</Table.Cell>
                            </Table.Row>
                            <Table.Row className="flex">
                              <Table.Cell>Kontaktní osoba</Table.Cell>
                              <Table.Cell singleLine>{item.contactPerson}</Table.Cell>
                            </Table.Row>
                            <Table.Row className="flex">
                              <Table.Cell>Telefon</Table.Cell>
                              <Table.Cell singleLine>{item.contactPhone}</Table.Cell>
                            </Table.Row>
                            <Table.Row className="flex">
                              <Table.Cell>Email</Table.Cell>
                              <Table.Cell singleLine>{item.contactEmail}</Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      );
                    })}
              <Table.Footer>
                <Table.Row>
                  <Table.Cell colSpan={16} textAlign={"right"}>
                    <Button
                      color={"green"}
                      onClick={() =>
                        history.push(`${View.PROJECTS.path + "/new"}`)
                      }
                    >
                      <Icon name={"plus"} />
                      Přidat projekt
                    </Button>
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
              <br />
              <br />
              {projects.filter((project) => project.archived === true)
                .length !== 0 && (
                <div>
                  <h4>Archivované projekty</h4>
                  <p>
                    <Icon name={"info circle"} /> Tyto projekty nebudou
                    dostupné při tvorbě nových faktur.
                  </p>
                  <Table basic striped selectable className={"projects"}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Projekt</Table.HeaderCell>
                        <Table.HeaderCell>Kontaktní osoba</Table.HeaderCell>
                        <Table.HeaderCell>Telefon</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {projects
                        .filter((project) => project.archived === true)
                        .map((item) => {
                          return (
                            <Table.Row
                              onClick={() =>
                                history.push(
                                  `${View.PROJECTS.path + "/" + item.id}`
                                )
                              }
                              key={item.id}
                            >
                              <Table.Cell singleLine>{item.name}</Table.Cell>
                              <Table.Cell singleLine>{item.contactPerson}</Table.Cell>
                              <Table.Cell singleLine>{item.contactPhone}</Table.Cell>
                              <Table.Cell singleLine>{item.contactEmail}</Table.Cell>
                            </Table.Row>
                          );
                        })}
                    </Table.Body>
                  </Table>
                </div>
              )}
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Container>
  );
}
