import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Form, Grid, Icon } from "semantic-ui-react";
import { View } from "../../../../base/enums/View";
import Breadcrumbs from "../../../../components/base/Breadcrumbs";
import SimpleLoader from "../../../../components/SimpleLoader";
import toast from "react-hot-toast";
import {DocumentTypeValidityOptions} from "../../../../base/enums/document/DocumentTypeValidityOptions";
import {DocumentTypeNotificationOptions} from "../../../../base/enums/document/DocumentTypeNotificationOptions";
import {
  createDocumentType,
  deleteDocumentType,
  DocumentTypeDto,
  getDocumentType, updateDocumentType
} from '../../../../api/generated-sources'

// const demandsInitial: demandsInitialI = {
//   groups: [
//     {
//       key: "1",
//       text: "Zaměstnanci HPP",
//       value: "hpp",
//       required: { key: "1", text: "Nikdy nevyžadovat", value: "no" },
//     },
//     {
//       key: "2",
//       text: "Zaměstnanci DPP",
//       value: "dpp",
//       required: { key: "1", text: "Nikdy nevyžadovat", value: "no" },
//     },
//     {
//       key: "3",
//       text: "Zaměstnanci DPČ",
//       value: "dpc",
//       required: { key: "1", text: "Nikdy nevyžadovat", value: "no" },
//     },
//     {
//       key: "4",
//       text: "Cizinci",
//       value: "foreigner",
//       required: { key: "1", text: "Nikdy nevyžadovat", value: "no" },
//     },
//     {
//       key: "5",
//       text: "Kontraktoři",
//       value: "сontractors",
//       required: { key: "1", text: "Nikdy nevyžadovat", value: "no" },
//     },
//     {
//       key: "6",
//       text: "Požaduje slevu na dítě",
//       value: "saleChild",
//       required: { key: "1", text: "Nikdy nevyžadovat", value: "no" },
//     },
//     {
//       key: "7",
//       text: "Je ZTP",
//       value: "ztp",
//       required: { key: "1", text: "Nikdy nevyžadovat", value: "no" },
//     },
//     {
//       key: "8",
//       text: "Požaduje slevu na manželku",
//       value: "saleWife",
//       required: { key: "1", text: "Nikdy nevyžadovat", value: "no" },
//     },
//   ],
//   value: [
//     { key: "1", text: "Nikdy nevyžadovat", value: "no" },
//     { key: "2", text: "Vždy vyžadovat", value: "yes" },
//     { key: "3", text: "Vyžadovat jen někdy", value: "sometime" },
//   ],
// };

export const DocumentTypeDetailPage = () => {
  const [data, setData] = useState<DocumentTypeDto>({
    expiresAfterMonths: DocumentTypeValidityOptions.getOptions()[0].value as number,
    notifyDaysBeforeExpiration: DocumentTypeNotificationOptions.getOptions()[0].value as number
  });
  // const [demands, setDemands] = useState<demandsInitialI>(demandsInitial);
  const [createState, setCreateState] = useState(false);

  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const handleCreateDocument = () => {
    const type : DocumentTypeDto= {
      title: data?.title,
      notifyDaysBeforeExpiration: data?.notifyDaysBeforeExpiration === 0 ? null : data?.notifyDaysBeforeExpiration,
      expiresAfterMonths: data?.expiresAfterMonths === 0 ? null : data?.expiresAfterMonths,
      required: true,
    };

    const promise = createDocumentType(type);
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Typ dokument byl úspěšně vytvořen.",
        error: "Něco se pokazilo!",
      }
    );
    promise
      .then(data => {
        setData(data);
        history.push(View.DOCUMENT_MANAGEMENT.path);
      })
      .catch(console.error)
  };

  const handleDeleteDocument = (id: number) => {
    const promise = deleteDocumentType(+id)
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Dokument byl úspěšně smazán.",
        error: "Něco se pokazilo!",
      }
    );
    promise
      .then(() => history.push(View.DOCUMENT_MANAGEMENT.path))
      .catch(console.error)
  };

  const handleChange = (value: any, field: string) => {
    setData({
      ...data,
      [field]: value,
    });
  };

  const handleUpdateDocumentType = () => {
    const updateItem : DocumentTypeDto= {
      title: data?.title,
      notifyDaysBeforeExpiration: data?.notifyDaysBeforeExpiration === 0 ? null : data?.notifyDaysBeforeExpiration,
      expiresAfterMonths: data?.expiresAfterMonths === 0 ? null : data?.expiresAfterMonths,
      required: data?.required,
      deletable: true,
      id: +data?.id,
    };

    const promise = updateDocumentType(updateItem.id, updateItem)
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Dokument byl úspěšně upraven.",
        error: "Něco se pokazilo!",
      }
    );
    promise
      .then(data => {
              setData(data);
              history.push(View.DOCUMENT_MANAGEMENT.path);
      })
      .catch(console.error)
  };

  useEffect(() => {
    if (id !== "new") {
      getDocumentType(+id)
        .then(data => {
          setData({
            ...data,
            notifyDaysBeforeExpiration: data.notifyDaysBeforeExpiration ?? DocumentTypeNotificationOptions.getOptions()[0].value as number,
            expiresAfterMonths: data.expiresAfterMonths ?? DocumentTypeValidityOptions.getOptions()[0].value as number,
          });
        })
        .catch(reason => {
          console.error(reason.message)
        })

    } else {
      setCreateState(true);
    }
  }, [id]);

  const requiredFieldsPresent = () => {
    return !!data.title;
  };

  const displayLoader = !data;

  return (
    <>
      {displayLoader && id !== "new" ? (
        <Grid>
          <SimpleLoader text={"Načítám data"} />
        </Grid>
      ) : (
        <>
          <Breadcrumbs title={!createState ? `Typ dokumentu ${data && data?.title}` : "Nový typ dokumentu"}/>
          <br/>
          <br/>
          <div className={"invoice-detail"}>
            <Form>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Form.Group widths={15}>
                      <Form.Input
                        value={data?.title}
                        width={5}
                        required
                        type={"text"}
                        label={"Název typu dokumentu"}
                        onChange={(event: any) =>
                          handleChange(event.target.value, "title")
                        }
                      />
                      <Form.Select
                        width={5}
                        value={data.expiresAfterMonths}
                        required
                        options={DocumentTypeValidityOptions.getOptions()}
                        label={"Výchozí platnost"}
                        readOnly={!!data}
                        onChange={(event, data) =>
                          handleChange(data.value, "expiresAfterMonths")
                        }
                      />
                      <Form.Select
                        width={5}
                        value={data.notifyDaysBeforeExpiration}
                        required
                        options={DocumentTypeNotificationOptions.getOptions()}
                        label={"Notifikovat o skončení platnosti"}
                        onChange={(event, data) =>
                          handleChange(data.value, "notifyDaysBeforeExpiration")
                        }
                      />
                    </Form.Group>
                  </Grid.Column>
                </Grid.Row>
                {/*<Grid.Row>*/}
                {/*    {demands?.groups.map((item, index) => {*/}
                {/*      return (*/}
                {/*        <Grid.Column width={4} key={item.key}>*/}
                {/*          <Form.Group grouped>*/}
                {/*            <label>{item?.text}</label>*/}
                {/*            {demands?.value.map((value) => {*/}
                {/*              return (*/}
                {/*                <Form.Checkbox*/}
                {/*                  key={value.key}*/}
                {/*                  label={value.text}*/}
                {/*                  type="checkbox"*/}
                {/*                  value={value.value}*/}
                {/*                  checked={*/}
                {/*                    value.value ===*/}
                {/*                    demands?.groups[index]?.required?.value*/}
                {/*                  }*/}
                {/*                  onChange={(e, data) =>*/}
                {/*                    handleChangeDemands(*/}
                {/*                      data.value,*/}
                {/*                      item.value*/}
                {/*                    )*/}
                {/*                  }*/}
                {/*                />*/}
                {/*              );*/}
                {/*            })}*/}
                {/*          </Form.Group>*/}
                {/*        </Grid.Column>*/}
                {/*      );*/}
                {/*    })}*/}
                {/*</Grid.Row>*/}
                <Grid.Row>
                  <Grid.Column width={16}>
                    <div className="action-btn">
                    <Button
                      icon="left chevron"
                      onClick={() =>
                        history.push(View.DOCUMENT_MANAGEMENT.path)
                      }
                      className="mobile-btn"
                    />
                    <Button
                      labelPosition="left"
                      icon="left chevron"
                      content="Zpět"
                      onClick={() =>
                        history.push(View.DOCUMENT_MANAGEMENT.path)
                      }
                      className="mobile-btnHidden"
                    />
                    <div className="flex">
                      {!createState && data.deletable && (
                        <Button
                          floated={"left"}
                          color={"red"}
                          onClick={() => handleDeleteDocument(+id)}
                        >
                          <Icon name={"trash"} />
                          <span className="action-btnSpan">Smazat</span>
                        </Button>
                      )}
                      <Button
                        color={"black"}
                        disabled={!requiredFieldsPresent()}
                        onClick={() => {
                          if (createState) {
                            handleCreateDocument();
                          } else {
                            handleUpdateDocumentType();
                          }
                        }}
                      >
                        <Icon name={"save"} />
                        <span className="action-btnSpan">
                          {!createState
                            ? "Uložit změny"
                            : "Vytvořit nový typ dokumentu"}
                        </span>
                      </Button>
                    </div>
                  </div>
                  </Grid.Column>
              </Grid.Row>
            </Grid>
            </Form>
          </div>
        </>
      )}
    </>
  );
};
