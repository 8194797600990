/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
import useSwr from "swr";
import type { Key, SWRConfiguration } from "swr";
import type {
  AccountStatementDto,
  ErrorResponseResponse,
  GetAccountStatementForMonthParams,
} from "../intranetApi.schemas";
import { IntranetClientFetchInstance } from "../../intranetClientFetchInstance";
import type { ErrorType } from "../../intranetClientFetchInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get account statement for month
 */
export const getAccountStatementForMonth = (
  params: GetAccountStatementForMonthParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<AccountStatementDto>(
    { url: `/bank`, method: "GET", params },
    options
  );
};

export const getGetAccountStatementForMonthKey = (
  params: GetAccountStatementForMonthParams
) => [`/bank`, ...(params ? [params] : [])] as const;

export type GetAccountStatementForMonthQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAccountStatementForMonth>>
>;
export type GetAccountStatementForMonthQueryError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary get account statement for month
 */
export const useGetAccountStatementForMonth = <
  TError = ErrorType<ErrorResponseResponse>
>(
  params: GetAccountStatementForMonthParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getAccountStatementForMonth>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetAccountStatementForMonthKey(params) : null));
  const swrFn = () => getAccountStatementForMonth(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
