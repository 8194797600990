import {Button, Checkbox, DropdownProps, Grid, Icon, Input, List, Select} from "semantic-ui-react";
import { Formatter } from "../../../base/util/Formatter";
import { PieChart } from "react-minimal-pie-chart";
import React, { useEffect, useState } from "react";
import { SyncOperationState } from "../../../base/state/SyncOperationState";
import { VatUtil } from "../../../base/util/VatUtil";
import Breadcrumbs from "../../../components/base/Breadcrumbs";
import toast from "react-hot-toast";
import { TransactionExpensesTable } from "./TransactionExpensesTable";
import {StringUtil} from "../../../base/util/StringUtil";
import {
  deleteTransactionAttachment,
  TransactionDto,
  updateTransaction
} from '../../../api/generated-sources'
import moment from "moment";

interface TransactionDetailProps {
  transaction: TransactionDto;
  uploadStatus: SyncOperationState;
  onTransactionUpdate: (transaction: TransactionDto) => void;
  onUploadAttachment: (
    id: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
}

export default function TransactionDetail(props: TransactionDetailProps) {
  const [transaction, setTransaction] = useState<TransactionDto>(
    props.transaction
  );
  const [deleteState, setDeleteState] = useState<SyncOperationState>({
    inProgress: false,
  });
  const [updateState, setUpdateState] = useState<SyncOperationState>({
    inProgress: false,
  });

  useEffect(() => {
    setTransaction(props.transaction);
  }, [props.transaction, props]);

  const onAttachmentDeleteSuccess = () => {
    if (transaction) {
      const t = { ...transaction };
      t.attachmentUrl = undefined;

      setDeleteState({
        inProgress: false,
      });

      props.onTransactionUpdate(t);
    }
  };

  const deleteAttachment = (transaction: TransactionDto) => {
    setDeleteState({
      inProgress: true,
    });
    const promise = deleteTransactionAttachment(transaction.id);
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Doklad byl úspěšně smazaný.",
        error: "Něco se pokazilo!",
      }
    );
    promise
      .then(onAttachmentDeleteSuccess)
      .catch(reason => {
        console.error(reason);
        setDeleteState({
          inProgress: false,
        });
      })
  };

  const onUpdateTransactionSuccess = (data: TransactionDto) => {
    if (transaction) {
      const t = {
        ...transaction,
        taxDeductible: data.taxDeductible,
        requiresAttachment: data.requiresAttachment,
        vat: data.vat,
        expenses: data.expenses,
      };
      setUpdateState({
        inProgress: false,
        error: false,
      });
      props.onTransactionUpdate(t);
    }
  };

  const update = (
    transaction: TransactionDto,
    requiresAttachment: boolean,
    taxDeductible: boolean,
    vat: number
  ) => {
    setUpdateState({
      inProgress: true,
    });
    updateTransaction(transaction.id, {
      id: transaction.id,
      transactionId: transaction.transactionId,
      requiresAttachment: requiresAttachment,
      taxDeductible: taxDeductible,
      vat: vat,
    })
      .then(onUpdateTransactionSuccess)
      .catch(reason => {
        console.error(reason);
        setUpdateState({
          error: true,
          inProgress: false,
        });
      });
  };

  const getPurchaseChartData = (t: TransactionDto) => {
    if (typeof t === "undefined") {
      return [];
    }
    let amountCovered = 0;
    const data = transaction.expenses.map((expense) => {
      const title = expense.owner.fullname.split(" ")[1];
      const value = expense.amount;
      amountCovered += value;
      const color = ["#0AE1F3", "#18E912", "#FFF718", "#FFA500"];
      return { title: title, value: value, color: color[expense.owner.id % 4] };
    });

    if (Math.abs(t.amount - amountCovered) > 1) {
      data?.push({
        title: "Firma",
        value: t.amount - amountCovered,
        color: t.amount > 0 ? "#21AD1C" : "#ED1C24",
      });
    }
    return data;
  };

  const getInOutLabel = () => {
    if (transaction) {
      if (transaction.amount > 0) {
        return "příchozí";
      } else if (transaction.amount < 0) {
        return "odchozí";
      }
    }
  }

  return (
    <>
      <Breadcrumbs title={`Detail ${getInOutLabel()} platby ${StringUtil.getTransactionLabel(props.transaction)}`} />
      {transaction && (
        <>
          <Grid>
            <div className="flex-transaction">
              <Grid.Column>
                <List>
                  {transaction.date && (
                    <List.Item>
                      <strong>Datum:</strong> {moment(transaction.date).format("D. M. YYYY")}
                    </List.Item>
                  )}
                  {transaction.amount && (
                    <List.Item>
                      <strong>Částka:</strong>{" "}
                      {Formatter.money(transaction.amount)}
                    </List.Item>
                  )}
                  {transaction.authorizedBy && (
                    <List.Item>
                      <strong>Autorizoval:</strong> {transaction.authorizedBy}
                    </List.Item>
                  )}
                  {transaction.type && (
                    <List.Item>
                      <strong>Typ platby:</strong> {transaction.type}
                    </List.Item>
                  )}
                  {transaction.comment && (
                    <List.Item>
                      <strong>Poznámka:</strong> {transaction.comment}
                    </List.Item>
                  )}
                  {transaction.messageForRecipient && (
                    <List.Item>
                      <strong>Zpráva pro příjemce:</strong> {transaction.messageForRecipient}
                    </List.Item>
                  )}
                  {transaction.counterAccountName && (
                    <List.Item>
                      <strong>Platil:</strong> {transaction.counterAccountName}
                    </List.Item>
                  )}
                  {transaction.counterAccount && (
                    <List.Item>
                      <strong>Číslo účtu:</strong> {transaction.counterAccount}
                    </List.Item>
                  )}
                  {transaction.vs && (
                    <List.Item>
                      <strong>Variabilní symbol:</strong> {transaction.vs}
                    </List.Item>
                  )}
                  {transaction.ss && (
                    <List.Item>
                      <strong>Specifický symbol:</strong> {transaction.ss}
                    </List.Item>
                  )}
                </List>
                <List relaxed>
                  {!transaction.attachmentUrl && (
                    <List.Item>
                      <Button
                        loading={props.uploadStatus.inProgress}
                        icon
                        onClick={() =>
                          window.document
                            .getElementById("uploadAttachmentInput")
                            ?.click()
                        }
                        labelPosition="right"
                      >
                        Nahrát doklad <Icon name="upload" />
                      </Button>
                    </List.Item>
                  )}
                  {props.uploadStatus.error ? (
                    <List.Item className={"highlight-warning"}>
                      Limit 100MB (formáty .png, .jpg, .pdf){" "}
                      <Icon name={"warning"} />{" "}
                    </List.Item>
                  ) : (
                    <List.Item>
                      Limit 100MB (formáty .png, .jpg, .pdf)
                    </List.Item>
                  )}
                  {!!transaction.attachmentUrl && (
                    <List.Item>
                      <a
                        href={transaction.attachmentUrl}
                        target={"_blank"}
                        rel="noopener noreferrer"
                      >
                        <Button color={"black"} icon labelPosition="left">
                          <Icon name="download" /> Stáhnout doklad
                        </Button>
                      </a>
                      {!transaction.invoice && (
                        <Button
                          basic
                          icon={"trash"}
                          color={"red"}
                          loading={deleteState.inProgress}
                          onClick={() => deleteAttachment(transaction)}
                        />
                      )}
                    </List.Item>
                  )}
                  <List.Item>
                    <input
                      hidden
                      id={"uploadAttachmentInput"}
                      type={"file"}
                      accept="image/png, image/jpeg, application/pdf"
                      onChange={(event) =>
                        props.onUploadAttachment(transaction.id, event)
                      }
                    />
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column className="wrapp-table-detailTransaction">
                {transaction.expenses.length < 2 ? (
                  <PieChart
                    data={getPurchaseChartData(transaction)}
                    radius={44}
                    lineWidth={20}
                    paddingAngle={2}
                    labelPosition={70}
                    segmentsStyle={{
                      transition: "stroke .4s",
                      cursor: "pointer",
                    }}
                    animate
                    label={({ dataEntry }) =>
                      dataEntry.title + ": " + Formatter.money(dataEntry.value)
                    }
                    labelStyle={{
                      fill: "#111",
                      fontSize: "5px",
                      fontWeight: "bold",
                      fontFamily: "sans-serif",
                    }}
                  />
                ) : (
                  <TransactionExpensesTable data={transaction?.expenses} />
                )}
              </Grid.Column>
            </div>
            <Grid.Row>
              <Grid.Column>
                <p>
                  <strong>DPH</strong>
                </p>
                {
                  new Date(transaction.date).getFullYear() < new Date().getFullYear() ?
                    <Input label={"DPH"} disabled={true} value={transaction.vat + "%"} />
                    :
                    <Select
                    disabled={updateState.inProgress}
                    label="DPH"
                    options={VatUtil.getVatOptions()}
                    value={transaction.vat}
                    onChange={(e, data: DropdownProps) =>
                      update(
                        transaction,
                        transaction.requiresAttachment,
                        transaction.taxDeductible,
                        typeof data.value === "number" ? data.value : 0
                      )
                    }
                  />
                }
              </Grid.Column>
            </Grid.Row>
            {transaction.amount < 0 && (
              <Grid.Row>
                <Grid.Column>
                  <Checkbox
                    disabled={updateState.inProgress}
                    onClick={() =>
                      update(
                        transaction,
                        transaction.requiresAttachment,
                        !transaction.taxDeductible,
                        transaction.vat
                      )
                    }
                    checked={transaction.taxDeductible}
                    label="Daňově uznatelný náklad"
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Column>
                <Checkbox
                  disabled={updateState.inProgress}
                  onClick={() =>
                    update(
                      transaction,
                      !transaction.requiresAttachment,
                      transaction.taxDeductible,
                      transaction.vat
                    )
                  }
                  checked={transaction.requiresAttachment}
                  label="Vyžaduje doklad"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      )}
    </>
  );
}
