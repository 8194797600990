import {Button, Form, Icon, List, Modal} from "semantic-ui-react";
import {useParams} from "react-router-dom";
import toast from "react-hot-toast";
import {StringUtil} from "../../../base/util/StringUtil";
import React, {useEffect, useState} from "react";
import {SyncOperationState} from "../../../base/state/SyncOperationState";
import {FileUploadUtil} from "../../../base/util/FileUploadUtil";
import {
  createPayrollDeduction, deletePayrollDeduction, deletePayrollDeductionAttachment, EDocumentType,
  FileAttachmentDto,
  PayrollDeductionInputDto, updatePayrollDeduction,
} from '../../../api/generated-sources'
import {LocalDate} from '../../../build/core/dto/LocalDate'

type PayrollDeductionTableModalProps = {
  selectedRow: PayrollDeductionInputDto;
  close?: () => void;
  isOpen?: boolean;
  handleUpdate: () => void;
  handleDelete: (id: number) => void;
};

export function PayrollDeductionTableModal(props: PayrollDeductionTableModalProps) {
  const {
    selectedRow,
    isOpen,
    close,
    handleUpdate,
    handleDelete,
  } = props;

  const { id } = useParams<{ id: string }>();
  const [payrollDeduction, setPayrollDeduction] = useState<PayrollDeductionInputDto>(selectedRow);
  const [syncState, setSyncState] = useState<SyncOperationState>({
    inProgress: false,
  });
  const [deleteState, setDeleteState] = useState<SyncOperationState>({
    inProgress: false,
  });

  const deleteAttachment = (id: number) => {
    setDeleteState({inProgress: true, type: "delete"});
    const promise = deletePayrollDeductionAttachment(id)
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Dokument byl úspěšně smazán.",
        error: "Něco se pokazilo!",
      }
    );
    promise.then(data => {
      setDeleteState({inProgress: false, type: undefined});
      handleUpdate();
    }).catch(console.error);
  }

  const handleChangeAttachment = (attachment: FileAttachmentDto) => {
    setPayrollDeduction({
      ...payrollDeduction,
      attachment: attachment,
    });
  };

  const handleChange = (value: any, field: string) => {
    setPayrollDeduction({
      ...payrollDeduction,
      [field]: value,
    });
  };

  useEffect(() => {
    setSyncState({inProgress: false});
  }, [isOpen]);

  useEffect(() => {
    setPayrollDeduction({
      ...selectedRow,
      validFrom: selectedRow.validFrom ?? new LocalDate((new Date()).getFullYear(), (new Date()).getMonth(), 1).toString()
    });
  }, [selectedRow]);

  const create = () => {
    setSyncState({inProgress: true});
    const newItem: PayrollDeductionInputDto = {
      validFrom: payrollDeduction.validFrom ? payrollDeduction.validFrom : new LocalDate(new Date()).toString(),
      validTo: payrollDeduction.validTo,
      documentType: EDocumentType.DOHODA_O_SRAZKACH_ZE_MZDY,
      attachment: payrollDeduction.attachment,
      note: payrollDeduction.note,
      amount: payrollDeduction.amount,
      userId: +id,
    };

    const promise = createPayrollDeduction(newItem);
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Záznam byl úspěšně vytvořen.",
        error: "Něco se pokazilo!",
      }
    ).then(() => close());
    promise.then(data => {
      setSyncState({inProgress: false, type: undefined});
      handleUpdate();
      close();
    }).catch(console.error)
  };

  const update = () => {
    setSyncState({inProgress: true});
    const updateItem = {
      id: payrollDeduction.id,
      amount: payrollDeduction.amount,
      note: payrollDeduction.note,
      userId: +id,
      attachment: payrollDeduction.attachment,
      documentType: EDocumentType.DOHODA_O_SRAZKACH_ZE_MZDY,
      validFrom: payrollDeduction.validFrom,
      validTo: payrollDeduction.validTo ? payrollDeduction.validTo : null,
    };
    const promise = updatePayrollDeduction(updateItem.id, updateItem);
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Záznam byl úspěšně upraven.",
        error: "Něco se pokazilo!",
      }
    ).then(() => close());
    promise
      .then(data => {
        setSyncState({inProgress: false, type: undefined});
        handleUpdate();
        close();
      })
      .catch(console.error)
  }

  const handleDeletePayrollDeduction = () => {
    setSyncState({inProgress: true, type: "delete"});
    const promise = deletePayrollDeduction(payrollDeduction.id);
    toast.promise(promise,
      {
        loading: "Zpracovávám...",
        success: "Záznam byl úspěšně smazán.",
        error: "Něco se pokazilo!",
      }
    ).then(() => close());
    promise.then(data => {
      setSyncState({inProgress: false, type: undefined});
      handleDelete(payrollDeduction.id);
      close();
    }).catch(console.error);
  };

  const requiredFieldsPresent = () => {
    return payrollDeduction.amount && !!payrollDeduction.validFrom;
  };

  return (
    <Modal
      className={"invoice-detail"}
      onClose={close}
      open={isOpen}
      size={"small"}
      closeIcon
    >
      <Modal.Header>
        <Icon name={'edit'} /> {payrollDeduction.id ? "Úprava srážky ze mzdy" : "Nová srážka ze mzdy"}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths={2}>
            <Form.Input
              required
              type={"number"}
              width={8}
              label={"Měsíční částka"}
              onChange={(e) => handleChange(e?.target.value, "amount")}
              value={payrollDeduction.amount}
            />
            <Form.Input
              width={8}
              label={"Poznámka"}
              onChange={(e) => handleChange(e?.target.value, "note")}
              value={payrollDeduction.note}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Input
              label="Platnost od"
              required
              type="date"
              value={payrollDeduction.validFrom}
              onChange={(e) => handleChange(e?.target.value, "validFrom")}
            />
            <Form.Field>
              <label>Platnost do</label>
              <Form.Input
                type="date"
                value={payrollDeduction.validTo}
                onChange={(e) => handleChange(e?.target.value, "validTo")}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths={2}>
            <List relaxed className={"formUpload"}>
              {!payrollDeduction.documentUrl && (
                <List.Item>
                  <Button disabled={syncState.inProgress} icon labelPosition="right"
                    onClick={() =>
                      window.document.getElementById("uploadAttachmentInput")?.click()
                    }
                  >
                  {payrollDeduction.documentUrl ? "Zobrazit dokument" : payrollDeduction.attachment ? StringUtil.shortenWithEllipsis(payrollDeduction.attachment.filename, 27) : "Nahrát dokument"} <Icon name="upload" />
                  </Button>
                </List.Item>
              )}
              {
                !payrollDeduction.documentUrl && <List.Item className={syncState.error ? "highlight-warning" : null}>
                  Limit 100MB (formáty .png, .jpg, .pdf) {syncState.error && <Icon name={"warning"} />}
                </List.Item>
              }
              {!!payrollDeduction.documentUrl && (
                <List.Item>
                  <Button color={"black"} icon labelPosition="left" onClick={() => window.open(payrollDeduction.documentUrl)}>
                    <Icon name="download" /> Zobrazit dokument
                  </Button>
                  <Button basic icon={"trash"} color={"red"} loading={deleteState.inProgress} onClick={(e) => deleteAttachment(payrollDeduction.id)} />
                </List.Item>
              )}
              <List.Item>
                  <input
                    hidden
                    id={"uploadAttachmentInput"}
                    type={"file"}
                    accept="image/png, image/jpeg, application/pdf"
                    onChange={(event) => FileUploadUtil.setAttachment(event, handleChangeAttachment, setSyncState)}
                  />
              </List.Item>
            </List>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions className="button-ppv">
        {payrollDeduction.id ? <>
            <Button color={"red"} floated="left" type="submit" icon={"trash alternate"}
                    loading={syncState.inProgress && syncState.type === "delete"}
                    disabled={syncState.inProgress}
                    onClick={handleDeletePayrollDeduction} />
            <Button color={"black"} floated="right" type="submit"
                    loading={syncState.inProgress && syncState.type !== "delete"}
                    disabled={syncState.inProgress || !requiredFieldsPresent()}
                    onClick={update}
            >
              <Icon name={"save"} /> Uložit změny
            </Button>
          </> : <Button color={"black"} floated="right" type="submit"
                        onClick={create}
                        loading={syncState.inProgress && syncState.type !== "delete"}
                        disabled={syncState.inProgress || !requiredFieldsPresent()}>
            <Icon name={"save"} /> Vytvořit
          </Button>
        }
      </Modal.Actions>
    </Modal>
  );
}
