export interface DynamicConfig {
  BASE_URL: string;
  AZURE_AD_ENVIRONMENT: string;
  AZURE_AD_TENANT_ID: string;
  AZURE_AD_CLIENT_ID: string;
  LOGIN_TYPE: "aad" | "local";
  ENVIRONMENT: "TEST" | "PROD";
  START_YEAR: number;
  START_MONTH: number;
}

export const defaultConfig: DynamicConfig = {
  BASE_URL: "https://intranet-test-cloud.redrobot.cz",
  AZURE_AD_ENVIRONMENT: "login.microsoftonline.com",
  AZURE_AD_TENANT_ID: "b7cf220a-92a4-4dfc-ad83-aa5f2829351d",
  AZURE_AD_CLIENT_ID: "aaaaaaaaaaaaaaaaaaaa",
  LOGIN_TYPE: "aad",
  ENVIRONMENT: "TEST",
  START_YEAR: 2020,
  START_MONTH: 5,
};

class Config {
  env: DynamicConfig = defaultConfig;
}

export const config = new Config();

export const configUrl = "/config.json";
