import React, { useContext, useEffect, useState } from "react";
import SimpleLoader from "../../../components/SimpleLoader";
import { Form, Select, SemanticWIDTHS } from "semantic-ui-react";
import { FilterContext, FilterValue } from "../../../contexts/FilterContext";
import {useGetInvoiceSubjects} from '../../../api/generated-sources'

interface InvoiceSubjectSelectorProps {
  onSubjectChange: (id: number) => void;
  nothingSelectedText: string;
  required?: boolean;
  value?: number;
  label?: string;
  emptyItem?: boolean;
  width?: SemanticWIDTHS;
}

export default function InvoiceSubjectSelector(
  props: InvoiceSubjectSelectorProps
) {
  const [selected, setSelected] = useState(-1);
  const { setInvoicePurchaser } = useContext<FilterValue>(FilterContext);

  const handleSelectChange = (e: any, data?: any) => {
    setSelected(data?.value);
    setInvoicePurchaser(data?.value);
    props.onSubjectChange(data?.value);
  };

  useEffect(() => {
    if (props.value) {
      setSelected(props.value);
    }
  }, [props.value]);

  const {data, isLoading} = useGetInvoiceSubjects();

  const subjects = data
    ? data.filter((item) => !item.archived)
      .map((item) => {
        return {
          key: item.id,
          text: item.name,
          value: item.id,
        };
      })
    : [];

  if (props.emptyItem) {
    subjects?.push({
      key: null,
      text: props.nothingSelectedText,
      value: null,
    });
  }

  if (isLoading) {
    return <SimpleLoader text={"loading"} />;
  }

  return (
    <>
      <Form.Field
        width={props.width ? props.width : 8}
        required={props.required ?? false}
        label={props.label}
        control={Select}
        options={subjects ? subjects : null}
        value={selected}
        onChange={(e: any, data: any) => handleSelectChange(e, data)}
        placeholder={props.nothingSelectedText}
        search
      />
    </>
  );
}
