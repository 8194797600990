import { View } from "./View";
import { Module } from "./Module";

export class ModuleViews {
  public static DASHBOARD: View[] = [View.DASHBOARD];
  public static FINANCE: View[] = [
    View.TRANSACTIONS,
    View.PLANNED_TRANSACTIONS,
    View.SUMMARY,
    View.LOANS,
    View.WORK,
  ];
  public static INVOICE: View[] = [View.INVOICES, View.INVOICES_SUBJECTS, View.PROJECTS];
  public static USER_MANAGEMENT: View[] = [
    View.USER_MANAGEMENT,
    View.ROLE_MANAGEMENT,
  ];
  public static PERSON: View[] = [View.PERSON];
  public static HR_MANAGER: View[] = [
    View.MY_OVERVIEW,
    View.EMPLOYEES_MANAGEMENT,
    View.DOCUMENT_MANAGEMENT,
  ];

  static getViews(module: string): View[] {
    if (module === Module.FINANCE) {
      return ModuleViews.FINANCE;
    }
    if (module === Module.INVOICING) {
      return ModuleViews.INVOICE;
    }
    if (module === Module.USER_MANAGEMENT) {
      return ModuleViews.USER_MANAGEMENT;
    }
    if (module === Module.PERSON) {
      return ModuleViews.PERSON;
    }
    if (module === Module.HR_MANAGER) {
      return ModuleViews.HR_MANAGER;
    }
    return ModuleViews.DASHBOARD;
  }
}
