import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Icon, Image, Label, Menu } from "semantic-ui-react";
import { Module } from "../../base/enums/Module";
import Translate from "../translation/Translate";
import Navigation from "../../base/locale/translations/Navigation";
import { Link } from "react-router-dom";
import { View } from "../../base/enums/View";
import { FilterContext, FilterValue } from "../../contexts/FilterContext";
import { Auth, AuthContext } from "../../contexts/AuthContext";
import { Role } from "../../base/enums/Role";
import { LoginType } from "../../base/enums/LoginType";
import { config } from "../../base/config/DynamicConfig";
import {useMsal} from "@azure/msal-react";

interface SideNavigationProps {
  module: string;
  onModuleChange: (item: string) => void;
  isToggleExpanded: Dispatch<SetStateAction<boolean>>;
  isScroll: Dispatch<SetStateAction<boolean>>;
}

export default function SideNavigation(props: SideNavigationProps) {
  const { isScroll } = props;
  const { month, year } = useContext<FilterValue>(FilterContext);
  const auth = useContext<Auth>(AuthContext);
  const [isHoverExpanded, setIsHoverExpanded] = useState(false);
  const [isToggleExpanded, setIsToggleExpanded] = useState(true);
  const [burgerMenu, setBurgerMenu] = useState(true);
  const { instance, accounts } = useMsal();

  useEffect(() => {
    props.isToggleExpanded(isToggleExpanded);
  }, [isToggleExpanded, props]);

  useEffect(() => {
    isScroll(burgerMenu);
  }, [burgerMenu, isScroll]);

  const logout = () => {
    auth.setToken(undefined);
    instance.logout({
      account: accounts[0]
    });
  };
  const handleCloseMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLDivElement;

    if (target.className === "item") {
      setBurgerMenu(!burgerMenu);
    }
  };

  const getResize = () => {
    if (window.innerWidth <= 1100) {
      setIsToggleExpanded(false);
      setBurgerMenu(false);
    } else {
      setIsToggleExpanded(true);
    }
  };

  useEffect(() => {
    getResize();

    window.addEventListener("resize", getResize);

    return () => {
      window.removeEventListener("resize", getResize);
    };
  }, []);

  if (isToggleExpanded || burgerMenu) {
    return (
      <div className="shadow-box">
        <div className="menu-burger">
          <Icon
            className={"bars"}
            name={"bars"}
            alt={"menu"}
            onClick={() => setBurgerMenu(!burgerMenu)}
          />
        </div>
        <Menu
          className={"side-navigation"}
          color={"red"}
          vertical
          onMouseEnter={() => {
            if (!isHoverExpanded) setIsHoverExpanded(true);
          }}
          onMouseLeave={() => {
            if (isHoverExpanded) setIsHoverExpanded(false);
          }}
        >
          <div
            className={burgerMenu ? "menu-wrapp active" : "menu-wrapp"}
            onClick={(e) => handleCloseMenu(e)}
          >
            <div className="menu-burgerClose">
              <Icon
                className={"close"}
                name={"close"}
                alt={"menu close"}
                onClick={() => setBurgerMenu(!burgerMenu)}
              />
            </div>
            {config.env.BASE_URL.includes("test") && (
              <Label color={"violet"} className={"test-flag"}>
                <Icon
                  name={
                    config.env.BASE_URL.includes("cloud") ? "cloud" : "disk"
                  }
                />
                TEST ENVIRONMENT
              </Label>
            )}
            {config.env.BASE_URL.includes("localhost") && (
              <Label color={"teal"} className={"test-flag"}>
                <Icon name={"computer"} />
                LOCAL ENVIRONMENT
              </Label>
            )}
            <Link
              className="text-link"
              to={View.DASHBOARD.path}
              onClick={() => props.onModuleChange(Module.DASHBOARD)}
            >
              <div
                className={"icon-bar"}
                style={{
                  height: "200px",
                }}
              >
                <Image src={"/redrobot.svg"} />
              </div>
            </Link>
            <Link
              className="text-link"
              to={View.DASHBOARD.path}
              onClick={() => props.onModuleChange(Module.DASHBOARD)}
            >
              <div
                className={
                  props.module === Module.DASHBOARD ? "active item" : "item"
                }
              >
                <strong>
                  <Translate label={Navigation.DASHBOARD} />
                </strong>{" "}
                <Icon name={"dashboard"} />
              </div>
            </Link>

            {(auth.hasRole(Role.INVOICE) || auth.isAdmin()) && (
              <Link
                className="text-link"
                to={View.INVOICES.path}
                onClick={() => props.onModuleChange(Module.INVOICING)}
              >
                <div
                  className={
                    props.module === Module.INVOICING ? "active item" : "item"
                  }
                >
                  <strong>
                    <Translate label={Navigation.INVOICING} />
                  </strong>{" "}
                  <Icon name={"book"} />
                </div>
              </Link>
            )}

            {(auth.hasRole(Role.TRANSACTION) || auth.isAdmin()) && (
              <Link
                className="text-link"
                to={View.TRANSACTIONS.path + "?y=" + year + "&m=" + month}
                onClick={() => props.onModuleChange(Module.FINANCE)}
              >
                <div
                  className={
                    props.module === Module.FINANCE ? "active item" : "item"
                  }
                >
                  <strong>
                    <Translate label={Navigation.FINANCE} />
                  </strong>{" "}
                  <Icon name={"money bill alternate outline"} />
                </div>
              </Link>
            )}

            {config.env.LOGIN_TYPE === LoginType.LOCAL &&
              (auth.hasRole(Role.HR_MANAGER) || auth.isAdmin()) && (
                <Link
                  className="text-link"
                  to={View.USER_MANAGEMENT.path}
                  onClick={() => props.onModuleChange(Module.USER_MANAGEMENT)}
                >
                  <div
                    className={
                      props.module === Module.USER_MANAGEMENT
                        ? "active item"
                        : "item"
                    }
                  >
                    <strong>
                      <Translate label={Navigation.USER_MANAGEMENT} />
                    </strong>{" "}
                    <Icon name={"users"} />
                  </div>
                </Link>
              )}

            <Link
              className="text-link"
              to={View.MY_OVERVIEW.path}
              onClick={() => props.onModuleChange(Module.HR_MANAGER)}
            >
              <div
                className={
                  props.module === Module.HR_MANAGER ? "active item" : "item"
                }
              >
                <strong>
                  <Translate label={Navigation.HR_MANAGER} />
                </strong>{" "}
                <Icon name={"address book"} />
              </div>
            </Link>

            <div className={"spacer"} />

            <Menu.Item name="user">
              <strong>{auth.name}</strong>
              <Icon
                className={"action"}
                name={"log out"}
                alt={"Log out"}
                onClick={() => logout()}
              />
            </Menu.Item>
          </div>
        </Menu>
      </div>
    );
  } else {
    return (
      <Menu
        className={"side-navigation"}
        color={"red"}
        vertical
        style={{
          width: "3em",
        }}
        onMouseEnter={() => {
          if (!isHoverExpanded) setIsHoverExpanded(true);
        }}
        onMouseLeave={() => {
          if (isHoverExpanded) setIsHoverExpanded(false);
        }}
      >
        <div className="menu-burger">
          <Icon
            className={"bars"}
            name={"bars"}
            alt={"menu"}
            onClick={() => setBurgerMenu(!burgerMenu)}
          />
        </div>
        <div className={"menu-wrappTop"}>
          {config.env.BASE_URL.includes("test") && (
            <Label color={"violet"} className={"test-flag-collapsed"}>
              <Icon name={"flag"} />
            </Label>
          )}
          {config.env.BASE_URL.includes("localhost") && (
            <Label color={"teal"} className={"test-flag-collapsed"}>
              <Icon name={"flag"} />
            </Label>
          )}
          <Link
            className="text-link"
            to={View.DASHBOARD.path}
            onClick={() => props.onModuleChange(Module.DASHBOARD)}
          >
            <div
              className={"icon-bar"}
              style={{
                padding: "0px 3px",
                height: "200px",
              }}
            >
              <Image src={"/redrobot.svg"} />
            </div>
          </Link>
          <div className={"angle-container-collapsed"}>
            {!isToggleExpanded && isHoverExpanded && (
              <Icon
                className={"action angle"}
                name={"angle right"}
                alt={"Log out"}
                onClick={() => setIsToggleExpanded(true)}
              />
            )}
          </div>
          <Link
            className="text-link"
            to={View.DASHBOARD.path}
            onClick={() => props.onModuleChange(Module.DASHBOARD)}
          >
            <div
              className={
                props.module === Module.DASHBOARD ? "active item" : "item"
              }
            >
              <Icon
                name={"dashboard"}
                style={{ float: "none", marginLeft: "-0.5em" }}
              />
            </div>
          </Link>

          {(auth.hasRole(Role.INVOICE) || auth.isAdmin()) && (
            <Link
              className="text-link"
              to={View.INVOICES.path}
              onClick={() => props.onModuleChange(Module.INVOICING)}
            >
              <div
                className={
                  props.module === Module.INVOICING ? "active item" : "item"
                }
              >
                <Icon
                  name={"book"}
                  style={{ float: "none", marginLeft: "-0.5em" }}
                />
              </div>
            </Link>
          )}

          {(auth.hasRole(Role.TRANSACTION) || auth.isAdmin()) && (
            <Link
              className="text-link"
              to={View.TRANSACTIONS.path + "?y=" + year + "&m=" + month}
              onClick={() => props.onModuleChange(Module.FINANCE)}
            >
              <div
                className={
                  props.module === Module.FINANCE ? "active item" : "item"
                }
              >
                <Icon
                  name={"money bill alternate outline"}
                  style={{ float: "none", marginLeft: "-0.5em" }}
                />
              </div>
            </Link>
          )}

          {config.env.LOGIN_TYPE === LoginType.LOCAL &&
            (auth.hasRole(Role.HR_MANAGER) || auth.isAdmin()) && (
              <Link
                className="text-link"
                to={View.USER_MANAGEMENT.path}
                onClick={() => props.onModuleChange(Module.USER_MANAGEMENT)}
              >
                <div
                  className={
                    props.module === Module.USER_MANAGEMENT
                      ? "active item"
                      : "item"
                  }
                >
                  <strong>
                    <Translate label={Navigation.USER_MANAGEMENT} />
                  </strong>{" "}
                  <Icon
                    name={"users"}
                    style={{ float: "none", marginLeft: "-0.5em" }}
                  />
                </div>
              </Link>
            )}
          <div className={"spacer"} />

          <Menu.Item name="user">
            <Icon
              className={"action"}
              name={"log out"}
              alt={"Log out"}
              onClick={() => logout()}
            />
          </Menu.Item>
        </div>
      </Menu>
    );
  }
}
