import {
  Button,
  Grid,
  Header,
  Icon,
  Table,
} from "semantic-ui-react";
import { useEffect, useMemo, useState } from "react";
import SimpleLoader from "../../../../components/SimpleLoader";
import { View } from "../../../../base/enums/View";
import { useHistory } from "react-router-dom";
import FullTextFilter from "../../../../components/base/FullTextFilter";
import {DocumentTypeDto, getDocumentTypes} from '../../../../api/generated-sources'

export function DocumentManagementView() {
  const [documents, setDocuments] = useState<DocumentTypeDto[]>();
  const [filter, setFilter] = useState<DocumentTypeDto[]>();

  const [column, setColumn] = useState<string>();
  const [direction, setDirection] = useState<"ascending" | "descending">(
    "ascending"
  );

  const history = useHistory();

  const reloadTable = () => {
    getDocumentTypes({
      page: 0,
    })
      .then(data => setDocuments(data.documentTypes))
      .catch(reason => console.error(reason.message));
  }

  useEffect(() => {
    reloadTable();
  }, []);

  const handleSort = (clickedColumn: string) => {
    setFilter([])
    if (column === clickedColumn) {
      setDirection(direction === "ascending" ? "descending" : "ascending");
    } else {
      setColumn(clickedColumn);
      setDirection("ascending");
    }
  };

  const sortedData = useMemo(() => {
    if (documents) {
      const sorted = [...documents]?.sort((a, b) => {
        const columnType = typeof a[column as keyof DocumentTypeDto];
        if (columnType === "string" || column === "birthDate") {
          return direction === "ascending"
            ? a[column as keyof DocumentTypeDto]
                .toString()
                .localeCompare(b[column as keyof DocumentTypeDto].toString())
            : b[column as keyof DocumentTypeDto]
                .toString()
                .localeCompare(a[column as keyof DocumentTypeDto].toString());
        } else {
          let numA = !a[column as keyof DocumentTypeDto] ? 0 : Number(a[column as keyof DocumentTypeDto]);
          let numB = !b[column as keyof DocumentTypeDto] ? 0 : Number(b[column as keyof DocumentTypeDto]);
          let diff = numA - numB;
          return direction === "ascending" ? diff : -diff;
        }
      });
      return sorted;
    }
  }, [column, direction]);

  const getDocumentTypeRow = (item : DocumentTypeDto) => {
    return <Table.Row
        key={item?.id}
        onClick={() =>
          history.push(
            View.DOCUMENT_MANAGEMENT.path + "/" + item.id
          )
        }
      >
        <Table.Cell>{item.title}</Table.Cell>
        <Table.Cell textAlign={'center'}><Icon name={item.required ? 'checkmark' : 'box'} /></Table.Cell>
        {(!item.expiresAfterMonths || item.expiresAfterMonths === 0) && <Table.Cell textAlign={'center'}>Neomezená</Table.Cell>}
        {(item.expiresAfterMonths % 12 === 0 && item.expiresAfterMonths > 0) && <Table.Cell textAlign={'center'}>{item.expiresAfterMonths / 12} {item.expiresAfterMonths > 12 ? 'roky' : 'rok'}</Table.Cell>}
        {(item.expiresAfterMonths % 12 !== 0 && item.expiresAfterMonths > 0) && <Table.Cell textAlign={'center'}>{item.expiresAfterMonths} {item.expiresAfterMonths > 1 ? 'měsíců' : 'měsíc'}</Table.Cell>}
        {(!item.notifyDaysBeforeExpiration || item.notifyDaysBeforeExpiration === 0) && <Table.Cell textAlign={'center'}>Ne</Table.Cell>}
        {(item.notifyDaysBeforeExpiration > 0 && item.notifyDaysBeforeExpiration % 30 === 0) && <Table.Cell textAlign={'center'}>{item.notifyDaysBeforeExpiration / 30} {item.notifyDaysBeforeExpiration > 30 ? 'měsíce' : 'měsíc'}</Table.Cell>}
        {(item.notifyDaysBeforeExpiration > 0 && item.notifyDaysBeforeExpiration % 7 === 0) && <Table.Cell textAlign={'center'}>{item.notifyDaysBeforeExpiration / 7} {item.notifyDaysBeforeExpiration > 7 ? 'týdny' : 'týden'}</Table.Cell>}
      </Table.Row>
  }

  const displayLoader = !documents;

  return (
    <>
      {displayLoader ? (
        <Grid>
          <SimpleLoader text={"Načítám přednastavené dokumenty"} />
        </Grid>
      ) : (
        <Grid>
          <Grid.Column width={16}>
            <Header>Typy přednastavených dokumentů</Header>
          </Grid.Column>
          <Grid.Row width={8}>
            <Grid.Column width={8}>
              <div>
                <FullTextFilter
                  data={documents}
                  formatData={{
                    formatDateType: "DD. MM. yyyy",
                    activeItem: ["expiresAfterMonths", "id", "title"],
                  }}
                  returnFilteredData={setFilter}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Column width={16}>
            <Table selectable sortable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={column === "title" ? direction : null}
                    onClick={() => {
                      setColumn("title");
                      handleSort("title");
                    }}
                  >
                    Název typu dokumentu
                  </Table.HeaderCell>
                  <Table.HeaderCell className="mobile-align" textAlign="center">
                    Vždy vyžadován
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="center"
                    sorted={column === "expiresAfterMonths" ? direction : null}
                    onClick={() => {
                      setColumn("expiresAfterMonths");
                      handleSort("expiresAfterMonths");
                    }}
                  >
                    Výchozí platnost
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    textAlign="center"
                    sorted={column === "notifyDaysBeforeExpiration" ? direction : null}
                    onClick={() => {
                      setColumn("notifyDaysBeforeExpiration");
                      handleSort("notifyDaysBeforeExpiration");
                    }}
                  >
                    Notifikace před vypršením
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {documents?.length === 0 && (
                  <Table.Row>
                    <Table.Cell textAlign={"center"} colSpan={7}>
                      Žádné dokumenty
                    </Table.Cell>
                  </Table.Row>
                )}
                {filter?.length
                  ? filter?.map(getDocumentTypeRow)
                  : sortedData
                  ? sortedData
                      .map(getDocumentTypeRow)
                  : documents
                      .map(getDocumentTypeRow)
                }
              </Table.Body>
              <Table.Footer fullWidth>
                <Table.Row
                  className="tr-add"
                  onClick={() =>
                    history.push(View.DOCUMENT_MANAGEMENT.path + "/new")
                  }
                >
                  <Table.HeaderCell colSpan="5" className="new-btn">
                    <Button
                      floated="right"
                      icon
                      labelPosition="left"
                      positive
                      size="small"
                    >
                      <Icon name="add" /> Nový typ dokumentu
                    </Button>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Grid.Column>
        </Grid>
      )}
    </>
  );
}
