import React, { useEffect } from "react";
import "./App.css";
import LoginLocal from "./components/login/LoginLocal";
import LoginAAD from "./components/login/LoginAAD";
import AuthProvider from "./contexts/AuthContext";
import { LoginType } from "./base/enums/LoginType";
import { config } from "./base/config/DynamicConfig";
import { BrowserRouter } from "react-router-dom";

function App() {
  useEffect(() => {
    if (config.env.BASE_URL.includes("test")) {
      document.title = "Intranet (TEST) - Red Robot s.r.o.";
    } else {
      if (config.env.BASE_URL.includes("localhost")) {
        document.title = "Intranet (LOCAL) - Red Robot s.r.o.";
      } else {
        document.title = "Intranet - Red Robot s.r.o.";
      }
    }
    return;
  });

  return (
    <AuthProvider>
      <BrowserRouter>
        {config.env.LOGIN_TYPE === LoginType.LOCAL ? (
          <LoginLocal />
        ) : (
          <LoginAAD />
        )}
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
