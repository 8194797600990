import moment from "moment";
import React, {useContext, useEffect, useState} from "react";
import toast from "react-hot-toast";
import {useParams} from "react-router-dom";
import {Button, Header, Icon, Pagination, Table} from "semantic-ui-react";
import SimpleLoader from "../../../components/SimpleLoader";
import {DocumentTableModal} from "./DocumentTableModal";
import {SyncOperationState} from "../../../base/state/SyncOperationState";
import {Auth, AuthContext} from "../../../contexts/AuthContext";
import {Role} from "../../../base/enums/Role";
import {DateUtil} from "../../../base/util/DateUtil";
import {
  createDocumentAttachment,
  DocumentDto,
  FileAttachmentDto, getDocumentsForCurrentUser,
  getDocumentsForUser,
  PageInfo, SortDirection
} from '../../../api/generated-sources'

export function DocumentTable() {
  const [list, setList] = useState<DocumentDto[]>();
  const [active, setActive] = useState<DocumentDto>();
  const { id } = useParams<{ id: string }>();
  const auth = useContext<Auth>(AuthContext);
  const [attachment, setAttachment] = useState<FileAttachmentDto>();
  const canEdit = id && (auth.hasRole(Role.HR_MANAGER) || auth.isAdmin());
  const [pageInfo, setPageInfo] = useState<PageInfo>({
      numberOfElements: 0,
      pageNumber: 0,
      pageSize: 10,
      totalElements: 0,
      totalPages: 0,
  });
  const [uploadState, setUploadState] = useState<SyncOperationState>({
    inProgress: false,
    error: false,
  });

  useEffect(() => {
    reloadTable();
  }, [id, pageInfo.pageNumber]);

  const handleDelete = (id: number) => {
    const updateArr = list.filter((item) => item.id !== id);
    setList(updateArr);
  };

  const reloadTable = () => {
    if (id) {
      getDocumentsForUser(+id, {
        page: pageInfo.pageNumber,
        size: pageInfo.pageSize,
        sortBy: "validTo",
        sortDirection: SortDirection.DESC
      })
        .then(data => {
          setPageInfo(data.pageInfo);
          setList(data.documents);
        }).catch(console.error);
    } else {
      getDocumentsForCurrentUser({
        page: pageInfo.pageNumber,
        size: pageInfo.pageSize,
        sortBy: "validTo",
        sortDirection: SortDirection.DESC
      }).then(data => {
        setPageInfo(data.pageInfo);
        setList(data.documents);
      }).catch(console.error);
    }
  }

  const onAttachmentUploadSuccess = () => {
    setUploadState({ inProgress: false, id: undefined, error: false });
    reloadTable();
  };

  const onAttachmentUploadError = (data: any) => {
    console.error(data);
    setUploadState({
      inProgress: false,
      error: true,
    });
  };

  const uploadAttachment = (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const filetype = event.target.files[0].type;
      if (file.size > 100000000 || !["image/png", "image/jpeg", "application/pdf"].includes(filetype)) {
        setUploadState({
          error: true,
        });
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const fileAttachment: FileAttachmentDto = {
          filename: file.name,
          type: filetype,
          content: reader.result,
        };
        if (id) {
          setUploadState({
            id: id,
            inProgress: true,
            error: false,
          });
          const promise = createDocumentAttachment(id, {id: id, attachment: fileAttachment});
          toast.promise(promise,
            {
              loading: "Zpracovávám...",
              success: "Dokument byl úspěšně nahraný.",
              error: "Něco se pokazilo!",
            }
          );
          promise
            .then(onAttachmentUploadSuccess)
            .catch(onAttachmentUploadError)
        } else {
          setAttachment(fileAttachment);
        }
      };
    }
  };

  const displayLoader = !list;

  return (
    <>
      <Header as="h3" textAlign="center">
        DOKUMENTY
      </Header>
      {displayLoader ? <SimpleLoader text={"Načítám data"} />
      : <>
          {
            canEdit && !!active && <DocumentTableModal
              selectedRow={{documentDto: active}}
              close={() => {setActive(undefined)}}
              isOpen={!!active}
              attachmentName={attachment?.filename}
              handleUpdate={reloadTable}
              handleDelete={handleDelete}
            />
          }

          <Table basic striped selectable={canEdit}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Platné od</Table.HeaderCell>
                <Table.HeaderCell>Platné do</Table.HeaderCell>
                <Table.HeaderCell>Typ</Table.HeaderCell>
                <Table.HeaderCell>Poznámka</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Dokument</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {list?.length ? (
                list?.map((item) => {
                  return (
                    <Table.Row key={item?.id}
                      onClick={() => {
                        if (canEdit) {
                          setActive(item);
                        }
                      }}
                    >
                      <Table.Cell style={{borderLeft: DateUtil.isCurrentlyValid(item.validFrom, item.validTo) ? "4px solid #21BA45" : ""}}>
                        {item?.validFrom &&
                          moment(item?.validFrom, moment.ISO_8601).format(
                            "DD. MM. yyyy"
                          )}
                      </Table.Cell>
                      <Table.Cell>
                        {!item.validTo
                          ? "-"
                          : moment(item?.validTo, moment.ISO_8601).format(
                              "DD. MM. yyyy"
                            )}
                      </Table.Cell>
                      <Table.Cell>{item?.documentType?.title}</Table.Cell>
                      <Table.Cell>{item?.note}</Table.Cell>
                        <Table.Cell textAlign="center" onClick={(e: Event) => {e.stopPropagation();}}>
                          {
                            uploadState.id === item.id && <Icon loading name={"circle notch"} />
                          }
                          {
                            uploadState.id !== item.id && item.attachmentUrl && <a target="_blank" rel="noopener noreferrer" href={item.attachmentUrl}>
                              <Icon color="black" name="download" />
                            </a>
                          }
                          {
                            canEdit && uploadState.id !== item.id && !item.attachmentUrl && <Icon.Group onClick={() => window.document.getElementById( "uploadInput-" + item.id )?.click()}>
                                {
                                  item?.documentType?.required ? <>
                                    <Icon color="red" size={"big"} name="circle outline" />
                                    <Icon color="black" name="file alternate outline" />
                                    <Icon color="red" size={"small"} corner name="warning"/>
                                  </>
                                  :
                                  <>
                                    <Icon color="grey" size={"big"} name="circle outline" />
                                    <Icon color="black" name="file alternate outline" />
                                  </>
                                }
                              <input hidden id={"uploadInput-" + item.id} type={"file"} accept="image/png, image/jpeg, application/pdf" onChange={(event) =>
                                uploadAttachment(item.id, event)
                              }/>
                            </Icon.Group>
                          }
                        </Table.Cell>
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell textAlign={"center"} colSpan={7}>
                    U zaměstnance nejsou nahrané žádné dokumenty
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
            {
              canEdit && <Table.Footer>
                <Table.Row className="new-btn">
                  <Table.HeaderCell colSpan="5">
                    {
                      pageInfo.totalPages > 1 && <Pagination size={'tiny'} activePage={pageInfo.pageNumber + 1} totalPages={pageInfo.totalPages} siblingRange={2} onPageChange={(e, page) => setPageInfo({...pageInfo, pageNumber: (page.activePage as number) - 1})} />
                    }
                    <Button
                      floated="right"
                      icon
                      labelPosition="left"
                      size="small"
                      positive
                      onClick={() => {
                        setActive({userId: +id});
                      }}
                    >
                      <Icon name="add" /> Nový dokument
                    </Button>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            }
          </Table>
        </>
      }
    </>
  );
}
