import {EDocumentType} from '../../api/generated-sources'

export class DocumentTypeUtil {
  public static getSalaryTypeOptions = () => {
    return [
      { key: EDocumentType.MZDOVY_VYMER, value: EDocumentType.MZDOVY_VYMER, text: "Mzdový výměr" },
      { key: EDocumentType.DODATEK_K_PRACOVNI_SMLOUVE, value: EDocumentType.DODATEK_K_PRACOVNI_SMLOUVE, text: "Dodatek k pracovní smlouvě" },
    ];
  };
  public static getEmploymentTypeOptions = () => {
    return [
      { key: EDocumentType.ZAMESTNANECKA_SMLOUVA, value: EDocumentType.ZAMESTNANECKA_SMLOUVA, text: "Smlouva o HPP" },
      { key: EDocumentType.DPP, value: EDocumentType.DPP, text: "Dohoda o provedení práce" },
      { key: EDocumentType.DPC, value: EDocumentType.DPC, text: "Dohoda o pracovní činnosti" },
      { key: EDocumentType.SMLOUVA_O_DILO, value: EDocumentType.SMLOUVA_O_DILO, text: "Smlouva o dílo" },
    ];
  };
  public static getAllOptions = () => {
    return [
      ...DocumentTypeUtil.getEmploymentTypeOptions(),
      ...DocumentTypeUtil.getSalaryTypeOptions(),
      { key: EDocumentType.DOHODA_O_SRAZKACH_ZE_MZDY, value: EDocumentType.DOHODA_O_SRAZKACH_ZE_MZDY, text: "Dohoda o srážkách ze mzdy" },
      { key: EDocumentType.GDPR, value: EDocumentType.GDPR, text: "GDPR" },
      { key: EDocumentType.BOZP, value: EDocumentType.BOZP, text: "BOZP" },
    ]
  };
}
