export class DocumentTypeNotificationOptions {
  public static getOptions = () => {
    return [
      {key: "0", text: "Ne", value: 0},
      {key: "2", text: "3 Měsíce před skončením platnosti", value: 90},
      {key: "3", text: "2 Měsíce před skončením platnosti", value: 60},
      {key: "4", text: "1 Měsíc před skončením platnosti", value: 30},
      {key: "5", text: "2 Týdny před skončením platnosti", value: 14},
      {key: "6", text: "1 Týden před skončením platnosti", value: 7},
    ];
  }
}
