export class LocalDate extends Date{
  toISOString(): string {
    return this.getFullYear()+'-'+this.formatNumber(this.getMonth()+1)+'-'+this.formatNumber(this.getDate())
  }
  toString(): string {
    return this.toISOString()
  }
  private formatNumber(n:number):string {
    return ('0'+n).substr(-2);
  }
}
