import { RedirectRequest, SilentRequest } from "@azure/msal-browser";
import { AccountIdentifiers } from "@azure/msal-react/dist/types/AccountIdentifiers";
import { config } from "../config/DynamicConfig";

export const getRedirectRequest = (): RedirectRequest => {
  return {
    scopes: [
      "openid",
      "profile",
      "email",
      "api://" + config.env.AZURE_AD_CLIENT_ID + "/default",
    ],
  };
};

export const getSilentRequest = (
  account: AccountIdentifiers
): SilentRequest => {
  return {
    scopes: [
      "openid",
      "profile",
      "email",
      "api://" + config.env.AZURE_AD_CLIENT_ID + "/default",
    ],
    account: {
      username: account.username,
      homeAccountId: account.homeAccountId,
      localAccountId: account.localAccountId,
      environment: config.env.AZURE_AD_ENVIRONMENT,
      tenantId: config.env.AZURE_AD_TENANT_ID,
    },
  };
};
