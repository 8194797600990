import {Button, Header, Icon, Label, Pagination, Table} from "semantic-ui-react";
import SimpleLoader from "../../../components/SimpleLoader";
import {Formatter} from "../../../base/util/Formatter";
import {SalaryTableModal} from "./SalaryTableModal";
import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import {useParams} from "react-router-dom";
import {Auth, AuthContext} from "../../../contexts/AuthContext";
import {Role} from "../../../base/enums/Role";
import {DateUtil} from "../../../base/util/DateUtil";
import {
  getSalariesForCurrentUser,
  getSalariesForUser, PageInfo,
  SalaryDto,
  SalaryInputDto,
  SortDirection
} from '../../../api/generated-sources'

export function SalaryTable() {
  const [list, setList] = useState<SalaryDto[]>();
  const [active, setActive] = useState<SalaryInputDto>();
  const { id } = useParams<{ id: string }>();
  const auth = useContext<Auth>(AuthContext);
  const canEdit = id && (auth.hasRole(Role.HR_MANAGER) || auth.isAdmin());
  const [pageInfo, setPageInfo] = useState<PageInfo>({
      numberOfElements: 0,
      pageNumber: 0,
      pageSize: 5,
      totalElements: 0,
      totalPages: 0,
  });

  const getPercentDifference = (num: number, index: number) => {
    const optionSalary = list[index + 1].monthly
      ? list[index + 1].monthly
      : list[index + 1].hourly;
    const percent = (num / optionSalary - 1) * 100;
    return `${percent.toFixed(2)} %`;
  };

  const activeRow = (id: number) => {
    const activeItem = list.filter((item) => item.id === id);
    setActive(activeItem[0]);
  };

  const handleDelete = (id: number) => {
    const updateArr = list.filter((item) => item.id !== id);
    setList(updateArr);
  };

  const reloadTable = () => {
    if (id) {
      getSalariesForUser(+id,{
        page: pageInfo.pageNumber,
        size: pageInfo.pageSize,
        sortBy: "validFrom",
        sortDirection: SortDirection.DESC
      })
        .then(data => {
          setPageInfo(data.pageInfo);
          setList(data.salary);
        })
        .catch(console.error)
    } else {
      getSalariesForCurrentUser({
        page: pageInfo.pageNumber,
        size: pageInfo.pageSize,
        sortBy: "validFrom",
        sortDirection: SortDirection.DESC
      })
        .then(data => {
          setPageInfo(data.pageInfo);
          setList(data.salary);
        })
        .catch(console.error)
    }
  }

  useEffect(() => {
    reloadTable();
  }, [id, pageInfo.pageNumber]);

  const displayLoader = !list;

  return (
    <>
      {displayLoader ? <SimpleLoader text={"Načítám data"} />
      : <>
          {
            canEdit && !!active && <SalaryTableModal
              selectedRow={active}
              close={() => setActive(undefined)}
              isOpen={!!active}
              handleUpdate={reloadTable}
              handleDelete={handleDelete}
            />
          }
          <Header as="h3" textAlign="center">
            MZDOVÁ HISTORIE
          </Header>
          <Table basic striped selectable={canEdit}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Platnost od</Table.HeaderCell>
                <Table.HeaderCell>Platnost do</Table.HeaderCell>
                <Table.HeaderCell>Částka</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Navýšení</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Dokument</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {list?.length ? (
                list?.map((item, index) => {
                  return (
                    <Table.Row
                      key={item.id}
                      onClick={() => {
                        if (canEdit) {
                          activeRow(item.id);
                        }
                      }}
                    >
                      <Table.Cell singleLine style={{borderLeft: DateUtil.isCurrentlyValid(new Date(item.validFrom), new Date(item.validTo)) ? "4px solid #21BA45" : "4px solid white"}}>
                        {item?.validFrom &&
                          moment(item?.validFrom, moment.ISO_8601).format(
                            "DD. MM. yyyy"
                          )}
                      </Table.Cell>
                      <Table.Cell singleLine>
                        {!item.validTo
                          ? "-"
                          : moment(item?.validTo, moment.ISO_8601).format(
                              "DD. MM. yyyy"
                            )}{" "}
                      </Table.Cell>
                      <Table.Cell>
                        {Formatter.money(
                          +item?.monthly ? +item?.monthly : +item?.hourly
                        )}
                      </Table.Cell>
                      {index < (list.length - 1) ? (
                        <>
                          {!getPercentDifference(item.monthly, index).includes(
                            "-"
                          ) ? (
                            <Table.Cell textAlign="center">
                              <Label color={"green"}>
                                <Icon name="arrow up" />{" "}
                                {getPercentDifference(
                                  +item.monthly ? +item.monthly : +item.hourly,
                                  index
                                )}
                              </Label>
                            </Table.Cell>
                          ) : (
                            <Table.Cell textAlign="center">
                              <Label color={"red"}>
                                <Icon name="arrow down" />{" "}
                                {getPercentDifference(
                                  +item.monthly ? +item.monthly : +item.hourly,
                                  index
                                )}
                              </Label>
                            </Table.Cell>
                          )}
                        </>
                      ) : (
                        <Table.Cell textAlign="center">
                          {" "}
                          <Icon name="minus" />{" "}
                        </Table.Cell>
                      )}
                      <Table.Cell textAlign="center">
                        {
                          item.documentUrl ? <a target="_blank" rel="noopener noreferrer" href={item.documentUrl} onClick={(e) => e.stopPropagation()}>
                            <Icon color="black" name="download" />
                          </a>
                          :
                          <Icon.Group>
                            <>
                              <Icon color="red" size={"big"} name="circle outline" />
                              <Icon color="black" name="file alternate outline" />
                              <Icon color="red" size={"small"} corner name="warning"/>
                            </>
                          </Icon.Group>
                        }
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row>
                  <Table.Cell textAlign={"center"} colSpan={7}>
                    Historie mezd je prázdná
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
            {
              canEdit && <Table.Footer>
                <Table.Row className="new-btn">
                  <Table.HeaderCell colSpan="5">
                    {
                      pageInfo.totalPages > 1 && <Pagination size={'tiny'} activePage={pageInfo.pageNumber + 1} totalPages={pageInfo.totalPages} siblingRange={2} onPageChange={(e, page) => setPageInfo({...pageInfo, pageNumber: (page.activePage as number) - 1})} />
                    }
                    <Button
                      floated="right"
                      icon
                      labelPosition="left"
                      size="small"
                      positive
                      onClick={() => {
                        setActive({userId: +id});
                      }}
                    >
                      <Icon name="add" /> Nová změna
                    </Button>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            }
          </Table>
        </>
      }
    </>
  );
}
