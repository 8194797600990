/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
import useSwr from "swr";
import type { Arguments, Key, SWRConfiguration } from "swr";
import useSWRMutation from "swr/mutation";
import type { SWRMutationConfiguration } from "swr/mutation";
import type {
  DocumentDto,
  DocumentInputDto,
  ErrorResponseResponse,
  FileAttachmentInputDto,
  GetDocumentsForCurrentUserParams,
  GetDocumentsForUserParams,
  PageableDocumentResponse,
} from "../intranetApi.schemas";
import { IntranetClientFetchInstance } from "../../intranetClientFetchInstance";
import type { ErrorType } from "../../intranetClientFetchInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get documents for current user
 */
export const getDocumentsForCurrentUser = (
  params?: GetDocumentsForCurrentUserParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<PageableDocumentResponse>(
    { url: `/document/currentUser`, method: "GET", params },
    options
  );
};

export const getGetDocumentsForCurrentUserKey = (
  params?: GetDocumentsForCurrentUserParams
) => [`/document/currentUser`, ...(params ? [params] : [])] as const;

export type GetDocumentsForCurrentUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDocumentsForCurrentUser>>
>;
export type GetDocumentsForCurrentUserQueryError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary get documents for current user
 */
export const useGetDocumentsForCurrentUser = <
  TError = ErrorType<ErrorResponseResponse>
>(
  params?: GetDocumentsForCurrentUserParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getDocumentsForCurrentUser>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetDocumentsForCurrentUserKey(params) : null));
  const swrFn = () => getDocumentsForCurrentUser(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary get documents for user
 */
export const getDocumentsForUser = (
  id: number,
  params?: GetDocumentsForUserParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<PageableDocumentResponse>(
    { url: `/document/${id}`, method: "GET", params },
    options
  );
};

export const getGetDocumentsForUserKey = (
  id: number,
  params?: GetDocumentsForUserParams
) => [`/document/${id}`, ...(params ? [params] : [])] as const;

export type GetDocumentsForUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDocumentsForUser>>
>;
export type GetDocumentsForUserQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get documents for user
 */
export const useGetDocumentsForUser = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  params?: GetDocumentsForUserParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getDocumentsForUser>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetDocumentsForUserKey(id, params) : null));
  const swrFn = () => getDocumentsForUser(id, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary delete document
 */
export const deleteDocument = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<string>(
    { url: `/document/${id}`, method: "DELETE" },
    options
  );
};

export const getDeleteDocumentMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, __: { arg: Arguments }): Promise<string> => {
    return deleteDocument(id, options);
  };
};
export const getDeleteDocumentMutationKey = (id: number) =>
  `/document/${id}` as const;

export type DeleteDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteDocument>>
>;
export type DeleteDocumentMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary delete document
 */
export const useDeleteDocument = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteDocument>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof deleteDocument>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getDeleteDocumentMutationKey(id);
  const swrFn = getDeleteDocumentMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary update document
 */
export const updateDocument = (
  id: number,
  documentInputDto: DocumentInputDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<DocumentDto>(
    {
      url: `/document/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: documentInputDto,
    },
    options
  );
};

export const getUpdateDocumentMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: DocumentInputDto }
  ): Promise<DocumentDto> => {
    return updateDocument(id, arg, options);
  };
};
export const getUpdateDocumentMutationKey = (id: number) =>
  `/document/${id}` as const;

export type UpdateDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateDocument>>
>;
export type UpdateDocumentMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary update document
 */
export const useUpdateDocument = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateDocument>>,
      TError,
      string,
      DocumentInputDto,
      Awaited<ReturnType<typeof updateDocument>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getUpdateDocumentMutationKey(id);
  const swrFn = getUpdateDocumentMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary create document
 */
export const createDocument = (
  documentInputDto: DocumentInputDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<DocumentDto>(
    {
      url: `/document`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: documentInputDto,
    },
    options
  );
};

export const getCreateDocumentMutationFetcher = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: DocumentInputDto }
  ): Promise<DocumentDto> => {
    return createDocument(arg, options);
  };
};
export const getCreateDocumentMutationKey = () => `/document` as const;

export type CreateDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof createDocument>>
>;
export type CreateDocumentMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary create document
 */
export const useCreateDocument = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createDocument>>,
    TError,
    string,
    DocumentInputDto,
    Awaited<ReturnType<typeof createDocument>>
  > & { swrKey?: string };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCreateDocumentMutationKey();
  const swrFn = getCreateDocumentMutationFetcher(requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary create document attachment
 */
export const createDocumentAttachment = (
  id: number,
  fileAttachmentInputDto: FileAttachmentInputDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<DocumentDto>(
    {
      url: `/document/${id}/attachment`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: fileAttachmentInputDto,
    },
    options
  );
};

export const getCreateDocumentAttachmentMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: FileAttachmentInputDto }
  ): Promise<DocumentDto> => {
    return createDocumentAttachment(id, arg, options);
  };
};
export const getCreateDocumentAttachmentMutationKey = (id: number) =>
  `/document/${id}/attachment` as const;

export type CreateDocumentAttachmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof createDocumentAttachment>>
>;
export type CreateDocumentAttachmentMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary create document attachment
 */
export const useCreateDocumentAttachment = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof createDocumentAttachment>>,
      TError,
      string,
      FileAttachmentInputDto,
      Awaited<ReturnType<typeof createDocumentAttachment>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getCreateDocumentAttachmentMutationKey(id);
  const swrFn = getCreateDocumentAttachmentMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary delete document attachment
 */
export const deleteDocumentAttachment = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<string>(
    { url: `/document/${id}/attachment`, method: "DELETE" },
    options
  );
};

export const getDeleteDocumentAttachmentMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, __: { arg: Arguments }): Promise<string> => {
    return deleteDocumentAttachment(id, options);
  };
};
export const getDeleteDocumentAttachmentMutationKey = (id: number) =>
  `/document/${id}/attachment` as const;

export type DeleteDocumentAttachmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteDocumentAttachment>>
>;
export type DeleteDocumentAttachmentMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary delete document attachment
 */
export const useDeleteDocumentAttachment = <
  TError = ErrorType<ErrorResponseResponse>
>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteDocumentAttachment>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof deleteDocumentAttachment>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey =
    swrOptions?.swrKey ?? getDeleteDocumentAttachmentMutationKey(id);
  const swrFn = getDeleteDocumentAttachmentMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
