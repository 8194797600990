import { Language, Translation } from "./Localization.model";

export function translate(
  translation: Translation,
  language: Language
): string {
  return translation[language];
}

export function useTranslation(translation: Translation) {
  const language = Language.CZ;
  return translate(translation, language);
}
