/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
import useSwr from "swr";
import type { Key, SWRConfiguration } from "swr";
import useSWRMutation from "swr/mutation";
import type { SWRMutationConfiguration } from "swr/mutation";
import type {
  ErrorResponseResponse,
  GetWorkReportParams,
  SynchronizeWorkReportBody,
  WorkReportDto,
} from "../intranetApi.schemas";
import { IntranetClientFetchInstance } from "../../intranetClientFetchInstance";
import type { ErrorType } from "../../intranetClientFetchInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get work report
 */
export const getWorkReport = (
  params: GetWorkReportParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<WorkReportDto>(
    { url: `/workReport`, method: "GET", params },
    options
  );
};

export const getGetWorkReportKey = (params: GetWorkReportParams) =>
  [`/workReport`, ...(params ? [params] : [])] as const;

export type GetWorkReportQueryResult = NonNullable<
  Awaited<ReturnType<typeof getWorkReport>>
>;
export type GetWorkReportQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get work report
 */
export const useGetWorkReport = <TError = ErrorType<ErrorResponseResponse>>(
  params: GetWorkReportParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getWorkReport>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetWorkReportKey(params) : null));
  const swrFn = () => getWorkReport(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary synchronize work report
 */
export const synchronizeWorkReport = (
  synchronizeWorkReportBody: SynchronizeWorkReportBody,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<WorkReportDto>(
    {
      url: `/workReport`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: synchronizeWorkReportBody,
    },
    options
  );
};

export const getSynchronizeWorkReportMutationFetcher = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: SynchronizeWorkReportBody }
  ): Promise<WorkReportDto> => {
    return synchronizeWorkReport(arg, options);
  };
};
export const getSynchronizeWorkReportMutationKey = () => `/workReport` as const;

export type SynchronizeWorkReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof synchronizeWorkReport>>
>;
export type SynchronizeWorkReportMutationError =
  ErrorType<ErrorResponseResponse>;

/**
 * @summary synchronize work report
 */
export const useSynchronizeWorkReport = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof synchronizeWorkReport>>,
    TError,
    string,
    SynchronizeWorkReportBody,
    Awaited<ReturnType<typeof synchronizeWorkReport>>
  > & { swrKey?: string };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getSynchronizeWorkReportMutationKey();
  const swrFn = getSynchronizeWorkReportMutationFetcher(requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
