import { Translation } from "../Localization.model";
import {DashboardWidgetDto} from '../../../api/generated-sources'

export class DashboardWidgetName {
  public static VM_STATUS: Translation = {
    en: "Status of Virtual Machines",
    cz: "Stav Virtuálních strojů",
  };
  public static INVOICE_SUBJECT_BREAKDOWN: Translation = {
    en: "Subject's overview",
    cz: "Přehled klientů",
  };
  public static FINANCE_GRAPH: Translation = {
    en: "Graph of Company's balance breakdown",
    cz: "Graf firemních příjmů a výdajů",
  };
  public static FINANCE_TABLE: Translation = {
    en: "Company's balance overview",
    cz: "Přehled firemních přijmů a výdajů",
  };
  public static COMPANY_DEBT: Translation = {
    en: "Company's Debt towards owners",
    cz: "Firemní dluh vůči majitelům",
  };

  public static get(widget: DashboardWidgetDto): Translation {
    switch (widget.name) {
      case "vm_status":
        return DashboardWidgetName.VM_STATUS;
      case "invoice_subject_breakdown":
        return DashboardWidgetName.INVOICE_SUBJECT_BREAKDOWN;
      case "finance_graph":
        return DashboardWidgetName.FINANCE_GRAPH;
      case "finance_table":
        return DashboardWidgetName.FINANCE_TABLE;
      case "company_debt":
        return DashboardWidgetName.COMPANY_DEBT;
    }
  }
}

export class DashboardWidgetDescription {
  public static VM_STATUS: Translation = {
    en: "Quick overview of virtual machines and their status (whether they are online/offline)",
    cz: "Přehled virtuálních strojů a jejich stavu (zda běží nebo jsou vypnutý)",
  };
  public static INVOICE_SUBJECT_BREAKDOWN: Translation = {
    en: "Pie chart of income by clients",
    cz: "Koláčový graf příjmů podle jednotlivých klientů",
  };
  public static FINANCE_GRAPH: Translation = {
    en: "Graph comparing company's income and spending within selected time period",
    cz: "Graf firemních příjmů a výdajů",
  };
  public static FINANCE_TABLE: Translation = {
    en: "",
    cz: "Tabulkový přehled příjmů a výdajů",
  };
  public static COMPANY_DEBT: Translation = {
    en: "Simple overview of how much company owes to owners",
    cz: "Stručný přehled firemního dluhu vůči majitelům",
  };

  public static get(widget: DashboardWidgetDto): Translation {
    switch (widget.name) {
      case "vm_status":
        return DashboardWidgetDescription.VM_STATUS;
      case "invoice_subject_breakdown":
        return DashboardWidgetDescription.INVOICE_SUBJECT_BREAKDOWN;
      case "finance_graph":
        return DashboardWidgetDescription.FINANCE_GRAPH;
      case "finance_table":
        return DashboardWidgetDescription.FINANCE_TABLE;
      case "company_debt":
        return DashboardWidgetDescription.COMPANY_DEBT;
    }
  }
}
