import { useEffect, useState } from "react";
import {
  Image,
  Form,
  Button,
  Grid,
  Icon,
} from "semantic-ui-react";
import SimpleLoader from "../../../../components/SimpleLoader";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumbs from "../../../../components/base/Breadcrumbs";
import { View } from "../../../../base/enums/View";
import { StringUtil } from "../../../../base/util/StringUtil";
import toast from "react-hot-toast";
import { LoginType } from "../../../../base/enums/LoginType";
import { config } from "../../../../base/config/DynamicConfig";
import {
  createUser,
  getCurrentUser,
  getUser,
  updateUser,
  UserDto
} from '../../../../api/generated-sources'
import {LocalDate} from '../../../../build/core/dto/LocalDate'

export default function MyOverviewView() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const initialState: UserDto = {
    firstname: "",
    lastname: "",
    maidenName: "",
    birthDate: new LocalDate(new Date()),
    placeOfBirth: "",
    position: "",
    bankAccount: "",
    phone: "",
    contactEmail: "",
    insurance: "",
    nationality: "",
    enabled: false,
  };

  const [myOverview, setMyOverview] = useState<UserDto>();
  const [readOnly, setReadOnly] = useState(true);
  const [newUser, setNewUser] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setReadOnly(true);
  };

  const handleChange = (value?: any, field?: string) => {
    setMyOverview({
      ...myOverview,
      [field]: value,
    });
  };

  const handleUpdateUser = (user: UserDto) => {
    const predicate = updateUser(user.id, user);
    predicate.then(value => {
      // setMyOverview(value)
    })
      .catch(console.error)
    return predicate
  }

  const saveEdit = () => {
      toast.promise(handleUpdateUser({
        id: myOverview.id,
        firstname: myOverview.firstname,
        lastname: myOverview.lastname,
        maidenName: myOverview.maidenName,
        birthDate: myOverview.birthDate,
        placeOfBirth: myOverview.placeOfBirth,
        position: myOverview.position,
        bankAccount: myOverview.bankAccount,
        phone: myOverview.phone,
        contactEmail: myOverview.contactEmail,
        insurance: myOverview.insurance,
        nationality: myOverview.nationality,
        enabled: false,
      }),
      {
        loading: "Zpracovávám...",
        success: "Zaměstnanec byl úspěšně upraven.",
        error: "Něco se pokazilo!",
      }
    );
  };

  const switchArchivedFlag = (value: boolean) => {
    setMyOverview({
      ...myOverview,
      enabled: value,
    });

    toast.promise(
      handleUpdateUser({
        id: myOverview?.id,
        firstname: myOverview.firstname,
        lastname: myOverview.lastname,
        maidenName: myOverview.maidenName,
        birthDate: myOverview.birthDate,
        placeOfBirth: myOverview.placeOfBirth,
        position: myOverview.position,
        bankAccount: myOverview.bankAccount,
        phone: myOverview.phone,
        contactEmail: myOverview.contactEmail,
        insurance: myOverview.insurance,
        nationality: myOverview.nationality,
        enabled: value,
      }),
      {
        loading: "Zpracovávám...",
        success: myOverview.enabled
          ? "Zaměstnanec byl deaktivován."
          : "Zaměstnanec byl aktivován.",
        error: "Něco se pokazilo!",
      }
    );
  };

  const create = () => {
    if (config.env.LOGIN_TYPE !== LoginType.LOCAL) {
      return;
    }
    const promise = createUser({
            bankAccount: myOverview.bankAccount,
            birthDate: myOverview.birthDate,
            contactEmail: myOverview.contactEmail,
            enabled: false,
            firstname: myOverview.firstname,
            insurance: myOverview.insurance,
            lastname: myOverview.lastname,
            maidenName: myOverview.maidenName,
            nationality: myOverview.nationality,
            phone: myOverview.phone,
            placeOfBirth: myOverview.placeOfBirth,
            position: myOverview.position,
          })
    toast.promise(promise, {
        loading: "Zpracovávám...",
        success: "Zaměstnanec byl úspěšně vytvořen.",
        error: "Něco se pokazilo!",
      }
    );
    promise
      .then(() => history.push(View.EMPLOYEES_MANAGEMENT.path))
      .catch(console.error)
  };

  useEffect(() => {
    if (id === undefined) {
      getCurrentUser()
        .then(setMyOverview)
        .catch(console.error);
    } else if (id !== "new" && id !== undefined) {
      getUser(+id)
        .then(setMyOverview)
        .catch(console.error);
    } else if (id === "new") {
      setNewUser(true);
      setReadOnly(false);
      setMyOverview(initialState);
    }
  }, []);

  const displayLoader = !myOverview && !newUser;

  const requiredFieldsPresent = () => {
    return (
      StringUtil.isEmpty(myOverview?.firstname) ||
      StringUtil.isEmpty(myOverview?.lastname) ||
      StringUtil.isEmpty(myOverview?.insurance) ||
      StringUtil.isEmpty(myOverview?.position) ||
      StringUtil.isEmpty(myOverview?.nationality) ||
      StringUtil.isEmpty(myOverview?.placeOfBirth)
    );
  };

  return (
    <div className="overview-wrap">
      {displayLoader ? (
        <SimpleLoader text={"Načítám data"} />
      ) : (
        <>
          {" "}
          <Breadcrumbs
            title={
              !newUser
                ? myOverview?.firstname + " " + myOverview?.lastname
                : "Nový zaměstnanec"
            }
          />
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Grid className="overview-grid">
              <Grid.Row className="overview-flex">
                <Grid.Column width={4} className="overview-img">
                  <Image
                    src="https://react.semantic-ui.com/images/wireframe/image.png"
                    size="medium"
                    rounded
                    wrapped
                  />
                  {!readOnly && (
                    <Form.Input type="file" className="overview-file" />
                  )}
                </Grid.Column>
                <Grid.Column width={12} className="overview-info">
                  <Form.Group unstackable widths={2}>
                    <Form.Field required>
                      <label>Jméno</label>
                      <Form.Input
                        defaultValue={myOverview && myOverview?.firstname}
                        disabled={newUser ? !newUser : readOnly}
                        onChange={(e) =>
                          handleChange(e.target.value, "firstname")
                        }
                      />
                    </Form.Field>
                    <Form.Field required>
                      <label>Příjmení</label>
                      <Form.Input
                        defaultValue={myOverview && myOverview?.lastname}
                        disabled={newUser ? !newUser : readOnly}
                        onChange={(e) =>
                          handleChange(e.target.value, "lastname")
                        }
                      />
                    </Form.Field>
                    {!readOnly ||
                      (myOverview?.maidenName && (
                        <Form.Field>
                          <label>Rodné příjmení</label>
                          <Form.Input
                            defaultValue={myOverview && myOverview?.maidenName}
                            disabled={newUser ? !newUser : readOnly}
                            onChange={(e) =>
                              handleChange(e.target.value, "maidenName")
                            }
                          />
                        </Form.Field>
                      ))}
                  </Form.Group>
                  <Form.Group widths={2}>
                    <Form.Field required>
                      <label>Datum narození</label>
                      <Form.Input
                        type="date"
                        defaultValue={
                          myOverview?.birthDate &&
                          new LocalDate(myOverview?.birthDate)
                        }
                        disabled={newUser ? !newUser : readOnly}
                        onChange={(e) =>
                          handleChange(e.target.value, "birthDate")
                        }
                      />
                    </Form.Field>
                    <Form.Field required>
                      <label>Pracovni pozice</label>
                      <Form.Input
                        defaultValue={myOverview && myOverview?.position}
                        disabled={newUser ? !newUser : readOnly}
                        onChange={(e) =>
                          handleChange(e.target.value, "position")
                        }
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Group widths={2}>
                    <Form.Field>
                      <label>Email</label>
                      <Form.Input
                        defaultValue={myOverview && myOverview?.contactEmail}
                        disabled={newUser ? !newUser : readOnly}
                        onChange={(e) =>
                          handleChange(e.target.value, "contactEmail")
                        }
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Telefonní číslo</label>
                      <Form.Input
                        defaultValue={myOverview && myOverview?.phone}
                        disabled={newUser ? !newUser : readOnly}
                        onChange={(e) => handleChange(e.target.value, "phone")}
                      />
                    </Form.Field>
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Form.Group widths={2}>
                    <Form.Field required>
                      <label>Bankovní účet</label>
                      <Form.Input
                        defaultValue={myOverview && myOverview?.bankAccount}
                        disabled={newUser ? !newUser : readOnly}
                        onChange={(e) =>
                          handleChange(e.target.value, "bankAccount")
                        }
                      />
                    </Form.Field>
                    <Form.Field required>
                      <label>Zdravotní pojišťovna</label>
                      <Form.Input
                        defaultValue={myOverview && myOverview?.insurance}
                        disabled={newUser ? !newUser : readOnly}
                        onChange={(e) =>
                          handleChange(e.target.value, "insurance")
                        }
                      />
                    </Form.Field>
                    <Form.Field required>
                      <label>Národnost</label>
                      <Form.Input
                        defaultValue={myOverview && myOverview?.nationality}
                        disabled={newUser ? !newUser : readOnly}
                        onChange={(e) =>
                          handleChange(e.target.value, "nationality")
                        }
                      />
                    </Form.Field>
                    <Form.Field required>
                      <label>Místo narození</label>
                      <Form.Input
                        defaultValue={myOverview && myOverview?.placeOfBirth}
                        disabled={newUser ? !newUser : readOnly}
                        onChange={(e) =>
                          handleChange(e.target.value, "placeOfBirth")
                        }
                      />
                    </Form.Field>
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                {config.env.LOGIN_TYPE === LoginType.AAD && (
                  <>
                    {newUser && <Grid.Column width={8} className="flex-column">
                        <Button
                          icon="left chevron"
                          onClick={() =>
                            history.push(View.EMPLOYEES_MANAGEMENT.path)
                          }
                          className="mobile-btn"
                        />
                        <Button
                          labelPosition="left"
                          icon="left chevron"
                          content="Zpět"
                          onClick={() =>
                            history.push(View.EMPLOYEES_MANAGEMENT.path)
                          }
                          className="mobile-btnHidden"
                        />
                        <Button
                          icon="add"
                          onClick={create}
                          disabled={requiredFieldsPresent()}
                          type="submit"
                          positive
                          floated="right"
                          className="mobile-btn"
                        />
                        <Button
                          type="submit"
                          positive
                          floated="right"
                          onClick={create}
                          className="mobile-btnHidden"
                          disabled={requiredFieldsPresent()}
                        >
                          Vytvořit nového zaměstnance
                        </Button>
                      </Grid.Column>
                    }
                    {!newUser && !!id && <>
                      { config.env.LOGIN_TYPE === LoginType.LOCAL && <Button floated={"left"} color={"grey"} onClick={() => switchArchivedFlag(!myOverview.enabled)}>
                          <Icon name={myOverview.enabled ? 'arrow alternate circle down' : 'arrow alternate circle up outline'} />
                          <span className="action-btnSpan">
                            {myOverview.enabled ? 'Deaktivovat zaměstnance' : 'Aktivovat zaměstnance'}
                          </span>
                        </Button>
                      }
                      {
                        readOnly && <Button
                              type="button"
                              floated="right"
                              onClick={() => setReadOnly(false)}
                            >
                              Upravit údaje
                            </Button>
                      }
                      {
                        !readOnly && <div>
                          <Button
                            type="submit"
                            positive
                            floated="right"
                            onClick={saveEdit}
                          >
                            Uložit
                          </Button>
                          <Button
                            onClick={() => {
                              setReadOnly(true);
                            }}
                            negative
                            floated="right"
                          >
                            Zrušit
                          </Button>
                        </div>
                      }
                  </>
                }
                </>)}
              </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </>
      )}
    </div>
  );
}
