import { DateUtil } from "./DateUtil";
import {Icon, Label, Popup, SemanticCOLORS, SemanticICONS, Table} from "semantic-ui-react";
import React from "react";
import {InvoiceDto, InvoiceStatus} from "../../api/generated-sources";
import { LocalDate } from "../../build/core/dto/LocalDate";

export interface InvoiceOption {
  key: string;
  value: string;
  text: string;
}

export class InvoiceUtil {
  static PERIOD_TODAY: InvoiceOption = {
    key: "today",
    value: "today",
    text: "Dnes",
  };
  static PERIOD_THIS_WEEK: InvoiceOption = {
    key: "this-week",
    value: "this-week",
    text: "Tento týden",
  };
  static PERIOD_THIS_MONTH: InvoiceOption = {
    key: "this-month",
    value: "this-month",
    text: "Tento měsíc",
  };
  static PERIOD_THIS_QUARTER: InvoiceOption = {
    key: "this-quarter",
    value: "this-quarter",
    text: "Tento kvartál",
  };
  static PERIOD_THIS_YEAR: InvoiceOption = {
    key: "this-year",
    value: "this-year",
    text: "Tento rok",
  };
  static PERIOD_ALL: InvoiceOption = { key: "all", value: "all", text: "Vše" };
  static PERIOD_LAST_WEEK: InvoiceOption = {
    key: "last-week",
    value: "last-week",
    text: "Minulý týden",
  };
  static PERIOD_LAST_MONTH: InvoiceOption = {
    key: "last-month",
    value: "last-month",
    text: "Minulý měsíc",
  };
  static PERIOD_LAST_QUARTER: InvoiceOption = {
    key: "last-quarter",
    value: "last-quarter",
    text: "Minulý kvartál",
  };
  static PERIOD_LAST_YEAR: InvoiceOption = {
    key: "last-year",
    value: "last-year",
    text: "Minulý rok",
  };
  static ROUNDING_ITEM_NAME = "Zaokrouhlení";

  public static getFrom(period: string): LocalDate {
    const today = new Date();
    if (period === InvoiceUtil.PERIOD_TODAY.key) {
      return new LocalDate(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
    } else if (period === InvoiceUtil.PERIOD_THIS_WEEK.key) {
      return InvoiceUtil.shift(today, -1 * today.getDay());
    } else if (period === InvoiceUtil.PERIOD_THIS_MONTH.key) {
      return new LocalDate(today.getFullYear(), today.getMonth(), 1);
    } else if (period === InvoiceUtil.PERIOD_THIS_QUARTER.key) {
      const quarterStartMonth = today.getMonth() - (today.getMonth() % 3);
      return new LocalDate(today.getFullYear(), quarterStartMonth, 1);
    } else if (period === InvoiceUtil.PERIOD_THIS_YEAR.key) {
      return new LocalDate(today.getFullYear(), 0, 1);
    } else if (period === InvoiceUtil.PERIOD_LAST_WEEK.key) {
      return InvoiceUtil.shift(today, -1 * (today.getDay() + 7));
    } else if (period === InvoiceUtil.PERIOD_LAST_MONTH.key) {
      if (today.getMonth() === 0) {
        return new LocalDate(today.getFullYear() - 1, 11, 1);
      } else {
        return new LocalDate(today.getFullYear(), today.getMonth() - 1, 1);
      }
    } else if (period === InvoiceUtil.PERIOD_LAST_QUARTER.key) {
      const thisQuarter = Math.floor(today.getMonth() / 3);
      if (thisQuarter === 0) {
        return new LocalDate(today.getFullYear() - 1, 9, 1);
      } else {
        return new LocalDate(today.getFullYear(), (thisQuarter - 1) * 3, 1);
      }
    } else if (period === InvoiceUtil.PERIOD_LAST_YEAR.key) {
      return new LocalDate(today.getFullYear() - 1, 0, 1);
    }
    return undefined;
  }

  public static getTo(period: string): LocalDate {
    const today = new Date();
    if (period === InvoiceUtil.PERIOD_TODAY.key) {
      return new LocalDate(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
    } else if (period === InvoiceUtil.PERIOD_THIS_WEEK.key) {
      return InvoiceUtil.shift(today, 6 - today.getDay());
    } else if (period === InvoiceUtil.PERIOD_THIS_MONTH.key) {
      return new LocalDate(
        today.getFullYear(),
        today.getMonth(),
        DateUtil.getMonthLength(today.getFullYear(), today.getMonth())
      );
    } else if (period === InvoiceUtil.PERIOD_THIS_QUARTER.key) {
      const quarterEndMonth = today.getMonth() + (2 - (today.getMonth() % 3));
      return new LocalDate(
        today.getFullYear(),
        quarterEndMonth,
        DateUtil.getMonthLength(today.getFullYear(), quarterEndMonth)
      );
    } else if (period === InvoiceUtil.PERIOD_THIS_YEAR.key) {
      return new LocalDate(today.getFullYear(), 11, 31);
    } else if (period === InvoiceUtil.PERIOD_LAST_WEEK.key) {
      return InvoiceUtil.shift(today, -1 * (today.getDay() + 1));
    } else if (period === InvoiceUtil.PERIOD_LAST_MONTH.key) {
      if (today.getMonth() === 0) {
        return new LocalDate(today.getFullYear() - 1, 11, 31);
      } else {
        return new LocalDate(
          today.getFullYear(),
          today.getMonth() - 1,
          DateUtil.getMonthLength(today.getFullYear(), today.getMonth() - 1)
        );
      }
    } else if (period === InvoiceUtil.PERIOD_LAST_QUARTER.key) {
      const quarterEndMonth = today.getMonth() - (today.getMonth() % 3) - 1;
      if (quarterEndMonth === -1) {
        return new LocalDate(today.getFullYear() - 1, 11, 31);
      } else {
        return new LocalDate(
          today.getFullYear(),
          quarterEndMonth,
          DateUtil.getMonthLength(today.getFullYear(), quarterEndMonth)
        );
      }
    } else if (period === InvoiceUtil.PERIOD_LAST_YEAR.key) {
      return new LocalDate(today.getFullYear() - 1, 11, 31);
    }
    return undefined;
  }

  private static shift(origin: Date, shiftBy: number): LocalDate {
    if (shiftBy === 0) {
      return new LocalDate(
        origin.getFullYear(),
        origin.getMonth(),
        origin.getDate()
      );
    }
    if (origin.getDate() + shiftBy <= 0) {
      if (origin.getMonth() === 0) {
        return new LocalDate(
          origin.getFullYear() - 1,
          11,
          origin.getDate() - shiftBy + 31
        );
      } else {
        return new LocalDate(
          origin.getFullYear(),
          origin.getMonth() - 1,
          origin.getDate() +
            shiftBy +
            DateUtil.getMonthLength(origin.getFullYear(), origin.getMonth())
        );
      }
    } else if (
      origin.getDate() + shiftBy >
      DateUtil.getMonthLength(origin.getFullYear(), origin.getMonth())
    ) {
      if (origin.getMonth() === 11) {
        return new LocalDate(
          origin.getFullYear() + 1,
          0,
          origin.getDate() + shiftBy - 31
        );
      } else {
        return new LocalDate(
          origin.getFullYear(),
          origin.getMonth() + 1,
          origin.getDate() +
            shiftBy -
            DateUtil.getMonthLength(origin.getFullYear(), origin.getMonth())
        );
      }
    }
  }

  public static getInvoicePeriodOption(key: string): InvoiceOption {
    if (key === InvoiceUtil.PERIOD_TODAY.key) {
      return InvoiceUtil.PERIOD_TODAY;
    }
    if (key === InvoiceUtil.PERIOD_THIS_MONTH.key) {
      return InvoiceUtil.PERIOD_THIS_MONTH;
    }
    if (key === InvoiceUtil.PERIOD_THIS_QUARTER.key) {
      return InvoiceUtil.PERIOD_THIS_QUARTER;
    }
    if (key === InvoiceUtil.PERIOD_THIS_YEAR.key) {
      return InvoiceUtil.PERIOD_THIS_YEAR;
    }
    if (key === InvoiceUtil.PERIOD_LAST_MONTH.key) {
      return InvoiceUtil.PERIOD_LAST_MONTH;
    }
    if (key === InvoiceUtil.PERIOD_LAST_QUARTER.key) {
      return InvoiceUtil.PERIOD_LAST_QUARTER;
    }
    if (key === InvoiceUtil.PERIOD_LAST_YEAR.key) {
      return InvoiceUtil.PERIOD_LAST_YEAR;
    }
    if (key === InvoiceUtil.PERIOD_ALL.key) {
      return InvoiceUtil.PERIOD_ALL;
    }
  }

  public static getInvoicePeriodOptions(): InvoiceOption[] {
    return [
      InvoiceUtil.PERIOD_TODAY,
      InvoiceUtil.PERIOD_THIS_MONTH,
      InvoiceUtil.PERIOD_THIS_QUARTER,
      InvoiceUtil.PERIOD_THIS_YEAR,
      InvoiceUtil.PERIOD_LAST_MONTH,
      InvoiceUtil.PERIOD_LAST_QUARTER,
      InvoiceUtil.PERIOD_LAST_YEAR,
      InvoiceUtil.PERIOD_ALL,
    ];
  }

  public static getInvoiceStatusOption(key: string): InvoiceStatus {
    if (key === InvoiceStatus.ALL) {
      return InvoiceStatus.ALL;
    }
    if (key === InvoiceStatus.PAID) {
      return InvoiceStatus.PAID;
    }
    if (key === InvoiceStatus.UNPAID) {
      return InvoiceStatus.UNPAID;
    }
    if (key === InvoiceStatus.AFTER_DATE_DUE) {
      return InvoiceStatus.AFTER_DATE_DUE;
    }
  }

  public static getInvoiceStatusOptions(): InvoiceOption[] {
    return [
      { key: InvoiceStatus.ALL, value: InvoiceStatus.ALL, text: "Všechny" },
      { key: InvoiceStatus.PAID, value: InvoiceStatus.PAID, text: "Zaplacené" },
      {
        key: InvoiceStatus.UNPAID,
        value: InvoiceStatus.UNPAID,
        text: "Nezaplacené",
      },
      {
        key: InvoiceStatus.AFTER_DATE_DUE,
        value: InvoiceStatus.AFTER_DATE_DUE,
        text: "Po splatnosti",
      },
    ];
  }

  public static getIsPaidMetadata(invoice : InvoiceDto, amountPaid : number) {
    let labelColor : SemanticCOLORS = "grey";
    let labelIcon : SemanticICONS = "money bill alternate";
    let labelText = "Nezaplaceno";

    // If not paid at all and after due date
    if (invoice.paid) {
      labelText = 'Zaplaceno';
      labelColor = "green";
      labelIcon = "money bill alternate";
    }
    // If not paid at all and after due date
    if (!invoice.paid && DateUtil.isBeforeToday(new Date(invoice.dateDue)) && amountPaid === 0) {
      labelText = 'Po splatnosti';
      labelColor = "red";
      labelIcon = "clock";
    }
    // If a part of the totalPriceWithVat was paid (but not all of it)
    else if (!invoice.paid && amountPaid !== 0 && amountPaid < invoice.totalPriceWithVat) {
      labelText = 'Částečně zaplaceno';
      labelColor = "yellow";
      labelIcon = "exclamation triangle";
    }
    // If more than totalPriceWithVat was paid for some reason
    else if (!invoice.paid && amountPaid !== 0 && amountPaid > invoice.totalPriceWithVat) {
      labelText = 'Přeplaceno'
      labelColor = "yellow";
      labelIcon = "exclamation triangle";
    }
    return {
      text: labelText,
      color: labelColor,
      icon: labelIcon
    }
  }

  public static getIsPaidPopup(invoice : InvoiceDto, amountPaid : number) {
    const metadata = InvoiceUtil.getIsPaidMetadata(invoice, amountPaid);
    return <Popup position={"left center"}
        trigger={
          <Table.Cell textAlign={"center"}>
            <Icon name={metadata.icon} color={metadata.color} />
          </Table.Cell>
        }
      >
      <Popup.Content>
        <span>{metadata.text}</span>
      </Popup.Content>
    </Popup>
  }

  public static getIsPaidLabel(invoice : InvoiceDto, amountPaid : number) {
    const metadata = InvoiceUtil.getIsPaidMetadata(invoice, amountPaid);
    return <Label color={metadata.color}>
      <Icon name={metadata.icon} />{metadata.text}
    </Label>
  }
}
