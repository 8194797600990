/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
import useSwr from "swr";
import type { Arguments, Key, SWRConfiguration } from "swr";
import useSWRMutation from "swr/mutation";
import type { SWRMutationConfiguration } from "swr/mutation";
import type {
  ArchiveProjectBody,
  ErrorResponseResponse,
  ProjectDto,
} from "../intranetApi.schemas";
import { IntranetClientFetchInstance } from "../../intranetClientFetchInstance";
import type { ErrorType } from "../../intranetClientFetchInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get projects
 */
export const getProjects = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<ProjectDto[]>(
    { url: `/project`, method: "GET" },
    options
  );
};

export const getGetProjectsKey = () => [`/project`] as const;

export type GetProjectsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjects>>
>;
export type GetProjectsQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get projects
 */
export const useGetProjects = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof getProjects>>, TError> & {
    swrKey?: Key;
    enabled?: boolean;
  };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetProjectsKey() : null));
  const swrFn = () => getProjects(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary create Project
 */
export const createProject = (
  projectDto: ProjectDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<ProjectDto>(
    {
      url: `/project`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: projectDto,
    },
    options
  );
};

export const getCreateProjectMutationFetcher = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, { arg }: { arg: ProjectDto }): Promise<ProjectDto> => {
    return createProject(arg, options);
  };
};
export const getCreateProjectMutationKey = () => `/project` as const;

export type CreateProjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof createProject>>
>;
export type CreateProjectMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary create Project
 */
export const useCreateProject = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createProject>>,
    TError,
    string,
    ProjectDto,
    Awaited<ReturnType<typeof createProject>>
  > & { swrKey?: string };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCreateProjectMutationKey();
  const swrFn = getCreateProjectMutationFetcher(requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary get project
 */
export const getProject = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<ProjectDto>(
    { url: `/project/${id}`, method: "GET" },
    options
  );
};

export const getGetProjectKey = (id: number) => [`/project/${id}`] as const;

export type GetProjectQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProject>>
>;
export type GetProjectQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get project
 */
export const useGetProject = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getProject>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetProjectKey(id) : null));
  const swrFn = () => getProject(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary delete project
 */
export const deleteProject = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<string>(
    { url: `/project/${id}`, method: "DELETE" },
    options
  );
};

export const getDeleteProjectMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, __: { arg: Arguments }): Promise<string> => {
    return deleteProject(id, options);
  };
};
export const getDeleteProjectMutationKey = (id: number) =>
  `/project/${id}` as const;

export type DeleteProjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteProject>>
>;
export type DeleteProjectMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary delete project
 */
export const useDeleteProject = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteProject>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof deleteProject>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getDeleteProjectMutationKey(id);
  const swrFn = getDeleteProjectMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary update Project
 */
export const updateProject = (
  id: number,
  projectDto: ProjectDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<ProjectDto>(
    {
      url: `/project/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: projectDto,
    },
    options
  );
};

export const getUpdateProjectMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, { arg }: { arg: ProjectDto }): Promise<ProjectDto> => {
    return updateProject(id, arg, options);
  };
};
export const getUpdateProjectMutationKey = (id: number) =>
  `/project/${id}` as const;

export type UpdateProjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateProject>>
>;
export type UpdateProjectMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary update Project
 */
export const useUpdateProject = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateProject>>,
      TError,
      string,
      ProjectDto,
      Awaited<ReturnType<typeof updateProject>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getUpdateProjectMutationKey(id);
  const swrFn = getUpdateProjectMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary archive Project
 */
export const archiveProject = (
  id: number,
  archiveProjectBody: ArchiveProjectBody,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<ProjectDto>(
    {
      url: `/project/${id}/archive`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: archiveProjectBody,
    },
    options
  );
};

export const getArchiveProjectMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: ArchiveProjectBody }
  ): Promise<ProjectDto> => {
    return archiveProject(id, arg, options);
  };
};
export const getArchiveProjectMutationKey = (id: number) =>
  `/project/${id}/archive` as const;

export type ArchiveProjectMutationResult = NonNullable<
  Awaited<ReturnType<typeof archiveProject>>
>;
export type ArchiveProjectMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary archive Project
 */
export const useArchiveProject = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof archiveProject>>,
      TError,
      string,
      ArchiveProjectBody,
      Awaited<ReturnType<typeof archiveProject>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getArchiveProjectMutationKey(id);
  const swrFn = getArchiveProjectMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
