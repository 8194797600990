/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Red Robot - Intranet API
 * OpenAPI spec version: 1.1.0
 */
import useSwr from "swr";
import type { Arguments, Key, SWRConfiguration } from "swr";
import useSWRMutation from "swr/mutation";
import type { SWRMutationConfiguration } from "swr/mutation";
import type {
  ErrorResponseResponse,
  GetInvoicesParams,
  GetNextInvoiceNumberParams,
  InvoiceDto,
  PairInvoiceBody,
  UnpairInvoiceBody,
} from "../intranetApi.schemas";
import { IntranetClientFetchInstance } from "../../intranetClientFetchInstance";
import type { ErrorType } from "../../intranetClientFetchInstance";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get invoices
 */
export const getInvoices = (
  params?: GetInvoicesParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<InvoiceDto[]>(
    { url: `/invoice`, method: "GET", params },
    options
  );
};

export const getGetInvoicesKey = (params?: GetInvoicesParams) =>
  [`/invoice`, ...(params ? [params] : [])] as const;

export type GetInvoicesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInvoices>>
>;
export type GetInvoicesQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get invoices
 */
export const useGetInvoices = <TError = ErrorType<ErrorResponseResponse>>(
  params?: GetInvoicesParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getInvoices>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetInvoicesKey(params) : null));
  const swrFn = () => getInvoices(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary create invoice
 */
export const createInvoice = (
  invoiceDto: InvoiceDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<InvoiceDto>(
    {
      url: `/invoice`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: invoiceDto,
    },
    options
  );
};

export const getCreateInvoiceMutationFetcher = (
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, { arg }: { arg: InvoiceDto }): Promise<InvoiceDto> => {
    return createInvoice(arg, options);
  };
};
export const getCreateInvoiceMutationKey = () => `/invoice` as const;

export type CreateInvoiceMutationResult = NonNullable<
  Awaited<ReturnType<typeof createInvoice>>
>;
export type CreateInvoiceMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary create invoice
 */
export const useCreateInvoice = <
  TError = ErrorType<ErrorResponseResponse>
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createInvoice>>,
    TError,
    string,
    InvoiceDto,
    Awaited<ReturnType<typeof createInvoice>>
  > & { swrKey?: string };
  request?: SecondParameter<typeof IntranetClientFetchInstance>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getCreateInvoiceMutationKey();
  const swrFn = getCreateInvoiceMutationFetcher(requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary get invoice by id
 */
export const getInvoice = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<InvoiceDto>(
    { url: `/invoice/${id}`, method: "GET" },
    options
  );
};

export const getGetInvoiceKey = (id: number) => [`/invoice/${id}`] as const;

export type GetInvoiceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInvoice>>
>;
export type GetInvoiceQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get invoice by id
 */
export const useGetInvoice = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getInvoice>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!id;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetInvoiceKey(id) : null));
  const swrFn = () => getInvoice(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary delete invoice
 */
export const deleteInvoice = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<string>(
    { url: `/invoice/${id}`, method: "DELETE" },
    options
  );
};

export const getDeleteInvoiceMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, __: { arg: Arguments }): Promise<string> => {
    return deleteInvoice(id, options);
  };
};
export const getDeleteInvoiceMutationKey = (id: number) =>
  `/invoice/${id}` as const;

export type DeleteInvoiceMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteInvoice>>
>;
export type DeleteInvoiceMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary delete invoice
 */
export const useDeleteInvoice = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteInvoice>>,
      TError,
      string,
      Arguments,
      Awaited<ReturnType<typeof deleteInvoice>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getDeleteInvoiceMutationKey(id);
  const swrFn = getDeleteInvoiceMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary update invoice
 */
export const updateInvoice = (
  id: number,
  invoiceDto: InvoiceDto,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<InvoiceDto>(
    {
      url: `/invoice/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: invoiceDto,
    },
    options
  );
};

export const getUpdateInvoiceMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (_: string, { arg }: { arg: InvoiceDto }): Promise<InvoiceDto> => {
    return updateInvoice(id, arg, options);
  };
};
export const getUpdateInvoiceMutationKey = (id: number) =>
  `/invoice/${id}` as const;

export type UpdateInvoiceMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateInvoice>>
>;
export type UpdateInvoiceMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary update invoice
 */
export const useUpdateInvoice = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateInvoice>>,
      TError,
      string,
      InvoiceDto,
      Awaited<ReturnType<typeof updateInvoice>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getUpdateInvoiceMutationKey(id);
  const swrFn = getUpdateInvoiceMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary pair invoice
 */
export const pairInvoice = (
  id: number,
  pairInvoiceBody: PairInvoiceBody,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<InvoiceDto>(
    {
      url: `/invoice/${id}/pair`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: pairInvoiceBody,
    },
    options
  );
};

export const getPairInvoiceMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: PairInvoiceBody }
  ): Promise<InvoiceDto> => {
    return pairInvoice(id, arg, options);
  };
};
export const getPairInvoiceMutationKey = (id: number) =>
  `/invoice/${id}/pair` as const;

export type PairInvoiceMutationResult = NonNullable<
  Awaited<ReturnType<typeof pairInvoice>>
>;
export type PairInvoiceMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary pair invoice
 */
export const usePairInvoice = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof pairInvoice>>,
      TError,
      string,
      PairInvoiceBody,
      Awaited<ReturnType<typeof pairInvoice>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getPairInvoiceMutationKey(id);
  const swrFn = getPairInvoiceMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary unpair invoice
 */
export const unpairInvoice = (
  id: number,
  unpairInvoiceBody: UnpairInvoiceBody,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<InvoiceDto>(
    {
      url: `/invoice/${id}/unpair`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: unpairInvoiceBody,
    },
    options
  );
};

export const getUnpairInvoiceMutationFetcher = (
  id: number,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return (
    _: string,
    { arg }: { arg: UnpairInvoiceBody }
  ): Promise<InvoiceDto> => {
    return unpairInvoice(id, arg, options);
  };
};
export const getUnpairInvoiceMutationKey = (id: number) =>
  `/invoice/${id}/unpair` as const;

export type UnpairInvoiceMutationResult = NonNullable<
  Awaited<ReturnType<typeof unpairInvoice>>
>;
export type UnpairInvoiceMutationError = ErrorType<ErrorResponseResponse>;

/**
 * @summary unpair invoice
 */
export const useUnpairInvoice = <TError = ErrorType<ErrorResponseResponse>>(
  id: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof unpairInvoice>>,
      TError,
      string,
      UnpairInvoiceBody,
      Awaited<ReturnType<typeof unpairInvoice>>
    > & { swrKey?: string };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const swrKey = swrOptions?.swrKey ?? getUnpairInvoiceMutationKey(id);
  const swrFn = getUnpairInvoiceMutationFetcher(id, requestOptions);

  const query = useSWRMutation(swrKey, swrFn, swrOptions);

  return {
    swrKey,
    ...query,
  };
};
/**
 * @summary get next invoice number
 */
export const getNextInvoiceNumber = (
  params: GetNextInvoiceNumberParams,
  options?: SecondParameter<typeof IntranetClientFetchInstance>
) => {
  return IntranetClientFetchInstance<string>(
    { url: `/invoice/nextInvoiceNumber`, method: "GET", params },
    options
  );
};

export const getGetNextInvoiceNumberKey = (
  params: GetNextInvoiceNumberParams
) => [`/invoice/nextInvoiceNumber`, ...(params ? [params] : [])] as const;

export type GetNextInvoiceNumberQueryResult = NonNullable<
  Awaited<ReturnType<typeof getNextInvoiceNumber>>
>;
export type GetNextInvoiceNumberQueryError = ErrorType<ErrorResponseResponse>;

/**
 * @summary get next invoice number
 */
export const useGetNextInvoiceNumber = <
  TError = ErrorType<ErrorResponseResponse>
>(
  params: GetNextInvoiceNumberParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getNextInvoiceNumber>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof IntranetClientFetchInstance>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetNextInvoiceNumberKey(params) : null));
  const swrFn = () => getNextInvoiceNumber(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};
